import { Modal, TouchableWithoutFeedback, View } from 'react-native'
import { useOverlayProvider } from '../../context/OverlayProvider'
import { memo, useRef } from 'react'
import uuid from 'react-native-uuid'

const containerId = uuid.v4() as string

const ModalOverlay = memo(({ children, visible }: { children: React.ReactNode; visible: boolean }) => {
    const { closeShowOverlay } = useOverlayProvider()
    const isCloseModal = useRef(true)

    return (
        <Modal style={{ display: visible ? 'flex' : 'none' }} transparent visible={visible}>
            <TouchableWithoutFeedback onPress={(e: any) => (e.target.id === containerId ? closeShowOverlay() : null)}>
                <View
                    nativeID={containerId}
                    style={{ flexGrow: 1, zIndex: 5 }}
                    onTouchStart={() => {
                        // { evento 2 }
                        if (isCloseModal.current) closeShowOverlay()
                        isCloseModal.current = true
                    }}
                >
                    <View
                        onTouchStart={() => {
                            // Si a este view le hacen touch este evento se ejecuta primero que el del paadre
                            // { evento 1 }
                            isCloseModal.current = false
                        }}
                    >
                        {children}
                    </View>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    )
})

export default ModalOverlay
