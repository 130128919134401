import { View } from 'react-native'
import { useContext, useEffect, useState } from 'react'
import { CartContext } from '../../../context/cart'
import ButtonNative from '../ButtonNative'
import useCheckoutSubmit from './useCheckoutSubmit'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { PlanContext } from '../../../context/plan'
import { getStyleSheet } from '../../../assets/theme/styles'
import { CardField, StripeProvider, createPaymentMethod, useConfirmPayment } from '@stripe/stripe-react-native'
import { useUserProvider } from '../../../context/UserProvider'
import { useStripeProvider } from '../../../context/StripeProvider'
import { useNavigation } from '@react-navigation/native'

interface ICheckoutFormMobile {
    setError: (error: string) => void
    clearComponents: () => void
    setShowSuccessPay: (value: boolean) => void
    handleUpdateSeats: () => Promise<void>
    subscriptionId: string | undefined
    isUpdatePaymentMethod: boolean
    cardNumberPlaceholder: string
    isUpdateOnlySeats: boolean
    cvcPlaceholder: string
    nameCheckout: string
    inputSeat: string
    totalPrice: number
}

const USDollar = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
})

export default function CheckoutFormMobile({
    setError,
    setShowSuccessPay,
    clearComponents,
    handleUpdateSeats,
    subscriptionId,
    isUpdatePaymentMethod,
    inputSeat,
    isUpdateOnlySeats,
    cardNumberPlaceholder,
    cvcPlaceholder,
    nameCheckout,
    totalPrice,
}: ICheckoutFormMobile) {
    const navigation = useNavigation()
    const { cart, priceId, total, clearCart } = useContext(CartContext)
    const [isLoadingPaymentUpdate, setIsLoadingPaymentUpdate] = useState(false)
    const { dbUserData } = useUserProvider()
    const { checkout } = useContext(PlanContext)
    const { userEmail, userSub } = useContext(ThemeContext)
    const { updateSubscriptionPaymentMethodFn } = useStripeProvider()
    const { confirmPayment, loading } = useConfirmPayment()
    const { orderDetails, organization, setOrderDetails, cancelOldSubscriptions, saveChangesOrganization } =
        useCheckoutSubmit()
    const payButtonTitle = isUpdatePaymentMethod ? 'UPDATE' : `PAY ${USDollar.format(totalPrice)}`
    const styles = getStyleSheet()

    useEffect(() => {
        if (orderDetails.priceId) CheckoutPlan()
    }, [orderDetails])

    const handleSubmit = () => {
        if (isUpdateOnlySeats) return handleUpdateSeats()
        if (isUpdatePaymentMethod) return handlePaymentUpdate()
        return handleCheckout()
    }

    const handlePaymentUpdate = async () => {
        setIsLoadingPaymentUpdate(true)

        const { error, paymentMethod } = await createPaymentMethod({
            paymentMethodType: 'Card',
            paymentMethodData: { billingDetails: { name: nameCheckout } },
        })

        if (error) {
            setIsLoadingPaymentUpdate(false)
            setError(error.message)
        } else {
            try {
                if (dbUserData?.customerIdStripe && subscriptionId) {
                    const response: any = await updateSubscriptionPaymentMethodFn(
                        dbUserData.customerIdStripe,
                        subscriptionId,
                        paymentMethod.id
                    )
                    if (response.statusCode === 200) {
                        //@ts-ignore
                        navigation.navigate('SuccessChanges', { to: 'Billing' } as never)
                    }
                    setIsLoadingPaymentUpdate(false)
                }
            } catch (err: any) {
                setError(err.message)
                setIsLoadingPaymentUpdate(false)
            }
        }
    }

    const handleCheckout = () => {
        setError('')

        if (!nameCheckout) return setError('Name required.' as any)

        setOrderDetails({
            ...orderDetails,
            cart: cart,
            name: nameCheckout,
            email: userEmail,
            sub: userSub,
            total: total,
            priceId: priceId,
            seatsAmount: +inputSeat,
        })
    }

    const CheckoutPlan = async () => {
        checkout(orderDetails).then(async response => {
            const { error } = await confirmPayment(response?.clientSecret, {
                paymentMethodType: 'Card',
                paymentMethodData: {
                    billingDetails: {
                        name: nameCheckout,
                    },
                },
            })

            if (error?.message) return setError(error?.message)

            cancelOldSubscriptions().then(() => {
                // Cancel old subscriptions
                saveChangesOrganization(organization)
                    .then(responseOrganization => {
                        // Successful subscription payment
                        if (responseOrganization) {
                            clearCart()
                            setShowSuccessPay(true)
                            clearComponents()
                        } else {
                            setError('There was an error while executing the action. Plan not updated')
                        }
                    })
                    .catch(() => {
                        setError('There was an error while executing the action. Plan not updated')
                    })
            })
        })
    }

    return (
        <StripeProvider publishableKey="pk_test_51M6wrzLr1sVvxmlPCZFFc0hqIi20Y1NA1vr7AhFvYGGGuLdhEWrTLQ3NEiXTCTimbNCiBgN1W7JE3jvmKPmLdZTe000UNEDEd8">
            <View style={{ gap: 20 }}>
                <View pointerEvents={isUpdateOnlySeats ? 'none' : 'auto'}>
                    <CardField
                        placeholders={{ cvc: cvcPlaceholder, number: cardNumberPlaceholder }}
                        postalCodeEnabled={false}
                        style={{ width: '100%', height: 50 }}
                        cardStyle={{
                            borderRadius: 5,
                            backgroundColor: styles.backgroundComponent,
                            placeholderColor: styles.text,
                            textColor: styles.text,
                        }}
                    />
                </View>

                <ButtonNative
                    title={payButtonTitle}
                    primaryTheme
                    titleStyle={{ fontSize: 16, fontWeight: '600' }}
                    buttonStyle={{ height: 50 }}
                    onPress={handleSubmit}
                    loading={loading || isLoadingPaymentUpdate}
                />
            </View>
        </StripeProvider>
    )
}
