import { useEffect, memo, ReactNode, useRef, useState } from 'react'
import { Text, View, StyleSheet, Pressable, Dimensions, StyleProp, ViewStyle } from 'react-native'
import { Col, Row, Grid } from 'react-native-easy-grid'
import ButtonNative from '../ButtonNative'
import { getStyleSheet } from '../../../assets/theme/styles'
import useDatePicker from './useDatePicker'
import { DATE_RANGE } from '../../../screens/Reports'
import useDimensions from '../../../hooks/useDimensions'
import { faChevronLeft, faChevronRight, faCalendar } from '@fortawesome/free-solid-svg-icons'
import { useOverlayProvider } from '../../../context/OverlayProvider'
import ModalOverlay from '../ModalOverlay'

interface IDatePicker {
    value?: Date
    multipleValues?: Date[]
    disabled?: boolean
    isSelectedByRange?: boolean
    buttonLabel?: string
    customStyles?: StyleProp<ViewStyle>
    children?: ReactNode
    identificator: string
    onChange?: (date: Date) => void
    onPress?: () => void
    onChangeMultiple?: (date: Date[]) => void
    setDateRange?: React.Dispatch<React.SetStateAction<DATE_RANGE>>
}

enum ViewTypeEnum {
    days = 1,
    months,
    years,
}

const DatePicker = memo(
    ({
        value,
        disabled,
        isSelectedByRange,
        children,
        multipleValues,
        buttonLabel,
        customStyles,
        identificator,
        onChangeMultiple,
        onChange,
        onPress = () => {},
        setDateRange,
    }: IDatePicker) => {
        const { closeShowOverlay, handleShowOverlay, activeId, isShowOverlay, handleSetActiveId } = useOverlayProvider()
        const isShowDatePicker = identificator === activeId && isShowOverlay

        const {
            getLabel,
            handleOnSelectedDate,
            handleSetHeaderName,
            isFutureTask,
            isSelected,
            isToday,
            nextNavigation,
            onClickHeaderName,
            onSelectDate,
            previusNavigation,
            setTime,
            isDateInRange,
            isNotCurrentMonth,
            isRangeOfAMonth,
            getLabelButton,
            currentView,
            date,
            daysOfWeek,
            headerName,
            months,
            selectedDate,
            weeks,
            years,
        } = useDatePicker({
            value,
            multipleValues,
            isSelectedByRange,
            closeDatePicker: closeShowOverlay,
            onChange,
            onChangeMultiple,
            setDateRange,
        })

        const { windowWidth, windowHeight } = useDimensions()
        const styles = getStyleSheet()

        const overlayPanelRef = useRef<View>(null)
        const buttonRef = useRef<View>(null)
        const [isRenderedButton, setIsRenderedButton] = useState(false)
        const [isRenderedOverlay, setIsRenderedOverlay] = useState(false)
        const [overlayLayout, setOverlayLayout] = useState({ width: 0, height: 0 })
        const [overlayFinalDimension, setOverlayFinalDimension] = useState({ pageX: 0, pageY: 0 })

        useEffect(() => {
            if (activeId === identificator && !isShowOverlay) {
                // Eliminando el active id del overlayProvider cuando este se cierra
                handleSetActiveId(null)
            }
        }, [isShowOverlay, activeId])

        useEffect(() => {
            const isLoadedDimensions = overlayLayout.width && overlayLayout.height

            if (isRenderedButton && isLoadedDimensions && isShowDatePicker) {
                buttonRef.current?.measureInWindow((pageX, pageY, width, height) => {
                    const spaceClearence = 5 // Holgura de espacio para que no quede pegado al botón

                    // Calculando si el overlay se mostrara por debajo o encima del botón
                    const positionEjeY =
                        pageY + overlayLayout.height + height + spaceClearence > windowHeight
                            ? pageY - overlayLayout.height
                            : pageY + height + spaceClearence

                    const positionEjeX =
                        pageX + overlayLayout.width > windowWidth ? pageX + width - overlayLayout.width : pageX

                    setOverlayFinalDimension({ pageX: positionEjeX, pageY: positionEjeY })
                })
            }
        }, [activeId, overlayLayout, isRenderedButton])

        useEffect(() => {
            if (isRenderedOverlay) {
                // Guardando el ancho y el alto de la lista de elementos cuando este se renderiza
                overlayPanelRef.current?.measureInWindow((_pageX, _pageY, width, height) => {
                    setOverlayLayout({ height, width })
                })
            }
        }, [isRenderedOverlay])

        useEffect(() => {
            setTime()
            handleSetHeaderName()
        }, [currentView, date])

        return (
            <View style={customStyles}>
                <View ref={buttonRef} onLayout={() => setIsRenderedButton(true)}>
                    <ButtonNative
                        disabled={disabled}
                        showIcon
                        icon={faCalendar}
                        onPress={() => {
                            onPress()
                            handleShowOverlay(identificator)
                        }}
                        title={isSelectedByRange ? getLabelButton(buttonLabel || '') : ''}
                        titleStyle={isSelectedByRange ? { color: styles.text, fontWeight: '400' } : null}
                        buttonStyle={
                            isSelectedByRange
                                ? [
                                      localStyles.buttonWhenModeRange,
                                      {
                                          backgroundColor: styles.filterBackground,
                                          borderColor: styles.filterBorderColor,
                                      },
                                  ]
                                : null
                        }
                    />
                </View>

                <ModalOverlay visible={isShowDatePicker}>
                    <View
                        ref={overlayPanelRef}
                        onLayout={() => setIsRenderedOverlay(true)}
                        style={{
                            position: 'absolute',
                            opacity: overlayFinalDimension.pageX && overlayFinalDimension.pageY ? 1 : 0,
                            flexDirection: windowWidth <= 540 ? 'column' : 'row',
                            left: overlayFinalDimension.pageX,
                            top: overlayFinalDimension.pageY,
                        }}
                    >
                        {isSelectedByRange && children ? children : null}

                        <View
                            style={[
                                { backgroundColor: styles.backgroundColor, width: windowWidth > 600 ? 300 : 220 },
                                localStyles.subContainer,
                            ]}
                        >
                            <View style={{ flexDirection: 'row', justifyContent: 'center' }}>
                                <ButtonNative
                                    buttonStyle={{ width: 30 }}
                                    onPress={previusNavigation}
                                    title=""
                                    showIcon
                                    icon={faChevronLeft}
                                />

                                <ButtonNative
                                    containerStyle={{ flex: 1 }}
                                    titleStyle={{ color: styles.text, fontWeight: '700', fontSize: 18 }}
                                    onPress={onClickHeaderName}
                                    title={headerName}
                                />

                                <ButtonNative
                                    containerStyle={{ alignItems: 'flex-end' }}
                                    buttonStyle={{ width: 30 }}
                                    onPress={nextNavigation}
                                    title=""
                                    showIcon
                                    icon={faChevronRight}
                                />
                            </View>

                            <View style={{ backgroundColor: styles.text, height: 2, marginBottom: 7 }} />

                            <Grid style={{ gap: 10 }}>
                                {currentView === ViewTypeEnum.days ? (
                                    <Row>
                                        {daysOfWeek.map((dayOfWeek, index) => (
                                            <Col key={index}>
                                                <Text
                                                    style={[
                                                        { textAlign: 'center', color: styles.text },
                                                        localStyles.daysOfWeek,
                                                    ]}
                                                >
                                                    {dayOfWeek.text}
                                                </Text>
                                            </Col>
                                        ))}
                                    </Row>
                                ) : (
                                    <Row>
                                        <Col></Col>
                                        <Col></Col>
                                        <Col></Col>
                                        <Col></Col>
                                    </Row>
                                )}

                                {currentView === ViewTypeEnum.days ? (
                                    <>
                                        {weeks.map(currentWeek => (
                                            <Row>
                                                {currentWeek.map(day => (
                                                    <Col>
                                                        <Pressable
                                                            disabled={
                                                                (!isFutureTask(day) && !isSelectedByRange) ||
                                                                !isRangeOfAMonth(day)
                                                            }
                                                            onPress={() => handleOnSelectedDate(day)}
                                                        >
                                                            <Text
                                                                style={[
                                                                    { textAlign: 'center', color: styles.text },
                                                                    isToday(day) ? localStyles.today : null,
                                                                    isSelected(day) && !isSelectedByRange
                                                                        ? localStyles.selected
                                                                        : null,
                                                                    isDateInRange(day) ? localStyles.selected : null,
                                                                    !isRangeOfAMonth(day) ? localStyles.disabled : null,
                                                                    isNotCurrentMonth(day) && !isSelectedByRange
                                                                        ? localStyles.disabled
                                                                        : null,
                                                                    !isFutureTask(day) && !isSelectedByRange
                                                                        ? localStyles.disabled
                                                                        : null,
                                                                ]}
                                                            >
                                                                {getLabel(day)}
                                                            </Text>
                                                        </Pressable>
                                                    </Col>
                                                ))}
                                            </Row>
                                        ))}
                                    </>
                                ) : null}

                                {currentView === ViewTypeEnum.months ? (
                                    <>
                                        {months.map(currentMonth => (
                                            <Row>
                                                {currentMonth.map(month => (
                                                    <Col>
                                                        <Pressable onPress={() => onSelectDate(month)}>
                                                            <Text
                                                                style={[
                                                                    { textAlign: 'center', color: styles.text },
                                                                    isToday(month) ? localStyles.today : null,
                                                                    selectedDate && isSelected(month)
                                                                        ? localStyles.selected
                                                                        : null,
                                                                ]}
                                                            >
                                                                {getLabel(month)}
                                                            </Text>
                                                        </Pressable>
                                                    </Col>
                                                ))}
                                            </Row>
                                        ))}
                                    </>
                                ) : null}

                                {currentView === ViewTypeEnum.years ? (
                                    <>
                                        {years.map(currentYear => (
                                            <Row>
                                                {currentYear.map(year => (
                                                    <Col>
                                                        <Pressable onPress={() => onSelectDate(year)}>
                                                            <Text
                                                                style={[
                                                                    { textAlign: 'center', color: styles.text },
                                                                    isToday(year) ? localStyles.today : null,
                                                                    selectedDate && isSelected(year)
                                                                        ? localStyles.selected
                                                                        : null,
                                                                ]}
                                                            >
                                                                {getLabel(year)}
                                                            </Text>
                                                        </Pressable>
                                                    </Col>
                                                ))}
                                            </Row>
                                        ))}
                                    </>
                                ) : null}
                            </Grid>
                        </View>
                    </View>
                </ModalOverlay>
            </View>
        )
    }
)

const localStyles = StyleSheet.create({
    subContainer: {
        borderRadius: 5,
        paddingVertical: 10,
        paddingHorizontal: 5,
        shadowColor: '#585454',
        shadowOffset: { width: 0, height: 0 },
        shadowOpacity: 10,
        shadowRadius: 10,
        gap: 10,
    },
    today: {
        color: 'black',
        fontWeight: 'bold',
        backgroundColor: '#00000008',
        borderRadius: 8,
    },
    selected: {
        color: '#FF3162',
        fontWeight: 'bold',
        backgroundColor: '#ff316217',
        borderRadius: 8,
    },
    daysOfWeek: {
        fontSize: 14,
        fontWeight: 'bold',
    },
    actions: {
        padding: 10,
    },
    sectionRight: {
        borderTopRightRadius: 20,
    },
    sectionLeft: {
        borderTopLeftRadius: 20,
    },
    monthName: {
        color: '#000000',
        fontWeight: 'bold',
        fontSize: 12,
        backgroundColor: '#F3F3F3',
        textTransform: 'uppercase',
    },
    navigation: {
        backgroundColor: '#E6E6E6',
    },
    datePickerContainer: {
        borderWidth: 2,
        borderColor: '#F3F3F3',
        borderRadius: 20,
    },
    grid: {
        margin: -5,
    },
    disabled: {
        opacity: 0.5,
    },
    buttonWhenModeRange: {
        gap: 15,
        height: 41,
        paddingHorizontal: 10,
        paddingVertical: 10,
        flexDirection: 'row-reverse',
        borderWidth: 1,
    },
})

export default DatePicker
