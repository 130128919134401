import React, { useEffect, useState } from 'react'
import { View, StyleSheet, Text, Image } from 'react-native'
import { useNavigation, useRoute } from '@react-navigation/native'
import { getStyleSheet } from '../assets/theme/styles'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import ButtonNative from '../components/common/ButtonNative'

const iconTimers = require('../assets/images/timerz_orange_logo.png')

const SuccessChanges = () => {
    const current = 'Success'
    const styles = getStyleSheet()
    const navigation = useNavigation()
    const routeNavigation = useRoute()
    const [navegateTo, setNavegateTo] = useState<string>('Timer')

    useEffect(() => {
        const params: null | { to: string } = routeNavigation.params as any
        if (params?.to) setNavegateTo(params?.to)
    }, [])

    return (
        <View style={[localStyles.container, styles.styleBackground.container]}>
            <Sidebar children={{ active: current }} />

            <View style={localStyles.subcontainer}>
                <TopBar children={{ active: current, initial: 0 }} />

                <View style={[styles.styleBackground.container, { height: '100%' }]}>
                    <View style={[styles.baseStyleContent.container, localStyles.workArea]}>
                        <View style={[localStyles.areaMessage]}>
                            <Image resizeMode="contain" source={iconTimers} style={localStyles.imageLogo} />
                            <Text style={{ color: styles.text, marginVertical: 10, fontSize: 18 }}>Success!</Text>
                            <View
                                style={{
                                    height: 1,
                                    width: '100%',
                                    backgroundColor: styles.text,
                                    marginVertical: 10,
                                }}
                            />
                            <Text style={{ marginTop: 10, marginBottom: 20, color: styles.text, fontSize: 16 }}>
                                Changes have been saved
                            </Text>
                            <ButtonNative
                                title="Done"
                                primaryTheme
                                onPress={() => navigation.navigate(navegateTo as never)}
                            />
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
    areaMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        margin: 'auto',
    },
    imageLogo: {
        width: 250,
        height: 250,
    },
})

export default SuccessChanges
