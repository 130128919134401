import { generateClient } from 'aws-amplify/api'
import { uniqBy } from 'lodash'
import { useState, createContext, useEffect, useContext } from 'react'
import { Role, StatusProject, StatusTask, TypeTask } from '../API'
import { listProjects } from '../graphql/queries'
import { IProjectProvider } from '../interfaces/project-provider.interface'
import { useUserProvider } from './UserProvider'
import { IUserOrganization } from '../interfaces/user-organization.interface'
import { TaskService } from '../services/TaskService'
import { useTaskProvider } from './TaskProvider'

const ProjectContext = createContext<IProjectProvider>(null as any)

function ProjectProvider({ children }) {
    const { localUserData } = useUserProvider()
    const [activeProjects, setActiveProjects] = useState<any[]>([])
    const { updateSingleTask } = useTaskProvider()

    const getProjectsDropDown = async (userSub: string, organization: IUserOrganization) => {
        let list: any[] = []
        let items: any[] = []

        if (activeProjects.length > 0) {
            items = activeProjects
        } else {
            items = await getProjectsActive(userSub, organization)
        }

        for (const item of items) {
            const itemFound = list.find(i => i.clientId === item.clientID)
            const project = { projectID: item.id, projectName: item.name, projectColor: item.groupcolor }
            if (itemFound) {
                itemFound.projects.push(project)
            } else {
                list.push({
                    clientId: item?.client?.id || item?.clientID,
                    clientName: item?.client?.name || item?.clientName,
                    projects: [project],
                })
            }
        }

        list = list.sort((a, b) => {
            if (a.clientName < b.clientName) {
                return -1
            }
            if (a.clientName > b.clientName) {
                return 1
            }
            return 0
        })

        return list
    }

    const getProjectsActive = async (userSub: string, organization: IUserOrganization) => {
        const response: any = await generateClient().graphql({
            query: listProjects,
            variables: { filter: { organizationID: { eq: organization.organizationID } } },
        })

        const list = response?.data?.listProjects?.items.map((item: any) => {
            return {
                ...item,
                pomodoro: 0,
                taskTotal: 0,
                // pomodoro: item.Tasks.items.filter(e => e.type === TypeTask.FOCUS && e.status === StatusTask.COMPLETED)
                //     .length,
                // taskTotal: uniqBy(item.Tasks.items, 'name').length,
                clientID: item.clientID || '- No client -',
                clientName: item.client?.name || '- No client -',
            }
        })

        const listActive = list.filter(f => {
            return f._deleted !== true && f.status === StatusProject.ACTIVE
        })

        if (organization.role === Role.USER) {
            let filterList = listActive.map(element => {
                return {
                    ...element,
                    userAccess: element.group?.UsersGroup?.items.filter(t => !t._deleted && t.userID === userSub),
                }
            })

            filterList = filterList.filter(element => element.userAccess !== undefined && element.userAccess.length > 0)

            return filterList
        }

        setActiveProjects(listActive)

        return listActive
    }

    const onChangeProject = async (selected, row) => {
        const arrayIds = row.id.split(',')

        for (const id of arrayIds) {
            const taskResult = await TaskService().getTaskById(id)

            if (taskResult && taskResult.id) {
                const task = {
                    id: taskResult.id,
                    name: taskResult.name,
                    organizationID: taskResult.organizationID,
                    projectID: selected.projectID,
                    status: taskResult.status,
                    time: taskResult.time,
                    type: taskResult.type,
                    usersub: taskResult.usersub,
                    _version: taskResult._version,
                }

                await updateSingleTask(task)
            }
        }
    }

    return (
        <ProjectContext.Provider
            value={{
                getProjectsDropDown,
                getProjectsActive,
                onChangeProject,
            }}
        >
            {children}
        </ProjectContext.Provider>
    )
}

function useProjectProvider() {
    const context = useContext(ProjectContext)
    if (context === undefined) {
        throw new Error('useTaskProvider must be used within a ProjectProvider')
    }
    return context
}

export { ProjectProvider, useProjectProvider }
