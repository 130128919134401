import { useContext, useState } from 'react'
import SubscriptionService, { ISuscription } from '../../../services/SubscriptionService'
import { OrganizationService } from '../../../services/OrganizationService'
import { UpgradeContext } from '../../../context/upgrade'
import { useUserProvider } from '../../../context/UserProvider'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'

interface IOrderDetails {
    cart: any[]
    total: number
    name: string | null
    email: string | null
    sub: any
    token: string
    priceId: any
    seatsAmount: number
}

export default function useCheckoutSubmit() {
    const [subscription, setSubscription] = useState<ISuscription | null | undefined>(null)
    const { setMaxUsersNumber, setMaxProjectsNumber, setMaxClientsNumber, setMaxGroupsNumber } =
        useContext<any>(UpgradeContext)
    const { setUpdatePlanIdOrganization } = useUserProvider()
    const { setSubscribedSeats, userSub } = useContext(ThemeContext)
    const [organization, setOrganization] = useState<any>(null)

    const [orderDetails, setOrderDetails] = useState<IOrderDetails>({
        cart: [],
        total: 0,
        name: null,
        email: null,
        sub: null,
        token: '',
        priceId: null,
        seatsAmount: 1,
    })

    const cancelOldSubscriptions = async () => {
        if (subscription?.id) {
            SubscriptionService()
                .cancelSubscription(subscription.id)
                .then(response => {})
                .catch(error => {
                    console.error('Error', error)
                })
        }
    }

    const getOrganizationDefault = () => {
        OrganizationService()
            .getDefaultOrganizationName(userSub)
            .then(organizationUser => {
                setOrganization(organizationUser?.organization)
            })
            .catch(() => {
                return { error: { message: 'There was an error while executing the organization action.' } }
            })
    }

    const saveChangesOrganization = async (organization: any) => {
        const updateObject = {
            id: organization.id,
            planID: orderDetails?.cart[0]?.id,
            subscribedSeats: orderDetails?.seatsAmount,
            _version: organization._version,
        }

        const response = await OrganizationService().updateOrganizationById(updateObject)

        if (response) {
            setUpdatePlanIdOrganization(response?.planID)
            setMaxUsersNumber(response?.plan?.max_users)
            setMaxProjectsNumber(response?.plan?.max_projects)
            setMaxClientsNumber(response?.plan?.max_clients)
            setMaxGroupsNumber(response?.plan?.max_groups)
            setSubscribedSeats(response?.subscribedSeats)
            return response !== null
        }

        return response === null
    }

    return {
        subscription,
        orderDetails,
        organization,
        setOrganization,
        setSubscription,
        setOrderDetails,
        cancelOldSubscriptions,
        saveChangesOrganization,
        getOrganizationDefault,
    }
}
