/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getOrder = /* GraphQL */ `query GetOrder($id: ID!) {
  getOrder(id: $id) {
    id
    usersub
    date
    total
    subscriptionId
    createdAt
    updatedAt
    plans {
      items {
        id
        orderID
        planID
        order {
          id
          usersub
          date
          total
          subscriptionId
          createdAt
          updatedAt
          plans {
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetOrderQueryVariables, APITypes.GetOrderQuery>;
export const listOrders = /* GraphQL */ `query ListOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      usersub
      date
      total
      subscriptionId
      createdAt
      updatedAt
      plans {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrdersQueryVariables,
  APITypes.ListOrdersQuery
>;
export const syncOrders = /* GraphQL */ `query SyncOrders(
  $filter: ModelOrderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      usersub
      date
      total
      subscriptionId
      createdAt
      updatedAt
      plans {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrdersQueryVariables,
  APITypes.SyncOrdersQuery
>;
export const getInvitation = /* GraphQL */ `query GetInvitation($id: ID!) {
  getInvitation(id: $id) {
    id
    organizationID
    organization {
      id
      name
      planID
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      pomodoroTime
      shortBreakTime
      longBreakTime
      automaticStartTime
      editionTimeFrame
      created_by
      subscribedSeats
      OrganizationUser {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tags {
        items {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    email
    invitedOn
    invitedBy
    user {
      id
      givenName
      familyName
      name
      email
      timeZone
      dateFormat
      timeFormat
      image
      themeMode
      accepTerms
      newUser
      customerIdStripe
      disabled
      OrganizationUsers {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    role
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetInvitationQueryVariables,
  APITypes.GetInvitationQuery
>;
export const listInvitations = /* GraphQL */ `query ListInvitations(
  $filter: ModelInvitationFilterInput
  $limit: Int
  $nextToken: String
) {
  listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationID
      organization {
        id
        name
        planID
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        pomodoroTime
        shortBreakTime
        longBreakTime
        automaticStartTime
        editionTimeFrame
        created_by
        subscribedSeats
        OrganizationUser {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Clients {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tags {
          items {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      email
      invitedOn
      invitedBy
      user {
        id
        givenName
        familyName
        name
        email
        timeZone
        dateFormat
        timeFormat
        image
        themeMode
        accepTerms
        newUser
        customerIdStripe
        disabled
        OrganizationUsers {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      role
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListInvitationsQueryVariables,
  APITypes.ListInvitationsQuery
>;
export const syncInvitations = /* GraphQL */ `query SyncInvitations(
  $filter: ModelInvitationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncInvitations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      organizationID
      organization {
        id
        name
        planID
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        pomodoroTime
        shortBreakTime
        longBreakTime
        automaticStartTime
        editionTimeFrame
        created_by
        subscribedSeats
        OrganizationUser {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Clients {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tags {
          items {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      email
      invitedOn
      invitedBy
      user {
        id
        givenName
        familyName
        name
        email
        timeZone
        dateFormat
        timeFormat
        image
        themeMode
        accepTerms
        newUser
        customerIdStripe
        disabled
        OrganizationUsers {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      role
      status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncInvitationsQueryVariables,
  APITypes.SyncInvitationsQuery
>;
export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    givenName
    familyName
    name
    email
    timeZone
    dateFormat
    timeFormat
    image
    themeMode
    accepTerms
    newUser
    customerIdStripe
    disabled
    OrganizationUsers {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        usersub
        role
        is_default
        status
        createdAt
        updatedAt
        userID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Invitations {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        invitedOn
        invitedBy
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        userID
        groupID
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      givenName
      familyName
      name
      email
      timeZone
      dateFormat
      timeFormat
      image
      themeMode
      accepTerms
      newUser
      customerIdStripe
      disabled
      OrganizationUsers {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      givenName
      familyName
      name
      email
      timeZone
      dateFormat
      timeFormat
      image
      themeMode
      accepTerms
      newUser
      customerIdStripe
      disabled
      OrganizationUsers {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getOrganizationUser = /* GraphQL */ `query GetOrganizationUser($id: ID!) {
  getOrganizationUser(id: $id) {
    id
    organizationID
    organization {
      id
      name
      planID
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      pomodoroTime
      shortBreakTime
      longBreakTime
      automaticStartTime
      editionTimeFrame
      created_by
      subscribedSeats
      OrganizationUser {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tags {
        items {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    email
    usersub
    role
    is_default
    status
    createdAt
    updatedAt
    userID
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationUserQueryVariables,
  APITypes.GetOrganizationUserQuery
>;
export const listOrganizationUsers = /* GraphQL */ `query ListOrganizationUsers(
  $filter: ModelOrganizationUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizationUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      organizationID
      organization {
        id
        name
        planID
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        pomodoroTime
        shortBreakTime
        longBreakTime
        automaticStartTime
        editionTimeFrame
        created_by
        subscribedSeats
        OrganizationUser {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Clients {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tags {
          items {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      email
      usersub
      role
      is_default
      status
      createdAt
      updatedAt
      userID
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationUsersQueryVariables,
  APITypes.ListOrganizationUsersQuery
>;
export const syncOrganizationUsers = /* GraphQL */ `query SyncOrganizationUsers(
  $filter: ModelOrganizationUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrganizationUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      organizationID
      organization {
        id
        name
        planID
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        pomodoroTime
        shortBreakTime
        longBreakTime
        automaticStartTime
        editionTimeFrame
        created_by
        subscribedSeats
        OrganizationUser {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Clients {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tags {
          items {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      email
      usersub
      role
      is_default
      status
      createdAt
      updatedAt
      userID
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrganizationUsersQueryVariables,
  APITypes.SyncOrganizationUsersQuery
>;
export const getOrganization = /* GraphQL */ `query GetOrganization($id: ID!) {
  getOrganization(id: $id) {
    id
    name
    planID
    plan {
      id
      name
      description
      price
      max_users
      max_projects
      max_clients
      max_groups
      status
      priceIdStripe
      membership
      Organization {
        items {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    pomodoroTime
    shortBreakTime
    longBreakTime
    automaticStartTime
    editionTimeFrame
    created_by
    subscribedSeats
    OrganizationUser {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        usersub
        role
        is_default
        status
        createdAt
        updatedAt
        userID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Invitations {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        invitedOn
        invitedBy
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Clients {
      items {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tasks {
      items {
        id
        name
        time
        usersub
        type
        status
        hasTimeEdited
        projectID
        organizationID
        project {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        TagsTask {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        searchableText
        createdAt
        updatedAt
        deleted
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tags {
      items {
        id
        name
        color
        status
        organizationID
        Clients {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetOrganizationQueryVariables,
  APITypes.GetOrganizationQuery
>;
export const listOrganizations = /* GraphQL */ `query ListOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
) {
  listOrganizations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      planID
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      pomodoroTime
      shortBreakTime
      longBreakTime
      automaticStartTime
      editionTimeFrame
      created_by
      subscribedSeats
      OrganizationUser {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tags {
        items {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListOrganizationsQueryVariables,
  APITypes.ListOrganizationsQuery
>;
export const syncOrganizations = /* GraphQL */ `query SyncOrganizations(
  $filter: ModelOrganizationFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncOrganizations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      planID
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      pomodoroTime
      shortBreakTime
      longBreakTime
      automaticStartTime
      editionTimeFrame
      created_by
      subscribedSeats
      OrganizationUser {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tags {
        items {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncOrganizationsQueryVariables,
  APITypes.SyncOrganizationsQuery
>;
export const getPlan = /* GraphQL */ `query GetPlan($id: ID!) {
  getPlan(id: $id) {
    id
    name
    description
    price
    max_users
    max_projects
    max_clients
    max_groups
    status
    priceIdStripe
    membership
    Organization {
      items {
        id
        name
        planID
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        pomodoroTime
        shortBreakTime
        longBreakTime
        automaticStartTime
        editionTimeFrame
        created_by
        subscribedSeats
        OrganizationUser {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Clients {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tags {
          items {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orders {
      items {
        id
        orderID
        planID
        order {
          id
          usersub
          date
          total
          subscriptionId
          createdAt
          updatedAt
          plans {
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetPlanQueryVariables, APITypes.GetPlanQuery>;
export const listPlans = /* GraphQL */ `query ListPlans(
  $filter: ModelPlanFilterInput
  $limit: Int
  $nextToken: String
) {
  listPlans(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      price
      max_users
      max_projects
      max_clients
      max_groups
      status
      priceIdStripe
      membership
      Organization {
        items {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListPlansQueryVariables, APITypes.ListPlansQuery>;
export const syncPlans = /* GraphQL */ `query SyncPlans(
  $filter: ModelPlanFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncPlans(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      price
      max_users
      max_projects
      max_clients
      max_groups
      status
      priceIdStripe
      membership
      Organization {
        items {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncPlansQueryVariables, APITypes.SyncPlansQuery>;
export const getTag = /* GraphQL */ `query GetTag($id: ID!) {
  getTag(id: $id) {
    id
    name
    color
    status
    organizationID
    Clients {
      items {
        id
        tagID
        clientID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tasks {
      items {
        id
        tagID
        taskID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        task {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTagQueryVariables, APITypes.GetTagQuery>;
export const listTags = /* GraphQL */ `query ListTags($filter: ModelTagFilterInput, $limit: Int, $nextToken: String) {
  listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      color
      status
      organizationID
      Clients {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTagsQueryVariables, APITypes.ListTagsQuery>;
export const syncTags = /* GraphQL */ `query SyncTags(
  $filter: ModelTagFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      color
      status
      organizationID
      Clients {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncTagsQueryVariables, APITypes.SyncTagsQuery>;
export const getGroup = /* GraphQL */ `query GetGroup($id: ID!) {
  getGroup(id: $id) {
    id
    name
    status
    organizationID
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UsersGroup {
      items {
        id
        userID
        groupID
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGroupQueryVariables, APITypes.GetGroupQuery>;
export const listGroups = /* GraphQL */ `query ListGroups(
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UsersGroup {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupsQueryVariables,
  APITypes.ListGroupsQuery
>;
export const syncGroups = /* GraphQL */ `query SyncGroups(
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UsersGroup {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncGroupsQueryVariables,
  APITypes.SyncGroupsQuery
>;
export const getClient = /* GraphQL */ `query GetClient($id: ID!) {
  getClient(id: $id) {
    id
    name
    TagsClient {
      items {
        id
        tagID
        clientID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    status
    organizationID
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetClientQueryVariables, APITypes.GetClientQuery>;
export const listClients = /* GraphQL */ `query ListClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
) {
  listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      TagsClient {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClientsQueryVariables,
  APITypes.ListClientsQuery
>;
export const syncClients = /* GraphQL */ `query SyncClients(
  $filter: ModelClientFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClients(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      TagsClient {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncClientsQueryVariables,
  APITypes.SyncClientsQuery
>;
export const getProject = /* GraphQL */ `query GetProject($id: ID!) {
  getProject(id: $id) {
    id
    name
    description
    status
    groupcolor
    clientID
    client {
      id
      name
      TagsClient {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    groupID
    group {
      id
      name
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UsersGroup {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    organizationID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProjectQueryVariables,
  APITypes.GetProjectQuery
>;
export const listProjects = /* GraphQL */ `query ListProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
) {
  listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      status
      groupcolor
      clientID
      client {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      group {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProjectsQueryVariables,
  APITypes.ListProjectsQuery
>;
export const syncProjects = /* GraphQL */ `query SyncProjects(
  $filter: ModelProjectFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncProjects(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      description
      status
      groupcolor
      clientID
      client {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      group {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncProjectsQueryVariables,
  APITypes.SyncProjectsQuery
>;
export const getTask = /* GraphQL */ `query GetTask($id: ID!) {
  getTask(id: $id) {
    id
    name
    time
    usersub
    type
    status
    hasTimeEdited
    projectID
    organizationID
    project {
      id
      name
      description
      status
      groupcolor
      clientID
      client {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      group {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    TagsTask {
      items {
        id
        tagID
        taskID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        task {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    searchableText
    createdAt
    updatedAt
    deleted
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTaskQueryVariables, APITypes.GetTaskQuery>;
export const listTasks = /* GraphQL */ `query ListTasks(
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
) {
  listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      time
      usersub
      type
      status
      hasTimeEdited
      projectID
      organizationID
      project {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      TagsTask {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      searchableText
      createdAt
      updatedAt
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListTasksQueryVariables, APITypes.ListTasksQuery>;
export const syncTasks = /* GraphQL */ `query SyncTasks(
  $filter: ModelTaskFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTasks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      time
      usersub
      type
      status
      hasTimeEdited
      projectID
      organizationID
      project {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      TagsTask {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      searchableText
      createdAt
      updatedAt
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncTasksQueryVariables, APITypes.SyncTasksQuery>;
export const getByOrder = /* GraphQL */ `query GetByOrder($id: ID!) {
  getByOrder(id: $id) {
    id
    orderID
    planID
    order {
      id
      usersub
      date
      total
      subscriptionId
      createdAt
      updatedAt
      plans {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    plan {
      id
      name
      description
      price
      max_users
      max_projects
      max_clients
      max_groups
      status
      priceIdStripe
      membership
      Organization {
        items {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetByOrderQueryVariables,
  APITypes.GetByOrderQuery
>;
export const listByOrders = /* GraphQL */ `query ListByOrders(
  $filter: ModelByOrderFilterInput
  $limit: Int
  $nextToken: String
) {
  listByOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      orderID
      planID
      order {
        id
        usersub
        date
        total
        subscriptionId
        createdAt
        updatedAt
        plans {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListByOrdersQueryVariables,
  APITypes.ListByOrdersQuery
>;
export const syncByOrders = /* GraphQL */ `query SyncByOrders(
  $filter: ModelByOrderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncByOrders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      orderID
      planID
      order {
        id
        usersub
        date
        total
        subscriptionId
        createdAt
        updatedAt
        plans {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncByOrdersQueryVariables,
  APITypes.SyncByOrdersQuery
>;
export const getUserGroup = /* GraphQL */ `query GetUserGroup($id: ID!) {
  getUserGroup(id: $id) {
    id
    userID
    groupID
    user {
      id
      givenName
      familyName
      name
      email
      timeZone
      dateFormat
      timeFormat
      image
      themeMode
      accepTerms
      newUser
      customerIdStripe
      disabled
      OrganizationUsers {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    group {
      id
      name
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UsersGroup {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserGroupQueryVariables,
  APITypes.GetUserGroupQuery
>;
export const listUserGroups = /* GraphQL */ `query ListUserGroups(
  $filter: ModelUserGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      userID
      groupID
      user {
        id
        givenName
        familyName
        name
        email
        timeZone
        dateFormat
        timeFormat
        image
        themeMode
        accepTerms
        newUser
        customerIdStripe
        disabled
        OrganizationUsers {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      group {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserGroupsQueryVariables,
  APITypes.ListUserGroupsQuery
>;
export const syncUserGroups = /* GraphQL */ `query SyncUserGroups(
  $filter: ModelUserGroupFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserGroups(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      userID
      groupID
      user {
        id
        givenName
        familyName
        name
        email
        timeZone
        dateFormat
        timeFormat
        image
        themeMode
        accepTerms
        newUser
        customerIdStripe
        disabled
        OrganizationUsers {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      group {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserGroupsQueryVariables,
  APITypes.SyncUserGroupsQuery
>;
export const getClientTag = /* GraphQL */ `query GetClientTag($id: ID!) {
  getClientTag(id: $id) {
    id
    tagID
    clientID
    tag {
      id
      name
      color
      status
      organizationID
      Clients {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    client {
      id
      name
      TagsClient {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetClientTagQueryVariables,
  APITypes.GetClientTagQuery
>;
export const listClientTags = /* GraphQL */ `query ListClientTags(
  $filter: ModelClientTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listClientTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tagID
      clientID
      tag {
        id
        name
        color
        status
        organizationID
        Clients {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      client {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListClientTagsQueryVariables,
  APITypes.ListClientTagsQuery
>;
export const syncClientTags = /* GraphQL */ `query SyncClientTags(
  $filter: ModelClientTagFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncClientTags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tagID
      clientID
      tag {
        id
        name
        color
        status
        organizationID
        Clients {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      client {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncClientTagsQueryVariables,
  APITypes.SyncClientTagsQuery
>;
export const getTaskTag = /* GraphQL */ `query GetTaskTag($id: ID!) {
  getTaskTag(id: $id) {
    id
    tagID
    taskID
    tag {
      id
      name
      color
      status
      organizationID
      Clients {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    task {
      id
      name
      time
      usersub
      type
      status
      hasTimeEdited
      projectID
      organizationID
      project {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      TagsTask {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      searchableText
      createdAt
      updatedAt
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetTaskTagQueryVariables,
  APITypes.GetTaskTagQuery
>;
export const listTaskTags = /* GraphQL */ `query ListTaskTags(
  $filter: ModelTaskTagFilterInput
  $limit: Int
  $nextToken: String
) {
  listTaskTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tagID
      taskID
      tag {
        id
        name
        color
        status
        organizationID
        Clients {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      task {
        id
        name
        time
        usersub
        type
        status
        hasTimeEdited
        projectID
        organizationID
        project {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        TagsTask {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        searchableText
        createdAt
        updatedAt
        deleted
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTaskTagsQueryVariables,
  APITypes.ListTaskTagsQuery
>;
export const syncTaskTags = /* GraphQL */ `query SyncTaskTags(
  $filter: ModelTaskTagFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTaskTags(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tagID
      taskID
      tag {
        id
        name
        color
        status
        organizationID
        Clients {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      task {
        id
        name
        time
        usersub
        type
        status
        hasTimeEdited
        projectID
        organizationID
        project {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        TagsTask {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        searchableText
        createdAt
        updatedAt
        deleted
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTaskTagsQueryVariables,
  APITypes.SyncTaskTagsQuery
>;
