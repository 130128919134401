import { useState, useEffect } from 'react'
import { getStyleSheet } from '../../assets/theme/styles'
import { StyleSheet, TextStyle, ViewStyle, ScrollView } from 'react-native'
import ButtonNative from './ButtonNative'
import useDimensions from '../../hooks/useDimensions'

type Props = {
    options: { name: string; value: string | number }[]
    onChange: (value: string | number) => void
    value: string | number
    buttonStyle?: ViewStyle
    titleStyle?: TextStyle
    containerStyle?: ViewStyle
    subContainerStyle?: ViewStyle
}

const TabNative = ({ options, value, buttonStyle, titleStyle, containerStyle, subContainerStyle, onChange }: Props) => {
    const [currentIndex, setCurrentIntex] = useState(0)
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()

    useEffect(() => {
        const findCurrentIndex = options.findIndex(option => option.value === value) || 0
        setCurrentIntex(findCurrentIndex)
    }, [value, options])

    return (
        <ScrollView
            horizontal
            contentContainerStyle={subContainerStyle}
            style={[
                { backgroundColor: styles.selectbuttonBackground },
                localStyles.container,
                windowWidth < 600 ? { maxWidth: 200 } : null,
                containerStyle,
            ]}
        >
            {options.map((option, index) => (
                <ButtonNative
                    key={option.name}
                    title={option.name}
                    onPress={() => onChange(option.value)}
                    titleStyle={[
                        {
                            color: currentIndex === index ? 'white' : '#818a94',
                        },
                        localStyles.titleStyle,
                        titleStyle,
                    ]}
                    buttonStyle={[
                        {
                            backgroundColor:
                                currentIndex === index
                                    ? styles.backgroundColorPrimaryButton
                                    : styles.selectbuttonBackground,
                        },
                        localStyles.buttonStyle,
                        buttonStyle,
                    ]}
                />
            ))}
        </ScrollView>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flexGrow: 0,
        borderRadius: 6,
        height: 34,
    },
    buttonStyle: {
        borderRadius: 6,
        height: 34,
        justifyContent: 'center',
        paddingVertical: 0,
        paddingHorizontal: 20,
    },
    titleStyle: {
        fontSize: 14,
        fontWeight: '500',
    },
})

export default TabNative
