import { View, Text, StyleProp, ViewStyle, Image } from 'react-native'
import MultiselectTags from './MultiselectTags/MultiselectTags'
import { memo } from 'react'
import ButtonNative from './ButtonNative'
import { getStyleSheet } from '../../assets/theme/styles'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { MomentaryView } from './DataTable'
import useDimensions from '../../hooks/useDimensions'
import ItemSkeleton from './ItemSkeleton'

type ReportDetail = {
    createdDateFormat: string
    name?: string
    projectName?: string
    timeRange: string
    durationFormat: string
    pomodoroTime: string
    time: string
    id: string
    arrayTags: any[]
}

interface IReportDetails {
    reportDetails: ReportDetail
    isLoading: boolean
    isChildRow?: boolean
    tags: any[]
    isRowExpand: boolean
    hasChildrenTask: boolean
    toggleRowExpand: () => void
}

const RowReportDetails = memo(
    ({ reportDetails, isLoading, isChildRow, tags, hasChildrenTask, toggleRowExpand, isRowExpand }: IReportDetails) => {
        const styles = getStyleSheet()
        const { windowWidth } = useDimensions()
        const largeSizeWidth = 1000

        const selectedTagsFormat = reportDetails.arrayTags?.map(item => {
            return {
                value: item.id,
                label: item.name,
                color: item.color.includes('#') ? item.color : '#' + item.color,
            }
        })

        const ComponentPomodoro = ({ customStyles }: { customStyles?: StyleProp<ViewStyle> }) => {
            return (
                <View style={[{ flexDirection: 'row', alignItems: 'center', gap: 10 }, customStyles]}>
                    <Text style={{ color: styles.text, display: reportDetails.pomodoroTime ? 'flex' : 'none' }}>
                        {reportDetails.pomodoroTime}
                    </Text>
                    <Image source={require('../../assets/icons/pomodoro.png')} style={{ width: 23, height: 23 }} />
                </View>
            )
        }

        const ViewSkeleton = ({
            children,
            loading,
            skeletonStyles,
            customStyles,
        }: {
            children: React.ReactElement
            loading: boolean
            skeletonStyles?: StyleProp<ViewStyle>
            customStyles?: StyleProp<ViewStyle>
        }) => {
            if (loading) return <ItemSkeleton customStyles={skeletonStyles} />
            return <View style={customStyles}>{children}</View>
        }

        return (
            <View
                style={[
                    {
                        backgroundColor: isChildRow ? styles.rowExpandBackground : styles.backgroundColorRow,
                        paddingVertical: 14,
                        paddingHorizontal: 20,
                        borderRadius: 6,
                        rowGap: 20,
                    },
                    windowWidth > largeSizeWidth ? { flexDirection: 'row', alignItems: 'center' } : null,
                ]}
            >
                <MomentaryView
                    customStyles={{ flexDirection: 'row', gap: 20, alignItems: 'center' }}
                    showViewUp={largeSizeWidth}
                >
                    {!isChildRow && hasChildrenTask ? (
                        <ButtonNative
                            title=""
                            showIcon
                            icon={isRowExpand ? faChevronDown : faChevronRight}
                            containerStyle={{ width: windowWidth > largeSizeWidth ? '5%' : 'auto' }}
                            buttonStyle={{
                                paddingHorizontal: 0,
                                paddingVertical: 0,
                            }}
                            onPress={toggleRowExpand}
                        />
                    ) : (
                        <View style={{ width: '5%' }} />
                    )}

                    <ViewSkeleton
                        loading={isLoading}
                        skeletonStyles={[windowWidth > largeSizeWidth ? { width: '25%', marginLeft: 25 } : null]}
                        customStyles={[
                            { flexGrow: 1 },
                            windowWidth > largeSizeWidth ? { width: '25%', paddingLeft: 25, flexGrow: 0 } : null,
                        ]}
                    >
                        <Text style={{ color: styles.text, opacity: !reportDetails.projectName?.length ? 0.5 : 1 }}>
                            {reportDetails.name || '- No title -'}
                        </Text>
                    </ViewSkeleton>

                    {windowWidth <= largeSizeWidth ? <ComponentPomodoro /> : null}
                </MomentaryView>

                <MomentaryView
                    customStyles={{ flexDirection: 'row', justifyContent: 'space-between' }}
                    showViewUp={largeSizeWidth}
                >
                    <ViewSkeleton
                        loading={isLoading}
                        skeletonStyles={{ marginRight: 5 }}
                        customStyles={{ width: windowWidth > largeSizeWidth ? '10%' : 'auto' }}
                    >
                        <Text
                            style={{
                                color: styles.text,
                                textAlign: 'center',
                                opacity: !reportDetails.projectName?.length ? 0.5 : 1,
                            }}
                        >
                            {reportDetails.projectName || '- No project -'}
                        </Text>
                    </ViewSkeleton>

                    {windowWidth <= largeSizeWidth ? (
                        <ViewSkeleton loading={isLoading}>
                            <Text style={{ color: styles.text }}>
                                {!isChildRow ? reportDetails.durationFormat : reportDetails.time}
                            </Text>
                        </ViewSkeleton>
                    ) : null}
                </MomentaryView>

                <MomentaryView
                    customStyles={{ flexDirection: 'row', justifyContent: 'space-between' }}
                    showViewUp={largeSizeWidth}
                >
                    <ViewSkeleton
                        loading={isLoading}
                        customStyles={{ width: windowWidth > largeSizeWidth ? '20%' : 'auto' }}
                    >
                        <Text numberOfLines={1} style={{ color: styles.text, textAlign: 'center' }}>
                            {reportDetails.timeRange}
                        </Text>
                    </ViewSkeleton>

                    {windowWidth > largeSizeWidth ? (
                        <ViewSkeleton loading={isLoading} customStyles={{ width: '10%' }}>
                            <Text style={{ color: styles.text, textAlign: 'center' }}>
                                {!isChildRow ? reportDetails.durationFormat : reportDetails.time}
                            </Text>
                        </ViewSkeleton>
                    ) : null}

                    {windowWidth > largeSizeWidth ? (
                        <ComponentPomodoro customStyles={{ width: '10%', justifyContent: 'center' }} />
                    ) : null}

                    {!isChildRow ? (
                        <ViewSkeleton
                            loading={isLoading}
                            skeletonStyles={{ width: windowWidth > largeSizeWidth ? '20%' : '100%', marginLeft: 5 }}
                            customStyles={{ width: windowWidth > largeSizeWidth ? '20%' : 'auto' }}
                        >
                            <Text style={[{ color: styles.text, textAlign: 'center' }]}>
                                {reportDetails.createdDateFormat}
                            </Text>
                        </ViewSkeleton>
                    ) : (
                        <MultiselectTags
                            indentificator={reportDetails.id}
                            tags={tags}
                            loading={isLoading}
                            skeletonStyles={{ width: windowWidth > largeSizeWidth ? '20%' : '100%' }}
                            customStyles={{ width: windowWidth > largeSizeWidth ? '20%' : 'auto', maxWidth: '20%' }}
                            value={selectedTagsFormat}
                            onChangeTag={async () => {}}
                            disabled={true}
                        />
                    )}
                </MomentaryView>
            </View>
        )
    },
    (prev, next) => {
        if (next.isChildRow) {
            return prev.isChildRow === next.isChildRow
        }
        return false
    }
)

export default RowReportDetails
