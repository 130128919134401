import React, { useCallback, useContext, useEffect } from 'react'
import { OrganizationService } from '../../../services/OrganizationService'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { useUserProvider } from '../../../context/UserProvider'
import { useSidemenuProvider } from '../../../context/SidemenuProvider'
import useState from 'react-usestateref'
import { StyleProp, ViewStyle } from 'react-native'

export interface Props {
    currentOrganization?: any
    style?: StyleProp<ViewStyle>
}

const useDropdownOrganization = (props: Props) => {
    const navigation = useNavigation()
    const { userRole, userSub } = useContext<any>(ThemeContext)
    const { userActiveOrganization, updateNameOrganization } = useUserProvider()
    const { showSidebar, setShowSidebar } = useSidemenuProvider()
    const [focus, setFocus] = useState(false)
    const [organizations, setOrganizations] = useState<any>([])
    const [organizationsGroup, setOrganizationsGroup] = useState<any>([])
    const [selectedOrganization, setSelectedOrganization] = useState(null)

    const pressSettings = () => {
        setFocus(false)
        navigation.navigate('Settings' as never)
        setShowSidebar(false)
    }

    const onOrganizationChange = async value => {
        setSelectedOrganization(value)
        OrganizationService()
            .updateDefaultOrganizationUser(value, userSub)
            .then(response => {
                if (response) {
                    window.location.reload()
                }
            })
    }

    const getOrganizations = () => {
        OrganizationService()
            .getOrganizationDropdown(userSub)
            .then(data => {
                setOrganizations(data)
            })
            .catch(error => {
                console.log(error)
                return
            })
    }

    const getOrganizationsGroup = () => {
        OrganizationService()
            .getOrganizationDropdown(userSub)
            .then(data => {
                let groupedItems = [
                    {
                        label: 'Settings',
                        code: 'settings',
                        items: data,
                    },
                ]
                setOrganizationsGroup(groupedItems)
            })
            .catch(error => {
                console.log(error)
                return
            })
    }

    const getDefaultOrganization = () => {
        OrganizationService()
            .getDefaultOrganizationId(userSub)
            .then(data => {
                setSelectedOrganization(data)
            })
    }

    useFocusEffect(
        useCallback(() => {
            if (userActiveOrganization) {
                setSelectedOrganization(userActiveOrganization?.organizationID as any)
            }
            if (userSub) {
                getOrganizations()
                getOrganizationsGroup()
                getDefaultOrganization()
            }
        }, [userRole, userSub, userActiveOrganization])
    )

    return [
        {
            userRole,
            focus,
            organizations,
            organizationsGroup,
            selectedOrganization,
            onOrganizationChange,
            pressSettings,
        },
    ]
}

export default useDropdownOrganization
