import React, { useEffect, useContext, useCallback, useState } from 'react'
import { View, Text } from 'react-native'
import { useElements, useStripe, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'
import { ThemeContext } from '../../../../assets/theme/ThemeProvider'
import { CartContext } from '../../../../context/cart'
import { PlanContext } from '../../../../context/plan'
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native'
import { useUserProvider } from '../../../../context/UserProvider'
import SubscriptionService, { IPaymentMethod } from '../../../../services/SubscriptionService'
import UserSeats from '../UserSeats'
import FormCheckout from './Form'
import useDimensions from '../../../../hooks/useDimensions'
import useCheckoutSubmit from '../useCheckoutSubmit'
import { useStripeProvider } from '../../../../context/StripeProvider'
import useStateRef from 'react-usestateref'
import { generateClient } from 'aws-amplify/api'
import { getStripeCoupon } from '../../../../graphql/mutations'
import InputText from '../../InputText'
import { getStyleSheet } from '../../../../assets/theme/styles'
import { IUser } from '../../../../interfaces/user.interface'

export const CheckoutForm = () => {
    const navigation = useNavigation()
    const {
        cart,
        total,
        clearCart,
        priceId,
        changeAmount,
        isUpdateOnlySeats,
        activeUpdateOnlySeats,
        activeModePaymentMethod,
        isUpdatePayment,
    } = useContext(CartContext)
    const { addToCart, setPriceId } = useContext(CartContext)
    const { plans, checkout } = useContext(PlanContext)
    const { userEmail, userSub } = useContext(ThemeContext)
    const stripe = useStripe()
    const elements = useElements()
    const { dbUserData, setDbUserData } = useUserProvider()
    const { windowWidth } = useDimensions()
    const [error, setError] = useState<any>(null)
    const { updateSubscriptionPlanFn, updateSubscriptionPaymentMethodFn } = useStripeProvider()
    const [inputSeat, setInputSeat] = useState<string>('1')
    const [priceMembership, setPriceMembership, priceMembershipRef] = useStateRef<number>(0)
    const [totalPrice, setTotalPrice] = useState<number>(0)
    const [nameCheckout, setNameCheckout] = useState('')
    const routeNavigation = useRoute()
    const [currenntPaymentMethod, setCurrenntPaymentMethod] = useState<IPaymentMethod | undefined>()
    const [loading, setLoading] = useState(false)
    const [disabledPay, setDisablePay] = useState(false)
    const [couponCode, setCouponCode] = useState('')
    const [couponCodeMessage, setCouponCodeMessage] = useState('')
    const [discountByCouponCode, setDiscountByCouponCode] = useState(null)
    const [hasErrorCouponCode, setHasErrorCouponCode] = useState(false)
    const styles = getStyleSheet()
    const {
        subscription,
        orderDetails,
        organization,
        getOrganizationDefault,
        setOrderDetails,
        setSubscription,
        cancelOldSubscriptions,
        saveChangesOrganization,
    } = useCheckoutSubmit()

    useEffect(() => {
        if (orderDetails.priceId) {
            CheckoutPlan()
        }
    }, [orderDetails])

    useEffect(() => {
        if (cart && cart.length) {
            setPriceMembership(cart[0].price)
        }

        if (discountByCouponCode) {
            const discont = (total * discountByCouponCode) / 100
            setTotalPrice(total - discont)
        } else setTotalPrice(total)
    }, [cart, total])

    useFocusEffect(
        useCallback(() => {
            clearComponents()
            if (userSub) {
                getOrganizationDefault()
            }
            if (dbUserData) {
                getSubscriptions()
            }
        }, [userSub, dbUserData])
    )

    useEffect(() => {
        const params: null | { isUpdateOnlySeats: boolean; planId: string; isUpdatePayment: boolean } =
            routeNavigation.params as any
        const foundPlan = plans.find(plan => plan.id === params?.planId)
        if (params?.isUpdateOnlySeats) activeUpdateOnlySeats()
        if (params?.isUpdatePayment) activeModePaymentMethod()

        if (foundPlan) {
            foundPlan.membershipPrice = foundPlan.price
            setPriceId(foundPlan.priceIdStripe)
            addToCart(foundPlan)
        }
    }, [plans, routeNavigation])

    useEffect(() => {
        if (subscription?.seats) {
            setInputSeat(String(subscription.seats))
            totalPriceCheckout(subscription.seats)
        }
    }, [subscription])

    const handleChange = event => {
        if (event.error) {
            setError(event.error.message)
        } else {
            setError(null)
        }
    }

    const handleSubmit = () => {
        if (isUpdateOnlySeats) return handleUpdateSeats()
        if (isUpdatePayment) return handleUpdatePaymentMethod()
        return handleCheckout()
    }

    const handleUpdatePaymentMethod = async () => {
        if (!stripe || !elements || !subscription!!) return
        if (!nameCheckout) return setError('Name required.' as any)

        setLoading(true)

        const cardElement = elements.getElement(CardNumberElement)

        if (!cardElement) return

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: nameCheckout,
            },
        })

        if (error) {
            setLoading(false)
            console.log(error.message)
        } else {
            try {
                if (dbUserData?.customerIdStripe) {
                    const response: any = await updateSubscriptionPaymentMethodFn(
                        dbUserData.customerIdStripe,
                        subscription?.id,
                        paymentMethod.id
                    )
                    if (response.statusCode === 200) onSuccessPay()
                    else setError(response?.body)

                    setLoading(false)
                }
            } catch (err) {
                console.log(err)
                setLoading(false)
            }
        }
    }

    const handleCheckout = async () => {
        setError(null)
        if (!nameCheckout) return setError('Name required.' as any)

        setOrderDetails({
            ...orderDetails,
            cart: cart,
            name: nameCheckout,
            email: userEmail,
            sub: userSub,
            total: total,
            priceId: priceId,
            seatsAmount: +inputSeat,
        })
    }

    const handleUpdateSeats = async () => {
        if (cart.at(0).amount === subscription?.seats) return

        if (subscription) {
            setLoading(true)

            const response = await updateSubscriptionPlanFn(
                subscription?.id,
                subscription?.subscriptionsItems[0].id,
                priceId,
                cart.at(0).amount
            )

            if (response.statusCode === 200) onSuccessPay()

            setLoading(false)
        }
    }

    const CheckoutPlan = () => {
        setLoading(true)

        checkout(orderDetails, couponCode).then(response => {
            if (!stripe || !elements) return

            if (!dbUserData?.customerIdStripe && response?.customerId)
                setDbUserData({ ...dbUserData, customerIdStripe: response?.customerId } as IUser)

            const cardNumberElement = elements?.getElement(CardNumberElement)

            const handleSuccess = () => {
                cancelOldSubscriptions().then(() => {
                    saveChangesOrganization(organization)
                        .then(responseOrganization => {
                            // Successful subscription payment
                            if (responseOrganization) {
                                clearCart()
                                setLoading(false)
                                setDisablePay(true)
                                onSuccessPay()
                            } else {
                                setError('There was an error while executing the action. Plan not updated')
                            }
                        })
                        .catch(() => {
                            setError('There was an error while executing the action. Plan not updated')
                        })
                })
            }

            // When discount is 100% isn't has secret token or some was wrong
            if (response?.clientSecret) {
                return stripe
                    .confirmCardPayment(response?.clientSecret, {
                        payment_method: {
                            card: cardNumberElement as any,
                            billing_details: {
                                name: nameCheckout,
                            },
                            metadata: { temporalFieldForValidCardConfirm: 1 },
                        },
                    })
                    .then(result => {
                        if (result.error) {
                            setError(result?.error?.message)
                            setLoading(false)
                        } else {
                            // Cancel old subscriptions
                            handleSuccess()
                        }
                    })
            }
            if (response?.status) handleSuccess()
        })
    }

    const totalPriceCheckout = (amount: number) => {
        if (cart && cart.length > 0) {
            changeAmount(cart[0].id, amount)
        }

        setInputSeat(String(amount))
    }

    const clearComponents = () => {
        setLoading(false)
        setInputSeat('1')
        setPriceMembership(0)
        setNameCheckout('')
        setError(null)
        setDisablePay(false)
        setDiscountByCouponCode(null)
        setCouponCode('')
        setCouponCodeMessage('')
        setHasErrorCouponCode(false)
        elements?.getElement(CardNumberElement)?.clear()
        elements?.getElement(CardExpiryElement)?.clear()
        elements?.getElement(CardCvcElement)?.clear()
    }

    const getSubscriptions = async () => {
        if (!subscription) {
            setLoading(true)
        }

        if (!dbUserData?.customerIdStripe) {
            setLoading(false)
            return
        }
        SubscriptionService(plans)
            .getSubscriptionByUser(dbUserData.customerIdStripe)
            .then(response => {
                setSubscription(response?.subscription)
                setCurrenntPaymentMethod(response?.paymentMethod)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.error('Error', error)
            })
    }

    const onSuccessPay = () => {
        //@ts-ignore
        navigation.navigate('SuccessChanges', { to: 'Billing' } as never)
    }

    const handleChangeCouponCodeInput = (value: string) => {
        if (!value.length) {
            setHasErrorCouponCode(false)
            setCouponCodeMessage('')
            setDiscountByCouponCode(null)
            setTotalPrice(total)
        }
        setCouponCode(value)
    }

    const handleVerifyCouponCode = async () => {
        if (!couponCode.length) return

        const request: any = await generateClient().graphql({
            query: getStripeCoupon,
            variables: { input: { coupon: couponCode } },
        })

        const coupon = request.data?.getStripeCoupon

        if (!coupon && !coupon?.valid) {
            setHasErrorCouponCode(true)
            setDiscountByCouponCode(null)
            setTotalPrice(total)
            return setCouponCodeMessage('Invalid Coupon Code')
        }

        setHasErrorCouponCode(false)
        setDiscountByCouponCode(coupon.percent_off)
        setCouponCodeMessage(`${coupon.percent_off}% Valid Coupon Code`)

        // Updating state (totalPrice)
        const discont = (total * coupon.percent_off) / 100
        setTotalPrice(total - discont)
    }

    return (
        <View style={{ width: '100%' }}>
            <View
                style={[
                    { padding: windowWidth > 600 ? 40 : 0 },
                    windowWidth > 1100 ? { flexDirection: 'row', justifyContent: 'space-around' } : null,
                ]}
            >
                {!isUpdatePayment ? (
                    <UserSeats
                        customStyles={windowWidth > 1100 ? { flex: 0.45, alignSelf: 'flex-start' } : null}
                        priceMembership={priceMembership}
                        totalPrice={totalPrice}
                        discountByCouponCode={discountByCouponCode}
                        total={total}
                        inputSeat={inputSeat}
                        totalPriceCheckout={totalPriceCheckout}
                    />
                ) : null}

                <View style={{ marginTop: windowWidth <= 1100 ? 24 : 0, flex: windowWidth > 1100 ? 0.35 : 1 }}>
                    {!isUpdateOnlySeats && !isUpdatePayment ? (
                        <View style={{ marginBottom: 55, gap: 10 }}>
                            <Text style={{ fontSize: 14, color: styles.text }}>Coupon Code</Text>
                            <InputText
                                placeholder="Coupon Code"
                                value={couponCode}
                                onBlur={handleVerifyCouponCode}
                                onChange={e => handleChangeCouponCodeInput(e.valueOf())}
                                inputStyle={{
                                    backgroundColor: 'transparent',
                                    borderWidth: 1,
                                    borderColor: '#C6D2D9',
                                    height: 50,
                                }}
                            />

                            {couponCodeMessage.length ? (
                                <Text
                                    style={{
                                        color: hasErrorCouponCode ? 'red' : styles.backgroundColorPrimaryButton,
                                        fontWeight: '700',
                                    }}
                                >
                                    {couponCodeMessage}
                                </Text>
                            ) : null}
                        </View>
                    ) : null}

                    <FormCheckout
                        disabledPay={disabledPay}
                        subscriptionId={subscription?.id}
                        hasErrorCouponCode={hasErrorCouponCode}
                        currenntPaymentMethod={currenntPaymentMethod}
                        isUpdateOnlySeats={isUpdateOnlySeats}
                        isUpdatePaymentMethod={isUpdatePayment}
                        error={error}
                        totalPrice={totalPrice}
                        loading={loading}
                        nameCheckout={nameCheckout}
                        inputSeat={inputSeat}
                        handleUpdateSeats={handleUpdateSeats}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        setNameCheckout={setNameCheckout}
                        clearComponents={clearComponents}
                        setError={setError}
                        setShowSuccessPay={onSuccessPay}
                    />
                </View>
            </View>
        </View>
    )
}
