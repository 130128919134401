import { View, Text } from 'react-native'
import GroupedRecordMenu from './GroupedRecordMenu/GroupedRecordMenu'
import { getStyleSheet } from '../../assets/theme/styles'

interface IRowTeamGroup {
    teamGroup: any
    onShowConfirmationDelete: (id: string) => void
    onUpdateRow: (id: string) => void
}

const RowTeamGroup = ({ teamGroup, onShowConfirmationDelete, onUpdateRow }: IRowTeamGroup) => {
    const styles = getStyleSheet()

    const actions = [
        {
            action: () => onUpdateRow(teamGroup.id),
            label: 'Edit',
            icon: 'pencil',
        },
        {
            action: () => onShowConfirmationDelete(teamGroup.id),
            label: 'Delete',
            icon: 'delete',
        },
    ]

    return (
        <View
            style={{
                backgroundColor: styles.backgroundColorRow,
                padding: 10,
                gap: 10,
                borderRadius: 6,
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <View style={{ width: '100%', flexShrink: 1 }}>
                <Text style={{ color: styles.text, fontSize: 16 }}>{teamGroup.name}</Text>
            </View>

            <View style={{ width: '100%', flexShrink: 1 }}>
                <Text style={{ color: styles.text, fontSize: 16 }}>{`(${teamGroup.arrayUsers?.length})`}</Text>
            </View>

            <GroupedRecordMenu identificator={teamGroup.id} items={actions} />
        </View>
    )
}

export default RowTeamGroup
