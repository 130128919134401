import { generateClient } from 'aws-amplify/api'
import { createProject, deleteProject, updateProject } from '../graphql/mutations'
import { listProjects } from '../graphql/queries'
import { StatusProject, StatusTask } from '../models'
import { uniqBy } from 'lodash'
import { OrganizationService } from './OrganizationService'
import { TypeTask } from '../models'
import { Role } from '../models'
import { AuthService } from './AuthService'

export const ProjectService = () => {
    const getAllProjectByOrganization = async () => {
        try {
            const userSub = (await AuthService().getUserSub()) as string
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            const response: any = await generateClient().graphql({
                query: listProjects,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })
            const list = response?.data?.listProjects?.items
            return list
        } catch (error) {
            return []
        }
    }

    const getAllActiveProjectsByOrganization = async () => {
        try {
            const userSub = (await AuthService().getUserSub()) as string
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            const response: any = await generateClient().graphql({
                query: listProjects,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })
            const list = response?.data?.listProjects?.items.filter(
                e => !e._deleted && e.status === StatusProject.ACTIVE
            )
            return list
        } catch (error) {
            return []
        }
    }

    const getProjectsActive = async (userRole, userSub) => {
        try {
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            const response: any = await generateClient().graphql({
                query: listProjects,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })

            let list = response?.data?.listProjects?.items.map(item => {
                return {
                    ...item,
                    pomodoro: 0,
                    taskTotal: 0,
                    // pomodoro: item.Tasks.items.filter(
                    //     e => e.type === TypeTask.FOCUS && e.status === StatusTask.COMPLETED
                    // ).length,
                    // taskTotal: uniqBy(item.Tasks.items, 'name').length,
                    clientID: item.clientID || '- No client -',
                    clientName: item.client?.name || '- No client -',
                }
            })

            list = list.filter(f => {
                return f._deleted !== true && f.status === StatusProject.ACTIVE
            })

            if (userRole === Role.USER) {
                let filterList = list.map(element => {
                    return {
                        ...element,
                        userAccess: element.group?.UsersGroup?.items.filter(t => !t._deleted && t.userID === userSub),
                    }
                })
                filterList = filterList.filter(
                    element => element.userAccess !== undefined && element.userAccess.length > 0
                )

                return filterList
            }

            return list
        } catch (error) {
            return []
        }
    }

    const getProjectsArchived = async (userRole, userSub) => {
        try {
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            const response: any = await generateClient().graphql({
                query: listProjects,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })

            console.log({ response })

            let list = response?.data?.listProjects?.items.map(item => {
                return {
                    ...item,
                    pomodoro: 0,
                    taskTotal: 0,
                    // pomodoro: item.Tasks.items.length,
                    // taskTotal: uniqBy(item.Tasks.items, 'name').length,
                    clientName: item.client?.name || '',
                }
            })
            list = list.filter(f => {
                return f._deleted !== true && f.status === StatusProject.ARCHIVED
            })

            if (userRole === Role.USER) {
                let filterList = list.map(element => {
                    return {
                        ...element,
                        userAccess: element.group?.UsersGroup?.items.filter(t => !t._deleted && t.userID === userSub),
                    }
                })
                filterList = filterList.filter(
                    element => element.userAccess !== undefined && element.userAccess.length > 0
                )

                return filterList
            }

            return list
        } catch (error) {
            return
        }
    }

    const saveProject = async newEntry => {
        try {
            const response: any = await generateClient().graphql({
                query: createProject,
                variables: { input: newEntry },
            })
            return response?.data?.createProject
        } catch (error) {
            console.log(error)
            return
        }
    }

    const deleteProjectById = async (clientId: string, version) => {
        try {
            return await generateClient().graphql({
                query: deleteProject,
                variables: { input: { id: clientId, _version: version } },
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const updateProjectStatus = async (clientId: string, clientStatus, version) => {
        try {
            return await generateClient().graphql({
                query: updateProject,
                variables: { input: { id: clientId, status: clientStatus, _version: version } },
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const updateProjectById = async (newObject: any) => {
        try {
            return await generateClient().graphql({ query: updateProject, variables: { input: newObject } })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getProjectsMultiselect = async (userRole, userSub) => {
        try {
            let list: any[] = []
            const items = await getProjectsActive(userRole, userSub)

            for (const item of items) {
                const itemFound = list.find(i => i.code === item.clientID)
                const project = { value: item.id, label: item.name, color: item.groupcolor }
                if (itemFound) {
                    itemFound.items.push(project)
                } else {
                    list.push({
                        code: item.client?.id || item.clientID,
                        label: item.client?.name || item.clientName,
                        items: [project],
                    })
                }
            }

            list = list.sort((a, b) => {
                if (a.label < b.label) {
                    return -1
                }
                if (a.label > b.label) {
                    return 1
                }
                return 0
            })

            return list
        } catch (error) {
            return []
        }
    }

    return {
        getAllProjectByOrganization,
        getAllActiveProjectsByOrganization,
        getProjectsActive,
        getProjectsArchived,
        saveProject,
        deleteProjectById,
        updateProjectStatus,
        updateProjectById,
        getProjectsMultiselect,
    }
}
