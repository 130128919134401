import { Menu } from 'react-native-paper'
import React from 'react'
import { getStyleSheet } from '../../../assets/theme/styles'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { StyleProp, View, ViewStyle } from 'react-native'
import { useOverlayProvider } from '../../../context/OverlayProvider'
import DropdownNative from '../MultiSelectNative/MultiSelect'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { outlineStyle } from '../../../helpers/utils'
import ItemSkeleton from '../ItemSkeleton'

export interface IMenuItem {
    label: string
    icon: string
    isDisable?: boolean
    action: () => void
}

interface IGroupedRecord {
    items: IMenuItem[]
    disabled?: boolean
    identificator: string
    buttonTitleStyles?: StyleProp<ViewStyle>
    buttonStyles?: StyleProp<ViewStyle>
    skeletonStyles?: StyleProp<ViewStyle>
    loading?: boolean
    onPress?: () => void
}

const GroupedRecordMenu = ({
    items,
    disabled,
    buttonTitleStyles,
    buttonStyles,
    identificator,
    skeletonStyles,
    loading,
    onPress = () => {},
}: IGroupedRecord) => {
    const styles = getStyleSheet()
    const { closeShowOverlay } = useOverlayProvider()

    if (loading) return <ItemSkeleton customStyles={skeletonStyles} />

    return (
        <DropdownNative
            indentificator={`${identificator}-menu`}
            disabled={disabled}
            isShowDownButton={false}
            onPress={onPress}
            onChange={() => {}}
            optionLabel=""
            optionValue=""
            options={[]}
            containerStyles={{ flexGrow: 0 }}
            buttonTitleStyle={[buttonTitleStyles, { flexDirection: 'column' }]}
            customStyles={[{ paddingHorizontal: 5, paddingVertical: 5 }, buttonStyles]}
            placeholder=""
            valueTemplate={<FontAwesomeIcon style={[outlineStyle as any]} color={styles.text} icon={faEllipsisV} />}
        >
            <View
                style={{
                    backgroundColor: styles.backgroundComponent,
                    paddingVertical: 5,
                    shadowColor: 'rgba(0, 0, 0, 0.4)',
                    elevation: 5,
                    shadowRadius: 8,
                    shadowOffset: {
                        width: 0,
                        height: 2,
                    },
                }}
            >
                {items.map(item => (
                    <Menu.Item
                        titleStyle={[{ fontSize: 14, color: styles.text }, item.isDisable ? { opacity: 0.5 } : null]}
                        style={{ paddingHorizontal: 10, height: 35 }}
                        leadingIcon={item.icon}
                        disabled={item.isDisable}
                        onPress={() => {
                            closeShowOverlay()
                            item.action()
                        }}
                        title={item.label}
                    />
                ))}
            </View>
        </DropdownNative>
    )
}

export default GroupedRecordMenu
