import { Dimensions, FlexAlignType, FlexStyle, ImageStyle, StyleSheet } from 'react-native'
import { useTheme } from './ThemeProvider'
import { darkColors, lightColors } from './colorThemes'

const baseStyleTopBar = {
    container: {
        top: 0 as FlexStyle['top'],
        left: 0 as FlexStyle['left'],
        bottom: 0 as FlexStyle['bottom'],
        right: 0 as FlexStyle['right'],
        height: 58 as FlexStyle['height'],
    },
    separate: {
        width: 1 as FlexStyle['width'],
        height: 33 as FlexStyle['height'],
        marginRight: 18 as FlexStyle['marginRight'],
        marginVertical: 12 as FlexStyle['marginVertical'],
    },
    iconNotification: {
        width: 20 as FlexStyle['width'],
        height: 20 as FlexStyle['height'],
        marginRight: 16 as FlexStyle['marginRight'],
        marginLeft: 16 as FlexStyle['marginLeft'],
        marginVertical: 20 as FlexStyle['marginVertical'],
    },
    labelText: {
        position: 'relative' as FlexStyle['position'],
        justifyContent: 'center' as FlexStyle['justifyContent'],
        alignContent: 'center' as FlexStyle['alignContent'],
        paddingVertical: 20 as FlexStyle['paddingVertical'],
    },
    icon: {
        marginTop: -5 as FlexStyle['marginTop'],
        marginLeft: 10 as FlexStyle['marginLeft'],
        paddingVertical: 20 as FlexStyle['paddingVertical'],
    },
}

const baseStyleSidebar = {
    container: {
        flex: 1 as FlexStyle['flex'],
        maxWidth: 229 as FlexStyle['maxWidth'],
        minWidth: 229 as FlexStyle['minWidth'],
        width: '100%' as FlexStyle['width'],
        alignItems: 'center' as FlexStyle['alignItems'],
        justifyContent: 'center' as FlexStyle['justifyContent'],
    },
    imageBackground: {
        flex: 1 as FlexStyle['flex'],
        width: '100%' as FlexStyle['width'],
        resizeMode: 'cover' as ImageStyle['resizeMode'],
        justifyContent: 'flex-start' as FlexStyle['justifyContent'],
        alignItems: 'flex-start' as FlexStyle['alignItems'],
    },
    imageLogo: {
        width: 75 as FlexStyle['width'],
        height: 41 as FlexStyle['height'],
        marginTop: 16 as FlexStyle['marginTop'],
        marginLeft: 46.5 as FlexStyle['marginLeft'],
        marginBottom: 31 as FlexStyle['marginBottom'],
    },
    imageSmallLogo: {
        width: 40 as FlexStyle['width'],
        height: 25 as FlexStyle['height'],
        marginTop: 16 as FlexStyle['marginTop'],
        marginLeft: 10 as FlexStyle['marginLeft'],
        marginBottom: 31 as FlexStyle['marginBottom'],
    },
    flatList: {
        width: '100%' as FlexStyle['width'],
        justifyContent: 'flex-start' as FlexStyle['justifyContent'],
        alignItems: 'flex-start' as FlexStyle['alignItems'],
    },
    icon: {
        height: 17 as FlexStyle['height'],
        opacity: 0.5,
        marginRight: 11 as FlexStyle['marginRight'],
    },
    item: {
        width: 191 as FlexStyle['width'],
        borderRadius: 4,
        padding: 10 as FlexStyle['padding'],
        marginStart: 19 as FlexStyle['marginStart'],
        flexDirection: 'row' as FlexStyle['flexDirection'],
    },
    title: {
        fontSize: 16,
        lineHeight: 18.75,
        color: '#969696',
    },
}

const baseStyleDashboard = {
    container: {
        flex: 1 as FlexStyle['flex'],
        width: '100%' as FlexStyle['width'],
        alignItems: 'flex-start' as FlexStyle['alignItems'],
        justifyContent: 'flex-start' as FlexStyle['justifyContent'],
    },
}

const baseStyleContent = {
    container: {
        marginBottom: 30,
        width: '100%',
        top: 0 as FlexStyle['top'],
        left: 0 as FlexStyle['left'],
    },
}

const darkStyleSheet = {
    styleTopBar: StyleSheet.create({
        ...baseStyleTopBar,
        container: {
            ...baseStyleTopBar.container,
            backgroundColor: darkColors.backgroundColor,
        },
        separate: {
            ...baseStyleTopBar.separate,
            tintColor: darkColors.separatorColor,
        },
        iconNotification: {
            ...baseStyleTopBar.iconNotification,
            tintColor: darkColors.iconColor,
        },
        labelText: {
            ...baseStyleTopBar.labelText,
            color: darkColors.text,
        },
        colorText: {
            color: darkColors.text,
        },
        switchColor: {
            backgroundColor: darkColors.backgroundColorSwitch,
        },
        backgroundTopMenu: {
            backgroundColor: darkColors.backgroundColorTopMenu,
        },
        backgroundTopMenuHover: {
            backgroundColor: darkColors.backgroundColorTopMenuHover,
        },
    }),
    styleSidebar: StyleSheet.create({
        ...baseStyleSidebar,
        container: {
            ...baseStyleSidebar.container,
            backgroundColor: darkColors.backgroundColorSidebar,
        },
    }),
    styleDashboard: StyleSheet.create({
        ...baseStyleDashboard,
        container: {
            ...baseStyleDashboard.container,
            backgroundColor: darkColors.backgroundColorDashboard,
        },
    }),
    baseStyleContent: StyleSheet.create({
        ...baseStyleContent,
        container: {
            ...baseStyleContent.container,
        },
    }),
    styleBackground: StyleSheet.create({
        container: {
            backgroundColor: darkColors.backgroundColorDashboard,
        },
        input: {
            backgroundColor: darkColors.backgroundColorInput,
        },
        button: {
            backgroundColor: darkColors.backgroundColorPrimaryButton,
        },
    }),
    styleModalBackground: StyleSheet.create({
        container: {
            backgroundColor: darkColors.backgroundColor,
        },
    }),
    styleBorder: StyleSheet.create({
        border: {
            borderColor: darkColors.borderColor,
        },
    }),
    styleRow: StyleSheet.create({
        container: {
            backgroundColor: darkColors.backgroundColorRow,
        },
        colorText: {
            color: darkColors.text,
        },
    }),
    styleLogin: StyleSheet.create({
        container: {
            backgroundColor: darkColors.backgroundColorLogin,
        },
        colorText: {
            color: darkColors.text,
        },
        reverseColorText: {
            color: darkColors.reverseText,
        },
        containerInput: {
            backgroundColor: darkColors.backgroundColorInputLogin,
        },
        colorButtom: {
            backgroundColor: darkColors.backgroundColorButtonProviderLogin,
        },
    }),
    stylesBar: StyleSheet.create({
        boxShadow: {
            elevation: 5,
            shadowColor: 'rgba(0, 0, 0, 0.4)',
            shadowRadius: 2,
            shadowOpacity: 0.4,
            shadowOffset: {
                width: 0,
                height: 2,
            },
        },
    }),
    ...darkColors,
}

const lightStyleSheet = {
    styleTopBar: StyleSheet.create({
        ...baseStyleTopBar,
        container: {
            ...baseStyleTopBar.container,
            backgroundColor: lightColors.backgroundColor,
        },
        separate: {
            ...baseStyleTopBar.separate,
            tintColor: lightColors.separatorColor,
        },
        iconNotification: {
            ...baseStyleTopBar.iconNotification,
            tintColor: lightColors.iconColor,
        },
        labelText: {
            ...baseStyleTopBar.labelText,
            color: lightColors.text,
        },
        colorText: {
            color: lightColors.text,
        },
        switchColor: {
            backgroundColor: lightColors.backgroundColorSwitch,
        },
        backgroundTopMenu: {
            backgroundColor: lightColors.backgroundColorTopMenu,
        },
        backgroundTopMenuHover: {
            backgroundColor: lightColors.backgroundColorTopMenuHover,
        },
    }),
    styleSidebar: StyleSheet.create({
        ...baseStyleSidebar,
        container: {
            ...baseStyleSidebar.container,
            backgroundColor: lightColors.backgroundColorSidebar,
        },
    }),
    styleDashboard: StyleSheet.create({
        ...baseStyleDashboard,
        container: {
            ...baseStyleDashboard.container,
            backgroundColor: lightColors.backgroundColorDashboard,
        },
    }),
    baseStyleContent: StyleSheet.create({
        ...baseStyleContent,
        container: {
            ...baseStyleContent.container,
        },
    }),
    styleBackground: StyleSheet.create({
        container: {
            backgroundColor: lightColors.backgroundColorDashboard,
        },
        input: {
            backgroundColor: lightColors.backgroundColorInput,
        },
        button: {
            backgroundColor: lightColors.backgroundColorPrimaryButton,
        },
    }),
    styleModalBackground: StyleSheet.create({
        container: {
            backgroundColor: lightColors.backgroundColor,
        },
    }),
    styleBorder: StyleSheet.create({
        border: {
            borderColor: lightColors.borderColor,
        },
    }),
    styleRow: StyleSheet.create({
        container: {
            backgroundColor: lightColors.backgroundColorRow,
        },
        colorText: {
            color: lightColors.text,
        },
    }),
    styleLogin: StyleSheet.create({
        container: {
            backgroundColor: lightColors.backgroundColorLogin,
        },
        colorText: {
            color: lightColors.text,
        },
        reverseColorText: {
            color: lightColors.reverseText,
        },
        containerInput: {
            backgroundColor: lightColors.backgroundColorInputLogin,
        },
        colorButtom: {
            backgroundColor: lightColors.backgroundColorButtonProviderLogin,
        },
    }),
    stylesBar: StyleSheet.create({
        boxShadow: {
            elevation: 5,
            shadowColor: 'rgba(0, 0, 0, 0.4)',
            shadowRadius: 2,
            shadowOffset: {
                width: 0,
                height: 2,
            },
        },
    }),
    ...lightColors,
}

export const getStyleSheet = () => {
    const { theme } = useTheme()
    const isDark = theme === 'dark'
    return isDark ? darkStyleSheet : lightStyleSheet
}
