import useDropdownProjects, { Props } from './useDropdownProjects'
import React, { useContext } from 'react'
import DropdownNative from '../MultiSelectNative/MultiSelect'
import { View, Text, ScrollView } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { getStyleSheet } from '../../../assets/theme/styles'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import chroma from 'chroma-js'

const DropdownProjects: React.FC<Props> = props => {
    const [{ projects, selectedProject, onProjectChange, task }] = useDropdownProjects(props)
    const { theme } = useContext<any>(ThemeContext)
    const styles = getStyleSheet()

    const selectedProjectTemplate = (selectedProject: any) => {
        if (selectedProject?.projectID) {
            return (
                <View style={[{ flexDirection: 'row', alignItems: 'center', width: '100%' }]}>
                    <Text
                        numberOfLines={1}
                        style={{ color: theme === 'light' ? `#${selectedProject.projectColor}` : styles.text }}
                    >
                        {selectedProject.projectName}
                    </Text>
                    {selectedProject.clientName ? (
                        <>
                            <Text> - </Text>
                            <Text style={{ color: '#969696' }}>{selectedProject.clientName}</Text>
                        </>
                    ) : null}
                </View>
            )
        }

        return (
            <View
                style={{
                    flexDirection: 'row',
                    gap: 10,
                    alignItems: 'center',
                    flexGrow: 1,
                    justifyContent: 'space-between',
                }}
            >
                <Text style={{ color: styles.text }}>Project</Text>
                <FontAwesomeIcon
                    color={styles.text}
                    style={{ transform: [{ translateX: -10 }] }}
                    icon={faChevronDown}
                />
            </View>
        )
    }

    const groupedItemTemplate = (option: any) => {
        const darkLevel = theme === 'dark' ? 1 : 0
        const textColor = chroma(`#${option?.projects?.at(0).projectColor}`)
            .darken(darkLevel)
            .hex()

        return (
            <View
                style={{
                    paddingVertical: 10,
                    paddingHorizontal: 15,
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }}
            >
                <Text style={{ color: textColor, fontWeight: '500', fontSize: 15 }}>{option.clientName}</Text>
                <Text style={{ color: styles.text }}>
                    {option.projects.length} project{option.projects.length > 1 && 's'}
                </Text>
            </View>
        )
    }

    return (
        <DropdownNative
            uniqueSelection
            valueWhenUniqueSelection={selectedProject?.projectID}
            placeholder="Project"
            optionLabel="projectName"
            optionValue="clientId"
            optionGroupLabel="clientName"
            optionGroupValue="projectID"
            optionGroupChildren="projects"
            indentificator={`${task.id}-project`}
            emptyFilterMessage="No projects added"
            filterPlaceholder="Find client or project"
            options={projects}
            customStyles={{ paddingHorizontal: 10, paddingVertical: 10 }}
            containerStyles={[props.style]}
            optionGroupTemplate={groupedItemTemplate}
            valueTemplate={selectedProjectTemplate(selectedProject)}
            onChange={onProjectChange}
            disabled={props.disabledEditionFrame}
            onPress={props.onPress}
        />
    )
}

export default DropdownProjects
