import {
    TextInput,
    StyleSheet,
    Platform,
    StyleProp,
    TextStyle,
    InputModeOptions,
    TextInputKeyPressEventData,
    NativeSyntheticEvent,
    TextInputFocusEventData,
    ViewStyle,
} from 'react-native'
import { LegacyRef, forwardRef, useState } from 'react'
import { Hoverable } from 'react-native-web-hover'
import { getStyleSheet } from '../../assets/theme/styles'
import ItemSkeleton from './ItemSkeleton'

interface Input {
    name?: string
    placeholder?: string
    value: string
    secureTextEntry?: boolean
    inputMode?: InputModeOptions | undefined
    inputStyle?: StyleProp<TextStyle>
    containerStyle?: StyleProp<ViewStyle>
    disabled?: boolean
    notHovered?: boolean
    id?: string
    multiLine?: boolean
    numberOfLines?: number
    skeletonStyles?: StyleProp<ViewStyle>
    loading?: boolean
    onChange?: ((text: string) => void) | undefined
    onFocus?: () => void
    onBlur?: ((e: NativeSyntheticEvent<TextInputFocusEventData>) => void) | undefined
    onKeyPress?: ((e: NativeSyntheticEvent<TextInputKeyPressEventData>) => void) | undefined
}

export const inputStyle = Platform.OS === 'web' && { outline: 0, transition: 'all .2s ease-in-out' }

const InputText = forwardRef((props: Input, ref: LegacyRef<TextInput>) => {
    const styles = getStyleSheet()
    const [isFocused, setIsFocused] = useState(false)

    const handleTextInputChange = (text: string) => {
        props.onChange ? props.onChange(text) : null
    }

    if (props.loading) return <ItemSkeleton customStyles={props.skeletonStyles} />

    return (
        <Hoverable style={[props.containerStyle, { flexShrink: 1 }]}>
            {({ hovered }) => (
                <TextInput
                    collapsable
                    multiline={props.multiLine}
                    placeholderTextColor={styles.placeholderText}
                    numberOfLines={props.numberOfLines}
                    nativeID={props.id}
                    onFocus={() => {
                        props.onFocus ? props.onFocus() : null
                        setIsFocused(true)
                    }}
                    style={[
                        localStyles.input,
                        inputStyle as any,
                        hovered && !props.notHovered ? localStyles.hover : null,
                        localStyles.hover,
                        { backgroundColor: styles.backgroundComponent, color: styles.text },
                        props.inputStyle,
                    ]}
                    ref={ref}
                    value={props.value}
                    onChangeText={handleTextInputChange}
                    onBlur={e => {
                        props.onBlur ? props.onBlur(e) : null
                        setIsFocused(false)
                    }}
                    placeholder={props.placeholder}
                    secureTextEntry={props.secureTextEntry}
                    inputMode={props.inputMode}
                    onKeyPress={props.onKeyPress}
                    editable={!props.disabled}
                />
            )}
        </Hoverable>
    )
})

const localStyles = StyleSheet.create({
    input: {
        height: 40,
        paddingHorizontal: 15,
        borderColor: 'transparent',
        fontSize: 14,
        borderRadius: 4,
    },
    hover: {
        borderColor: '#C6D2D9',
        borderWidth: 1,
    },
})

export default InputText
