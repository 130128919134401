import awsconfig from './src/aws-exports'
import { Platform } from 'react-native'
import { ThemeProvider } from './src/assets/theme/ThemeProvider'
import { useFonts, Roboto_400Regular } from '@expo-google-fonts/roboto'
import { Amplify } from 'aws-amplify'
import { CartProvider } from './src/context/cart'
import { PlanProvider } from './src/context/plan'
import { UpgradeProvider } from './src/context/upgrade'
import { Authenticator } from '@aws-amplify/ui-react-native'
import { TaskProvider } from './src/context/TaskProvider'
import { UserProvider } from './src/context/UserProvider'
import { ProjectProvider } from './src/context/ProjectProvider'
import { TaskActiveProvider } from './src/context/TaskActiveProvider'
import { OrganizationProvider } from './src/context/OrganizationProvider'
import { GroupProvider } from './src/context/GroupProvider'
import { EnvName } from './src/enums/env-name.enum'
import { SidemenuProvider } from './src/context/SidemenuProvider'
import { Provider } from 'react-native-paper'
import Navigation from './src/navigation/Navigation'
import { StripeProvider } from './src/context/StripeProvider'
import { DatePickerProvider } from './src/context/DatePickerProvider'
import { OverlayProvider } from './src/context/OverlayProvider'

const isLocalhost =
    Platform.OS === 'web'
        ? Boolean(
              window.location.hostname === 'localhost' ||
                  // [::1] is the IPv6 localhost address.
                  window.location.hostname === '[::1]' ||
                  // 127.0.0.1/8 is considered localhost for IPv4.
                  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
          )
        : true

const [localRedirectSignIn, devRedirectSignIn, stagingRedirectSignIn, masterRedirectSignIn] =
    awsconfig.oauth.redirectSignIn.split(',')

const [localRedirectSignOut, devRedirectSignOut, stagingRedirectSignOut, masterRedirectSignOut] =
    awsconfig.oauth.redirectSignOut.split(',')

const getRedirectUrl = () => {
    if (isLocalhost) {
        return {
            redirectSignIn: localRedirectSignIn,
            redirectSignOut: localRedirectSignOut,
        }
    }

    const s3Name = awsconfig.aws_user_files_s3_bucket
    const envName = s3Name.split('-')[1]

    if (envName === EnvName.DEV) {
        return {
            redirectSignIn: devRedirectSignIn,
            redirectSignOut: devRedirectSignOut,
        }
    }

    if (envName === EnvName.PROD) {
        return {
            redirectSignIn: masterRedirectSignIn,
            redirectSignOut: masterRedirectSignOut,
        }
    }

    return {
        redirectSignIn: stagingRedirectSignIn,
        redirectSignOut: stagingRedirectSignOut,
    }
}

const updatedAwsConfig = {
    ...awsconfig,
    oauth: {
        ...awsconfig.oauth,
        redirectSignIn: getRedirectUrl().redirectSignIn,
        redirectSignOut: getRedirectUrl().redirectSignOut,
    },
}

Amplify.configure(updatedAwsConfig)

function App() {
    const [loading] = useFonts({ 'Roboto-Regular': Roboto_400Regular })
    if (!loading) return null

    return (
        <Provider>
            <ThemeProvider>
                <UserProvider>
                    <OrganizationProvider>
                        <GroupProvider>
                            <TaskProvider>
                                <ProjectProvider>
                                    <TaskActiveProvider>
                                        <PlanProvider>
                                            <CartProvider>
                                                <UpgradeProvider>
                                                    <StripeProvider>
                                                        <Authenticator.Provider>
                                                            <OverlayProvider>
                                                                <DatePickerProvider>
                                                                    <SidemenuProvider>
                                                                        <Navigation />
                                                                    </SidemenuProvider>
                                                                </DatePickerProvider>
                                                            </OverlayProvider>
                                                        </Authenticator.Provider>
                                                    </StripeProvider>
                                                </UpgradeProvider>
                                            </CartProvider>
                                        </PlanProvider>
                                    </TaskActiveProvider>
                                </ProjectProvider>
                            </TaskProvider>
                        </GroupProvider>
                    </OrganizationProvider>
                </UserProvider>
            </ThemeProvider>
        </Provider>
    )
}

export default App
