import React, { useCallback, useContext, useEffect, useRef } from 'react'
import ButtonNative from '../ButtonNative'
import { TypeTask } from '../../../models'
import { useTaskProvider } from '../../../context/TaskProvider'
import { ITask } from '../../../interfaces/task.interface'
import { useUserProvider } from '../../../context/UserProvider'
import { StatusTask } from '../../../API'
import { useBrowserNotifications } from '../../../hooks/useBrowserNotifications'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { useTaskActiveProvider } from '../../../context/TaskActiveProvider'
import { useProjectProvider } from '../../../context/ProjectProvider'
import { TagsService } from '../../../services/TagsService'
import { ListBoxProjects } from '../ListBoxProjects/ListBoxProjects'
import { ClientService } from '../../../services/ClientService'
import { useSearchTask } from '../../../hooks/useSearchTask'
import PomodoroLabel from '../PomodoroLabel/PomodoroLabel'
import MultiselectTags from '../MultiselectTags/MultiselectTags'
import moment, { Moment } from 'moment'
import useState from 'react-usestateref'
import useSound from 'use-sound'
import { getStyleSheet } from '../../../assets/theme/styles'
import { Platform, Pressable, ScrollView, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import InputText, { inputStyle } from '../InputText'
import useDimensions from '../../../hooks/useDimensions'
import { StyleSheet, Text } from 'react-native'
import { AutocompleteInput } from 'react-native-autocomplete-input'
import { debounce } from 'lodash'
import { IUserOrganization } from '../../../interfaces/user-organization.interface'
import { Hoverable } from 'react-native-web-hover'
import { useOverlayProvider } from '../../../context/OverlayProvider'
import { faClock, faSquarePlus, faSquare } from '@fortawesome/free-regular-svg-icons'
import DropdownNative from '../MultiSelectNative/MultiSelect'
import { MomentaryView } from '../DataTable'
import DatePicker from '../DatePicker/DatePicker'
import uuid from 'react-native-uuid'

const alarmMp3 = require('../../../assets/sounds/alarm.mp3')

interface Props {
    onNewTask: React.Dispatch<React.SetStateAction<boolean>>
    datesRange: Moment[]
}

const taskBarId = uuid.v4() as string

const taskNameAutocompleteId = uuid.v4() as string
const idOverlayButtonCancel = uuid.v4() as string
const idOverlayDownCount = uuid.v4() as string

const CreateTaskBar: React.FC<Props> = ({ onNewTask }) => {
    const { playTask, continueTask, saveTask, onlySaveTask, getAllTaskByUserNoLoading } = useTaskProvider()
    const {
        setActiveTask,
        activeTask,
        removeActiveTask,
        activeTaskTags,
        setActiveTaskTags,
        activeTaskProject,
        setActiveTaskProject,
    } = useTaskActiveProvider()
    const { userActiveOrganization, localUserData, timeFormat, dbUserData } = useUserProvider()
    const [onPressSkip, setOnPressSkip] = useState(false)
    const intervalRef = React.useRef<NodeJS.Timeout>()
    const [currentTaskAuto, setCurrentTaskAuto, currentTaskAutoRef] = useState<any>(null)
    const [limitTimeAuto, setLimitTimeAuto, limitTimeAutoRef] = useState<number>(0)
    const [pomodoroQuantity, setPomodoroQuantity, pomodoroQuantityRef] = useState(0)
    const [name, setName, nameRef] = useState('')
    const [selectedProject, setSelectedProject, selectedProjectRef] = useState<any>('')
    const [selectedTag, setSelectedTags, selectedTagRef] = useState<any[]>([])
    const { showBrowserNotification, permissionBrowserNotification } = useBrowserNotifications()
    const { theme, userRole, userSub, pomodoroTime, shortBreakTime, longBreakTime, automaticStartTime } =
        useContext<any>(ThemeContext)
    const { getProjectsDropDown } = useProjectProvider()
    const [allProjects, setAllProjects] = useState<any[]>([])
    const [allClients, setAllClients] = useState<any[]>([])
    const [allTags, setAllTags] = useState([])
    const [showClearAll, setShowClearAll] = useState(false)
    const [showManualTimer, setShowManualTimer] = useState(false)
    const [isActiveTimerRefresh, setIsActiveTimerRefresh, isActiveTimerRefreshRef] = useState(false)
    const [dateManualTimer, setDateManualTimer] = useState(moment().toDate())
    const [timeInitManualTimer, setTimeInitManualTimer] = useState('00:00')
    const [timeEndManualTimer, setTimeEndManualTimer] = useState('00:00')
    const [timeIntervalManualTimer, setTimeIntervalManualTimer] = useState('00:00:00')
    const [loadingAddButton, setLoadingAddButton] = useState(false)
    const taskNameRef = useRef<any>(null)
    const inputInitTimeManualRef = useRef<any>(null)
    const inputEndTimeManualRef = useRef<any>(null)
    const [play] = useSound(alarmMp3)
    const [secondsPass, setSecondsPass, secondsPassRef] = useState<number>(0)
    const [arrayIdIntervals, setArrayIdIntervals, arrayIdIntervalsRef] = useState<any>([])
    const [pressSkipAction, setPressSkipAction, pressSkipActionRef] = useState(false)
    const [pressBreakAction, setPressBreakAction, pressBreakActionRef] = useState(false)
    const [currentCreatedDate, setCurrentCreatedDate, currentCreatedDateRef] = useState<string>('')
    const [localActiveTask, setLocalActiveTask, localActiveTaskRef] = useState<ITask>()
    const [localActiveTaskTags, setLocalActiveTaskTags, localActiveTaskTagsRef] = useState<any>()
    const [localActiveTaskProject, setLocalActiveTaskProject, localActiveTaskProjectRef] = useState<any>()
    const { search, searchResults } = useSearchTask()
    const limitTimePomodoro = pomodoroTime
    const limitTimeSBreak = shortBreakTime
    const limitTimeLBreak = longBreakTime
    const limitTimeAutoStart = automaticStartTime
    const { windowWidth } = useDimensions()
    const { activeId, isShowOverlay, closeShowOverlay, handleShowOverlay } = useOverlayProvider()
    const isVisibleDropdown = activeId === taskNameAutocompleteId && isShowOverlay
    const [taskBarWidth, setTaskBarWidth] = useState(0)
    const styles = getStyleSheet()

    useEffect(() => {
        setLocalActiveTask(activeTask)
        setLocalActiveTaskTags(activeTaskTags)
        setLocalActiveTaskProject(activeTaskProject)
        if (activeTask?.name) setName(activeTask?.name)
    }, [activeTask])

    const onHandleSkip = () => {
        const newCreatedDateTask = currentTaskAutoRef.current?.createdAt
        stopInterval()
        setOnPressSkip(true)
        setPressSkipAction(true)

        onHandleCreate(TypeTask.FOCUS, newCreatedDateTask)
    }

    useEffect(() => {
        if (isVisibleDropdown) callback(name, userActiveOrganization)
    }, [name, isVisibleDropdown])

    const requiredStyleComponent = (elementRef: any) => {
        if (elementRef?.current) {
            elementRef.current.focus()

            if (Platform.OS === 'web') {
                elementRef.current.style.borderColor = '#E24C4C'
                elementRef.current.style.color = '#E24C4C'
                elementRef.current.style.transitionDuration = '1s'

                setTimeout(() => {
                    elementRef.current.style.borderColor = null
                    elementRef.current.style.color = null
                    elementRef.current.style.borderColor = null
                    elementRef.current.style.transitionDuration = null
                }, 4000)
            }
        }
    }

    const timeCalculate = (task: ITask) => {
        if (task) {
            const timeDiff = moment().diff(moment(task.createdAt))
            const timeDiffSecond = moment().diff(moment(task.createdAt), 'seconds')

            setActiveTask({
                ...task,
                countDown: moment.utc(timeDiff).format('HH:mm:ss'),
            })

            return {
                timeDiffSecond,
            }
        }
    }

    const timeCalculateAuto = async () => {
        const timeDiffSecondAuto = moment().diff(moment(currentTaskAutoRef.current.createdAt), 'seconds')

        setSecondsPass(timeDiffSecondAuto)
        if (timeDiffSecondAuto >= limitTimeAutoRef.current) {
            await completedCycleAuto()
        }
    }

    const startTimerAuto = async () => {
        await timeCalculateAuto()
        onNewTask(true)

        intervalRef.current = setInterval(async () => {
            await timeCalculateAuto()
        }, 1000)

        setArrayIdIntervals(current => [...current, intervalRef.current])
    }

    const startTimer = async (task: ITask) => {
        timeCalculate(task)
        onNewTask(true)

        const setIntervalRef = setInterval(async () => {
            const timeDiffSecond = timeCalculate(task)?.timeDiffSecond

            if (!localActiveTaskRef.current) {
                clearInterval(setIntervalRef)
                clearComponents()
                return
            }
            if (localActiveTaskRef.current?.limitTime) {
                if (timeDiffSecond && timeDiffSecond > localActiveTaskRef.current.limitTime) {
                    clearInterval(setIntervalRef)
                    await completedCycle()
                }
            }
        }, 1000)

        setArrayIdIntervals(current => [...current, setIntervalRef])
    }

    const stopInterval = () => {
        onNewTask(false)
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
        }

        if (intervalRef.current?.ref) {
            clearInterval(intervalRef.current?.ref())
        }
        while (arrayIdIntervalsRef.current.length) {
            clearInterval(arrayIdIntervalsRef.current.pop())
        }
    }

    const resetTimer = () => {
        if (localActiveTaskRef.current) {
            const taskType = localActiveTaskRef.current.type
            let newType = taskType

            if (taskType === TypeTask.FOCUS) {
                setPomodoroQuantity(pomodoroQuantityRef.current + 1)
                newType = pomodoroQuantityRef.current % 4 == 0 ? TypeTask.LBREAK : TypeTask.SBREAK
            } else if (taskType === TypeTask.SBREAK || taskType === TypeTask.LBREAK) {
                newType = TypeTask.FOCUS
            }

            setActiveTask({
                ...localActiveTaskRef.current,
                type: newType,
                status: '',
                countDown: '',
            })
            setOnPressSkip(false)
        }
    }

    const clearComponents = async () => {
        setActiveTask(undefined)
        setName('')
        setSelectedProject(null)
        setSelectedTags([])
        setPomodoroQuantity(0)
        stopInterval()
        removeActiveTask()
        setCurrentTaskAuto(null)
        setOnPressSkip(false)
        setShowClearAll(false)
        setSecondsPass(0)
    }

    const completedCycleAuto = async () => {
        setOnPressSkip(true)
        removeActiveTask()
        stopInterval()
        setSecondsPass(0)

        if (selectedTagRef?.current && selectedTagRef?.current.length > 0) setActiveTaskTags(selectedTagRef?.current)

        await onHandleCreate(TypeTask.FOCUS, currentTaskAutoRef.current.createdAt)
    }

    const completedCycle = async () => {
        if (!localActiveTaskRef.current) {
            return
        }
        const taskType = localActiveTaskRef.current.type
        const task = {
            ...localActiveTaskRef.current,
            createdAt:
                currentCreatedDateRef.current.length > 3
                    ? currentCreatedDateRef.current
                    : localActiveTaskRef.current.createdAt,
            time: localActiveTaskRef.current.countDown,
            name: nameRef.current,
        }

        if (task?.state) {
            delete task.state
        }

        if (selectedProjectRef?.current) {
            task['projectID'] = selectedProjectRef.current?.projectID
        }

        try {
            await saveTask(task, StatusTask.COMPLETED, selectedTagRef.current)

            resetTimer()
            const getURL = window.location.protocol + '//' + window.location.host

            play()
            if (taskType === TypeTask.FOCUS) {
                await showBrowserNotification({ title: 'Timerz', message: 'Time to take a short break!', url: getURL })
                let taskAuto = localActiveTaskRef.current
                taskAuto.createdAt = moment().format('YYYY-MM-DDTHH:mm:ssZ')
                ;(taskAuto.status = StatusTask.RUNNING), (taskAuto.state = 'START')

                setCurrentTaskAuto(taskAuto)
                setLimitTimeAuto(
                    moment().add(limitTimeAutoStart, 'seconds').diff(moment(taskAuto?.createdAt), 'seconds')
                )
                if (pressBreakActionRef.current) {
                    setPressBreakAction(false)
                    setCurrentCreatedDate('')
                }
            } else if (taskType === TypeTask.SBREAK) {
                await showBrowserNotification({ title: 'Timerz', message: 'Time to focus!', url: getURL })
            } else if (taskType === TypeTask.LBREAK) {
                await showBrowserNotification({ title: 'Timerz', message: 'Time to focus!', url: getURL })
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        if (typeof continueTask !== 'undefined' && Object.keys(continueTask).length !== 0) {
            const { name, project, arrayTags } = continueTask
            setName(name)

            let projectFormat = {
                projectColor: project.projectColor,
                projectID: project.projectID,
                projectName: project.projectName,
            }

            setSelectedProject(projectFormat)
            if (projectFormat && projectFormat.projectName) setActiveTaskProject(projectFormat)

            let arrayFormat = arrayTags.map(item => {
                return {
                    value: item.id,
                    label: item.name,
                    color: item.color.includes('#') ? item.color : '#' + item.color,
                }
            })

            setSelectedTags(arrayFormat)
            if (arrayFormat && arrayFormat.length > 0) setActiveTaskTags(arrayFormat)

            checkAllFields()

            if (continueTask.continue) {
                handleManualTimer('timer')
                onHandleCreate(TypeTask.FOCUS)
            }
            continueTask.continue = false
        }

        return () => clearInterval(intervalRef.current as NodeJS.Timeout)
    }, [continueTask])

    useEffect(() => {
        if (allProjects.length > 0) {
            if (!isActiveTimerRefreshRef.current) {
                if (!selectedProjectRef.current) {
                    if (localActiveTaskProjectRef?.current) {
                        setSelectedProject(localActiveTaskProjectRef?.current)
                    }

                    if (localActiveTaskTagsRef?.current && localActiveTaskTagsRef.current.length > 0) {
                        setSelectedTags(localActiveTaskTagsRef.current)
                    }

                    if (localActiveTaskRef?.current) {
                        setName(localActiveTaskRef?.current.name)
                        setIsActiveTimerRefresh(true)
                        // Decidiendo si la tarea arrancará automaticamente
                        if (localActiveTaskRef.current?.status === StatusTask.RUNNING)
                            startTimer(localActiveTaskRef.current)
                    }

                    checkAllFields()
                }
            }
        }
    }, [allProjects, selectedProjectRef.current, localActiveTaskRef.current])

    useFocusEffect(
        useCallback(() => {
            if (localUserData?.sub && userActiveOrganization) {
                getProjectsDropDown(localUserData.sub, userActiveOrganization).then(resp => {
                    setAllProjects(resp)
                })

                ClientService()
                    .getClientsDropDown(userSub)
                    .then(data => {
                        setAllClients(data)
                    })

                if (allTags.length === 0) {
                    TagsService()
                        .getAllTags(localUserData.sub)
                        .then(data => {
                            setAllTags(data)
                        })
                }
                permissionBrowserNotification()
            }
        }, [localUserData, userActiveOrganization])
    )

    const checkAllFields = () => {
        if (!name && !selectedProjectRef.current && !selectedTagRef.current.length) {
            setShowClearAll(false)
            return
        }
        setShowClearAll(true)
    }

    const onProjectChange = async project => {
        setSelectedProject(project)
        setActiveTaskProject(project)
        checkAllFields()
    }

    const onTagsChange = async selectedTags => {
        if (!activeTask) {
            setSelectedTags(selectedTags)
            setActiveTaskTags(selectedTags)
            checkAllFields()
            return
        }

        if (activeTask?.TagsTask?.items?.length > 0) {
            setSelectedTags([])
        }

        if (selectedTags.length === 0) {
            checkAllFields()
            return
        }

        setSelectedTags(selectedTags)
        setActiveTaskTags(selectedTags)
        checkAllFields()
    }

    const onHandleClearAll = async () => {
        clearComponents()
    }

    const onHandleCreate = async (type: TypeTask, createdDate?: string) => {
        if (!userSub || !userActiveOrganization?.organizationID) return

        setShowClearAll(true)
        closeShowOverlay()

        const task: ITask = {
            id: uuid.v4(),
            name: nameRef.current,
            usersub: userSub,
            organizationID: userActiveOrganization.organizationID,
            type,
            createdAt: createdDate ? createdDate : moment().format('YYYY-MM-DDTHH:mm:ssZ'),
            status: StatusTask.RUNNING,
            state: 'START',
            countDown: '00:00:00',
        } as any

        if (selectedProjectRef?.current) {
            task['projectID'] = selectedProjectRef.current?.projectID
        }

        const taskType = task.type
        if (taskType === TypeTask.FOCUS) {
            if (createdDate) {
                if (pressSkipActionRef.current) {
                    setPressSkipAction(false)
                    let secondsAutoPass = secondsPassRef?.current || 0
                    task['limitTime'] =
                        moment().add(limitTimePomodoro, 'seconds').diff(moment(task?.createdAt), 'seconds') -
                        secondsAutoPass
                } else {
                    if (currentTaskAutoRef?.current) {
                        let newTimePomodoro = moment()
                            .add(limitTimePomodoro, 'seconds')
                            .diff(moment(task?.createdAt), 'seconds')
                        newTimePomodoro = newTimePomodoro - limitTimeAutoStart
                        task['limitTime'] = newTimePomodoro
                    }
                }
            } else {
                if (pressBreakActionRef.current) {
                    if (secondsPassRef.current > 2) {
                        task['limitTime'] = moment()
                            .add(limitTimePomodoro, 'seconds')
                            .diff(moment(task?.createdAt), 'seconds')
                        setCurrentCreatedDate(task?.createdAt as string)
                        let taskStartdate = moment(task?.createdAt)
                        let newDateTask = moment(taskStartdate, 'YYYY-MM-DDTHH:mm:ssZ').subtract(
                            secondsPassRef.current,
                            'seconds'
                        )
                        task.createdAt = newDateTask.format('YYYY-MM-DDTHH:mm:ssZ')
                    } else {
                        task['limitTime'] = limitTimePomodoro
                    }
                } else {
                    task['limitTime'] = moment()
                        .add(limitTimePomodoro, 'seconds')
                        .diff(moment(task?.createdAt), 'seconds')
                }
            }
        } else if (taskType === TypeTask.SBREAK) {
            task['limitTime'] = moment().add(limitTimeSBreak, 'seconds').diff(moment(task?.createdAt), 'seconds')
        } else if (taskType === TypeTask.LBREAK) {
            task['limitTime'] = moment().add(limitTimeLBreak, 'seconds').diff(moment(task?.createdAt), 'seconds')
        }

        const timeDiff = moment().diff(moment(task.createdAt))

        task.countDown = moment.utc(timeDiff).format('HH:mm:ss')

        setActiveTask({
            ...task,
        })

        if (type === TypeTask.FOCUS || type === TypeTask.SBREAK || type === TypeTask.LBREAK) {
            startTimer(task)
        }
    }

    const onHandleStop = async () => {
        if (!localActiveTaskRef.current) {
            return
        }
        const task = { ...localActiveTaskRef.current }
        if (task?.state) {
            delete task.state
        }

        if (selectedProjectRef?.current) {
            task['projectID'] = selectedProjectRef.current?.projectID
        }

        saveTask(
            {
                ...task,
                createdAt:
                    currentCreatedDateRef.current.length > 3
                        ? currentCreatedDateRef.current
                        : localActiveTaskRef.current.createdAt,
                time: task.countDown,
                name: nameRef.current,
            },
            StatusTask.FINISHED,
            selectedTagRef.current
        )
            .then(() => {
                if (pressBreakActionRef.current) {
                    setPressBreakAction(false)
                    setCurrentCreatedDate('')
                }
            })
            .catch(err => {})
        clearComponents()
    }

    const onHandleBreak = (typeTask: TypeTask) => {
        stopInterval()
        setPressBreakAction(true)
        onHandleCreate(typeTask)
    }

    const handleKeyDownStartTask = event => {
        if (event.key === 'Enter') {
            if (showManualTimer) return
            onHandleCreate(TypeTask.FOCUS)
        }
    }

    useEffect(() => {
        if (
            currentTaskAutoRef.current &&
            currentTaskAutoRef.current?.state === 'START' &&
            limitTimeAutoRef.current !== 0
        ) {
            stopInterval()
            startTimerAuto()
        }
    }, [currentTaskAutoRef.current, limitTimeAuto])

    useFocusEffect(
        useCallback(() => {
            handleManualTimer('timer')
        }, [timeFormat])
    )

    const dropdownHide = async () => {
        clearComponents()
    }

    const countDownContent = () => {
        if (localActiveTaskRef.current?.type === 'SBREAK' && localActiveTaskRef.current?.status === '') return

        const timeCreated = moment(localActiveTaskRef.current?.createdAt).format('HH:mm:ss')
        const dayCreated = moment(localActiveTaskRef.current?.createdAt).isSame(moment(), 'day')
            ? 'Today'
            : moment(localActiveTaskRef.current?.createdAt).isSame(moment().subtract(1, 'day'), 'day')
            ? 'Yesterday'
            : moment(localActiveTaskRef.current?.createdAt).format('MM/DD/YYYY')
        return (
            <DropdownNative
                indentificator={idOverlayDownCount}
                onChange={() => {}}
                optionLabel=""
                optionValue=""
                options={[]}
                containerStyles={{ flexGrow: 0 }}
                customStyles={{ paddingHorizontal: 16, paddingVertical: 10 }}
                placeholder=""
                valueTemplate={
                    <Text
                        style={{
                            fontSize: 20,
                            color:
                                localActiveTaskRef.current?.state === 'START' && theme === 'light'
                                    ? localActiveTaskRef.current?.type === TypeTask.FOCUS
                                        ? styles.countColorRed
                                        : styles.backgroundColorPrimaryButton
                                    : styles.text,
                        }}
                    >
                        {localActiveTaskRef.current?.countDown || '00:00:00'}
                    </Text>
                }
            >
                <View
                    style={[
                        { padding: 12, backgroundColor: styles.backgroundColorRow },
                        windowWidth > 500 ? { left: 'auto', right: 0 } : null,
                    ]}
                >
                    <Text numberOfLines={1} style={{ fontSize: 16, color: 'rgb(150, 150, 150)' }}>
                        Start Time: <Text style={{ color: styles.text }}>{timeCreated}</Text> - {dayCreated}
                    </Text>
                </View>
            </DropdownNative>
        )
    }

    const cancelButton = () => {
        return (
            <DropdownNative
                indentificator={idOverlayButtonCancel}
                isShowDownButton={false}
                onChange={() => {}}
                optionLabel=""
                optionValue=""
                options={[]}
                containerStyles={{ flexGrow: 0 }}
                buttonTitleStyle={{ color: '#FFF' }}
                customStyles={{ paddingHorizontal: 10, paddingVertical: 8, backgroundColor: '#dc3545' }}
                placeholder="DELETE"
            >
                <View style={[{ backgroundColor: styles.backgroundColorRow }, localStyles.containerDropdownTaskDelete]}>
                    <Text numberOfLines={1} style={{ color: styles.text }}>
                        Are you sure?
                    </Text>
                    <ButtonNative
                        title="Delete"
                        titleStyle={{ color: '#fff' }}
                        containerStyle={{ marginLeft: 20 }}
                        primaryTheme
                        onPress={dropdownHide}
                    />
                </View>
            </DropdownNative>
        )
    }

    const controlsButtons = () => {
        if (localActiveTaskRef.current?.countDown) {
            return (
                <View style={{ flexDirection: 'row', gap: 10 }}>
                    <ButtonNative title="STOP" buttonStyle={{ backgroundColor: '#dbd8d8' }} onPress={onHandleStop} />
                    {cancelButton()}
                </View>
            )
        }

        if (!localActiveTaskRef.current) {
            return <></>
        }

        const isBreak = [TypeTask.SBREAK, TypeTask.LBREAK].includes(localActiveTaskRef.current?.type as TypeTask)

        if (isBreak) {
            const label = `START ${localActiveTaskRef.current?.type === TypeTask.SBREAK ? 'SHORT' : 'LONG'} BREAK`
            return (
                <View style={{ flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                    <ButtonNative
                        containerStyle={{ borderColor: '#4B4D55', borderWidth: 1 }}
                        titleStyle={{ color: styles.backgroundColorPrimaryButton }}
                        buttonStyle={{ backgroundColor: styles.backgroundColor }}
                        onPress={() => onHandleSkip()}
                        title="SKIP BREAK"
                    />
                    <ButtonNative
                        titleStyle={{ color: '#fff' }}
                        buttonStyle={{ backgroundColor: styles.backgroundColorPrimaryButton }}
                        onPress={() => onHandleBreak(localActiveTaskRef.current?.type as TypeTask)}
                        title={label}
                    />
                </View>
            )
        }

        if (onPressSkip) {
            return (
                <>
                    <ButtonNative
                        icon={faSquare}
                        iconColor="#d9d9d9"
                        showIcon
                        iconSize={25}
                        buttonStyle={{ borderWidth: 0, padding: 0 }}
                        containerStyle={{ marginRight: 15, width: 22 }}
                        onPress={() => onHandleStop()}
                    />
                    {cancelButton()}
                </>
            )
        }

        if (showClearAll && !showManualTimer) {
            return (
                <>
                    <ButtonNative
                        containerStyle={{
                            borderColor: '#4B4D55',
                            borderWidth: 1,
                            backgroundColor: styles.backgroundColor,
                        }}
                        titleStyle={{ color: '#D00500' }}
                        onPress={onHandleClearAll}
                        title="CLEAR ALL"
                    />
                </>
            )
        }
    }

    const selectTask = (task: ITask) => {
        const arrayTags = task.TagsTask.items
            .filter(t => !t._deleted)
            .map(t => {
                return { id: t.tag.id, name: t.tag.name, code: t.tag.name, color: t.tag.color }
            })
            .sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()))

        playTask({
            name: task.name,
            project: {
                clientId: task.project?.clientID,
                clientName: task.project?.client?.name || '',
                projectColor: task.project?.groupcolor,
                projectID: task.project?.id,
                projectName: task.project?.name,
            },
            arrayTags: arrayTags,
            continue: !showManualTimer,
        })
    }

    const calculateTimeManualTimer = (value, timeType) => {
        let startTime: Moment | any
        let endTime: Moment | any

        if (timeType === 'init') {
            startTime = moment(value, timeFormat)
            endTime = moment(timeEndManualTimer, timeFormat)
            setTimeInitManualTimer(value)
        } else {
            startTime = moment(timeInitManualTimer, timeFormat)
            endTime = moment(value, timeFormat)
            setTimeEndManualTimer(value)
        }

        const duration = moment.duration(endTime.diff(startTime))
        const calculateTime = moment.utc(duration.asMilliseconds()).format('HH:mm:ss')
        setTimeIntervalManualTimer(calculateTime)
    }

    const controlManualTimer = () => {
        return (
            <View style={{ display: 'flex', flexDirection: 'row', flexGrow: 1, justifyContent: 'space-around' }}>
                <View style={{ flexDirection: 'row' }}>
                    <InputText
                        ref={inputInitTimeManualRef}
                        containerStyle={{ width: 70 }}
                        inputStyle={{ textAlign: 'center', paddingHorizontal: 0 }}
                        value={timeInitManualTimer}
                        onChange={e => calculateTimeManualTimer(e.valueOf(), 'init')}
                    />
                    <Text style={{ alignSelf: 'center', paddingHorizontal: 5, color: styles.text }}>-</Text>
                    <InputText
                        inputStyle={{ paddingHorizontal: 0, textAlign: 'center' }}
                        containerStyle={{ width: 70 }}
                        value={timeEndManualTimer}
                        onChange={e => calculateTimeManualTimer(e.valueOf(), 'end')}
                    />
                </View>

                <DatePicker
                    identificator={taskBarId}
                    value={new Date(dateManualTimer)}
                    onChange={e => {
                        setDateManualTimer(e.toString() as any)
                    }}
                />

                <InputText
                    containerStyle={[{ width: 88, display: windowWidth > 1300 ? 'flex' : 'none' }]}
                    inputStyle={{ borderWidth: 0, paddingHorizontal: 0 }}
                    value={moment(dateManualTimer).format('DD/MM/YYYY')}
                    disabled
                />
                <InputText
                    value={timeIntervalManualTimer}
                    containerStyle={{ width: 70 }}
                    inputStyle={[
                        { fontSize: 18, borderWidth: 0, paddingHorizontal: 0 },
                        windowWidth > 1300 ? { transform: [{ translateY: -1 }] } : null,
                    ]}
                    disabled
                />
            </View>
        )
    }

    const handleManualTimer = value => {
        if (value === 'manual') {
            const currentDate = moment().format(timeFormat)
            setShowManualTimer(true)
            setTimeInitManualTimer(currentDate)
            setTimeEndManualTimer(currentDate)
            setDateManualTimer(new Date())
        } else {
            setShowManualTimer(false)
            setTimeIntervalManualTimer('00:00:00')
        }
    }

    const transformTimeToNumber = (time: string) => {
        if (!time.includes(':')) return 0
        let splitTime = time.split(':')
        let hoursDecimal = parseInt(splitTime[0]) * 3600
        let minutesDecimal = parseInt(splitTime[1]) * 60
        let secondsDecimal = parseInt(splitTime[2])
        let totalTime = hoursDecimal + minutesDecimal + secondsDecimal
        return totalTime
    }

    const checkIntervalManualTime = () => {
        const timeInitMoment = moment(timeInitManualTimer, 'HH:mm A')
        const timeEndMoment = moment(timeEndManualTimer, 'HH:mm A')
        const diffByMinutes = Math.abs(timeInitMoment.diff(timeEndMoment, 'minutes'))
        return diffByMinutes > 0
    }

    const onHandleManualTask = async () => {
        if (!checkIntervalManualTime()) {
            requiredStyleComponent(inputInitTimeManualRef)
            return
        }
        setLoadingAddButton(true)

        const intervalTimeManualToNumber = transformTimeToNumber(timeIntervalManualTimer)

        const dateStartTime = moment(timeInitManualTimer, 'hh:mm A')
        // const dateEndTime = moment(timeEndManualTimer, 'hh:mm A')

        const newCreatedDate = moment(dateManualTimer)
            .set({
                hour: dateStartTime.hour(),
                minute: dateStartTime.minute(),
            })
            .format('YYYY-MM-DDTHH:mmZ')

        // const endManualCreatedDate = moment(dateManualTimer).set({
        //     hour: dateEndTime.hour(),
        //     minute: dateEndTime.minute(),
        // })

        const task = {
            name: nameRef.current,
            usersub: userSub,
            organizationID: userActiveOrganization?.organizationID || '',
            type: TypeTask.FOCUS,
            createdAt: newCreatedDate,
            time: timeIntervalManualTimer,
            hasTimeEdited: true,
        }

        if (selectedProjectRef?.current) {
            task['projectID'] = selectedProjectRef.current?.projectID
        }

        if (intervalTimeManualToNumber === limitTimePomodoro) {
            await saveTask(task, StatusTask.COMPLETED, selectedTagRef.current)
        } else {
            await saveTask(task, StatusTask.FINISHED, selectedTagRef.current)
        }

        // if (intervalTimeManualToNumber > limitTimePomodoro) {
        //     await createRecordsFromManualTask(task, intervalTimeManualToNumber, endManualCreatedDate)
        // } else {
        //     if (intervalTimeManualToNumber === limitTimePomodoro) {
        //         await saveTask(task, StatusTask.COMPLETED, selectedTagRef.current)
        //     } else {
        //         await saveTask(task, StatusTask.FINISHED, selectedTagRef.current)
        //     }
        // }

        clearComponents()
        setLoadingAddButton(false)
    }

    const ItemTemplate = ({ item }: { item: ITask }) => {
        return (
            <Pressable
                style={{ backgroundColor: styles.backgroundColorRow, width: '100%' }}
                onPress={() => {
                    selectTask(item)
                    closeShowOverlay()
                }}
            >
                <View style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}>
                    <Text
                        numberOfLines={1}
                        style={{
                            paddingVertical: 10,
                            paddingLeft: 10,
                            color: styles.text,
                            fontSize: 16,
                            maxWidth: '33%',
                        }}
                    >
                        {item.name}
                    </Text>

                    {item?.project?.name ? (
                        <>
                            <Text numberOfLines={1} style={{ fontSize: 16, color: '#FF6347', maxWidth: '33%' }}>
                                • {item?.project?.name}
                            </Text>
                        </>
                    ) : null}
                    {item?.project?.client?.name ? (
                        <Text numberOfLines={1} style={{ fontSize: 16, color: '#969696', maxWidth: '33%' }}>
                            - {item?.project?.client?.name}
                        </Text>
                    ) : null}
                </View>
            </Pressable>
        )
    }

    const RenderTaskOptions = () => {
        return (
            <ScrollView style={[{ maxHeight: 300, backgroundColor: styles.backgroundColorRow }, localStyles.dropdown]}>
                {searchResults?.map(option => (
                    <ItemTemplate item={option} />
                ))}
            </ScrollView>
        )
    }

    const callback = useCallback(
        debounce(
            (value: string, userActiveOrganization?: IUserOrganization) => search(value, userActiveOrganization),
            500
        ),
        []
    )

    const LeftContents = () => {
        return (
            <View
                style={[
                    { gap: 4, marginTop: 10 },
                    windowWidth > 500 ? { gap: 10 } : null,
                    windowWidth > 1200 ? { flexDirection: 'row', gap: 20, alignItems: 'center' } : null,
                ]}
            >
                <MomentaryView
                    showViewUp={1200}
                    customStyles={[
                        { gap: 5, zIndex: 3000 },
                        windowWidth > 500 ? { flexDirection: 'row', gap: 0 } : null,
                    ]}
                >
                    <Hoverable
                        style={{
                            flexGrow: 1,
                            height: 43,
                            zIndex: 3000,
                            width: windowWidth > 500 && windowWidth <= 1200 ? '70%' : 'auto',
                        }}
                    >
                        {({ hovered }) => (
                            <Pressable>
                                <AutocompleteInput
                                    value={name}
                                    data={isVisibleDropdown ? searchResults : []}
                                    onKeyPress={handleKeyDownStartTask}
                                    onFocus={() => handleShowOverlay(taskNameAutocompleteId)}
                                    onBlur={checkAllFields}
                                    placeholder="What task will you work on?"
                                    placeholderTextColor={styles.placeholderText}
                                    onChangeText={e => {
                                        if (localActiveTaskRef.current?.countDown) return
                                        setName(e.valueOf())
                                    }}
                                    renderResultList={() => RenderTaskOptions()}
                                    inputContainerStyle={{ borderWidth: 0 }}
                                    style={[
                                        {
                                            padding: 12,
                                            color: styles.text,
                                            backgroundColor: styles.backgroundColorRow,
                                            borderColor: '#c6d2d9',
                                            borderWidth: 1,
                                            borderRadius: 6,
                                        },
                                        inputStyle as any,
                                    ]}
                                    flatListProps={{
                                        keyExtractor: (item: any) => item.id,
                                        renderItem: ({ item }: { item: any }) => <ItemTemplate item={item} />,
                                    }}
                                />
                            </Pressable>
                        )}
                    </Hoverable>

                    <MomentaryView showViewUp={500} customStyles={{ flexDirection: 'row' }}>
                        <ListBoxProjects
                            customStyles={[
                                {
                                    width: windowWidth <= 500 ? '50%' : '30%',
                                },
                                windowWidth > 1200 ? { width: 'auto', maxWidth: '20%', flexGrow: 0 } : null,
                            ]}
                            allProjects={allProjects || []}
                            allClients={allClients || []}
                            selectedProject={selectedProjectRef.current}
                            userSub={userSub}
                            userRole={userRole}
                            onProjectChange={onProjectChange}
                            themeColor={theme}
                        />

                        {windowWidth <= 500 ? (
                            <MultiselectTags
                                customStyles={{ zIndex: 100, width: '50%' }}
                                tags={allTags}
                                value={selectedTagRef.current}
                                onChangeTag={value => onTagsChange(value)}
                                indentificator="taskbar"
                            />
                        ) : null}
                    </MomentaryView>
                </MomentaryView>

                <MomentaryView showViewUp={1200} customStyles={[localStyles.controlsRow]}>
                    {windowWidth > 500 ? (
                        <MultiselectTags
                            customStyles={[
                                {
                                    zIndex: 100,
                                    maxWidth: windowWidth > 500 && windowWidth < 1200 ? '20%' : 'auto',
                                },
                                windowWidth > 1200 ? { maxWidth: '20%' } : null,
                            ]}
                            tags={allTags}
                            value={selectedTagRef.current}
                            onChangeTag={value => onTagsChange(value)}
                            indentificator="taskbar"
                        />
                    ) : null}

                    <View
                        style={[
                            localStyles.controlButtons,
                            {
                                flexGrow: 1,
                                justifyContent: 'space-between',
                                gap: 7,
                            },
                            windowWidth > 1200 ? { flexGrow: 0, justifyContent: 'flex-start' } : null,
                        ]}
                    >
                        {showManualTimer ? controlManualTimer() : <PomodoroLabel label={pomodoroQuantity} />}
                        {!showManualTimer ? countDownContent() : null}
                        {controlsButtons()}

                        {!localActiveTaskRef.current?.countDown &&
                            localActiveTaskRef.current?.type !== TypeTask.SBREAK &&
                            localActiveTaskRef.current?.type !== TypeTask.LBREAK &&
                            !onPressSkip && (
                                <>
                                    {showManualTimer ? (
                                        <>
                                            {windowWidth > 850 ? (
                                                <ButtonNative
                                                    title="ADD"
                                                    onPress={() => onHandleManualTask()}
                                                    loading={loadingAddButton}
                                                    titleStyle={{ color: '#fff' }}
                                                    primaryTheme
                                                    containerStyle={{
                                                        marginLeft: 0,
                                                        marginRight: 5,
                                                    }}
                                                />
                                            ) : null}
                                        </>
                                    ) : (
                                        <ButtonNative
                                            title="START"
                                            disabled={dbUserData?.disabled}
                                            onPress={() => onHandleCreate(TypeTask.FOCUS)}
                                            loading={loadingAddButton}
                                            titleStyle={{ color: '#fff' }}
                                            primaryTheme
                                        />
                                    )}

                                    {Platform.OS === 'web' ? (
                                        <View style={[{ gap: 4 }]}>
                                            <ButtonNative
                                                title=""
                                                showIcon
                                                iconSize={16}
                                                icon={faClock}
                                                buttonStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
                                                onPress={() => handleManualTimer('timer')}
                                            />

                                            <ButtonNative
                                                title=""
                                                showIcon
                                                iconSize={16}
                                                icon={faSquarePlus}
                                                buttonStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
                                                onPress={() => handleManualTimer('manual')}
                                            />
                                        </View>
                                    ) : null}
                                </>
                            )}
                    </View>
                </MomentaryView>
            </View>
        )
    }

    return (
        <View
            onLayout={({ nativeEvent }) => setTaskBarWidth(nativeEvent.layout.width)}
            style={[{ backgroundColor: styles.backgroundColorRow }, localStyles.container, styles.stylesBar.boxShadow]}
        >
            <View style={{ width: taskBarWidth, paddingHorizontal: windowWidth > 1200 ? 15 : 10 }}>
                {LeftContents()}

                {showManualTimer && windowWidth < 850 ? (
                    <View style={{ alignItems: 'flex-end', backgroundColor: styles.backgroundComponent }}>
                        <ButtonNative
                            title="ADD"
                            onPress={() => onHandleManualTask()}
                            loading={loadingAddButton}
                            primaryTheme
                            titleStyle={{ color: '#fff' }}
                            containerStyle={{
                                marginRight: windowWidth > 500 ? 5 : 0,
                                marginVertical: 5,
                            }}
                        />
                    </View>
                ) : null}
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        zIndex: 20,
        paddingBottom: 6,
    },
    containerAbsolute: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
        alignItems: 'flex-end',
    },
    containerDropdownTaskDelete: {
        flexDirection: 'row',
        alignItems: 'center',
        padding: 10,
        borderRadius: 5,
        shadowColor: 'rgba(0, 0, 0, 0.4)',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowRadius: 15,
        elevation: 5,
    },
    dropdown: {
        shadowColor: 'rgba(0, 0, 0, 0.4)',
        elevation: 5,
        shadowRadius: 8,
        shadowOffset: {
            width: 0,
            height: 4,
        },
    },
    controlButtons: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    controlsRow: {
        flexDirection: 'row',
        paddingHorizontal: 10,
        gap: 15,
        zIndex: 2000,
        alignItems: 'center',
    },
})

export default CreateTaskBar
