import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { StylesConfig } from 'react-select'
import { darkColors, lightColors } from '../../../assets/theme/colorThemes'
import chroma from 'chroma-js'

export interface ColourOption {
    readonly value: string
    readonly label: string
    readonly color: string
    readonly isFixed?: boolean
    readonly isDisabled?: boolean
}

export interface Props {
    onChangeTag: (value: any) => void
    allTags?: any
    tagsSelected?: any
    key?: string
    rowData?: any
    disabled?: boolean
    tdSelectStyle?: boolean
    clearComponent?: boolean
    className?: string
    containerStyle?: StyleProp<ViewStyle>
}

type Tag = {
    color: string
    label: string
    value: string
}

const useMultiselectTags = (props: Props) => {
    const { theme, userSub } = useContext<any>(ThemeContext)
    const [tags, setTags] = useState<Tag[] | []>([])
    const [tagsMultiselect, setTagsMultiselect] = useState([])
    const [selectedTag, setSelectedTag] = useState(null)
    const [stateTags, setStateTags] = useState<any>()
    const [limitSelectedTags, setLimitSelectedTags] = useState(props?.tagsSelected?.length > 2)

    let colorBackgroundMenu: string = lightColors.backgroundColorRow
    let colorText: string = lightColors.text
    let colorPlaceholderText: string = lightColors.placeholderText
    let colorTextFontbrightness: string = 'brightness(1)'
    if (theme === 'dark') {
        colorBackgroundMenu = darkColors.backgroundColorRow
        colorText = darkColors.text
        colorPlaceholderText = darkColors.placeholderText
        colorTextFontbrightness = 'brightness(2)'
    }

    const colourStyles: StylesConfig<ColourOption, true> = {
        container: styles => {
            return {
                width: '100%',
                position: 'relative',
            }
        },
        control: styles => {
            return {
                ...styles,
                backgroundColor: 'transparent',
                width: props.tdSelectStyle ? '100%' : 'max-content',
                overflow: props.tdSelectStyle ? 'hidden' : undefined,
                textOverflow: props.tdSelectStyle ? 'ellipsis' : undefined,
                whiteSpace: props.tdSelectStyle ? 'nowrap' : undefined,
                height: 40,
                display: 'flex',
                border: 'none',
                boxShadow: 'none',
                ':hover': {
                    border: '1px solid #C6D2D9',
                },
            }
        },
        placeholder: styles => ({ ...styles, color: colorPlaceholderText }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data?.color || 'white')
            return {
                ...styles,
                backgroundColor: isDisabled
                    ? undefined
                    : isSelected
                    ? data.color
                    : isFocused
                    ? color.alpha(0.1).css()
                    : undefined,
                color: isDisabled
                    ? '#ccc'
                    : isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color
                    ? data.color
                    : theme === 'dark'
                    ? 'white'
                    : 'black',
                cursor: isDisabled ? 'not-allowed' : 'default',
                filter: colorTextFontbrightness,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled ? (isSelected ? data.color : color.alpha(0.3).css()) : undefined,
                },
            }
        },
        input: styles => ({ ...styles, color: colorText }),
        menu: styles => ({ ...styles, backgroundColor: colorBackgroundMenu, width: 'max-content', zIndex: 999 }),
        valueContainer: styles => {
            return {
                ...styles,
                flexWrap: props.tdSelectStyle ? 'nowrap' : 'wrap',
                gap: '2px',
                overflowX: 'auto',
            }
        },
        multiValue: (styles, { data }) => {
            const color = chroma(data.color || 'white')
            const backgroundByTheme = theme === 'light' ? '#e0e0e0' : 'gray'
            return {
                ...styles,
                backgroundColor: !data.color ? backgroundByTheme : color.alpha(0.15).css(),
                minWidth: '45px',
                margin: 0,
                padding: '0 4px',
                overflow: 'hidden',
            }
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: theme === 'dark' ? 'white' : data.color,
            filter: colorTextFontbrightness,
            padding: '3px 0',
            borderRadius: 0,
            paddingLeft: 0,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: theme === 'dark' ? 'white' : data.color,
            filter: colorTextFontbrightness,
            borderRadius: 0,
            padding: 0,
            position: 'relative',
            right: -2,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),
        indicatorsContainer: styles => {
            return {
                display: props.tdSelectStyle ? 'none' : 'flex',
            }
        },
    }

    const handleChange = (value: any) => {
        setLimitSelectedTags(value.length > 2)
        setStateTags(value)
        props.onChangeTag(value)
    }

    const handleChangeFilter = (value: any) => {
        setSelectedTag(value)
        props.onChangeTag(value.map(i => i.code))
    }

    useEffect(() => {
        if (props.tagsSelected) {
            setStateTags(props.tagsSelected)
            setLimitSelectedTags(props.tagsSelected.length > 2)
        }
    }, [props.tagsSelected])

    useEffect(() => {
        if (props.clearComponent) {
            setStateTags([])
            setLimitSelectedTags(false)
        }
    }, [props.clearComponent])

    useEffect(() => {
        if (props.allTags) {
            let tagsObject = props.allTags
            let newTags = tagsObject.map(item => {
                return {
                    value: item.id,
                    label: item.name,
                    color: item.color.includes('#') ? item.color : '#' + item.color,
                }
            })
            setTags(newTags)
            setTagsMultiselect(props.allTags)
        }
    }, [props.allTags])

    return {
        theme,
        tags,
        tagsMultiselect,
        selectedTag,
        colourStyles,
        stateTags,
        limitSelectedTags,
        setStateTags,
        handleChange,
        handleChangeFilter,
    }
}

export default useMultiselectTags
