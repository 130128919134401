import { generateClient } from 'aws-amplify/api'
import { OrganizationService } from './OrganizationService'
import { listGroups } from '../graphql/queries'
import { createGroup, createUserGroup, deleteGroup, deleteUserGroup, updateGroup } from '../graphql/mutations'
import { AuthService } from './AuthService'

export const GroupService = () => {
    const getGroupByOrganization = async userSub => {
        try {
            if (userSub === '') return []
            let organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            if (organizationId === undefined) return []

            const groups: any = await generateClient().graphql({
                query: listGroups,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })

            const list = groups?.data?.listGroups?.items
                .filter(t => !t._deleted)
                .map(item => {
                    return {
                        ...item,
                        arrayUsers: item.UsersGroup.items
                            .filter(t => !t._deleted)
                            .map(t => {
                                const fullName = t.user.givenName + ' ' + t.user.familyName
                                return {
                                    id: t.user.id,
                                    name: t.user.name || fullName,
                                    image: t.user.image,
                                }
                            }),
                    }
                })

            return list
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const saveGroup = async newEntry => {
        try {
            const response: any = await generateClient().graphql({ query: createGroup, variables: { input: newEntry } })
            return response?.data?.createGroup
        } catch (error) {
            console.log(error)
            return
        }
    }

    const deleteGroupById = async (groupId: string, version: number) => {
        try {
            return await generateClient().graphql({
                query: deleteGroup,
                variables: { input: { id: groupId, _version: version } },
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const updateGroupById = async (newObject: any) => {
        try {
            return await generateClient().graphql({ query: updateGroup, variables: { input: newObject } })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const saveUsersGroup = async (users, groupId) => {
        try {
            for (const user of users) {
                let inputUserGroup = {
                    userID: user.code,
                    groupID: groupId,
                }
                await generateClient().graphql({ query: createUserGroup, variables: { input: inputUserGroup } })
            }
            return 'SUCCESS'
        } catch (error) {
            console.log(error)
            return 'FAIL'
        }
    }

    const deleteUsersGroup = async (arrayUsersGroup, groupId) => {
        try {
            for (const item of arrayUsersGroup) {
                const itemUserGroup = {
                    id: item.id,
                    _version: item._version,
                }

                await generateClient().graphql({
                    query: deleteUserGroup,
                    variables: { input: itemUserGroup, condition: { groupID: { eq: groupId } } },
                })
            }
            return 'SUCCESS'
        } catch (error) {
            console.log(error)
            return 'FAIL'
        }
    }

    const getGroupsDropdown = async userSub => {
        try {
            if (userSub === '') return []
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)

            const groups: any = await generateClient().graphql({
                query: listGroups,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })

            const list = groups?.data?.listGroups?.items
                .filter(t => !t._deleted)
                .map(item => {
                    return {
                        name: item.name,
                        code: item.id,
                    }
                })

            list.sort((a, b) => {
                if (a.name.toUpperCase() < b.name.toUpperCase()) {
                    return -1
                }
                if (a.name.toUpperCase() > b.name.toUpperCase()) {
                    return 1
                }
                return 0
            })
            return list
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const getAllGroupsByOrganization = async () => {
        try {
            const userSub = (await AuthService().getUserSub()) ?? ''
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            const response: any = await generateClient().graphql({
                query: listGroups,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })
            return response?.data?.listGroups?.items.filter(f => {
                return f._deleted !== true
            })
        } catch (error) {
            return []
        }
    }

    return {
        getAllGroupsByOrganization,
        getGroupByOrganization,
        saveGroup,
        deleteGroupById,
        updateGroupById,
        saveUsersGroup,
        deleteUsersGroup,
        getGroupsDropdown,
    }
}
