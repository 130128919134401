import { generateClient } from 'aws-amplify/api'
import { useState, useContext, createContext, useEffect } from 'react'
import { StatusInvitation } from '../API'
import { createUserGroup, updateOrganizationUser } from '../graphql/mutations'
import { listInvitations, listOrganizationUsers } from '../graphql/queries'
import { IGroupProvider } from '../interfaces/group-provider.interface'
import { UserService } from '../services/UserService'

const GroupContext = createContext<IGroupProvider>(null as any)

function GroupProvider({ children }) {
    const deleteAllGroupsInUser = async (groups: { id: string; _version: number }[]) => {
        const txnMutation: any = groups.map((txn, i) => {
            return `mutation${i}: deleteUserGroup(input: {id: "${txn.id}", _version: ${txn._version}}) { id }`
        })
        await generateClient().graphql({ query: `mutation batchMutation {${txnMutation}}` })
    }

    const saveGroupToUser = async (inputUserGroup, userID: string) => {
        const groupsData: any[] = []

        if (inputUserGroup.length > 0) {
            for (const group of inputUserGroup) {
                const inputUserGroup = { userID: userID, groupID: group.code }
                const response: any = await generateClient().graphql({
                    query: createUserGroup,
                    variables: { input: inputUserGroup },
                })

                groupsData.push(response.data.createUserGroup.group)
            }
        }
        return groupsData
    }

    return (
        <GroupContext.Provider
            value={{
                deleteAllGroupsInUser,
                saveGroupToUser,
            }}
        >
            {children}
        </GroupContext.Provider>
    )
}

function useGroupProvider() {
    const context = useContext(GroupContext)
    if (context === undefined) {
        throw new Error('useGroupProvider must be used within a UserProvider')
    }
    return context
}

export { GroupProvider, useGroupProvider }
