import { generateClient } from 'aws-amplify/api'
import { createOrganizationUser, updateOrganization, updateOrganizationUser } from '../graphql/mutations'
import {
    getOrganization,
    getOrganizationUser,
    getPlan,
    listOrganizations,
    listOrganizationUsers,
} from '../graphql/queries'
import { AuthService } from './AuthService'
import { UserService } from './UserService'

export const OrganizationService = () => {
    const getDefaultOrganizationId = async (userSub: string) => {
        try {
            if (!userSub || userSub === '') return null
            const response: any = await generateClient().graphql({
                query: listOrganizationUsers,
                variables: {
                    filter: { usersub: { eq: userSub }, is_default: { eq: true } },
                },
            })
            return response?.data?.listOrganizationUsers?.items[0]?.organizationID
        } catch (error) {
            console.log(error)
            return null
        }
    }

    const getDefaultOrganizationName = async (userSub: string) => {
        try {
            if (userSub === '' || userSub === undefined) return null
            const response: any = await generateClient().graphql({
                query: listOrganizationUsers,
                variables: {
                    filter: { usersub: { eq: userSub }, is_default: { eq: true } },
                },
            })

            return response?.data?.listOrganizationUsers?.items[0]
        } catch (error) {
            console.log(error)
            return null
        }
    }

    const getDefaultOrganizationUserId = async (userSub: string) => {
        try {
            if (userSub === '' || userSub === undefined) return null
            const response: any = await generateClient().graphql({
                query: listOrganizationUsers,
                variables: {
                    filter: { usersub: { eq: userSub }, is_default: { eq: true } },
                },
            })

            return response?.data?.listOrganizationUsers?.items[0].id
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getOrganizationById = async () => {
        try {
            const userSub = (await AuthService().getUserSub()) as string
            const organizationId = await getDefaultOrganizationId(userSub)
            if (!organizationId) return
            return await generateClient().graphql({ query: getOrganization, variables: { id: organizationId } })
        } catch (error) {
            console.log(error)
            return {}
        }
    }

    const getOrganizationUserDefaultByEmail = async (email: string) => {
        try {
            if (email === '' || email === undefined) return null
            const response: any = await generateClient().graphql({
                query: listOrganizationUsers,
                variables: {
                    filter: { email: { eq: email }, is_default: { eq: true } },
                },
            })

            return response?.data?.listOrganizationUsers?.items[0]
        } catch (error) {
            console.log(error)
            return null
        }
    }

    const getAllOrganizations = async () => {
        try {
            return await generateClient().graphql({ query: listOrganizations })
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const getUsersByOrganizationId = async (organizationId: string) => {
        try {
            const response: any = await generateClient().graphql({
                query: listOrganizationUsers,
                variables: {
                    filter: { organizationID: { eq: organizationId } },
                },
            })

            const list = await Promise.all(
                response?.data?.listOrganizationUsers?.items.map(async item => {
                    let userObject = await UserService().getUserById(item.userID)
                    let fullName = userObject?.givenName + ' ' + userObject?.familyName

                    return {
                        ...item,
                        userName: userObject?.name ? userObject?.name : fullName,
                        userImage: userObject ? userObject?.image : '',
                        userGroups: userObject?.Groups?.items
                            ?.filter(e => !e._deleted)
                            .map(t => {
                                if (!t.group._deleted && t.group.organizationID === organizationId) {
                                    return { code: t.group.id, name: t.group.name }
                                }
                                return
                            }),
                        Groups: userObject?.Groups?.items?.filter(e => !e._deleted),
                    }
                })
            )

            const filterUndefined = list.map(e => {
                return {
                    ...e,
                    userGroups: e.userGroups.filter(x => x !== undefined),
                }
            })

            return filterUndefined
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const getOrganizationDropdown = async (userSub: string) => {
        try {
            if (userSub === '' || userSub === undefined) return []
            const organizationsListUser: any = await generateClient().graphql({
                query: listOrganizationUsers,
                variables: {
                    filter: { usersub: { eq: userSub }, status: { eq: 'ACTIVE' } },
                },
            })
            const itemsDropDown = organizationsListUser?.data?.listOrganizationUsers.items.map(item => {
                return {
                    name: item.organization?.name,
                    code: item.organizationID,
                }
            })

            return itemsDropDown
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const updateOrganizationUserById = async (newObject: any) => {
        try {
            return await generateClient().graphql({ query: updateOrganizationUser, variables: { input: newObject } })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const updateDefaultOrganizationUser = async (organizationId: string, userSub: string) => {
        try {
            const defaultOrganizationUserId: any = await getDefaultOrganizationUserId(userSub)
            const changeDefault: any = await generateClient().graphql({
                query: getOrganizationUser,
                variables: { id: defaultOrganizationUserId },
            })

            if (!changeDefault.data.getOrganizationUser) return

            const updateObject = {
                id: changeDefault.data.getOrganizationUser.id,
                is_default: false,
                _version: changeDefault.data.getOrganizationUser._version,
            }

            await updateOrganizationUserById(updateObject)

            const activeDefault: any = await generateClient().graphql({
                query: listOrganizationUsers,
                variables: {
                    filter: {
                        usersub: { eq: userSub },
                        organizationID: { eq: organizationId },
                        is_default: { eq: false },
                    },
                },
            })

            if (!activeDefault.data.listOrganizationUsers) return

            const activeObject = {
                id: activeDefault.data.listOrganizationUsers.items[0].id,
                is_default: true,
                _version: activeDefault.data.listOrganizationUsers.items[0]._version,
            }

            await updateOrganizationUserById(activeObject)

            return true
        } catch (error) {
            console.log(error)
            return false
        }
    }

    const getDefaultOrganizationRole = async (handleCallBack?: React.Dispatch<React.SetStateAction<boolean>>) => {
        try {
            const userSub = await AuthService().getUserSub()
            if (!userSub || userSub === '' || userSub === undefined) {
                handleCallBack && handleCallBack(true)
                return null
            }
            const response: any = await generateClient().graphql({
                query: listOrganizationUsers,
                variables: {
                    filter: { usersub: { eq: userSub }, is_default: { eq: true } },
                },
            })
            handleCallBack && handleCallBack(true)
            return { role: response?.data?.listOrganizationUsers?.items[0]?.role, userSub }
        } catch (error) {
            console.log(error)
            return null
        }
    }

    const getOrganizationPlan = async (planId: string) => {
        try {
            return await generateClient().graphql({ query: getPlan, variables: { id: planId } })
        } catch (error) {
            console.log(error)
            return {}
        }
    }

    const saveOrganizationUser = async newEntry => {
        try {
            const response: any = await generateClient().graphql({
                query: createOrganizationUser,
                variables: { input: newEntry },
            })
            return response?.data?.createOrganizationUser
        } catch (error) {
            console.log(error)
            return
        }
    }

    const existsEmailAndOrganization = async (organizationId: string, userEmail: string) => {
        const response: any = await generateClient().graphql({
            query: listOrganizationUsers,
            variables: {
                filter: { organizationID: { eq: organizationId }, email: { eq: userEmail } },
            },
        })
        const findRecord = response?.data?.listOrganizationUsers?.items?.length || 0

        return findRecord > 0
    }

    const updateOrganizationById = async updateObject => {
        try {
            const response: any = await generateClient().graphql({
                query: updateOrganization,
                variables: { input: updateObject },
            })
            return response?.data?.updateOrganization
        } catch (error) {
            console.log(error)
            return
        }
    }

    return {
        getOrganizationById,
        getDefaultOrganizationId,
        getAllOrganizations,
        getUsersByOrganizationId,
        getOrganizationDropdown,
        updateDefaultOrganizationUser,
        getDefaultOrganizationRole,
        getOrganizationPlan,
        updateOrganizationUserById,
        saveOrganizationUser,
        existsEmailAndOrganization,
        getDefaultOrganizationName,
        updateOrganizationById,
        getOrganizationUserDefaultByEmail,
    }
}
