import React, { useState, useEffect, useContext } from 'react'
import { Modal, StyleSheet, Text, View } from 'react-native'
import { OrganizationService } from '../../services/OrganizationService'
import { Role, StatusInvitation } from '../../models'
import { InvitationService } from '../../services/InvitationService'
import { getStyleSheet } from '../../assets/theme/styles'
import { ThemeContext } from '../../assets/theme/ThemeProvider'
import { useOrganizationProvider } from '../../context/OrganizationProvider'
import moment from 'moment'
import InputText from './InputText'
import ButtonNative from './ButtonNative'
import DropdownNative from './MultiSelectNative/MultiSelect'
import uuid from 'react-native-uuid'
import { useNavigation } from '@react-navigation/native'
import { UpgradeContext } from '../../context/upgrade'
import { MomentaryView } from './DataTable'
import useDimensions from '../../hooks/useDimensions'

const dropdownRole = uuid.v4() as string

interface IInvitationBar {
    onRefreshInvitations?: () => void
}

export const InviteMembersModal = ({ onRefreshInvitations = () => {} }: IInvitationBar) => {
    const styles = getStyleSheet()
    const { userRole, userSub } = useContext<any>(ThemeContext)
    const { membersTotal, setMembersTotal } = useOrganizationProvider()
    const { maxGroupsNumber } = useContext(UpgradeContext)
    const [email, setEmail] = useState('')
    const [userClickedInvitationButton, setUserClickedInvitationButton] = useState(false)
    const [selectedRole, setSelectedRole] = useState('')
    const [organizationID, setOrganizationID] = useState('')
    const [loadingButton, setLoadingButton] = useState(false)
    const [roleAdmin, setRoleAdmin] = useState('')
    const navigation = useNavigation()
    const { windowWidth } = useDimensions()
    const [blockInvited, setBlockInvited] = useState(true)
    const isValidEmail = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}/.test(email)

    const clearComponents = () => {
        setSelectedRole('')
        setEmail('')
        setLoadingButton(false)
        setRoleAdmin('')
    }

    const updateInvitedOn = (existRecord: any) => {
        const updateRecord = {
            id: existRecord.id,
            invitedOn: moment().utc(true).toISOString(),
            _version: existRecord._version,
        }

        if (existRecord.status !== StatusInvitation.PENDING) {
            return
        }

        InvitationService()
            .updateInvitationById(updateRecord)
            .then(() => {
                clearComponents()
                onRefreshInvitations()
            })
            .catch(() => {})
    }

    const insertInvitation = (invitedRecord: any) => {
        InvitationService()
            .saveInvitation(invitedRecord)
            .then(() => {
                clearComponents()
                onRefreshInvitations()
                membersTotal ? setMembersTotal(membersTotal + 1) : null
            })
            .catch(() => {})
    }

    const onHandleInvite = () => {
        if (!isValidEmail) return setUserClickedInvitationButton(true)
        if (blockInvited) return

        setLoadingButton(true)

        const inviteobject = {
            organizationID: organizationID,
            email: email,
            invitedOn: moment().utc(true).toISOString(),
            invitedBy: userSub,
            role: roleAdmin === 'admin' ? Role.ADMIN : Role.USER,
            status: StatusInvitation.PENDING,
        }

        OrganizationService()
            .existsEmailAndOrganization(organizationID, email)
            .then(existRecord => {
                if (existRecord) {
                    setLoadingButton(false)
                    return
                }

                InvitationService()
                    .existsEmailAndOrganization(organizationID, email)
                    .then(existRecord => {
                        if (existRecord) {
                            updateInvitedOn(existRecord)
                            setLoadingButton(false)
                            return
                        }

                        insertInvitation(inviteobject)
                        setLoadingButton(false)
                    })
            })
            .catch(error => {
                console.log(error)
                setLoadingButton(false)
            })
    }

    const handleKeyDownInvite = event => {
        if (event.key === 'Enter') {
            onHandleInvite()
        }
    }

    const getOrganizationId = () => {
        OrganizationService()
            .getDefaultOrganizationId(userSub)
            .then(response => {
                setOrganizationID(response)
            })
    }

    const onRoleAdminChange = (e: string) => {
        setRoleAdmin(e)
    }

    useEffect(() => {
        if (membersTotal && maxGroupsNumber) setBlockInvited(maxGroupsNumber <= membersTotal)
    }, [membersTotal, maxGroupsNumber])

    useEffect(() => {
        if (userSub) {
            getOrganizationId()
        }
    }, [userSub])

    return (
        <View
            style={[
                {
                    flexDirection: windowWidth <= 600 ? 'column' : 'row',
                    gap: windowWidth <= 600 ? 10 : 20,
                    alignItems: windowWidth > 600 ? 'center' : 'flex-start',
                    backgroundColor: styles.backgroundColorRow,
                    marginTop: 20,
                    paddingVertical: 12,
                    paddingHorizontal: 20,
                    borderRadius: 10,
                },
                styles.stylesBar.boxShadow,
            ]}
        >
            <InputText
                value={email}
                inputStyle={{ borderWidth: 1, borderColor: '#C6D2D9' }}
                containerStyle={{ flexGrow: 1, width: windowWidth <= 600 ? '100%' : 'auto' }}
                placeholder="Email"
                onChange={e => setEmail(e.valueOf())}
                onKeyPress={handleKeyDownInvite}
            />

            {!isValidEmail && userClickedInvitationButton && email.length ? (
                <Text
                    style={[
                        {
                            color: 'rgb(255, 111, 111)',
                        },
                        windowWidth > 600 ? { left: 25, bottom: -23, position: 'absolute' } : null,
                    ]}
                >
                    Email not valid
                </Text>
            ) : null}

            <MomentaryView customStyles={{ flexDirection: 'row', gap: 10, width: '100%' }} showViewUp={600}>
                <DropdownNative
                    placeholder="ROLE"
                    uniqueSelection
                    valueWhenUniqueSelection={roleAdmin}
                    indentificator={dropdownRole}
                    options={[
                        { label: 'Admin', value: 'admin' },
                        { label: 'Standard user', value: 'invited' },
                    ]}
                    optionLabel="label"
                    containerStyles={{ flexGrow: windowWidth <= 600 ? 1 : 0 }}
                    customStyles={{
                        borderWidth: 1,
                        borderColor: '#C6D2D9',
                    }}
                    hideSearch
                    optionValue="value"
                    onChange={onRoleAdminChange}
                />

                {userRole === Role.ADMIN && membersTotal && maxGroupsNumber ? (
                    <>
                        {maxGroupsNumber <= membersTotal ? (
                            <>
                                <ButtonNative
                                    title="Upgrade"
                                    primaryTheme
                                    buttonStyle={{ backgroundColor: '#3B82f6' }}
                                    onPress={() => navigation.navigate('Upgrade' as never)}
                                />
                            </>
                        ) : (
                            <ButtonNative
                                title="INVITE"
                                primaryTheme
                                onPress={onHandleInvite}
                                loading={loadingButton}
                                disabled={blockInvited || (!isValidEmail && userClickedInvitationButton)}
                            />
                        )}
                    </>
                ) : null}
            </MomentaryView>
        </View>
    )
}

const stylesModal = StyleSheet.create({
    displayModalNone: {
        display: 'none',
    },
    displayModalFlex: {
        display: 'flex',
    },
    centeredView: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
        height: '100%',
        zIndex: 105,
    },
    modalCenter: {
        borderColor: 'transparent',
        marginTop: '10%',
        marginRight: 'auto',
        marginBottom: 0,
        marginLeft: 'auto',
    },
    modalView: {
        minWidth: 260,
        gap: 10,
        width: '55%',
        maxWidth: 450,
        maxHeight: 474,
        margin: 20,
        borderRadius: 6,
        padding: 35,
        alignItems: 'center',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 3.84,
        elevation: 5,
    },
    modalText: {
        marginBottom: 20,
        textAlign: 'center',
        fontSize: 16,
    },
})

const stylesForm = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    buttonStyle: {
        backgroundColor: '#D00500',
        height: 58,
        borderRadius: 4,
    },
    buttonTitle: {
        fontWeight: 'bold',
        fontSize: 16,
    },
})
