import React, { useContext, useState } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { getStyleSheet } from '../assets/theme/styles'
import { ProjectService } from '../services/ProjectService'
import { useFocusEffect } from '@react-navigation/native'
import { StatusProject } from '../models'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { UpgradeContext } from '../context/upgrade'
import Searchbar from '../components/common/Searchbar/Searchbar'
import SkeletonTable from '../components/common/SkeletonTable'
import DataTable from '../components/common/DataTable'
import { IProject } from '../interfaces/project.interface'
import RowProject, { localStylesProject } from '../components/common/RowProject'
import { GroupService } from '../services/GroupService'
import useDimensions from '../hooks/useDimensions'

const headerColumns = [
    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: 36 } },
    {
        label: 'PROJET',
        fieldToSorteable: 'name',
        isSorteable: true,
        styles: { flexGrow: 1 },
    },
    { label: 'CLIENT', fieldToSorteable: '', isSorteable: false, styles: { width: '20%' } },
    { label: 'GROUP', fieldToSorteable: '', isSorteable: false, styles: { width: '20%' } },

    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: 26 } },
]

const ProjectsArchived = () => {
    const current = 'ProjectsArchived'
    const styles = getStyleSheet()
    const { userRole, userSub } = useContext<any>(ThemeContext)
    const { createdProjectsNumber, setCreatedProjectsNumber } = useContext<any>(UpgradeContext)
    const { getProjectsArchived, updateProjectStatus } = ProjectService()
    const [projectsArchived, setProjectsArchived] = useState<any[]>([])
    const [allProjectsArchived, setAllProjectsArchived] = useState<any[]>([])
    const [allGroups, setAllGroups] = useState([])
    const [loading, setLoading] = useState(false)
    const [occupiedWidth, setOccupiedWidth] = useState(0)
    const { windowWidth } = useDimensions()

    const getGroups = () => {
        GroupService()
            .getGroupsDropdown(userSub)
            .then(data => {
                setAllGroups(data)
            })
    }

    const onUpdateStatusRow = async (id: any) => {
        if (!id) {
            return
        }

        const row = projectsArchived.find((item: any) => item.id === id) as any

        if (!row) {
            return
        }

        updateProjectStatus(row.id, StatusProject.ACTIVE, row._version).then(() => {
            setCreatedProjectsNumber(createdProjectsNumber + 1)
            getAllProjectsArchived()
        })
    }

    const getAllProjectsArchived = () => {
        setLoading(true)
        getProjectsArchived(userRole, userSub).then(data => {
            setProjectsArchived(data)
            setAllProjectsArchived(data)
            setLoading(false)
        })
    }

    useFocusEffect(
        React.useCallback(() => {
            getAllProjectsArchived()
            getGroups()
        }, [userRole])
    )

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current, initial: 1 }} />
                    <View
                        style={[styles.baseStyleContent.container, localStyles.workArea]}
                        onLayout={({ nativeEvent: { layout } }) => setOccupiedWidth(layout.width)}
                    >
                        <Searchbar
                            doNotCollapse
                            isCollapsed={false}
                            setIsCollapsed={() => {}}
                            items={allProjectsArchived}
                            onFilteredItems={filteredItems => {
                                setProjectsArchived(filteredItems)
                            }}
                        />

                        {loading || !occupiedWidth ? (
                            <SkeletonTable rowsQuantity={8} />
                        ) : (
                            <DataTable
                                isShowEmptyMessage={projectsArchived.length === 0}
                                emptyMessage="No archived projects."
                                itemsList={projectsArchived}
                                setItemsList={setProjectsArchived}
                                headerCustomStyles={[
                                    localStylesProject.containerWhenThereOneFile,
                                    { width: occupiedWidth, display: windowWidth > 1200 ? 'flex' : 'none' },
                                ]}
                                headerColumns={headerColumns as any}
                            >
                                {projectsArchived?.map((project: IProject) => (
                                    <RowProject
                                        isReadOnly
                                        style={{ width: occupiedWidth }}
                                        key={project.id}
                                        userRole={userRole}
                                        allClients={[]}
                                        allGroups={allGroups}
                                        project={project}
                                        onUpdateRow={onUpdateStatusRow}
                                    />
                                ))}
                            </DataTable>
                        )}
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flexGrow: 1,
        flexDirection: 'column',
    },
    searchContainer: {
        zIndex: 5010,
        flexDirection: 'row',
        height: 41,
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
    iconSearch: {
        width: 22,
        height: 22,
        position: 'absolute',
        marginTop: 10,
        right: 10,
    },
    textinputStyle: {
        paddingLeft: 10,
        width: 295,
        height: 40,
        borderColor: 'gray',
        borderRadius: 6,
        fontStyle: 'italic',
    },
})

export default ProjectsArchived
