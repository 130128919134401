import { getStyleSheet } from '../../assets/theme/styles'
import useDimensions from '../../hooks/useDimensions'
import { MomentaryView } from './DataTable'
import GroupedRecordMenu from './GroupedRecordMenu/GroupedRecordMenu'
import { Text, View } from 'react-native'

interface IRowTeamInvitation {
    teamInvitation: any
    onUpdateRow: (id: string) => void
    onShowConfirmationDelete: (id: string) => void
}

const RowTeamInvitation = ({ teamInvitation, onUpdateRow, onShowConfirmationDelete }: IRowTeamInvitation) => {
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()

    const actions = [
        {
            action: () => onUpdateRow(teamInvitation.id),
            label: 'Resend',
            icon: 'send',
        },
        {
            action: () => onShowConfirmationDelete(teamInvitation.id),
            label: 'Delete',
            icon: 'delete',
        },
    ]

    return (
        <View
            style={[
                {
                    backgroundColor: styles.backgroundColorRow,
                    padding: 10,
                    gap: 10,
                    borderRadius: 6,
                },
                windowWidth > 1000
                    ? {
                          flexDirection: 'row',
                          alignItems: 'center',
                          gap: 0,
                          paddingHorizontal: 20,
                      }
                    : null,
            ]}
        >
            <MomentaryView customStyles={{ flexDirection: 'row' }} showViewUp={1000}>
                <View style={{ width: windowWidth <= 1000 ? '90%' : '50%' }}>
                    <Text style={{ color: styles.text }}>{teamInvitation.email}</Text>
                </View>
                {windowWidth <= 1000 ? (
                    <View style={{ width: '10%' }}>
                        <GroupedRecordMenu identificator={teamInvitation.id} items={actions} />
                    </View>
                ) : null}
            </MomentaryView>

            <MomentaryView customStyles={{ flexDirection: 'row', justifyContent: 'space-between' }} showViewUp={1000}>
                <View style={windowWidth > 1000 ? { width: '25%' } : null}>
                    <Text style={{ color: styles.text }}>{teamInvitation.createdDateFormat}</Text>
                </View>

                <View style={windowWidth > 1000 ? { width: '10%', paddingRight: 10 } : null}>
                    <Text style={{ color: styles.text }}>{teamInvitation.status}</Text>
                </View>

                <View style={windowWidth > 1000 ? { width: '10%' } : null}>
                    <Text style={{ color: styles.text }}>{teamInvitation.role}</Text>
                </View>
            </MomentaryView>

            {windowWidth > 1000 ? (
                <View style={{ width: '5%' }}>
                    <GroupedRecordMenu identificator={teamInvitation.id} items={actions} />
                </View>
            ) : null}
        </View>
    )
}

export default RowTeamInvitation
