import React, { useEffect, useContext } from 'react'
import { View, ScrollView, StyleSheet, Animated } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { NewGroupModal } from '../components/common/NewGroupModal'
import { getStyleSheet } from '../assets/theme/styles'
import { GroupService } from '../services/GroupService'
import { OrganizationService } from '../services/OrganizationService'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { UpgradeContext } from '../context/upgrade'
import { useNavigation } from '@react-navigation/native'
import Searchbar from '../components/common/Searchbar/Searchbar'
import useState from 'react-usestateref'
import DataTable from '../components/common/DataTable'
import RowTeamGroup from '../components/common/RowTeamGroup'
import SkeletonTable from '../components/common/SkeletonTable'
import FooterDialog from '../components/common/FooterDialog'
import CustomModal from '../components/common/CustomModal'
import useSearchBar from '../components/common/Searchbar/useSearchBar'
import { Role } from '../API'

const headerColumns: any = [
    { label: 'GROUP NAME', fieldToSorteable: 'name', isSorteable: true, styles: { width: '100%', flexShrink: 1 } },
    { label: 'MEMBERS', fieldToSorteable: '', isSorteable: false, styles: { width: '100%', flexShrink: 1 } },
    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: 26 } },
]

const TeamGroup = () => {
    const current = 'TeamGroup'
    const styles = getStyleSheet()
    const navigation = useNavigation()
    const { userSub, userRole } = useContext(ThemeContext)
    const { maxGroupsNumber, createdGroupsNumber, setCreatedGroupsNumber } = useContext<any>(UpgradeContext)
    const [modalVisible, setModalVisible] = useState(false)
    const [groups, setGroups] = useState<any[]>([])
    const [allGroups, setAllGroups] = useState<any[]>([])
    const [loading, setLoading] = useState(true)
    const [updateObject, setUpdateObject, updateObjectRef] = useState(null)
    const [members, setMembers] = useState<any>([])
    const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
    const [showUpgradeButton, setShowUpgradeButton] = useState(false)
    const [currentDelete, setCurrentDelete] = useState(null)
    const { isCollapsed, toggleSetIsCollapsed, fadeAnim, handlerBarExpand } = useSearchBar()

    const onRefreshGroups = () => {
        getAllGroups()
        setUpdateObject(null)
    }

    const onDeleteRow = async () => {
        if (!currentDelete) {
            return
        }

        const row = groups.find((item: any) => item.id === currentDelete) as any

        if (!row) {
            return
        }

        setShowConfirmationDelete(false)
        await GroupService().deleteGroupById(row.id, row._version)
        setCreatedGroupsNumber(createdGroupsNumber - 1)
        getAllGroups()
    }

    const onUpdateRow = (id: any) => {
        if (!id) return

        const row = groups.find((item: any) => item.id === id) as any
        if (!row) return
        handlerBarExpand()
        setUpdateObject(row)
        setModalVisible(true)
    }

    const getAllGroups = () => {
        setLoading(true)
        if (userSub === '') return
        GroupService()
            .getGroupByOrganization(userSub)
            .then(response => {
                setGroups(response)
                setAllGroups(response)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getMembers = () => {
        if (userSub === '') return
        OrganizationService()
            .getDefaultOrganizationId(userSub)
            .then(organizationId => {
                OrganizationService()
                    .getUsersByOrganizationId(organizationId)
                    .then(listMember => {
                        let membersDropdown = listMember?.map(item => {
                            return {
                                label: item.userName,
                                code: item.userID,
                            }
                        })

                        setMembers(membersDropdown)
                    })
            })
            .catch(error => {})
    }

    const onHideConfirmationDelete = () => {
        setShowConfirmationDelete(false)
    }

    const onShowConfirmationDelete = (id: any) => {
        setCurrentDelete(id)
        setShowConfirmationDelete(true)
    }

    useEffect(() => {
        getMembers()
        getAllGroups()
    }, [userSub])

    useEffect(() => {
        setShowUpgradeButton(createdGroupsNumber >= maxGroupsNumber)
    }, [maxGroupsNumber, createdGroupsNumber])

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current, initial: 1 }} />
                    <View style={[styles.baseStyleContent.container, localStyles.workArea]}>
                        <Searchbar
                            isCollapsed={isCollapsed}
                            setIsCollapsed={toggleSetIsCollapsed}
                            items={allGroups}
                            onFilteredItems={filteredItems => {
                                setGroups(filteredItems)
                            }}
                        />

                        {userRole === Role.ADMIN ? (
                            <Animated.View
                                style={{
                                    transform: [{ translateY: fadeAnim ?? 0 }],
                                    display: isCollapsed ? 'flex' : 'none',
                                }}
                            >
                                <NewGroupModal
                                    onRefreshGroups={onRefreshGroups}
                                    isUpgrade={showUpgradeButton}
                                    children={{ groupUpdate: updateObjectRef.current }}
                                />
                            </Animated.View>
                        ) : null}

                        {loading ? (
                            <SkeletonTable rowsQuantity={6} />
                        ) : (
                            <DataTable
                                headerColumns={headerColumns}
                                isShowEmptyMessage={!groups.length}
                                headerCustomStyles={{ padding: 10, gap: 10 }}
                                itemsList={groups}
                                setItemsList={setGroups}
                                emptyMessage="Create a new group."
                            >
                                {groups?.map(group => (
                                    <RowTeamGroup
                                        key={group.id}
                                        onShowConfirmationDelete={onShowConfirmationDelete}
                                        onUpdateRow={onUpdateRow}
                                        teamGroup={group}
                                    />
                                ))}
                            </DataTable>
                        )}
                    </View>
                </ScrollView>
            </View>

            <CustomModal
                header="Delete"
                message="Are you sure you want to delete entry?"
                isVisible={showConfirmationDelete}
                onHide={setShowConfirmationDelete}
                footerModal={<FooterDialog handleOnNo={onHideConfirmationDelete} handleOnYes={onDeleteRow} />}
            />
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
        height: '100%',
    },
    containerTable: {
        width: '100%',
        flexDirection: 'row',
        marginLeft: 29,
        marginTop: 40,
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
    searchContainer: {
        zIndex: 5005,
        flexDirection: 'row',
        marginBottom: 12,
        height: 41,
    },
    textinputSearch: {
        paddingLeft: 10,
        width: 295,
        height: 40,
        borderColor: 'gray',
        borderRadius: 6,
        fontStyle: 'italic',
    },
    iconSearch: {
        width: 22,
        height: 22,
        position: 'absolute',
        marginTop: 10,
        right: 10,
    },
    HeadStyle: {
        height: 15,
        marginTop: 17,
        alignContent: 'center',
        paddingLeft: 29,
    },
    rowStyle: {
        height: 56,
        backgroundColor: '#555961',
        marginTop: 6,
        borderRadius: 6,
        paddingLeft: 20,
    },
    rowText: {
        margin: 10,
        color: '#818A94',
        fontSize: 10,
        textTransform: 'uppercase',
    },
    rowsText: {
        margin: 10,
        color: '#ffffff',
        fontSize: 14,
    },
})

export default TeamGroup
