import { useCardUpgradePlans } from './useCardUpgradePlans'
import { View, Text, StyleSheet } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faHandshake, faUserPlus, faUsers, faChartColumn, faClock, faCircle } from '@fortawesome/free-solid-svg-icons'
import ButtonNative from '../ButtonNative'
import { getStyleSheet } from '../../../assets/theme/styles'
import CustomModal from '../CustomModal'
import useDimensions from '../../../hooks/useDimensions'

const CardUpgradePlans = props => {
    const [
        {
            upgradePlans,
            organizationPlanId,
            planFreeId,
            showModalConditions,
            planPriceOrganization,
            handleUpgrade,
            setShowModalConditions,
            handleUpgradeSeats,
        },
    ] = useCardUpgradePlans(props)

    const styles = getStyleSheet()
    const { dimensions } = useDimensions()

    const modalWidth = () => {
        if (dimensions.window.width < 600) return '80%'
        if (dimensions.window.width < 1000) return '50%'
        return '30%'
    }

    const Footer = ({ planId, planPrice }) => {
        if (planId !== planFreeId) {
            if (planId === organizationPlanId) {
                return (
                    <ButtonNative
                        title="Edit Seats"
                        titleStyle={{ fontWeight: '700', fontSize: 16 }}
                        buttonStyle={{ borderWidth: 2, borderColor: 'transparent' }}
                        containerStyle={{ width: '100%' }}
                        onPress={() => handleUpgradeSeats(planId)}
                        primaryTheme
                    />
                )
            } else {
                return (
                    <ButtonNative
                        title={planPrice > planPriceOrganization ? 'Upgrade' : 'Downgrade'}
                        titleStyle={{ fontWeight: '700', fontSize: 16 }}
                        buttonStyle={{ borderWidth: 2, borderColor: 'transparent' }}
                        primaryTheme
                        onPress={() => handleUpgrade(planId)}
                    />
                )
            }
        } else {
            return <View style={{ alignItems: 'center', height: 41 }} />
        }
    }

    const onHideModalConditions = () => {
        setShowModalConditions(false)
    }

    const FooterConditions = () => {
        return <ButtonNative primaryTheme title="OK" onPress={onHideModalConditions} />
    }

    const getIconFontAwesome = nameIcon => {
        switch (nameIcon) {
            case 'userplus':
                return faUserPlus
            case 'handshake':
                return faHandshake
            case 'users':
                return faUsers
            case 'barchart':
                return faChartColumn
            default:
                return faClock
        }
    }

    const FeaturesPlan = ({ id, object }) => {
        const features = object.map((item, index) => {
            return (
                <View key={id + '-' + index} style={{ flexDirection: 'row' }}>
                    <FontAwesomeIcon
                        icon={getIconFontAwesome(item.nameIcon)}
                        style={{
                            marginRight: 10,
                            marginBottom: 'auto',
                            marginTop: 'auto',
                            width: 20,
                            color: styles.text,
                        }}
                    />
                    <Text style={{ fontSize: 14, color: styles.text, fontWeight: '500' }}>{item.description}</Text>
                </View>
            )
        })

        return <>{features}</>
    }

    const planCard = upgradePlans.map((element: any) => {
        const objJson = JSON.parse(element.description)
        const isCurrentPlan = element.id === organizationPlanId

        return (
            <View style={localStyles.containerCard}>
                <View
                    key={element.id}
                    style={[
                        localStyles.card,
                        { backgroundColor: styles.cardBackground, borderWidth: 4, borderColor: 'transparent' },
                        isCurrentPlan ? { borderColor: styles.backgroundColorPrimaryButton } : null,
                    ]}
                >
                    <View style={{ gap: 30 }}>
                        <Text style={[localStyles.title, { color: styles.text }]}>{element.name}</Text>
                        <View style={{ height: 140 }}>
                            <Text
                                style={[
                                    localStyles.description,
                                    { color: styles.text },
                                    isCurrentPlan ? { fontWeight: '700', fontSize: 16 } : null,
                                ]}
                            >
                                {isCurrentPlan ? 'Current Plan' : objJson.description}
                            </Text>

                            {isCurrentPlan && props.subscription ? (
                                <View
                                    style={{ marginTop: 20, flexDirection: 'row', gap: 10, justifyContent: 'center' }}
                                >
                                    <Text style={{ fontSize: 14, color: styles.text }}>Seats:</Text>

                                    <Text style={{ fontWeight: '700', fontSize: 14, color: styles.text }}>
                                        {props.subscription?.seats}
                                    </Text>
                                </View>
                            ) : null}
                        </View>
                    </View>

                    <View style={{ marginBottom: 40 }}>
                        {element.price !== 0 ? (
                            <Text style={{ fontSize: 18, textAlign: 'center', color: styles.text }}>
                                Price{' '}
                                <Text style={[localStyles.price, { color: styles.text }]}>
                                    ${element.price.toFixed(2)}
                                </Text>{' '}
                                per seat
                            </Text>
                        ) : (
                            <Text style={[localStyles.free, { color: styles.text }]}>Free</Text>
                        )}
                    </View>

                    <Footer planId={element.id} planPrice={element.price} />
                </View>
                <View
                    style={[
                        {
                            backgroundColor: styles.cardBackground,
                            borderRadius: 5,
                            padding: 20,
                            gap: 7,
                            borderWidth: 3,
                            borderColor: 'transparent',
                        },
                        isCurrentPlan ? { borderColor: styles.backgroundColorPrimaryButton } : null,
                    ]}
                >
                    <FeaturesPlan id={element.id} object={objJson.features} />
                </View>
            </View>
        )
    })

    return (
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: 15, justifyContent: 'center' }}>
            {planCard}

            <CustomModal
                isVisible={showModalConditions}
                header="Downgrade plan"
                customStyles={{ width: modalWidth() }}
                footerModal={<FooterConditions />}
                onHide={setShowModalConditions}
            >
                <Text style={{ fontWeight: '700', marginTop: 10, color: styles.text }}>
                    Right now you have a plan with higher benefits. In order to proceed with downgrade you must do the
                    following steps
                </Text>

                <View style={{ gap: 10, marginTop: 20 }}>
                    {CONDITIONS.map(item => (
                        <View style={{ flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                            <FontAwesomeIcon icon={faCircle} size={10} />
                            <Text style={{ color: styles.text }}>{item.label}</Text>
                        </View>
                    ))}
                </View>
            </CustomModal>
        </View>
    )
}

const CONDITIONS = [
    { label: 'It must have a maximum of 5 active clients' },
    { label: 'You must hace a maximum of 5 active projects' },
    { label: 'You must hace a maximum of 5 active groups' },
    { label: 'Then again try to downgrade to the plan' },
]

const localStyles = StyleSheet.create({
    containerCard: {
        gap: 20,
    },
    card: {
        flexDirection: 'column',
        width: 260,
        paddingTop: 25,
        paddingBottom: 15,
        paddingHorizontal: 10,
        borderRadius: 5,
    },
    title: {
        color: '#fff',
        fontSize: 40,
        fontWeight: '700',
        fontFamily: 'Roboto-Regular',
        textAlign: 'center',
    },
    description: {
        color: '#fff',
        textAlign: 'center',
    },
    price: {
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
        color: '#fff',
    },
    free: {
        fontSize: 18,
        textAlign: 'center',
        fontWeight: '700',
        color: '#fff',
    },
})

export default CardUpgradePlans
