import React, { useEffect, useMemo, useRef, useState, memo } from 'react'
import { View, Text, StyleSheet, ViewStyle, StyleProp, TextStyle, ScrollView } from 'react-native'
import { CheckBox } from 'react-native-elements'
import InputText from '../InputText'
import ButtonNative from '../ButtonNative'
import { getStyleSheet } from '../../../assets/theme/styles'
import { useOverlayProvider } from '../../../context/OverlayProvider'
import ItemMultiple from './ItemMultiple'
import useDimensions from '../../../hooks/useDimensions'
import { Pressable } from 'react-native'
import { Hoverable } from 'react-native-web-hover'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import ModalOverlay from '../ModalOverlay'
import ItemSkeleton from '../ItemSkeleton'

interface IMultiSelect {
    options: any[]
    optionLabel: string
    optionValue: string
    placeholder: string
    optionGroupLabel?: string
    optionGroupValue?: string
    optionGroupChildren?: string
    indentificator: string
    emptyFilterMessage?: string
    value?: any[]
    valueWhenUniqueSelection?: string
    uniqueSelection?: boolean
    buttonTitleStyle?: StyleProp<TextStyle>
    customStyles?: StyleProp<ViewStyle>
    containerStyles?: StyleProp<ViewStyle>
    overlayPanelStyles?: StyleProp<ViewStyle>
    disabled?: boolean
    hideSearch?: boolean
    filterPlaceholder?: string
    valueTemplate?: React.ReactNode
    childrenOverlay?: React.ReactNode
    includeHovered?: boolean
    children?: React.ReactNode
    isShowDownButton?: boolean
    skeletonStyles?: StyleProp<ViewStyle>
    loading?: boolean
    onChangeTextLabel?: (value: string) => void
    optionGroupTemplate?: (item: any) => React.ReactNode
    onChange: (selectedItems: any) => void
    onPress?: () => void
    onHide?: () => void
}

const DropdownNative = memo(
    ({
        options,
        optionLabel,
        optionValue,
        optionGroupLabel,
        optionGroupChildren,
        optionGroupValue,
        placeholder,
        indentificator,
        emptyFilterMessage,
        value,
        valueWhenUniqueSelection,
        uniqueSelection,
        customStyles,
        containerStyles,
        overlayPanelStyles,
        disabled,
        hideSearch,
        filterPlaceholder,
        valueTemplate,
        buttonTitleStyle,
        childrenOverlay,
        includeHovered,
        children,
        loading,
        skeletonStyles,
        isShowDownButton = true,
        optionGroupTemplate,
        onHide = () => {},
        onChange,
        onPress = () => {},
        onChangeTextLabel,
    }: IMultiSelect) => {
        const [searchText, setSearchText] = useState('')
        const styles = getStyleSheet()
        const { activeId, isShowOverlay, handleShowOverlay, closeShowOverlay, handleSetActiveId } = useOverlayProvider()
        const [allCheckOption, setAllCheckOption] = useState(false)
        const [selectedItems, setSelectedItems] = useState<string[]>([])
        const [itemSelected, setItemSelected] = useState(null)
        const [optionList, setOptionList] = useState<any[] | null>(null)
        const buttonRef = useRef<View>(null)
        const overlayPanelRef = useRef<View>(null)
        const newOptionValue = optionGroupValue || optionValue
        const [overlayLayout, setOverlayLayout] = useState({ width: 0, height: 0 })
        const [overlayFinalDimension, setOverlayFinalDimension] = useState({ pageX: 0, pageY: 0 })
        const [buttonWidth, setButtonWidth] = useState(0)
        const { windowWidth, windowHeight } = useDimensions()
        const [isRenderedButton, setIsRenderedButton] = useState(false)
        const [isRenderedOverlay, setIsRenderedOverlay] = useState(false)

        useEffect(() => {
            if (activeId === indentificator && !isShowOverlay) {
                // Eliminando el active id del overlayProvider cuando este se cierra
                handleSetActiveId(null)
                onHide()
            }
        }, [isShowOverlay, activeId])

        useEffect(() => {
            const isLoadedDimensions = overlayLayout.width && overlayLayout.height

            if (isRenderedButton && isLoadedDimensions && activeId === indentificator && isShowOverlay) {
                buttonRef.current?.measureInWindow((pageX, pageY, width, height) => {
                    setButtonWidth(width)
                    const spaceClearence = 5 // Holgura de espacio para que no quede pegado al botón

                    // Calculando si el overlay se mostrara por debajo o encima del botón
                    const positionEjeY =
                        pageY + overlayLayout.height + height + spaceClearence > windowHeight
                            ? pageY - overlayLayout.height
                            : pageY + height + spaceClearence

                    const positionEjeX =
                        pageX + overlayLayout.width > windowWidth ? pageX + width - overlayLayout.width : pageX

                    setOverlayFinalDimension({ pageX: positionEjeX, pageY: positionEjeY })
                })
            }
        }, [activeId, overlayLayout, isRenderedButton])

        useEffect(() => {
            if (isRenderedOverlay) {
                // Guardando el ancho y el alto de la lista de elementos cuando este se renderiza
                overlayPanelRef.current?.measureInWindow((_pageX, _pageY, width, height) => {
                    setOverlayLayout({ height, width })
                })
            }
        }, [isRenderedOverlay])

        const newOptions = useMemo(() => {
            return optionGroupChildren // Si la data viene agrupada mapeo los items para tener acceso al valor con el campo "newOptionValue"
                ? options.map(option => option[optionGroupChildren]).flat()
                : options
        }, [options])

        useEffect(() => {
            if (!options) return
            setOptionList(options)

            if (uniqueSelection) {
                setItemSelected(newOptions.find(option => option[newOptionValue] === valueWhenUniqueSelection))
            } else {
                const newSelectedItems = newOptions
                    .filter(option => value?.find(valueItem => valueItem[newOptionValue] === option[newOptionValue]))
                    .map(option => option[newOptionValue])
                setSelectedItems(newSelectedItems)
            }
        }, [options, value, valueWhenUniqueSelection])

        useEffect(() => {
            if (!searchText.length) return setOptionList(options)
            const newOptionList = options?.filter(option => {
                if (optionGroupChildren) {
                    return option[optionGroupChildren].some((optionChild: any) =>
                        optionChild[String(optionLabel)].toUpperCase().includes(searchText.toUpperCase())
                    )
                }
                return option[optionLabel].toUpperCase().includes(searchText.toUpperCase())
            })
            if (newOptionList) setOptionList(newOptionList)
        }, [searchText])

        const handleCheckItem = (selectedItem: any) => {
            if (!selectedItem[newOptionValue]) return

            if (uniqueSelection) handleCheckOneSelection(selectedItem)
            else handleCheckMultiple(selectedItem)
        }

        const handleCheckMultiple = (selectedItem: any) => {
            if (selectedItems.includes(selectedItem[newOptionValue])) {
                const newSelectedItems = selectedItems.filter(item => item !== selectedItem[newOptionValue])
                setSelectedItems(newSelectedItems)
                return onChange(newOptions.filter(option => newSelectedItems.includes(option[newOptionValue])))
            }

            setSelectedItems([...selectedItems, selectedItem[newOptionValue]])
            const itemsToOnChange = newOptions.filter(option => selectedItems.includes(option[newOptionValue]))
            onChange([...itemsToOnChange, selectedItem])
        }

        const handleCheckOneSelection = (selectedItem: any) => {
            if (itemSelected && selectedItem[newOptionValue] === itemSelected[newOptionValue]) return
            setItemSelected(selectedItem)
            onChange(selectedItem[newOptionValue])
            closeShowOverlay()
        }

        const handleOptionAllCheck = () => {
            if (!optionList?.length) return
            setAllCheckOption(!allCheckOption)

            if (allCheckOption) {
                setSelectedItems([])
                return onChange([])
            }

            const itemsToSelect = newOptions.filter(option => option[newOptionValue])
            setSelectedItems(itemsToSelect.map(option => option[newOptionValue]))
            onChange(itemsToSelect)
        }

        const titleButton = () => {
            if (uniqueSelection) return itemSelected ? itemSelected[optionLabel] : placeholder
            return selectedItems.length ? `${placeholder} (${selectedItems.length})` : placeholder
        }

        if (loading) return <ItemSkeleton customStyles={skeletonStyles} />

        return (
            <View style={[{ flexGrow: 1 }, containerStyles]}>
                <Hoverable style={{ flexGrow: 1 }}>
                    {({ hovered }) => (
                        <View style={{ flexGrow: 1 }} ref={buttonRef} onLayout={() => setIsRenderedButton(true)}>
                            <ButtonNative
                                onPress={() => {
                                    onPress()
                                    if (!disabled) handleShowOverlay(indentificator)
                                }}
                                title={titleButton()}
                                buttonStyle={[
                                    { backgroundColor: styles.backgroundComponent, flexGrow: 1, padding: 0 },
                                    localStyles.mainButton,
                                    windowWidth < 600
                                        ? { paddingHorizontal: 4, paddingVertical: 4 }
                                        : { paddingVertical: 10, paddingHorizontal: 26 },
                                    customStyles,
                                    disabled ? { opacity: 0.4 } : null,
                                    hovered && includeHovered ? { borderColor: '#C6D2D9', borderWidth: 1 } : null,
                                ]}
                                containerStyle={{ flexGrow: 1 }}
                                style={{ flexGrow: 1 }}
                                titleStyle={[{ color: styles.text, fontWeight: '400' }, buttonTitleStyle]}
                                showIcon={isShowDownButton}
                                iconSize={16}
                                icon={faChevronDown}
                            >
                                {valueTemplate}
                            </ButtonNative>
                        </View>
                    )}
                </Hoverable>

                {/* Por si se quiere pasar un children en vez de renderizar la lista  */}
                <ModalOverlay visible={Boolean(activeId === indentificator && isShowOverlay && children)}>
                    <View
                        ref={overlayPanelRef}
                        onLayout={() => setIsRenderedOverlay(true)}
                        style={[
                            {
                                opacity: overlayFinalDimension.pageX && overlayFinalDimension.pageY ? 1 : 0,
                                position: 'absolute',
                                left: overlayFinalDimension.pageX,
                                top: overlayFinalDimension.pageY,
                            },
                            overlayPanelStyles,
                        ]}
                    >
                        {children}
                    </View>
                </ModalOverlay>

                {/* Lista de opciones */}
                <ModalOverlay visible={activeId === indentificator && isShowOverlay && !children}>
                    <View
                        ref={overlayPanelRef}
                        onLayout={() => setIsRenderedOverlay(true)}
                        style={[
                            localStyles.overlayPanel,
                            localStyles.boxShadow,
                            {
                                opacity: overlayFinalDimension.pageX && overlayFinalDimension.pageY ? 1 : 0,
                                backgroundColor: styles.backgroundComponent,
                                left: overlayFinalDimension.pageX,
                                top: overlayFinalDimension.pageY,
                                minWidth: windowWidth < 600 ? 230 : 300,                            },
                            overlayPanelStyles,
                            buttonWidth > overlayLayout.width ? { maxWidth: 'auto', width: buttonWidth } : null,
                        ]}
                    >
                        {!hideSearch ? (
                            <View
                                style={[
                                    localStyles.overlayPanelHeader,
                                    { backgroundColor: styles.multiSelectHeaderBackground },
                                ]}
                            >
                                {!uniqueSelection ? (
                                    <CheckBox
                                        style={{ backgroundColor: styles.multiSelectHeaderBackground }}
                                        containerStyle={{ margin: 0, padding: 0 }}
                                        checked={allCheckOption || Boolean(selectedItems.length)}
                                        onPress={handleOptionAllCheck}
                                    />
                                ) : null}

                                <Pressable style={{ flex: 1 }}>
                                    <InputText
                                        placeholder={filterPlaceholder}
                                        value={searchText}
                                        onChange={e => {
                                            setSearchText(e.valueOf())
                                            onChangeTextLabel && onChangeTextLabel(e.valueOf())
                                        }}
                                    />
                                </Pressable>

                                {!uniqueSelection ? (
                                    <ButtonNative
                                        onPress={closeShowOverlay}
                                        title=""
                                        showIcon
                                        iconColor={styles.text}
                                        iconName="close"
                                    />
                                ) : null}
                            </View>
                        ) : null}

                        <ScrollView contentContainerStyle={[{ maxHeight: 200 }]}>
                            {childrenOverlay}
                            {optionList?.map((option, cont) => {
                                if (optionGroupChildren && optionGroupLabel && optionGroupValue) {
                                    return (
                                        <View key={cont}>
                                            {optionGroupTemplate ? (
                                                <>{optionGroupTemplate(option)}</>
                                            ) : (
                                                <Text
                                                    style={{
                                                        backgroundColor: styles.multiSelectHeaderBackground,
                                                        color: styles.text,
                                                        paddingVertical: 10,
                                                        paddingHorizontal: 18,
                                                    }}
                                                >
                                                    {option[optionGroupLabel]}
                                                </Text>
                                            )}

                                            {option[optionGroupChildren].map((item: any) => (
                                                <ItemMultiple
                                                    optionLabel={optionLabel}
                                                    optionValue={optionGroupValue}
                                                    handleCheckItem={handleCheckItem}
                                                    option={item}
                                                    selectedItems={selectedItems}
                                                    itemSelected={itemSelected}
                                                    uniqueSelection={uniqueSelection}
                                                />
                                            ))}
                                        </View>
                                    )
                                } else
                                    return (
                                        <ItemMultiple
                                            key={cont}
                                            optionLabel={optionLabel}
                                            optionValue={optionValue}
                                            handleCheckItem={handleCheckItem}
                                            option={option}
                                            selectedItems={selectedItems}
                                            itemSelected={itemSelected}
                                            uniqueSelection={uniqueSelection}
                                        />
                                    )
                            })}

                            {!optionList?.length && !hideSearch ? (
                                <Text style={{ paddingVertical: 10, paddingHorizontal: 16, color: styles.text }}>
                                    {emptyFilterMessage}
                                </Text>
                            ) : null}
                        </ScrollView>
                    </View>
                </ModalOverlay>
            </View>
        )
    }
)

const localStyles = StyleSheet.create({
    overlayPanel: {
        gap: 6,
        position: 'absolute',
        maxHeight: 300,
        maxWidth: 300,
    },
    boxShadow: {
        shadowColor: 'rgba(0, 0, 0, 0.4)',
        shadowOpacity: 0.4,
        elevation: 5,
        shadowRadius: 8,
        shadowOffset: {
            width: 0,
            height: 2,
        },
    },
    overlayPanelHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 8,
        paddingHorizontal: 16,
    },
    mainButton: {
        flexDirection: 'row-reverse',
        justifyContent: 'space-between',
        gap: 16,
    },
})

export default DropdownNative
