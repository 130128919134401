import React, { useState } from 'react'
import { getStyleSheet } from '../../assets/theme/styles'
import { StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle, Image, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { signOut } from 'aws-amplify/auth'
import { ReportIssueModal } from './ReportIssueModal'
import { useSidemenuProvider } from '../../context/SidemenuProvider'
import useDimensions from '../../hooks/useDimensions'
import { useUserProvider } from '../../context/UserProvider'

export const ItemMenu = ({ children, customStyles }: { customStyles?: StyleProp<ViewStyle>; children: any }) => {
    const styles = getStyleSheet()
    const navigation = useNavigation()
    const { item, hovered, active, isShowSmallAside } = children
    const { setShowSidebar } = useSidemenuProvider()
    const [reportIssueModalVisible, setReportIssueModalVisible] = useState(false)
    let currentBackgroundColor = item.id === active ? '#383A40' : ''
    let currentTextColor = item.id === active ? '#ffffff' : '#969696'
    let currentIconOpacity = item.id === active ? 1 : 0.5
    const { windowWidth } = useDimensions()
    const { setArePrivatePagesAvailable } = useUserProvider()

    currentBackgroundColor = hovered ? '#383A40' : currentBackgroundColor
    currentTextColor = hovered ? '#ffffff' : currentTextColor
    currentIconOpacity = hovered ? 1 : currentIconOpacity

    const combineStyles = StyleSheet.flatten([styles.styleSidebar.item, { backgroundColor: currentBackgroundColor }])
    const combineStylesText = StyleSheet.flatten([styles.styleSidebar.title, { color: currentTextColor, fontSize: 15 }])
    const combineStylesIcon = StyleSheet.flatten([
        styles.styleSidebar.icon,
        { opacity: currentIconOpacity, width: item.width ? 24 : 17 },
    ])

    const logOut = async () => {
        try {
            await signOut()
            navigation.navigate('Login' as never)
        } catch (error) {
            console.log(error)
        }
    }

    const navigate = () => {
        if (!item) return
        if (item.id === 'LogOut') {
            setArePrivatePagesAvailable(false)
            logOut()
        } else if (item.id === 'Report') {
            setReportIssueModalVisible(true)
            setShowSidebar(false)
        } else {
            navigation.navigate(item.title as never)
            setShowSidebar(false)
        }
    }

    return (
        <>
            <ReportIssueModal visible={reportIssueModalVisible} setShowModal={setReportIssueModalVisible} />
            <TouchableOpacity onPress={() => navigate()}>
                <View
                    style={[
                        combineStyles,
                        isShowSmallAside
                            ? {
                                  width: 60,
                                  padding: 0,
                                  paddingVertical: 10,
                                  marginStart: 0,
                              }
                            : null,
                        windowWidth >= 769 ? { paddingLeft: 30 } : null,
                        customStyles,
                    ]}
                >
                    <Image
                        source={item.icon}
                        resizeMode="contain"
                        style={[
                            combineStylesIcon,
                            isShowSmallAside ? { width: 60, height: 17, marginRight: 0, paddingVertical: 10 } : null,
                        ]}
                    />

                    {!isShowSmallAside ? <Text style={combineStylesText}>{item.title}</Text> : null}
                </View>
            </TouchableOpacity>
        </>
    )
}
