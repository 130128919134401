import React, { useRef, useState, useContext } from 'react'
import { StyleSheet, Text, View, ScrollView, Platform, Image } from 'react-native'
import { Button } from '@rneui/base'
import { resetPassword, confirmResetPassword, signInWithRedirect } from 'aws-amplify/auth'
import { getStyleSheet } from '../assets/theme/styles'
import { isEmpty } from 'lodash'
import { useFocusEffect } from '@react-navigation/native'
import Icon from 'react-native-vector-icons/FontAwesome'
import ColumnSignUp from '../components/common/ColumnSignUp'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import InputText from '../components/common/InputText'
import MessageNative from '../components/common/MessageNative'
import useDimensions from '../hooks/useDimensions'

const logoRed = require('../assets/images/timerz_orange.png')
const logoBlack = require('../assets/images/timerz_color.png')

const ForgotPassword = ({ navigation }) => {
    const styles = getStyleSheet()
    const emailRef = useRef<any>(null)
    const verifyMessage = 'Enter verification code and new password'
    const { theme } = useContext<any>(ThemeContext)
    const [email, setEmail] = useState('')
    const [newPass, setNewPass] = useState('')
    const [code, setCode] = useState('')
    const [showError, setShowError] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showConfirmEmail, setShowConfirmEmail] = useState(false)
    const [showConfirmPass, setShowConfirmPass] = useState(false)
    const [showRequiredEmail, setShowRequiredEmail] = useState(false)
    const [blockButtonConfirm, setBlockButtonConfirm] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const { windowWidth } = useDimensions()

    const requiredStyleComponent = (elementRef: any) => {
        if (elementRef?.current) {
            elementRef.current.focus()

            if (Platform.OS === 'web') {
                elementRef.current.style.borderColor = '#E24C4C'
                elementRef.current.style.transitionDuration = '1s'

                setTimeout(() => {
                    elementRef.current.style.borderColor = 'transparent'
                }, 3000)
            }
        }
    }

    const validateFields = () => {
        setShowError(false)
        setShowConfirm(false)
        setShowConfirmEmail(false)
        if (isEmpty(email)) {
            requiredStyleComponent(emailRef)
            setShowRequiredEmail(true)
            setErrorMessage('Email is required.')
            return true
        }
        return false
    }

    const sendConfirm = () => {
        if (!validateFields()) {
            resetPassword({ username: email })
                .then(data => {
                    setShowConfirm(true)
                    setShowConfirmEmail(true)
                    setSuccessMessage('An email was sent with the verification code.')
                })
                .catch(error => {
                    setShowError(true)
                    if (typeof error === 'object') setErrorMessage(error.message)
                    else setErrorMessage('Process could not be completed.')
                })
        }
    }

    const confirmCode = () => {
        setShowError(false)
        setBlockButtonConfirm(true)
        confirmResetPassword({ username: email, confirmationCode: code, newPassword: newPass })
            .then(data => {
                setShowConfirmEmail(false)
                setShowConfirmPass(true)
                setSuccessMessage('Password changed')
                setBlockButtonConfirm(false)

                setTimeout(() => {
                    navigation.navigate('Login')
                    setShowConfirm(false)
                }, 4000)
            })
            .catch(error => {
                setShowConfirmEmail(false)
                setShowError(true)
                setBlockButtonConfirm(false)
                if (typeof error === 'object') {
                    let errorMessage = error.message.replace('Username', 'Email')
                    setErrorMessage(errorMessage)
                } else setErrorMessage('Process could not be completed.')
            })
    }

    const handleKeyDownSendConfirm = event => {
        if (event.key === 'Enter') {
            sendConfirm()
        }
    }

    const handleKeyDownCodeConfirm = event => {
        if (event.key === 'Enter') {
            confirmCode()
        }
    }

    const clearComponents = () => {
        setEmail('')
        setNewPass('')
        setCode('')
        setShowError(false)
        setShowConfirm(false)
        setShowConfirmEmail(false)
        setShowConfirmPass(false)
        setShowRequiredEmail(false)
        setBlockButtonConfirm(false)
        setErrorMessage('')
        setSuccessMessage('')
    }

    useFocusEffect(
        React.useCallback(() => {
            clearComponents()
        }, [])
    )

    return (
        <ScrollView
            contentContainerStyle={{
                width: '100%',
                flexDirection: windowWidth > 768 ? 'row' : 'column',
                flexGrow: 1,
            }}
        >
            <View style={[styles.styleLogin.container, { flexGrow: 1 }]}>
                <View style={localStyles.container}>
                    <View style={localStyles.containerForm}>
                        {theme === 'dark' ? (
                            <Image resizeMode="contain" source={logoRed} style={localStyles.imageLogo} />
                        ) : (
                            <Image resizeMode="contain" source={logoBlack} style={localStyles.imageLogo} />
                        )}
                        <Text style={[localStyles.textTitle, styles.styleLogin.colorText]}>Reset your Password</Text>
                        {showConfirmEmail ? (
                            <View style={{ width: '100%' }}>
                                <MessageNative severity="success" text={successMessage} />
                            </View>
                        ) : null}
                        {showConfirmPass ? (
                            <View style={{ width: '100%' }}>
                                <MessageNative severity="success" text={successMessage} />
                            </View>
                        ) : null}
                        {showError ? (
                            <View style={{ width: '100%' }}>
                                <MessageNative severity="error" text={errorMessage} />
                            </View>
                        ) : null}
                        <Text style={[localStyles.textLabel, styles.styleLogin.colorText]}>Your Email</Text>
                        <View style={{ width: '100%', marginBottom: 20 }}>
                            <InputText
                                placeholder="Email"
                                inputStyle={{ backgroundColor: styles.loginInputBackground }}
                                ref={emailRef}
                                value={email}
                                onChange={e => {
                                    setShowRequiredEmail(false)
                                    setEmail(e.valueOf())
                                }}
                                onKeyPress={handleKeyDownSendConfirm}
                            />
                            {showRequiredEmail ? (
                                <Text style={{ color: '#ef9a9a', fontSize: 12 }}>{errorMessage}</Text>
                            ) : null}
                        </View>
                        {showConfirm ? (
                            <>
                                <Text style={[localStyles.textVerify, styles.styleLogin.colorText]}>
                                    {verifyMessage}
                                </Text>
                                <View style={{ width: '100%', marginBottom: 20 }}>
                                    <InputText
                                        inputStyle={{ backgroundColor: styles.loginInputBackground }}
                                        placeholder="Verification code"
                                        ref={emailRef}
                                        value={code}
                                        onChange={e => {
                                            setShowConfirmEmail(false)
                                            setCode(e.valueOf())
                                        }}
                                        onKeyPress={handleKeyDownCodeConfirm}
                                    />
                                </View>
                                <View style={{ width: '100%', marginBottom: 20 }}>
                                    <InputText
                                        inputStyle={{ backgroundColor: styles.loginInputBackground }}
                                        placeholder="New password"
                                        ref={emailRef}
                                        value={newPass}
                                        secureTextEntry
                                        onChange={e => {
                                            setShowConfirmEmail(false)
                                            setNewPass(e.valueOf())
                                        }}
                                        onKeyPress={handleKeyDownCodeConfirm}
                                    />
                                </View>
                                <Button
                                    buttonStyle={[localStyles.buttonReset, styles.styleBackground.button]}
                                    titleStyle={[localStyles.buttonResetTitle]}
                                    containerStyle={localStyles.buttonResetContainer}
                                    title="CONFIRM CODE"
                                    onPress={() => confirmCode()}
                                    disabled={blockButtonConfirm}
                                />
                            </>
                        ) : (
                            <Button
                                buttonStyle={[localStyles.buttonReset, styles.styleBackground.button]}
                                titleStyle={localStyles.buttonResetTitle}
                                containerStyle={localStyles.buttonResetContainer}
                                title="RESET PASSWORD"
                                onPress={() => sendConfirm()}
                            />
                        )}

                        <Text style={localStyles.textLabelCenter}>or log in using</Text>
                        <View style={localStyles.containerLoginButtons}>
                            <Button
                                buttonStyle={[localStyles.buttonProvider, styles.styleLogin.colorButtom]}
                                titleStyle={[localStyles.buttonProviderTitle, styles.styleLogin.colorText]}
                                containerStyle={localStyles.buttonProviderContainer}
                                icon={
                                    <Icon
                                        name="google"
                                        size={25}
                                        style={[localStyles.buttonIcon, styles.styleLogin.colorText]}
                                    />
                                }
                                title="GOOGLE"
                                onPress={() => signInWithRedirect({ provider: 'Google' })}
                            />
                            {/* <Button
                                    buttonStyle={[localStyles.buttonProvider, styles.styleLogin.colorButtom]}
                                    titleStyle={[localStyles.buttonProviderTitle, styles.styleLogin.colorText]}
                                    containerStyle={localStyles.buttonProviderContainer}
                                    icon={
                                        <Icon
                                            name="apple"
                                            size={25}
                                            style={[localStyles.buttonIcon, styles.styleLogin.colorText]}
                                        />
                                    }
                                    title="APPLE"
                                    onPress={() => console.log('hello apple')}
                                /> */}
                        </View>
                    </View>
                </View>
            </View>

            <ColumnSignUp navigation={navigation} />
        </ScrollView>
    )
}

const localStyles = StyleSheet.create({
    containerForm: {
        maxWidth: 378,
        width: '90%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    imageLogo: {
        width: 125,
        height: 41,
        marginTop: 20,
        marginBottom: 20,
    },
    containerLoginButtons: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    buttonStyle: {
        backgroundColor: '#D00500',
        height: 58,
        borderRadius: 4,
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 16,
    },
    buttonIcon: {
        fontSize: 22,
        color: '#fff',
        marginRight: 13,
    },
    buttonProvider: {
        height: 58,
        borderRadius: 4,
        marginLeft: 6,
        borderStyle: 'solid',
        borderColor: '#555961',
        borderWidth: 1,
    },
    buttonProviderTitle: {
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: 16,
    },
    buttonProviderContainer: {
        marginBottom: 23,
        flex: 1,
    },
    buttonReset: {
        backgroundColor: '#D00500',
        height: 58,
        borderRadius: 4,
    },
    buttonResetTitle: {
        color: '#FFF',
        fontWeight: 'bold',
        fontSize: 16,
    },
    buttonResetContainer: {
        width: '100%',
        marginBottom: 23,
    },
    textTitle: {
        color: '#ffffff',
        fontSize: 24,
        lineHeight: 28,
        marginBottom: 30,
        fontWeight: '700',
    },
    textLabelCenter: {
        width: '100%',
        color: '#C8D3DC',
        textAlign: 'center',
        marginBottom: 22,
        textTransform: 'lowercase',
    },
    textLabel: {
        width: '100%',
        color: '#ffffff',
        textAlign: 'left',
        marginBottom: 8,
        textTransform: 'uppercase',
    },
    textVerify: {
        width: '100%',
        textAlign: 'center',
        marginBottom: 20,
    },
})

export default ForgotPassword
