import { useCallback, useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { ClientService } from '../../../services/ClientService'
import { ProjectService } from '../../../services/ProjectService'
import { StyleProp, ViewStyle } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

export interface Props {
    onProjectChange: (value) => void
    key?: string
    selectedProject?: any
    style?: any
    buttomStyle?: StyleProp<ViewStyle>
    containerStyle?: StyleProp<ViewStyle>
}

const useMultiselectProjects = (props: Props) => {
    const { theme, userRole, userSub } = useContext<any>(ThemeContext)
    const [projects, setProjects] = useState<any>([])
    const [selectedProject, setSelectedProject] = useState(null)

    const handleChange = e => {
        setSelectedProject(e)
        props.onProjectChange(e.map(item => item.value))
    }

    const loadProjectGroup = async () => {
        const groupedProjects = await ProjectService().getProjectsMultiselect(userRole, userSub)
        setProjects(groupedProjects)
    }

    const setProjectGroup = () => {
        ClientService()
            .getClientsActive(userSub)
            .then((data: any) => {
                let groups = data
                    .filter(c => c.Projects.items.length > 0 && c.status === 'ACTIVE')
                    .map((client: any) => {
                        const items = client.Projects.items
                            .filter(p => p.status === 'ACTIVE')
                            .map((project: any) => {
                                return {
                                    label: project.name,
                                    value: project.id,
                                    color: project.groupcolor,
                                }
                            })

                        if (items.length > 0) {
                            return {
                                label: client?.name,
                                code: client?.id,
                                items,
                            }
                        }
                    })

                groups = groups.filter(element => {
                    return element !== undefined
                })

                setProjects(groups)
            })
    }

    useFocusEffect(
        useCallback(() => {
            loadProjectGroup()
        }, [])
    )

    useEffect(() => {
        if (props.selectedProject) {
            setSelectedProject(props.selectedProject)
        }
    }, [props.selectedProject])

    return [{ theme, projects, selectedProject, handleChange }]
}

export default useMultiselectProjects
