import { generateClient } from 'aws-amplify/api'
import { createInvitation, deleteInvitation, updateInvitation } from '../graphql/mutations'
import { getInvitation, listInvitations } from '../graphql/queries'
import moment from 'moment'

export const InvitationService = () => {
    const saveInvitation = async (newEntry: any) => {
        const response: any = await generateClient().graphql({
            query: createInvitation,
            variables: { input: newEntry },
        })
        return response?.data?.createInvitation
    }

    const existsEmailAndOrganization = async (organizationId: string, userEmail: string) => {
        const response: any = await generateClient().graphql({
            query: listInvitations,
            variables: { filter: { organizationID: { eq: organizationId }, email: { eq: userEmail } } },
        })
        const findRecord = response?.data?.listInvitations?.items[0]
        return findRecord
    }

    const updateInvitationById = async (updateObject: any) => {
        return await generateClient().graphql({ query: updateInvitation, variables: { input: updateObject } })
    }

    const getInvitationById = async (id: string) => {
        const response: any = await generateClient().graphql({ query: getInvitation, variables: { id } })
        const result = response?.data?.getInvitation
        if (result._deleted !== true) return result

        return undefined
    }

    const getAllInvitationsByOrganization = async (organizationId: string) => {
        const response: any = await generateClient().graphql({
            query: listInvitations,
            variables: { filter: { organizationID: { eq: organizationId } } },
        })
        const invitations = response?.data?.listInvitations.items.filter(f => {
            return f._deleted !== true
        })

        invitations.sort((a, b) => moment(b.invitedOn).valueOf() - moment(a.invitedOn).valueOf())

        return invitations.map(item => {
            return {
                ...item,
                createdDateFormat: moment(item.invitedOn).format('MMM DD, YYYY'),
            }
        })
    }

    const deleteInvitationById = async (invitationId: string, version) => {
        return await generateClient().graphql({
            query: deleteInvitation,
            variables: { input: { id: invitationId, _version: version } },
        })
    }

    return {
        saveInvitation,
        existsEmailAndOrganization,
        updateInvitationById,
        getInvitationById,
        getAllInvitationsByOrganization,
        deleteInvitationById,
    }
}
