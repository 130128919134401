import { Role } from '../../models'
import { useEffect, useState } from 'react'
import DropdownStatusOrgUser from './DropdownStatusOrgUser/DropdownStatusOrgUser'
import InputText from './InputText'
import ColorPickerNative from './ColorPicker'
import GroupedRecordMenu from './GroupedRecordMenu/GroupedRecordMenu'
import { View, Text, ViewStyle, StyleProp, StyleSheet } from 'react-native'
import { getStyleSheet } from '../../assets/theme/styles'
import useDimensions from '../../hooks/useDimensions'
import { MomentaryView } from './DataTable'
import ItemSkeleton from './ItemSkeleton'

interface IRowTag {
    tag: any
    isReadOnly?: boolean
    userRole: Role
    onUpdateRow: any
    style?: StyleProp<ViewStyle>
    loadingTags: boolean
    onShowConfirmationDelete: (id: string) => void
}

const RowTag = ({ tag, isReadOnly, userRole, onUpdateRow, style, loadingTags, onShowConfirmationDelete }: IRowTag) => {
    const [valueName, setValueName] = useState<string>('')
    const isAdmin = userRole === Role.ADMIN
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (tag.name !== valueName) setValueName(tag.name)
    }, [tag])

    useEffect(() => {
        if (valueName.includes(' ')) return setErrorMessage('Tag should not include spaces')
        if (valueName.length > 8) return setErrorMessage('Tag should not exceed a maximum of 8 letters')
        setErrorMessage('')
    }, [valueName])

    const actions = [
        {
            action: () => onShowConfirmationDelete(tag.id),
            label: 'Delete',
            icon: 'delete',
        },
    ]

    const onHandleChangeColor = (color: string, objectId: string, version: number) => {
        const objectUpdate = {
            id: objectId,
            groupcolor: color.replace('#', ''),
            _version: version,
        }
        if (tag.groupcolor === color.replace('#', '')) return
        onUpdateRow(null, objectUpdate)
    }

    const allStatus: {
        active: string
        inactive: string
    } = {
        active: 'ACTIVE',
        inactive: 'ARCHIVED',
    }

    const onCellEditComplete = ({
        newValue,
        field,
        oldValue,
    }: {
        newValue: string
        field: string
        oldValue: string
    }) => {
        if (newValue.trim().length && !errorMessage.length) {
            if (newValue.trim() !== oldValue.trim()) {
                tag[field] = newValue

                const objectUpdate = {
                    id: tag.id,
                    name: newValue,
                    _version: tag._version,
                }
                onUpdateRow(null, objectUpdate)
            }
        }
    }

    return (
        <View
            style={[
                {
                    backgroundColor: styles.backgroundColorRow,
                    padding: 10,
                    gap: 10,
                    borderRadius: 6,
                },
                windowWidth > 700 ? localStylesTag.containerWhenThereOneFile : null,
                style,
            ]}
        >
            <MomentaryView showViewUp={700} customStyles={{ flexDirection: 'row', flexGrow: 1, alignItems: 'center' }}>
                {isReadOnly ? (
                    <Text numberOfLines={1} style={{ flexGrow: 1, maxWidth: '90%' }}>
                        {tag.name}
                    </Text>
                ) : (
                    <>
                        <InputText
                            disabled={!isAdmin || isReadOnly}
                            value={valueName}
                            containerStyle={{ flexGrow: 1 }}
                            loading={loadingTags}
                            skeletonStyles={{ flexGrow: 1, width: 'auto' }}
                            inputStyle={[
                                errorMessage.length
                                    ? { color: 'rgb(255, 111, 111)', borderColor: 'rgb(255, 111, 111)' }
                                    : null,
                            ]}
                            onChange={e => setValueName(e.valueOf())}
                            onBlur={() => {
                                onCellEditComplete({
                                    field: 'name',
                                    newValue: valueName,
                                    oldValue: tag.name,
                                })
                            }}
                        />

                        {errorMessage.length && !loadingTags ? (
                            <Text style={{ color: 'rgb(255, 111, 111)', fontSize: 12, paddingLeft: 15, paddingTop: 2 }}>
                                {errorMessage}
                            </Text>
                        ) : null}
                    </>
                )}

                <ColorPickerNative
                    customStyles={{ marginLeft: 5, paddingRight: windowWidth > 700 ? 20 : 'auto' }}
                    value={tag.groupcolor}
                    identificator={tag.id}
                    loading={loadingTags}
                    skeletonStyles={{ width: 36, marginLeft: 5, marginRight: windowWidth > 700 ? 20 : 'auto' }}
                    onChange={color => onHandleChangeColor(color, tag.id, tag._version)}
                />
            </MomentaryView>

            <MomentaryView
                showViewUp={700}
                customStyles={[
                    { flexDirection: 'row', justifyContent: 'space-between', paddingHorizontal: !loadingTags ? 10 : 0 },
                    windowWidth > 700 ? { alignItems: 'center', paddingHorizontal: 0, gap: 20 } : null,
                ]}
            >
                <>
                    {loadingTags ? (
                        <ItemSkeleton customStyles={{ width: 112 }} />
                    ) : (
                        <DropdownStatusOrgUser
                            customStyles={{ width: windowWidth > 700 ? 112 : 'auto' }}
                            onStatusHandle={value => onUpdateRow(value, tag, 'status')}
                            currentStatus={tag?.status}
                            allStatus={allStatus}
                        />
                    )}

                    {isAdmin ? (
                        <GroupedRecordMenu
                            identificator={tag.id}
                            loading={loadingTags}
                            skeletonStyles={{ width: 26, marginLeft: 5 }}
                            buttonTitleStyles={{ width: windowWidth > 700 ? 48 : 'auto' }}
                            items={actions}
                        />
                    ) : (
                        <View />
                    )}
                </>
            </MomentaryView>
        </View>
    )
}

export const localStylesTag = StyleSheet.create({
    containerWhenThereOneFile: {
        flexDirection: 'row',
        alignItems: 'center',
        gap: 20,
        padding: 0,
        paddingVertical: 10,
        paddingHorizontal: 14,
    },
})

export default RowTag
