export const useDateFormat = () => {

    const dateStringToDate = (stringDate: string) => {
        const [strdate, strtime] = stringDate.split('T');
        const [year, month, day] = strdate.split('-');

        const date = new Date(+year, +month - 1, +day);

        return date.toDateString();
    }

    const toISOStringWithoutTimezone = (date: Date) => {
        const pad = n => `${Math.floor(Math.abs(n))}`.padStart(2, '0');
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            'Z'
    };

    const timeFormatString = (today: Date) => {
        let hourLocal = (today.getHours() < 10) ? '0' + today.getHours() : today.getHours();
        let minutesLocal = (today.getMinutes() < 10) ? '0' + today.getMinutes() : today.getMinutes();
        let secondsLocal =  (today.getSeconds() < 10) ? '0' + today.getSeconds() : today.getSeconds();
        let time = hourLocal + ":" + minutesLocal + ":" + secondsLocal;
        return time;
    }

    const formatLocaleDate = (stringDate: string) => {
        let date = new Date(stringDate);
        let today = date.toLocaleDateString('en-US', {  
            month : 'short',
            day : 'numeric',
            year : 'numeric'
        });
        return today;
    }

    const formatLocaleMonthDay = (stringDate: string) => {
        let date = new Date(stringDate);
        let today = date.toLocaleDateString('en-US', {  
            month : 'short',
            day : 'numeric'
        });
        return today;
    }

    return {
        dateStringToDate,
        toISOStringWithoutTimezone,
        timeFormatString,
        formatLocaleDate,
        formatLocaleMonthDay
    }
}