import React, { FC, useContext, useState } from 'react'
import { ImageBackground, TouchableOpacity, View, Animated, Image } from 'react-native'
import { Hoverable } from 'react-native-web-hover'
import { getStyleSheet } from '../../assets/theme/styles'
import { ItemMenu } from '../common/ItemMenu'
import { ThemeContext } from '../../assets/theme/ThemeProvider'
import { Role } from '../../models'
import { useFocusEffect } from '@react-navigation/native'
import { TopProfile } from '../common/TopProfile'
import { useSidemenuProvider } from '../../context/SidemenuProvider'
import useDimensions from '../../hooks/useDimensions'
import DropdownOrganization from '../common/DropdownOrganization/DropdownOrganization'

const logoRed = require('../../assets/images/timerz_orange.png')
const logoSmall = require('../../assets/images/timerz_orange_logo.png')
const backgroundPattern = require('../../assets/images/background-pattern.svg')

const DATA_TOP = [
    {
        id: 'Timer',
        icon: require('../../assets/icons/timer.png'),
        title: 'Timer',
        active: true,
    },
]

const DATA = [
    {
        id: 'Reports',
        icon: require('../../assets/icons/reports.png'),
        title: 'Reports',
        active: true,
    },
    {
        id: 'Projects',
        icon: require('../../assets/icons/projects.png'),
        title: 'Projects',
        active: true,
    },
    {
        id: 'Clients',
        icon: require('../../assets/icons/clients.png'),
        title: 'Clients',
        active: true,
    },
    {
        id: 'Team',
        icon: require('../../assets/icons/teams.png'),
        title: 'Team',
        active: true,
    },
    {
        id: 'Tags',
        icon: require('../../assets/icons/tags.png'),
        title: 'Tags',
        active: true,
    },
]

export const Sidebar: FC<any> = ({ children }) => {
    const styles = getStyleSheet()
    const { active } = children
    const { userRole } = useContext<any>(ThemeContext)
    const [dataBottom, setDataBottom] = useState<any>([])
    const { showSidebar, setShowSidebar, fadeAnim } = useSidemenuProvider()
    const { windowWidth, windowHeight } = useDimensions()

    console.log({ userRole })

    let DATA_BOTTOM_MENU = [
        {
            id: 'Upgrade',
            icon: require('../../assets/icons/upgrade.png'),
            title: 'Upgrade',
            active: true,
        },
    ]

    const accessMenu = () => {
        let isAdmin = userRole !== Role.ADMIN
        if (isAdmin) {
            var foundIndex = DATA.findIndex(x => x.title === 'Clients')
            DATA[foundIndex].active = false
            foundIndex = DATA.findIndex(x => x.title === 'Team')
            DATA[foundIndex].active = false
            foundIndex = DATA.findIndex(x => x.title === 'Tags')
            DATA[foundIndex].active = false
        } else {
            var foundIndex = DATA.findIndex(x => x.title === 'Clients')
            DATA[foundIndex].active = true
            foundIndex = DATA.findIndex(x => x.title === 'Team')
            DATA[foundIndex].active = true
            foundIndex = DATA.findIndex(x => x.title === 'Tags')
            DATA[foundIndex].active = true
        }

        if (isAdmin) {
            DATA_BOTTOM_MENU = DATA_BOTTOM_MENU.filter(item => item.title !== 'Upgrade')
        }
        setDataBottom(DATA_BOTTOM_MENU)
    }

    useFocusEffect(
        React.useCallback(() => {
            if (userRole) {
                accessMenu()
            }
        }, [userRole])
    )

    const isShowSmallAside = windowWidth >= 500 && windowWidth <= 768

    return (
        <>
            {showSidebar && windowWidth < 769 && (
                <TouchableOpacity
                    onPress={() => setShowSidebar(false)}
                    style={{
                        position: 'absolute',
                        height: windowHeight,
                        width: windowWidth,
                        backgroundColor: '#000',
                        opacity: 0.5,
                        zIndex: 999,
                    }}
                />
            )}

            <Animated.View
                style={[
                    styles.styleSidebar.container,
                    {
                        zIndex: 9999,
                        transform: [{ translateX: fadeAnim }],
                        position: 'absolute',
                        height: '100%',
                    },
                    isShowSmallAside ? { width: 60, minWidth: 'auto', maxWidth: 60 } : null,
                    windowWidth >= 500 ? { position: 'relative', transform: [{ translateX: 0 }] } : null,
                ]}
            >
                <ImageBackground resizeMode="cover" source={backgroundPattern} style={[{ flexGrow: 1, width: '100%' }]}>
                    <View style={{ flexGrow: 1 }}>
                        {!isShowSmallAside ? (
                            <Image
                                resizeMode="contain"
                                source={logoRed}
                                style={[styles.styleSidebar.imageLogo, { width: 115 }]}
                            />
                        ) : null}

                        {isShowSmallAside ? (
                            <Image resizeMode="contain" source={logoSmall} style={styles.styleSidebar.imageSmallLogo} />
                        ) : null}

                        <View
                            style={{
                                display: !isShowSmallAside && windowWidth <= 1200 ? 'flex' : 'none',
                                marginHorizontal: 24,
                                marginBottom: 14,
                            }}
                        >
                            <DropdownOrganization />
                        </View>

                        <View style={{ marginTop: 20 }}>
                            {DATA_TOP.map((item, i) => {
                                let current = active
                                if (current === 'TimerDone') {
                                    current = 'Timer'
                                }

                                if (item.active) {
                                    return (
                                        <Hoverable key={i}>
                                            {({ hovered }) => (
                                                <ItemMenu
                                                    children={{ item, isShowSmallAside, hovered, active: current }}
                                                />
                                            )}
                                        </Hoverable>
                                    )
                                }
                            })}
                        </View>

                        <View>
                            {DATA.map((item, i) => {
                                let current = active
                                if (current === 'ReportDetails') {
                                    current = 'Reports'
                                } else if (current === 'ProjectsArchived') {
                                    current = 'Projects'
                                } else if (current === 'TeamGroup' || current === 'TeamActivity') {
                                    current = 'Team'
                                }

                                if (item.active) {
                                    return (
                                        <Hoverable key={i}>
                                            {({ hovered }) => (
                                                <ItemMenu
                                                    children={{ item, isShowSmallAside, hovered, active: current }}
                                                />
                                            )}
                                        </Hoverable>
                                    )
                                }
                            })}
                        </View>

                        <View style={{ flexGrow: 1, justifyContent: 'flex-end' }}>
                            {dataBottom.map((item, i) => {
                                if (item.active) {
                                    return (
                                        <Hoverable key={i}>
                                            {({ hovered }) => (
                                                <ItemMenu children={{ item, isShowSmallAside, hovered, active }} />
                                            )}
                                        </Hoverable>
                                    )
                                }
                            })}
                        </View>

                        {windowWidth <= 1200 ? <TopProfile isShowSmallAside={isShowSmallAside} /> : null}

                        <View
                            style={{ height: 1, width: '100%', backgroundColor: 'rgb(70, 68, 68)', marginVertical: 10 }}
                        />

                        <Hoverable>
                            {({ hovered }) => (
                                <ItemMenu
                                    customStyles={{ opacity: 0.5, marginVertical: 10 }}
                                    children={{
                                        item: {
                                            id: 'Report',
                                            icon: require('../../assets/icons/report.png'),
                                            title: 'Report a problem',
                                            active: true,
                                        },
                                        isShowSmallAside,
                                        hovered,
                                        active: false,
                                    }}
                                />
                            )}
                        </Hoverable>
                    </View>
                </ImageBackground>
            </Animated.View>
        </>
    )
}
