import { Role } from '../../API'
import MultiselectTags from './MultiselectTags/MultiselectTags'
import { useEffect, useState } from 'react'
import InputText from './InputText'
import { StyleProp, View, ViewStyle, Text } from 'react-native'
import GroupedRecordMenu from './GroupedRecordMenu/GroupedRecordMenu'
import { MomentaryView } from './DataTable'
import { getStyleSheet } from '../../assets/theme/styles'
import useDimensions from '../../hooks/useDimensions'
import ItemSkeleton from './ItemSkeleton'

interface IRowClient {
    client: any
    userRole: Role
    loadingClients: boolean
    tags: any
    isReadOnly?: boolean
    customStyle?: StyleProp<ViewStyle>
    handleChangeTag: (selectedTags: any, client: any) => Promise<void>
    onUpdateRow: (id: string | null, newObject?: any) => void
}

const RowClient = ({
    client,
    userRole,
    loadingClients,
    tags,
    isReadOnly,
    customStyle,
    handleChangeTag,
    onUpdateRow,
}: IRowClient) => {
    const isAdmin = userRole === Role.ADMIN
    const [isLoading, setIsLoading] = useState(false)
    const [valueName, setValueName] = useState<string>('')
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()

    const action = {
        label: isReadOnly ? 'Restore' : 'Archived',
        icon: isReadOnly ? 'restore' : 'archive-arrow-down-outline',
    }

    useEffect(() => {
        if (client.name !== valueName) setValueName(client.name)
    }, [client])

    const selectedTagsFormat = client.arrayTags.map((item: any) => {
        return {
            value: item.id,
            label: item.name,
            color: item.color.includes('#') ? item.color : '#' + item.color,
        }
    })

    const onChangeTags = async (value: any) => {
        setIsLoading(true)
        await handleChangeTag(value, client)
        setIsLoading(false)
    }

    const items = [{ label: action.label, icon: action.icon, action: () => onUpdateRow(client.id) }]

    const onCellEditComplete = ({
        newValue,
        field,
        oldValue,
    }: {
        newValue: string
        field: string
        oldValue: string
    }) => {
        if (newValue.trim().length > 0) {
            if (newValue.trim() !== oldValue.trim()) {
                client[field] = newValue

                const objectUpdate = {
                    id: client.id,
                    name: newValue,
                    _version: client._version,
                }

                onUpdateRow(null, objectUpdate)
            }
        }
    }

    const RecordMenu = () => {
        if (loadingClients) return <ItemSkeleton width={26} />
        return <GroupedRecordMenu identificator={client.id} items={items} />
    }

    return (
        <View
            style={[
                {
                    backgroundColor: styles.backgroundColorRow,
                    padding: 10,
                    gap: 10,
                    borderRadius: 6,
                },
                windowWidth > 900 ? { flexDirection: 'row', alignItems: 'center' } : null,
                customStyle,
            ]}
        >
            <MomentaryView customStyles={{ flexDirection: 'row', alignItems: 'center' }} showViewUp={900}>
                {isReadOnly ? (
                    <Text style={{ flexGrow: 1, color: styles.text, paddingHorizontal: 15 }}>{client.name}</Text>
                ) : (
                    <InputText
                        containerStyle={{ flexGrow: 1 }}
                        disabled={!isAdmin}
                        value={valueName}
                        loading={loadingClients}
                        skeletonStyles={{ flexGrow: 1, marginRight: 10, width: 'auto' }}
                        onChange={e => {
                            setValueName(e.valueOf())
                        }}
                        onBlur={() => {
                            onCellEditComplete({
                                field: 'name',
                                newValue: valueName,
                                oldValue: client.name,
                            })
                        }}
                    />
                )}

                {isAdmin && windowWidth <= 900 ? RecordMenu() : null}
            </MomentaryView>

            <MultiselectTags
                customStyles={{ width: windowWidth > 900 ? '35%' : 'auto' }}
                indentificator={client.id}
                tags={tags}
                value={selectedTagsFormat}
                loading={loadingClients}
                skeletonStyles={{ width: windowWidth > 900 ? '35%' : 'auto' }}
                onChangeTag={value => onChangeTags(value)}
                disabled={!isAdmin || isLoading || isReadOnly}
            />

            {windowWidth > 900 ? RecordMenu() : null}
        </View>
    )
}

export default RowClient
