import React, { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { ClientService } from '../../../services/ClientService'
import { Status } from '../../../API'
import { StyleProp, ViewStyle } from 'react-native'

export interface Props {
    onClientChange: (value) => void
    key?: string
    selectedClient?: any
    containerStyle?: StyleProp<ViewStyle>
}

const useMultiselectClients = (props: Props) => {
    const { theme, userSub } = useContext<any>(ThemeContext)
    const [clients, setClients] = useState([])
    const [selectedClient, setSelectedClient] = useState(null)

    const handleChange = e => {
        setSelectedClient(e)
        props.onClientChange(e.map(i => i.code))
    }

    const setClient = () => {
        ClientService()
            .getClientsActive(userSub)
            .then((data: any) => {
                let elements = data
                    .filter(e => e.status === Status.ACTIVE)
                    .map(item => {
                        return {
                            label: item.name,
                            code: item.id,
                        }
                    })

                elements = elements.filter(element => {
                    return element !== undefined
                })

                setClients(elements)
            })
    }

    useEffect(() => {
        if (props.selectedClient) {
            setSelectedClient(props.selectedClient)
        }

        setClient()
    }, [props])

    return [{ theme, clients, selectedClient, handleChange }]
}

export default useMultiselectClients
