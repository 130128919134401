/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const processOrder = /* GraphQL */ `mutation ProcessOrder($input: ProcessOrderInput!) {
  processOrder(input: $input) {
    status
    clientSecret
    customerId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.ProcessOrderMutationVariables,
  APITypes.ProcessOrderMutation
>;
export const getStripeCoupon = /* GraphQL */ `mutation GetStripeCoupon($input: GetCouponInput) {
  getStripeCoupon(input: $input) {
    name
    valid
    isExistingCoupon
    percent_off
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetStripeCouponMutationVariables,
  APITypes.GetStripeCouponMutation
>;
export const getInvoicesByCustomer = /* GraphQL */ `mutation GetInvoicesByCustomer($input: GetInvoicesInput) {
  getInvoicesByCustomer(input: $input) {
    invoices {
      id
      created
      total
      invoice_pdf
      seats
      __typename
    }
    subscription {
      id
      planId
      amount
      interval
      seats
      subscriptionsItems {
        id
        quantity
        __typename
      }
      __typename
    }
    paymentMethod {
      id
      billing_details {
        name
        __typename
      }
      card {
        last4
        brand
        exp_month
        exp_year
        display_brand
        __typename
      }
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetInvoicesByCustomerMutationVariables,
  APITypes.GetInvoicesByCustomerMutation
>;
export const getReportPdfMutation = /* GraphQL */ `mutation GetReportPdfMutation($input: InputGetReportPdf) {
  getReportPdfMutation(input: $input)
}
` as GeneratedMutation<
  APITypes.GetReportPdfMutationMutationVariables,
  APITypes.GetReportPdfMutationMutation
>;
export const getSubscriptionsByCustomer = /* GraphQL */ `mutation GetSubscriptionsByCustomer($input: billingInput!) {
  getSubscriptionsByCustomer(input: $input) {
    statusCode
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetSubscriptionsByCustomerMutationVariables,
  APITypes.GetSubscriptionsByCustomerMutation
>;
export const cancelSubscriptionById = /* GraphQL */ `mutation CancelSubscriptionById($input: billingInput!) {
  cancelSubscriptionById(input: $input) {
    statusCode
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelSubscriptionByIdMutationVariables,
  APITypes.CancelSubscriptionByIdMutation
>;
export const updateSubscriptionPaymentMethod = /* GraphQL */ `mutation UpdateSubscriptionPaymentMethod($input: updatePaymentMethodInput!) {
  updateSubscriptionPaymentMethod(input: $input) {
    statusCode
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubscriptionPaymentMethodMutationVariables,
  APITypes.UpdateSubscriptionPaymentMethodMutation
>;
export const updateSubscriptionPlan = /* GraphQL */ `mutation UpdateSubscriptionPlan($input: updatePlanInput!) {
  updateSubscriptionPlan(input: $input) {
    statusCode
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateSubscriptionPlanMutationVariables,
  APITypes.UpdateSubscriptionPlanMutation
>;
export const getInvoiceUrl = /* GraphQL */ `mutation GetInvoiceUrl($input: invoiceInput!) {
  getInvoiceUrl(input: $input) {
    statusCode
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GetInvoiceUrlMutationVariables,
  APITypes.GetInvoiceUrlMutation
>;
export const createProblemReport = /* GraphQL */ `mutation CreateProblemReport($input: createProblemInput!) {
  createProblemReport(input: $input) {
    statusCode
    body
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProblemReportMutationVariables,
  APITypes.CreateProblemReportMutation
>;
export const createOrder = /* GraphQL */ `mutation CreateOrder(
  $input: CreateOrderInput!
  $condition: ModelOrderConditionInput
) {
  createOrder(input: $input, condition: $condition) {
    id
    usersub
    date
    total
    subscriptionId
    createdAt
    updatedAt
    plans {
      items {
        id
        orderID
        planID
        order {
          id
          usersub
          date
          total
          subscriptionId
          createdAt
          updatedAt
          plans {
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrderMutationVariables,
  APITypes.CreateOrderMutation
>;
export const updateOrder = /* GraphQL */ `mutation UpdateOrder(
  $input: UpdateOrderInput!
  $condition: ModelOrderConditionInput
) {
  updateOrder(input: $input, condition: $condition) {
    id
    usersub
    date
    total
    subscriptionId
    createdAt
    updatedAt
    plans {
      items {
        id
        orderID
        planID
        order {
          id
          usersub
          date
          total
          subscriptionId
          createdAt
          updatedAt
          plans {
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrderMutationVariables,
  APITypes.UpdateOrderMutation
>;
export const deleteOrder = /* GraphQL */ `mutation DeleteOrder(
  $input: DeleteOrderInput!
  $condition: ModelOrderConditionInput
) {
  deleteOrder(input: $input, condition: $condition) {
    id
    usersub
    date
    total
    subscriptionId
    createdAt
    updatedAt
    plans {
      items {
        id
        orderID
        planID
        order {
          id
          usersub
          date
          total
          subscriptionId
          createdAt
          updatedAt
          plans {
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrderMutationVariables,
  APITypes.DeleteOrderMutation
>;
export const createInvitation = /* GraphQL */ `mutation CreateInvitation(
  $input: CreateInvitationInput!
  $condition: ModelInvitationConditionInput
) {
  createInvitation(input: $input, condition: $condition) {
    id
    organizationID
    organization {
      id
      name
      planID
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      pomodoroTime
      shortBreakTime
      longBreakTime
      automaticStartTime
      editionTimeFrame
      created_by
      subscribedSeats
      OrganizationUser {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tags {
        items {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    email
    invitedOn
    invitedBy
    user {
      id
      givenName
      familyName
      name
      email
      timeZone
      dateFormat
      timeFormat
      image
      themeMode
      accepTerms
      newUser
      customerIdStripe
      disabled
      OrganizationUsers {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    role
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateInvitationMutationVariables,
  APITypes.CreateInvitationMutation
>;
export const updateInvitation = /* GraphQL */ `mutation UpdateInvitation(
  $input: UpdateInvitationInput!
  $condition: ModelInvitationConditionInput
) {
  updateInvitation(input: $input, condition: $condition) {
    id
    organizationID
    organization {
      id
      name
      planID
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      pomodoroTime
      shortBreakTime
      longBreakTime
      automaticStartTime
      editionTimeFrame
      created_by
      subscribedSeats
      OrganizationUser {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tags {
        items {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    email
    invitedOn
    invitedBy
    user {
      id
      givenName
      familyName
      name
      email
      timeZone
      dateFormat
      timeFormat
      image
      themeMode
      accepTerms
      newUser
      customerIdStripe
      disabled
      OrganizationUsers {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    role
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateInvitationMutationVariables,
  APITypes.UpdateInvitationMutation
>;
export const deleteInvitation = /* GraphQL */ `mutation DeleteInvitation(
  $input: DeleteInvitationInput!
  $condition: ModelInvitationConditionInput
) {
  deleteInvitation(input: $input, condition: $condition) {
    id
    organizationID
    organization {
      id
      name
      planID
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      pomodoroTime
      shortBreakTime
      longBreakTime
      automaticStartTime
      editionTimeFrame
      created_by
      subscribedSeats
      OrganizationUser {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tags {
        items {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    email
    invitedOn
    invitedBy
    user {
      id
      givenName
      familyName
      name
      email
      timeZone
      dateFormat
      timeFormat
      image
      themeMode
      accepTerms
      newUser
      customerIdStripe
      disabled
      OrganizationUsers {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    role
    status
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteInvitationMutationVariables,
  APITypes.DeleteInvitationMutation
>;
export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    givenName
    familyName
    name
    email
    timeZone
    dateFormat
    timeFormat
    image
    themeMode
    accepTerms
    newUser
    customerIdStripe
    disabled
    OrganizationUsers {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        usersub
        role
        is_default
        status
        createdAt
        updatedAt
        userID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Invitations {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        invitedOn
        invitedBy
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        userID
        groupID
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    givenName
    familyName
    name
    email
    timeZone
    dateFormat
    timeFormat
    image
    themeMode
    accepTerms
    newUser
    customerIdStripe
    disabled
    OrganizationUsers {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        usersub
        role
        is_default
        status
        createdAt
        updatedAt
        userID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Invitations {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        invitedOn
        invitedBy
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        userID
        groupID
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    givenName
    familyName
    name
    email
    timeZone
    dateFormat
    timeFormat
    image
    themeMode
    accepTerms
    newUser
    customerIdStripe
    disabled
    OrganizationUsers {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        usersub
        role
        is_default
        status
        createdAt
        updatedAt
        userID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Invitations {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        invitedOn
        invitedBy
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        userID
        groupID
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createOrganizationUser = /* GraphQL */ `mutation CreateOrganizationUser(
  $input: CreateOrganizationUserInput!
  $condition: ModelOrganizationUserConditionInput
) {
  createOrganizationUser(input: $input, condition: $condition) {
    id
    organizationID
    organization {
      id
      name
      planID
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      pomodoroTime
      shortBreakTime
      longBreakTime
      automaticStartTime
      editionTimeFrame
      created_by
      subscribedSeats
      OrganizationUser {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tags {
        items {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    email
    usersub
    role
    is_default
    status
    createdAt
    updatedAt
    userID
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationUserMutationVariables,
  APITypes.CreateOrganizationUserMutation
>;
export const updateOrganizationUser = /* GraphQL */ `mutation UpdateOrganizationUser(
  $input: UpdateOrganizationUserInput!
  $condition: ModelOrganizationUserConditionInput
) {
  updateOrganizationUser(input: $input, condition: $condition) {
    id
    organizationID
    organization {
      id
      name
      planID
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      pomodoroTime
      shortBreakTime
      longBreakTime
      automaticStartTime
      editionTimeFrame
      created_by
      subscribedSeats
      OrganizationUser {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tags {
        items {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    email
    usersub
    role
    is_default
    status
    createdAt
    updatedAt
    userID
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationUserMutationVariables,
  APITypes.UpdateOrganizationUserMutation
>;
export const deleteOrganizationUser = /* GraphQL */ `mutation DeleteOrganizationUser(
  $input: DeleteOrganizationUserInput!
  $condition: ModelOrganizationUserConditionInput
) {
  deleteOrganizationUser(input: $input, condition: $condition) {
    id
    organizationID
    organization {
      id
      name
      planID
      plan {
        id
        name
        description
        price
        max_users
        max_projects
        max_clients
        max_groups
        status
        priceIdStripe
        membership
        Organization {
          items {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        orders {
          items {
            id
            orderID
            planID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      status
      pomodoroTime
      shortBreakTime
      longBreakTime
      automaticStartTime
      editionTimeFrame
      created_by
      subscribedSeats
      OrganizationUser {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Clients {
        items {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tags {
        items {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    email
    usersub
    role
    is_default
    status
    createdAt
    updatedAt
    userID
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationUserMutationVariables,
  APITypes.DeleteOrganizationUserMutation
>;
export const createOrganization = /* GraphQL */ `mutation CreateOrganization(
  $input: CreateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  createOrganization(input: $input, condition: $condition) {
    id
    name
    planID
    plan {
      id
      name
      description
      price
      max_users
      max_projects
      max_clients
      max_groups
      status
      priceIdStripe
      membership
      Organization {
        items {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    pomodoroTime
    shortBreakTime
    longBreakTime
    automaticStartTime
    editionTimeFrame
    created_by
    subscribedSeats
    OrganizationUser {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        usersub
        role
        is_default
        status
        createdAt
        updatedAt
        userID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Invitations {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        invitedOn
        invitedBy
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Clients {
      items {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tasks {
      items {
        id
        name
        time
        usersub
        type
        status
        hasTimeEdited
        projectID
        organizationID
        project {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        TagsTask {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        searchableText
        createdAt
        updatedAt
        deleted
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tags {
      items {
        id
        name
        color
        status
        organizationID
        Clients {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateOrganizationMutationVariables,
  APITypes.CreateOrganizationMutation
>;
export const updateOrganization = /* GraphQL */ `mutation UpdateOrganization(
  $input: UpdateOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  updateOrganization(input: $input, condition: $condition) {
    id
    name
    planID
    plan {
      id
      name
      description
      price
      max_users
      max_projects
      max_clients
      max_groups
      status
      priceIdStripe
      membership
      Organization {
        items {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    pomodoroTime
    shortBreakTime
    longBreakTime
    automaticStartTime
    editionTimeFrame
    created_by
    subscribedSeats
    OrganizationUser {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        usersub
        role
        is_default
        status
        createdAt
        updatedAt
        userID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Invitations {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        invitedOn
        invitedBy
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Clients {
      items {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tasks {
      items {
        id
        name
        time
        usersub
        type
        status
        hasTimeEdited
        projectID
        organizationID
        project {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        TagsTask {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        searchableText
        createdAt
        updatedAt
        deleted
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tags {
      items {
        id
        name
        color
        status
        organizationID
        Clients {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateOrganizationMutationVariables,
  APITypes.UpdateOrganizationMutation
>;
export const deleteOrganization = /* GraphQL */ `mutation DeleteOrganization(
  $input: DeleteOrganizationInput!
  $condition: ModelOrganizationConditionInput
) {
  deleteOrganization(input: $input, condition: $condition) {
    id
    name
    planID
    plan {
      id
      name
      description
      price
      max_users
      max_projects
      max_clients
      max_groups
      status
      priceIdStripe
      membership
      Organization {
        items {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    status
    pomodoroTime
    shortBreakTime
    longBreakTime
    automaticStartTime
    editionTimeFrame
    created_by
    subscribedSeats
    OrganizationUser {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        usersub
        role
        is_default
        status
        createdAt
        updatedAt
        userID
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Invitations {
      items {
        id
        organizationID
        organization {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        email
        invitedOn
        invitedBy
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        role
        status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Clients {
      items {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tasks {
      items {
        id
        name
        time
        usersub
        type
        status
        hasTimeEdited
        projectID
        organizationID
        project {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        TagsTask {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        searchableText
        createdAt
        updatedAt
        deleted
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Groups {
      items {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tags {
      items {
        id
        name
        color
        status
        organizationID
        Clients {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            tagID
            taskID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteOrganizationMutationVariables,
  APITypes.DeleteOrganizationMutation
>;
export const createPlan = /* GraphQL */ `mutation CreatePlan(
  $input: CreatePlanInput!
  $condition: ModelPlanConditionInput
) {
  createPlan(input: $input, condition: $condition) {
    id
    name
    description
    price
    max_users
    max_projects
    max_clients
    max_groups
    status
    priceIdStripe
    membership
    Organization {
      items {
        id
        name
        planID
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        pomodoroTime
        shortBreakTime
        longBreakTime
        automaticStartTime
        editionTimeFrame
        created_by
        subscribedSeats
        OrganizationUser {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Clients {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tags {
          items {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orders {
      items {
        id
        orderID
        planID
        order {
          id
          usersub
          date
          total
          subscriptionId
          createdAt
          updatedAt
          plans {
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreatePlanMutationVariables,
  APITypes.CreatePlanMutation
>;
export const updatePlan = /* GraphQL */ `mutation UpdatePlan(
  $input: UpdatePlanInput!
  $condition: ModelPlanConditionInput
) {
  updatePlan(input: $input, condition: $condition) {
    id
    name
    description
    price
    max_users
    max_projects
    max_clients
    max_groups
    status
    priceIdStripe
    membership
    Organization {
      items {
        id
        name
        planID
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        pomodoroTime
        shortBreakTime
        longBreakTime
        automaticStartTime
        editionTimeFrame
        created_by
        subscribedSeats
        OrganizationUser {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Clients {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tags {
          items {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orders {
      items {
        id
        orderID
        planID
        order {
          id
          usersub
          date
          total
          subscriptionId
          createdAt
          updatedAt
          plans {
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdatePlanMutationVariables,
  APITypes.UpdatePlanMutation
>;
export const deletePlan = /* GraphQL */ `mutation DeletePlan(
  $input: DeletePlanInput!
  $condition: ModelPlanConditionInput
) {
  deletePlan(input: $input, condition: $condition) {
    id
    name
    description
    price
    max_users
    max_projects
    max_clients
    max_groups
    status
    priceIdStripe
    membership
    Organization {
      items {
        id
        name
        planID
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        status
        pomodoroTime
        shortBreakTime
        longBreakTime
        automaticStartTime
        editionTimeFrame
        created_by
        subscribedSeats
        OrganizationUser {
          items {
            id
            organizationID
            email
            usersub
            role
            is_default
            status
            createdAt
            updatedAt
            userID
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Invitations {
          items {
            id
            organizationID
            email
            invitedOn
            invitedBy
            role
            status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Clients {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tasks {
          items {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Groups {
          items {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Tags {
          items {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    orders {
      items {
        id
        orderID
        planID
        order {
          id
          usersub
          date
          total
          subscriptionId
          createdAt
          updatedAt
          plans {
            nextToken
            startedAt
            __typename
          }
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        plan {
          id
          name
          description
          price
          max_users
          max_projects
          max_clients
          max_groups
          status
          priceIdStripe
          membership
          Organization {
            nextToken
            startedAt
            __typename
          }
          orders {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeletePlanMutationVariables,
  APITypes.DeletePlanMutation
>;
export const createTag = /* GraphQL */ `mutation CreateTag(
  $input: CreateTagInput!
  $condition: ModelTagConditionInput
) {
  createTag(input: $input, condition: $condition) {
    id
    name
    color
    status
    organizationID
    Clients {
      items {
        id
        tagID
        clientID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tasks {
      items {
        id
        tagID
        taskID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        task {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTagMutationVariables,
  APITypes.CreateTagMutation
>;
export const updateTag = /* GraphQL */ `mutation UpdateTag(
  $input: UpdateTagInput!
  $condition: ModelTagConditionInput
) {
  updateTag(input: $input, condition: $condition) {
    id
    name
    color
    status
    organizationID
    Clients {
      items {
        id
        tagID
        clientID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tasks {
      items {
        id
        tagID
        taskID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        task {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTagMutationVariables,
  APITypes.UpdateTagMutation
>;
export const deleteTag = /* GraphQL */ `mutation DeleteTag(
  $input: DeleteTagInput!
  $condition: ModelTagConditionInput
) {
  deleteTag(input: $input, condition: $condition) {
    id
    name
    color
    status
    organizationID
    Clients {
      items {
        id
        tagID
        clientID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    Tasks {
      items {
        id
        tagID
        taskID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        task {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTagMutationVariables,
  APITypes.DeleteTagMutation
>;
export const createGroup = /* GraphQL */ `mutation CreateGroup(
  $input: CreateGroupInput!
  $condition: ModelGroupConditionInput
) {
  createGroup(input: $input, condition: $condition) {
    id
    name
    status
    organizationID
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UsersGroup {
      items {
        id
        userID
        groupID
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupMutationVariables,
  APITypes.CreateGroupMutation
>;
export const updateGroup = /* GraphQL */ `mutation UpdateGroup(
  $input: UpdateGroupInput!
  $condition: ModelGroupConditionInput
) {
  updateGroup(input: $input, condition: $condition) {
    id
    name
    status
    organizationID
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UsersGroup {
      items {
        id
        userID
        groupID
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupMutationVariables,
  APITypes.UpdateGroupMutation
>;
export const deleteGroup = /* GraphQL */ `mutation DeleteGroup(
  $input: DeleteGroupInput!
  $condition: ModelGroupConditionInput
) {
  deleteGroup(input: $input, condition: $condition) {
    id
    name
    status
    organizationID
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    UsersGroup {
      items {
        id
        userID
        groupID
        user {
          id
          givenName
          familyName
          name
          email
          timeZone
          dateFormat
          timeFormat
          image
          themeMode
          accepTerms
          newUser
          customerIdStripe
          disabled
          OrganizationUsers {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupMutationVariables,
  APITypes.DeleteGroupMutation
>;
export const createClient = /* GraphQL */ `mutation CreateClient(
  $input: CreateClientInput!
  $condition: ModelClientConditionInput
) {
  createClient(input: $input, condition: $condition) {
    id
    name
    TagsClient {
      items {
        id
        tagID
        clientID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    status
    organizationID
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClientMutationVariables,
  APITypes.CreateClientMutation
>;
export const updateClient = /* GraphQL */ `mutation UpdateClient(
  $input: UpdateClientInput!
  $condition: ModelClientConditionInput
) {
  updateClient(input: $input, condition: $condition) {
    id
    name
    TagsClient {
      items {
        id
        tagID
        clientID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    status
    organizationID
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClientMutationVariables,
  APITypes.UpdateClientMutation
>;
export const deleteClient = /* GraphQL */ `mutation DeleteClient(
  $input: DeleteClientInput!
  $condition: ModelClientConditionInput
) {
  deleteClient(input: $input, condition: $condition) {
    id
    name
    TagsClient {
      items {
        id
        tagID
        clientID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    status
    organizationID
    Projects {
      items {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClientMutationVariables,
  APITypes.DeleteClientMutation
>;
export const createProject = /* GraphQL */ `mutation CreateProject(
  $input: CreateProjectInput!
  $condition: ModelProjectConditionInput
) {
  createProject(input: $input, condition: $condition) {
    id
    name
    description
    status
    groupcolor
    clientID
    client {
      id
      name
      TagsClient {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    groupID
    group {
      id
      name
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UsersGroup {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    organizationID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProjectMutationVariables,
  APITypes.CreateProjectMutation
>;
export const updateProject = /* GraphQL */ `mutation UpdateProject(
  $input: UpdateProjectInput!
  $condition: ModelProjectConditionInput
) {
  updateProject(input: $input, condition: $condition) {
    id
    name
    description
    status
    groupcolor
    clientID
    client {
      id
      name
      TagsClient {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    groupID
    group {
      id
      name
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UsersGroup {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    organizationID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProjectMutationVariables,
  APITypes.UpdateProjectMutation
>;
export const deleteProject = /* GraphQL */ `mutation DeleteProject(
  $input: DeleteProjectInput!
  $condition: ModelProjectConditionInput
) {
  deleteProject(input: $input, condition: $condition) {
    id
    name
    description
    status
    groupcolor
    clientID
    client {
      id
      name
      TagsClient {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    groupID
    group {
      id
      name
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UsersGroup {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    organizationID
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProjectMutationVariables,
  APITypes.DeleteProjectMutation
>;
export const createTask = /* GraphQL */ `mutation CreateTask(
  $input: CreateTaskInput!
  $condition: ModelTaskConditionInput
) {
  createTask(input: $input, condition: $condition) {
    id
    name
    time
    usersub
    type
    status
    hasTimeEdited
    projectID
    organizationID
    project {
      id
      name
      description
      status
      groupcolor
      clientID
      client {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      group {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    TagsTask {
      items {
        id
        tagID
        taskID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        task {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    searchableText
    createdAt
    updatedAt
    deleted
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskMutationVariables,
  APITypes.CreateTaskMutation
>;
export const updateTask = /* GraphQL */ `mutation UpdateTask(
  $input: UpdateTaskInput!
  $condition: ModelTaskConditionInput
) {
  updateTask(input: $input, condition: $condition) {
    id
    name
    time
    usersub
    type
    status
    hasTimeEdited
    projectID
    organizationID
    project {
      id
      name
      description
      status
      groupcolor
      clientID
      client {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      group {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    TagsTask {
      items {
        id
        tagID
        taskID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        task {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    searchableText
    createdAt
    updatedAt
    deleted
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskMutationVariables,
  APITypes.UpdateTaskMutation
>;
export const deleteTask = /* GraphQL */ `mutation DeleteTask(
  $input: DeleteTaskInput!
  $condition: ModelTaskConditionInput
) {
  deleteTask(input: $input, condition: $condition) {
    id
    name
    time
    usersub
    type
    status
    hasTimeEdited
    projectID
    organizationID
    project {
      id
      name
      description
      status
      groupcolor
      clientID
      client {
        id
        name
        TagsClient {
          items {
            id
            tagID
            clientID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      groupID
      group {
        id
        name
        status
        organizationID
        Projects {
          items {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        UsersGroup {
          items {
            id
            userID
            groupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      organizationID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    TagsTask {
      items {
        id
        tagID
        taskID
        tag {
          id
          name
          color
          status
          organizationID
          Clients {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        task {
          id
          name
          time
          usersub
          type
          status
          hasTimeEdited
          projectID
          organizationID
          project {
            id
            name
            description
            status
            groupcolor
            clientID
            groupID
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          TagsTask {
            nextToken
            startedAt
            __typename
          }
          searchableText
          createdAt
          updatedAt
          deleted
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
    searchableText
    createdAt
    updatedAt
    deleted
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskMutationVariables,
  APITypes.DeleteTaskMutation
>;
export const createByOrder = /* GraphQL */ `mutation CreateByOrder(
  $input: CreateByOrderInput!
  $condition: ModelByOrderConditionInput
) {
  createByOrder(input: $input, condition: $condition) {
    id
    orderID
    planID
    order {
      id
      usersub
      date
      total
      subscriptionId
      createdAt
      updatedAt
      plans {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    plan {
      id
      name
      description
      price
      max_users
      max_projects
      max_clients
      max_groups
      status
      priceIdStripe
      membership
      Organization {
        items {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateByOrderMutationVariables,
  APITypes.CreateByOrderMutation
>;
export const updateByOrder = /* GraphQL */ `mutation UpdateByOrder(
  $input: UpdateByOrderInput!
  $condition: ModelByOrderConditionInput
) {
  updateByOrder(input: $input, condition: $condition) {
    id
    orderID
    planID
    order {
      id
      usersub
      date
      total
      subscriptionId
      createdAt
      updatedAt
      plans {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    plan {
      id
      name
      description
      price
      max_users
      max_projects
      max_clients
      max_groups
      status
      priceIdStripe
      membership
      Organization {
        items {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateByOrderMutationVariables,
  APITypes.UpdateByOrderMutation
>;
export const deleteByOrder = /* GraphQL */ `mutation DeleteByOrder(
  $input: DeleteByOrderInput!
  $condition: ModelByOrderConditionInput
) {
  deleteByOrder(input: $input, condition: $condition) {
    id
    orderID
    planID
    order {
      id
      usersub
      date
      total
      subscriptionId
      createdAt
      updatedAt
      plans {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    plan {
      id
      name
      description
      price
      max_users
      max_projects
      max_clients
      max_groups
      status
      priceIdStripe
      membership
      Organization {
        items {
          id
          name
          planID
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          status
          pomodoroTime
          shortBreakTime
          longBreakTime
          automaticStartTime
          editionTimeFrame
          created_by
          subscribedSeats
          OrganizationUser {
            nextToken
            startedAt
            __typename
          }
          Invitations {
            nextToken
            startedAt
            __typename
          }
          Clients {
            nextToken
            startedAt
            __typename
          }
          Projects {
            nextToken
            startedAt
            __typename
          }
          Tasks {
            nextToken
            startedAt
            __typename
          }
          Groups {
            nextToken
            startedAt
            __typename
          }
          Tags {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      orders {
        items {
          id
          orderID
          planID
          order {
            id
            usersub
            date
            total
            subscriptionId
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          plan {
            id
            name
            description
            price
            max_users
            max_projects
            max_clients
            max_groups
            status
            priceIdStripe
            membership
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteByOrderMutationVariables,
  APITypes.DeleteByOrderMutation
>;
export const createUserGroup = /* GraphQL */ `mutation CreateUserGroup(
  $input: CreateUserGroupInput!
  $condition: ModelUserGroupConditionInput
) {
  createUserGroup(input: $input, condition: $condition) {
    id
    userID
    groupID
    user {
      id
      givenName
      familyName
      name
      email
      timeZone
      dateFormat
      timeFormat
      image
      themeMode
      accepTerms
      newUser
      customerIdStripe
      disabled
      OrganizationUsers {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    group {
      id
      name
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UsersGroup {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserGroupMutationVariables,
  APITypes.CreateUserGroupMutation
>;
export const updateUserGroup = /* GraphQL */ `mutation UpdateUserGroup(
  $input: UpdateUserGroupInput!
  $condition: ModelUserGroupConditionInput
) {
  updateUserGroup(input: $input, condition: $condition) {
    id
    userID
    groupID
    user {
      id
      givenName
      familyName
      name
      email
      timeZone
      dateFormat
      timeFormat
      image
      themeMode
      accepTerms
      newUser
      customerIdStripe
      disabled
      OrganizationUsers {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    group {
      id
      name
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UsersGroup {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserGroupMutationVariables,
  APITypes.UpdateUserGroupMutation
>;
export const deleteUserGroup = /* GraphQL */ `mutation DeleteUserGroup(
  $input: DeleteUserGroupInput!
  $condition: ModelUserGroupConditionInput
) {
  deleteUserGroup(input: $input, condition: $condition) {
    id
    userID
    groupID
    user {
      id
      givenName
      familyName
      name
      email
      timeZone
      dateFormat
      timeFormat
      image
      themeMode
      accepTerms
      newUser
      customerIdStripe
      disabled
      OrganizationUsers {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          usersub
          role
          is_default
          status
          createdAt
          updatedAt
          userID
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Invitations {
        items {
          id
          organizationID
          organization {
            id
            name
            planID
            status
            pomodoroTime
            shortBreakTime
            longBreakTime
            automaticStartTime
            editionTimeFrame
            created_by
            subscribedSeats
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          email
          invitedOn
          invitedBy
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          role
          status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Groups {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    group {
      id
      name
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      UsersGroup {
        items {
          id
          userID
          groupID
          user {
            id
            givenName
            familyName
            name
            email
            timeZone
            dateFormat
            timeFormat
            image
            themeMode
            accepTerms
            newUser
            customerIdStripe
            disabled
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserGroupMutationVariables,
  APITypes.DeleteUserGroupMutation
>;
export const createClientTag = /* GraphQL */ `mutation CreateClientTag(
  $input: CreateClientTagInput!
  $condition: ModelClientTagConditionInput
) {
  createClientTag(input: $input, condition: $condition) {
    id
    tagID
    clientID
    tag {
      id
      name
      color
      status
      organizationID
      Clients {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    client {
      id
      name
      TagsClient {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateClientTagMutationVariables,
  APITypes.CreateClientTagMutation
>;
export const updateClientTag = /* GraphQL */ `mutation UpdateClientTag(
  $input: UpdateClientTagInput!
  $condition: ModelClientTagConditionInput
) {
  updateClientTag(input: $input, condition: $condition) {
    id
    tagID
    clientID
    tag {
      id
      name
      color
      status
      organizationID
      Clients {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    client {
      id
      name
      TagsClient {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateClientTagMutationVariables,
  APITypes.UpdateClientTagMutation
>;
export const deleteClientTag = /* GraphQL */ `mutation DeleteClientTag(
  $input: DeleteClientTagInput!
  $condition: ModelClientTagConditionInput
) {
  deleteClientTag(input: $input, condition: $condition) {
    id
    tagID
    clientID
    tag {
      id
      name
      color
      status
      organizationID
      Clients {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    client {
      id
      name
      TagsClient {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      status
      organizationID
      Projects {
        items {
          id
          name
          description
          status
          groupcolor
          clientID
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          groupID
          group {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          organizationID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteClientTagMutationVariables,
  APITypes.DeleteClientTagMutation
>;
export const createTaskTag = /* GraphQL */ `mutation CreateTaskTag(
  $input: CreateTaskTagInput!
  $condition: ModelTaskTagConditionInput
) {
  createTaskTag(input: $input, condition: $condition) {
    id
    tagID
    taskID
    tag {
      id
      name
      color
      status
      organizationID
      Clients {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    task {
      id
      name
      time
      usersub
      type
      status
      hasTimeEdited
      projectID
      organizationID
      project {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      TagsTask {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      searchableText
      createdAt
      updatedAt
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTaskTagMutationVariables,
  APITypes.CreateTaskTagMutation
>;
export const updateTaskTag = /* GraphQL */ `mutation UpdateTaskTag(
  $input: UpdateTaskTagInput!
  $condition: ModelTaskTagConditionInput
) {
  updateTaskTag(input: $input, condition: $condition) {
    id
    tagID
    taskID
    tag {
      id
      name
      color
      status
      organizationID
      Clients {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    task {
      id
      name
      time
      usersub
      type
      status
      hasTimeEdited
      projectID
      organizationID
      project {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      TagsTask {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      searchableText
      createdAt
      updatedAt
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTaskTagMutationVariables,
  APITypes.UpdateTaskTagMutation
>;
export const deleteTaskTag = /* GraphQL */ `mutation DeleteTaskTag(
  $input: DeleteTaskTagInput!
  $condition: ModelTaskTagConditionInput
) {
  deleteTaskTag(input: $input, condition: $condition) {
    id
    tagID
    taskID
    tag {
      id
      name
      color
      status
      organizationID
      Clients {
        items {
          id
          tagID
          clientID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          client {
            id
            name
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Tasks {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    task {
      id
      name
      time
      usersub
      type
      status
      hasTimeEdited
      projectID
      organizationID
      project {
        id
        name
        description
        status
        groupcolor
        clientID
        client {
          id
          name
          TagsClient {
            nextToken
            startedAt
            __typename
          }
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        groupID
        group {
          id
          name
          status
          organizationID
          Projects {
            nextToken
            startedAt
            __typename
          }
          UsersGroup {
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        organizationID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      TagsTask {
        items {
          id
          tagID
          taskID
          tag {
            id
            name
            color
            status
            organizationID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          task {
            id
            name
            time
            usersub
            type
            status
            hasTimeEdited
            projectID
            organizationID
            searchableText
            createdAt
            updatedAt
            deleted
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      searchableText
      createdAt
      updatedAt
      deleted
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTaskTagMutationVariables,
  APITypes.DeleteTaskTagMutation
>;
