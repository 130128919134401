import { useState } from 'react'
import { Role } from '../../API'
import { GroupProfilePhoto } from '../image/GroupProfilePhoto'
import DropdownStatusOrgUser from './DropdownStatusOrgUser/DropdownStatusOrgUser'
import DropdownNative from './MultiSelectNative/MultiSelect'
import SwitchRoleAdmin from './SwitchRoleAdmin/SwitchRoleAdmin'
import { View, Text } from 'react-native'
import { getStyleSheet } from '../../assets/theme/styles'
import { MomentaryView } from './DataTable'
import useDimensions from '../../hooks/useDimensions'

interface IRowTeam {
    team: any
    allGroups: any[]
    onUpdateRow: (id: any, data: any, type?: any) => void
    handleChangeGroup: (selectedGroups: any, user: any) => void
}

const RowTeam = ({ team, allGroups, onUpdateRow, handleChangeGroup }: IRowTeam) => {
    const [groups, setGroups] = useState(team.userGroups)
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()
    const largeSizeWidth = 1100

    return (
        <View
            style={[
                {
                    backgroundColor: styles.backgroundColorRow,
                    paddingHorizontal: 14,
                    paddingVertical: 10,
                    rowGap: 16,
                    borderRadius: 6,
                },
                windowWidth > largeSizeWidth ? { flexDirection: 'row', alignItems: 'center' } : null,
            ]}
        >
            <MomentaryView
                showViewUp={largeSizeWidth}
                customStyles={[
                    { columnGap: 10, rowGap: 14 },
                    windowWidth > 600
                        ? { flexDirection: 'row', alignItems: 'center', columnGap: 25, justifyContent: 'space-between' }
                        : null,
                ]}
            >
                <MomentaryView
                    showViewUp={largeSizeWidth}
                    customStyles={{ flexDirection: 'row', alignItems: 'center', gap: 20 }}
                >
                    <View style={windowWidth > largeSizeWidth ? { width: '5%' } : null}>
                        <GroupProfilePhoto image={team.userImage} />
                    </View>

                    <View style={windowWidth > largeSizeWidth ? { width: '20%' } : null}>
                        <Text style={{ color: styles.text }}>{team.userName}</Text>
                    </View>
                </MomentaryView>

                <View
                    style={{
                        paddingRight: windowWidth > 600 ? 20 : 0,
                        width: windowWidth > largeSizeWidth ? '25%' : 'auto',
                    }}
                >
                    <Text style={{ color: styles.text }}>{team.email}</Text>
                </View>
            </MomentaryView>

            {windowWidth > largeSizeWidth ? (
                <DropdownNative
                    containerStyles={{ width: '20%', flexGrow: 0, paddingRight: 20 }}
                    customStyles={{ paddingHorizontal: 5 }}
                    value={groups}
                    indentificator={team.id}
                    options={allGroups}
                    onChange={setGroups}
                    onHide={() => handleChangeGroup(groups, team)}
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Group"
                    emptyFilterMessage="No groups added"
                />
            ) : null}

            <MomentaryView
                showViewUp={largeSizeWidth}
                customStyles={[
                    { gap: 10 },
                    windowWidth > 600 ? { flexDirection: 'row', alignItems: 'center', gap: 0 } : null,
                ]}
            >
                <MomentaryView customStyles={{ flexDirection: 'row', gap: 40 }} showViewUp={largeSizeWidth}>
                    <SwitchRoleAdmin
                        customStyles={windowWidth > largeSizeWidth ? { width: '15%' } : null}
                        onRoleHandle={value => onUpdateRow(value ? Role.ADMIN : Role.USER, team, 'role')}
                        currentRole={team?.role}
                    />

                    <DropdownStatusOrgUser
                        customStyles={windowWidth > largeSizeWidth ? { width: '15%' } : null}
                        onStatusHandle={value => onUpdateRow(value, team, 'status')}
                        currentStatus={team?.status}
                        allStatus={{
                            active: 'ACTIVE',
                            inactive: 'INACTIVE',
                        }}
                    />
                </MomentaryView>

                {windowWidth <= largeSizeWidth ? (
                    <DropdownNative
                        containerStyles={windowWidth > 600 ? { width: '50%', paddingLeft: 20 } : null}
                        customStyles={windowWidth > 600 ? { paddingHorizontal: 20, paddingVertical: 20 } : null}
                        value={groups}
                        indentificator={team.id}
                        options={allGroups}
                        onChange={setGroups}
                        onHide={() => handleChangeGroup(groups, team)}
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Group"
                        emptyFilterMessage="No groups added"
                    />
                ) : null}
            </MomentaryView>
        </View>
    )
}

export default RowTeam
