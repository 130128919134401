import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Role } from '../../../models'
import useDropdownOrganization, { Props } from './useDropdownOrganization'
import DropdownNative from '../MultiSelectNative/MultiSelect'
import GenerateUUID from 'react-native-uuid'
import { useOverlayProvider } from '../../../context/OverlayProvider'
import useDimensions from '../../../hooks/useDimensions'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { faRepeat } from '@fortawesome/free-solid-svg-icons'
import { getStyleSheet } from '../../../assets/theme/styles'

const dropdownOrganizationId = GenerateUUID.v4() as string

const DropdownOrganization: React.FC<Props> = props => {
    const [{ userRole, organizations, organizationsGroup, selectedOrganization, onOrganizationChange, pressSettings }] =
        useDropdownOrganization(props)
    const { windowWidth } = useDimensions()
    const styles = getStyleSheet()
    const { closeShowOverlay } = useOverlayProvider()

    const groupedItemTemplate = (option: any) => {
        return (
            <Text
                style={{ color: '#3B82f6', fontWeight: '500', textAlign: 'right', padding: 6 }}
                onPress={() => {
                    pressSettings()
                    closeShowOverlay()
                }}
            >
                {option.label}
            </Text>
        )
    }

    const valueTemplate = () => {
        const organizationFind = organizations?.find(organization => organization.code === selectedOrganization)

        return (
            <View style={localStyles.buttonTemplate}>
                <FontAwesomeIcon
                    style={{ minWidth: 16, width: 16 }}
                    color={windowWidth > 1200 ? styles.text : '#fff'}
                    icon={faRepeat}
                />
                <Text
                    numberOfLines={1}
                    style={[localStyles.buttonTitleTemplate, windowWidth > 1200 ? { color: styles.text } : null]}
                >
                    {organizationFind?.name}
                </Text>
            </View>
        )
    }

    return (
        <>
            {userRole === Role.ADMIN ? (
                <DropdownNative
                    uniqueSelection
                    hideSearch
                    valueWhenUniqueSelection={selectedOrganization as any}
                    placeholder="Organization"
                    optionLabel="name"
                    optionValue="code"
                    optionGroupLabel="label"
                    optionGroupValue="code"
                    optionGroupChildren="items"
                    indentificator={dropdownOrganizationId}
                    options={organizationsGroup}
                    buttonTitleStyle={windowWidth <= 1200 ? { color: '#969696' } : null}
                    overlayPanelStyles={{ minWidth: 'auto' }}
                    customStyles={[
                        localStyles.buttonStyle,
                        windowWidth > 1200 ? { backgroundColor: 'transparent' } : null,
                    ]}
                    containerStyles={props.style}
                    optionGroupTemplate={groupedItemTemplate}
                    valueTemplate={valueTemplate()}
                    onChange={onOrganizationChange}
                />
            ) : (
                <DropdownNative
                    uniqueSelection
                    hideSearch
                    valueWhenUniqueSelection={selectedOrganization as any}
                    placeholder="Organization"
                    optionLabel="name"
                    optionValue="code"
                    indentificator={dropdownOrganizationId}
                    options={organizations}
                    buttonTitleStyle={windowWidth <= 1200 ? { color: '#969696' } : null}
                    overlayPanelStyles={{ minWidth: 'auto' }}
                    customStyles={[
                        localStyles.buttonStyle,
                        windowWidth > 1200 ? { backgroundColor: 'transparent' } : null,
                    ]}
                    containerStyles={props.style}
                    optionGroupTemplate={groupedItemTemplate}
                    valueTemplate={valueTemplate()}
                    onChange={onOrganizationChange}
                />
            )}
        </>
    )
}

const localStyles = StyleSheet.create({
    buttonStyle: {
        paddingHorizontal: 10,
        paddingVertical: 10,
        backgroundColor: 'rgba(59, 59 ,64, .5)',
        flexDirection: 'column',
    },
    buttonTemplate: {
        flexDirection: 'row',
        width: '100%',
        gap: 14,
        alignItems: 'center',
    },
    buttonTitleTemplate: {
        color: '#ffffff',
        fontWeight: '500',
        fontSize: 14,
    },
})

export default DropdownOrganization
