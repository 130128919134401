import { useState } from 'react'
import { View, StyleSheet, Text, StyleProp, ViewStyle } from 'react-native'
import { getStyleSheet } from '../../assets/theme/styles'
import ButtonNative from './ButtonNative'
import { faArrowUp, faArrowDown, faArrowDownUpAcrossLine } from '@fortawesome/free-solid-svg-icons'
import useDimensions from '../../hooks/useDimensions'

export const MomentaryView = ({
    showViewUp,
    children,
    customStyles,
}: {
    showViewUp: number
    children: React.ReactNode
    customStyles?: StyleProp<ViewStyle>
}): JSX.Element => {
    const { windowWidth } = useDimensions()
    if (windowWidth <= showViewUp) return <View style={customStyles}>{children}</View>
    return <>{children}</>
}

interface IDataTable {
    children: React.ReactNode
    headerColumns?: { fieldToSorteable: string; isSorteable: boolean; label: string; styles?: StyleProp<ViewStyle> }[]
    headerRowClassName?: string
    emptyMessage: string
    isShowEmptyMessage?: boolean
    customStyles?: StyleProp<ViewStyle>
    headerCustomStyles?: StyleProp<ViewStyle>
    itemsList: any[]
    setItemsList: any
}

const DataTable = ({
    children,
    headerColumns,
    emptyMessage,
    isShowEmptyMessage,
    customStyles,
    itemsList,
    setItemsList,
    headerCustomStyles,
}: IDataTable) => {
    const [activeHeaderColumn, setActiveHeaderColumn] = useState<{ current: string | null; upward: boolean }>({
        current: null,
        upward: false,
    })

    const handlerToSorteable = (fieldName: string, isAscendancy: boolean) => {
        const newItemsList = [...itemsList].sort((a, b) => {
            if (isAscendancy) {
                return String(a[fieldName]).localeCompare(String(b[fieldName]))
            }
            return String(b[fieldName]).localeCompare(String(a[fieldName]))
        })
        setItemsList(newItemsList)
    }

    const style = getStyleSheet()

    return (
        <View style={[localStyles.container, customStyles]}>
            <View style={[[localStyles.headerRow, headerCustomStyles]]}>
                {headerColumns?.map(column => (
                    <View style={[column.styles, { flexDirection: 'row', alignItems: 'center' }]}>
                        <Text style={localStyles.headerTitleRow}>{column.label}</Text>

                        {column.isSorteable ? (
                            <>
                                {column.label.length && column.label !== activeHeaderColumn.current ? (
                                    <ButtonNative
                                        showIcon
                                        icon={faArrowDownUpAcrossLine}
                                        iconSize={16}
                                        iconColor="#818a94"
                                        onPress={() => {
                                            setActiveHeaderColumn({ current: column.label, upward: true })
                                            handlerToSorteable(column.fieldToSorteable, true)
                                        }}
                                    />
                                ) : null}

                                {column.label === activeHeaderColumn.current && activeHeaderColumn.upward ? (
                                    <ButtonNative
                                        showIcon
                                        icon={faArrowUp}
                                        iconSize={16}
                                        iconColor="#818a94"
                                        onPress={() => {
                                            setActiveHeaderColumn({ current: column.label, upward: false })
                                            handlerToSorteable(column.fieldToSorteable, false)
                                        }}
                                    />
                                ) : null}

                                {column.label === activeHeaderColumn.current && !activeHeaderColumn.upward ? (
                                    <ButtonNative
                                        showIcon
                                        icon={faArrowDown}
                                        iconSize={16}
                                        iconColor="#818a94"
                                        onPress={() => setActiveHeaderColumn({ current: null, upward: false })}
                                    />
                                ) : null}
                            </>
                        ) : null}
                    </View>
                ))}
            </View>

            {children}

            {isShowEmptyMessage ? (
                <View style={[localStyles.emptyMessage, style.styleRow.container]}>
                    <Text style={style.styleRow.colorText}>{emptyMessage}</Text>
                </View>
            ) : null}
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 15,
        marginTop: 30,
        flexGrow: 1,
    },
    emptyMessage: {
        height: 50,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 6,
    },
    headerRow: {
        alignItems: 'center',
        flexDirection: 'row',
    },
    headerTitleRow: {
        fontSize: 10,
        color: '#818a94',
    },
})

export default DataTable
