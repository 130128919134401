import { ReactNode, createContext, useContext, useState } from 'react'

interface IOverlay {
    activeId: string | null
    isShowOverlay: boolean
    handleShowOverlay: (currentId: string) => void
    handleSetActiveId: (activeId: string | null) => void
    closeShowOverlay: () => void
    openOverlay: () => void
}

const DatePickerContext = createContext<IOverlay>(null as any)

const OverlayProvider = ({ children }: { children: ReactNode }) => {
    const [activeId, setActiveId] = useState<string | null>(null)
    const [isShowOverlay, setIsShowOverlay] = useState(false)

    const handleShowOverlay = (currentId: string) => {
        currentId !== activeId ? setIsShowOverlay(true) : toggleShowOverlay()
        setActiveId(currentId)
    }

    const closeShowOverlay = () => {
        setIsShowOverlay(false)
    }

    const openOverlay = () => {
        setIsShowOverlay(true)
    }

    const toggleShowOverlay = () => setIsShowOverlay(!isShowOverlay)

    const handleSetActiveId = (activeId: string | null) => setActiveId(activeId)

    return (
        <DatePickerContext.Provider
            value={{
                activeId,
                isShowOverlay,
                handleShowOverlay,
                openOverlay,
                closeShowOverlay,
                handleSetActiveId,
            }}
        >
            {children}
        </DatePickerContext.Provider>
    )
}

function useOverlayProvider() {
    const context = useContext(DatePickerContext)
    if (context === undefined) {
        throw new Error('useOverlayProvider must be used within a OverlayProvider')
    }
    return context
}

export { OverlayProvider, useOverlayProvider }
