import { useStripeProvider } from '../../context/StripeProvider'
import { Role } from '../../models'
import GroupedRecordMenu from './GroupedRecordMenu/GroupedRecordMenu'
import { View, Text } from 'react-native'
import { getStyleSheet } from '../../assets/theme/styles'
import useDimensions from '../../hooks/useDimensions'
import { Invoice } from '../../services/SubscriptionService'

interface IRowBilling {
    billing: Invoice
    userRole: Role
    onShowConfirmationDelete: (id: string) => void
}

const RowBilling = ({ billing, userRole }: IRowBilling) => {
    const { downloadInvoicePdf } = useStripeProvider()
    const isAdmin = userRole === Role.ADMIN
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()
    const newActions = [
        { label: 'Download PDF', icon: 'file-pdf-box', action: () => downloadInvoicePdf(billing.invoice_pdf) },
    ]

    return (
        <View
            style={{
                backgroundColor: styles.backgroundColorRow,
                padding: windowWidth <= 600 ? 5 : 15,
                borderRadius: 6,
                flexDirection: 'row',
                alignItems: 'center',
            }}
        >
            <View style={{ width: windowWidth > 600 ? '32%' : 'auto', flexGrow: 1 }}>
                <Text
                    style={{
                        color: styles.text,
                        fontSize: windowWidth <= 600 ? 12 : 16,
                        paddingLeft: 5,
                    }}
                >
                    {billing.createdDateFormat}
                </Text>
            </View>

            <View style={{ width: windowWidth > 600 ? '32%' : 'auto', flexGrow: 1 }}>
                <Text
                    style={{
                        color: styles.text,
                        fontSize: windowWidth <= 600 ? 12 : 16,
                        textAlign: 'center',
                    }}
                >
                    {billing.seats}
                </Text>
            </View>

            <View style={{ width: windowWidth > 600 ? '32%' : 'auto', flexGrow: 1 }}>
                <Text
                    style={{
                        color: styles.text,
                        fontSize: windowWidth <= 600 ? 12 : 16,
                        paddingRight: 40,
                        textAlign: 'right',
                    }}
                >
                    {billing.priceFormat}
                </Text>
            </View>

            {isAdmin ? (
                <View style={{ marginRight: windowWidth > 600 ? 20 : 0 }}>
                    <GroupedRecordMenu identificator={billing.id} items={newActions} />
                </View>
            ) : null}
        </View>
    )
}

export default RowBilling
