import React, { useState, useEffect, useContext, useCallback } from 'react'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { OrganizationService } from '../../../services/OrganizationService'
import { ViewStyle, StyleProp } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

export interface Props {
    onMemberChange: (value: any) => void
    key?: string
    selectedMember?: any
    containerStyle?: StyleProp<ViewStyle>
    buttomStyle?: StyleProp<ViewStyle>
    membersOrganization?: any
    otherClass?: string
    filtering?: boolean
    includeHovered?: boolean
}

const useMultiselectMembers = (props: Props) => {
    const { theme, userSub } = useContext<any>(ThemeContext)
    const [members, setMembers] = useState<any>([])
    const [selectedMember, setSelectedMember] = useState<any[]>([])

    const handleChange = (value: any[]) => {
        setSelectedMember(value)
        props.onMemberChange(value?.map(item => item.code))
    }

    const getMembers = () => {
        OrganizationService()
            .getDefaultOrganizationId(userSub)
            .then(organizationId => {
                OrganizationService()
                    .getUsersByOrganizationId(organizationId)
                    .then(listMember => {
                        let membersDropdown = listMember
                            ?.filter(item => item.status === 'ACTIVE')
                            ?.map(item => {
                                return {
                                    label: item.userName.trim().length ? item.userName : item.email,
                                    code: item.userID,
                                }
                            })

                        setMembers(membersDropdown)
                    })
            })
            .catch(error => {
                console.log(error)
            })
    }

    useFocusEffect(
        useCallback(() => {
            getMembers()
        }, [])
    )

    useEffect(() => {
        if (props.membersOrganization) {
            setMembers(props.membersOrganization)
        }

        if (props.selectedMember) setSelectedMember(props.selectedMember)
    }, [props.selectedMember, props.membersOrganization])

    return [{ theme, members, selectedMember, handleChange }]
}

export default useMultiselectMembers
