import React, { useEffect, useState, useContext } from 'react'
import { ScrollView, View, StyleSheet, Animated } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { getStyleSheet } from '../assets/theme/styles'
import { useNavigation } from '@react-navigation/native'
import { InvitationService } from '../services/InvitationService'
import { useUserProvider } from '../context/UserProvider'
import { useFocusEffect } from '@react-navigation/native'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { Role, StatusInvitation, StatusOrganizationUser } from '../models'
import { useOrganizationProvider } from '../context/OrganizationProvider'
import { InviteMembersModal } from '../components/common/InviteMembersModal'
import { UserService } from '../services/UserService'
import moment from 'moment'
import DataTable from '../components/common/DataTable'
import RowTeamInvitation from '../components/common/RowTeamInvitation'
import SkeletonTable from '../components/common/SkeletonTable'
import FooterDialog from '../components/common/FooterDialog'
import CustomModal from '../components/common/CustomModal'
import useDimensions from '../hooks/useDimensions'
import { UpgradeContext } from '../context/upgrade'
import useSearchBar from '../components/common/Searchbar/useSearchBar'
import Searchbar from '../components/common/Searchbar/Searchbar'

const headerColumns: any = [
    { label: 'EMAIL', fieldToSorteable: '', isSorteable: false, styles: { width: '50%' } },
    { label: 'INVITED ON', fieldToSorteable: '', isSorteable: false, styles: { width: '25%' } },
    { label: 'ROLE', fieldToSorteable: '', isSorteable: false, styles: { width: '10%' } },
    { label: 'STATUS', fieldToSorteable: 'status', isSorteable: true, styles: { width: '10%' } },
    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: '5%' } },
]

const TeamInvitation = () => {
    const current = 'TeamInvitation'
    const styles = getStyleSheet()
    const navigation = useNavigation()
    const [loading, setLoading] = useState(true)
    const [invitations, setInvitations] = useState<any[]>([])
    const [allRoles, setAllRoles] = useState<any>([])
    const [blockInvited, setBlockInvited] = useState(true)
    const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
    const [currentDelete, setCurrentDelete] = useState<any>(null)
    const { userActiveOrganization } = useUserProvider()
    const { userRole, subscribedSeats } = useContext<any>(ThemeContext)
    const { maxGroupsNumber } = useContext(UpgradeContext)
    const { windowWidth } = useDimensions()
    const { getOrganizationMembers, getInvitationsByOrganization, membersTotal, setMembersTotal } =
        useOrganizationProvider()
    const { isCollapsed, toggleSetIsCollapsed, fadeAnim } = useSearchBar()

    const onRefreshInvitations = () => {
        if (userActiveOrganization) {
            getInvitations(userActiveOrganization.organizationID)
        }
    }

    const getUserRoles = () => {
        const allRoles = UserService().getDropDownRoles()
        setAllRoles(allRoles)
    }

    const onUpdateRow = async (id: string) => {
        const row = invitations.find((item: any) => item.id === id) as any

        if (!row) {
            return
        }

        const updateRecord = {
            id: row.id,
            invitedOn: moment().utc(true).toISOString(),
            updatedAt: moment().utc(true).toISOString(),
            _version: row._version,
        }
        try {
            await InvitationService().updateInvitationById(updateRecord)
        } catch (error) {
            if (userActiveOrganization) {
                getInvitations(userActiveOrganization.organizationID)
            }
        }
    }

    const onDeleteRow = async () => {
        if (!currentDelete) {
            return
        }

        const row = invitations.find((item: any) => item.id === currentDelete) as any

        if (!row) {
            return
        }

        setShowConfirmationDelete(false)
        await InvitationService().deleteInvitationById(row.id, row._version)

        if (userActiveOrganization) {
            setTimeout(() => {
                getInvitations(userActiveOrganization.organizationID)
            }, 500)
        }
    }

    const operationNumberUser = (listMembers: any, listInvitations: any) => {
        let membersNumber = listMembers.filter(item => item.status === StatusOrganizationUser.ACTIVE).length
        let invitationNumber = listInvitations.length
        setMembersTotal(membersNumber + invitationNumber)
    }

    const onHideConfirmationDelete = () => {
        setShowConfirmationDelete(false)
    }

    const onShowConfirmationDelete = (id: any) => {
        setCurrentDelete(id)
        setShowConfirmationDelete(true)
    }

    const getInvitations = (organizationId: string) => {
        InvitationService()
            .getAllInvitationsByOrganization(organizationId)
            .then(response => {
                setInvitations(response)
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    useFocusEffect(
        React.useCallback(() => {
            if (userActiveOrganization) {
                setLoading(true)
                getInvitations(userActiveOrganization.organizationID)
                getUserRoles()
            }
        }, [userActiveOrganization])
    )

    useEffect(() => {
        if (maxGroupsNumber && membersTotal) setBlockInvited(maxGroupsNumber <= membersTotal)
    }, [membersTotal, maxGroupsNumber, userRole])

    useEffect(() => {
        setLoading(true)
        if (userActiveOrganization) {
            getOrganizationMembers(userActiveOrganization.organizationID).then(
                listMembers => {
                    getInvitationsByOrganization(userActiveOrganization.organizationID, StatusInvitation.PENDING).then(
                        listInvitations => {
                            operationNumberUser(listMembers, listInvitations)
                        }
                    )
                },
                err => {
                    console.log(err)
                    setLoading(false)
                }
            )
        }
    }, [userActiveOrganization])

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current, initial: 0 }} />
                    <View style={[styles.baseStyleContent.container, localStyles.workArea]}>
                        <Searchbar
                            items={invitations}
                            isCollapsed={isCollapsed}
                            setIsCollapsed={toggleSetIsCollapsed}
                            filterField="email"
                            onFilteredItems={setInvitations}
                        />

                        {userRole === Role.ADMIN ? (
                            <Animated.View
                                style={{
                                    transform: [{ translateY: fadeAnim ?? 0 }],
                                    display: isCollapsed ? 'flex' : 'none',
                                }}
                            >
                                <InviteMembersModal onRefreshInvitations={onRefreshInvitations} />
                            </Animated.View>
                        ) : null}

                        {loading ? (
                            <SkeletonTable rowsQuantity={6} />
                        ) : (
                            <DataTable
                                headerColumns={headerColumns}
                                isShowEmptyMessage={!invitations.length}
                                customStyles={{ marginTop: 35 }}
                                headerCustomStyles={{
                                    paddingVertical: 10,
                                    paddingHorizontal: 20,
                                    display: windowWidth > 1000 ? 'flex' : 'none',
                                }}
                                setItemsList={setInvitations}
                                itemsList={invitations}
                                emptyMessage="No invitations."
                            >
                                {invitations.map(invitation => (
                                    <RowTeamInvitation
                                        onShowConfirmationDelete={onShowConfirmationDelete}
                                        key={invitation.id}
                                        onUpdateRow={onUpdateRow}
                                        teamInvitation={invitation}
                                    />
                                ))}
                            </DataTable>
                        )}
                    </View>
                </ScrollView>
            </View>

            <CustomModal
                header="Delete"
                message="Are you sure you want to delete entry?"
                isVisible={showConfirmationDelete}
                footerModal={<FooterDialog handleOnNo={onHideConfirmationDelete} handleOnYes={onDeleteRow} />}
                onHide={setShowConfirmationDelete}
            />
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
        height: '100%',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
})

export default TeamInvitation
