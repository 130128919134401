import moment from 'moment'
import { useEffect, useState } from 'react'
import { DATE_RANGE } from '../../../screens/Reports'

const daysOfWeek = [
    { text: 'Su', value: 1 },
    { text: 'Mo', value: 2 },
    { text: 'Tu', value: 3 },
    { text: 'We', value: 4 },
    { text: 'Th', value: 5 },
    { text: 'Fr', value: 6 },
    { text: 'Sa', value: 7 },
]

export const monthsOfYear = [
    { text: 'Enuary', value: 0 },
    { text: 'February', value: 1 },
    { text: 'March', value: 2 },
    { text: 'April', value: 3 },
    { text: 'May', value: 4 },
    { text: 'June', value: 5 },
    { text: 'July', value: 6 },
    { text: 'August', value: 7 },
    { text: 'September', value: 8 },
    { text: 'October', value: 9 },
    { text: 'November', value: 10 },
    { text: 'December', value: 11 },
]

enum ViewTypeEnum {
    days = 1,
    months,
    years,
}

type IUseDatePicker = {
    value?: Date
    multipleValues?: Date[]
    isSelectedByRange?: boolean
    closeDatePicker: () => void
    onChange?: (date: Date) => void
    onChangeMultiple?: (date: Date[]) => void
    setDateRange?: React.Dispatch<React.SetStateAction<DATE_RANGE>>
}

const useDatePicker = ({
    value,
    multipleValues,
    isSelectedByRange,
    closeDatePicker,
    onChange = () => {},
    onChangeMultiple = () => {},
    setDateRange = () => {},
}: IUseDatePicker) => {
    const [currentView, setCurrentView] = useState<ViewTypeEnum>(ViewTypeEnum.days)
    const [date, setDate] = useState(new Date())
    const [weeks, setWeeks] = useState<Array<Array<Date>>>([])
    const [months, setMonths] = useState<Array<Array<Date>>>([])
    const [years, setYears] = useState<Array<Array<Date>>>([])
    const [headerName, setHeaderName] = useState<string>('')
    const [selectedDate, setSelectedDate] = useState<Date | null>(null)
    const [selectedDatesRange, setSelectedDatesRange] = useState<Date[] | []>([])
    const [stateMultipleValues, setStateMultipleValues] = useState<Date[]>([])
    const [thirtyDaysBeforeAndAfterDate, setThirtyDaysBeforeAndAfterDate] = useState<Date[]>([])

    useEffect(() => {
        if (multipleValues?.length) {
            handleSetDateRange(multipleValues)
            setStateMultipleValues(multipleValues)
        }
        if (value) setSelectedDate(value) // When is not mode date range
    }, [multipleValues, value])

    const setTime = () => {
        const newWeeks = []
        const newYears = []
        const newMonths = []

        switch (currentView) {
            case ViewTypeEnum.days:
                for (let i = 1; i < 6; i++) {
                    newWeeks.push(getWeeklyDates(i) as never)
                }
                setWeeks(newWeeks)
                break
            case ViewTypeEnum.months:
                for (let i = 1; i < 5; i++) {
                    newMonths.push(getMonthlyDates(i) as never)
                }
                setMonths(newMonths)
                break
            default:
                for (let i = 1; i < 5; i++) {
                    newYears.push(getYearlyDates(i) as never)
                }
                setYears(newYears)
                break
        }
    }

    const getWeeklyDates = (weekNumber: number): Array<Date> => {
        let weekDates = new Array()
        const newDate = new Date(date.setDate(1))
        const index = weekNumber * 7
        newDate.setDate(newDate.getDate() - newDate.getDay())
        for (let i = 0; i < index; i++) {
            weekDates.push(new Date(newDate))
            newDate.setDate(newDate.getDate() + 1)
        }
        weekDates = weekDates.slice(index - 7, weekDates.length)
        return weekDates
    }

    const getMonthlyDates = (yearNumber: number): Array<Date> => {
        let monthsDates = new Array()
        const index = yearNumber * 3
        const newDate = new Date(date.setDate(1))
        for (let i = 0; i < index; i++) {
            monthsDates.push(new Date(new Date(newDate).getFullYear(), i))
        }
        monthsDates = monthsDates.slice(index - 3, monthsDates.length)
        return monthsDates
    }

    const getYearlyDates = (yearNumber: number): Array<Date> => {
        let yearsDates = new Array()
        const index = yearNumber * 3
        const newDate = new Date(date.setDate(1))
        for (let i = 0; i < index; i++) {
            yearsDates.push(new Date(new Date(newDate).getFullYear() + i, 1))
        }
        yearsDates = yearsDates.slice(index - 3, yearsDates.length)
        return yearsDates
    }

    const getLabel = (date: Date): string => {
        switch (currentView) {
            case ViewTypeEnum.days:
                return date.getDate().toString()
            case ViewTypeEnum.months:
                return monthsOfYear[date.getMonth()].text
            default:
                return date.getFullYear().toString()
        }
    }

    const isToday = (date: Date): boolean => {
        switch (currentView) {
            case ViewTypeEnum.days:
                return (
                    new Date().getDate() === date.getDate() &&
                    new Date().getMonth() === date.getMonth() &&
                    new Date().getFullYear() === date.getFullYear()
                )
            case ViewTypeEnum.months:
                return new Date().getMonth() === date.getMonth() && new Date().getFullYear() === date.getFullYear()
            default:
                return new Date().getFullYear() === date.getFullYear()
        }
    }

    const isSelected = (date: Date): boolean => {
        if (!selectedDate) return false

        switch (currentView) {
            case ViewTypeEnum.days:
                return (
                    selectedDate.getDate() === date.getDate() &&
                    selectedDate.getMonth() === date.getMonth() &&
                    selectedDate.getFullYear() === date.getFullYear()
                )

            case ViewTypeEnum.months:
                return selectedDate.getMonth() === date.getMonth() && selectedDate.getFullYear() === date.getFullYear()
            default:
                return selectedDate.getFullYear() === date.getFullYear()
        }
    }

    const onSelectDate = (date: Date): void => {
        switch (currentView) {
            case ViewTypeEnum.days:
                setSelectedDate(date)
                setDate(new Date(date))
                break
            case ViewTypeEnum.months:
                setSelectedDate(date)
                setDate(new Date(date))
                setCurrentView(ViewTypeEnum.days)
                break
            default:
                setSelectedDate(date)
                setDate(new Date(date))
                setCurrentView(ViewTypeEnum.months)
                break
        }
    }

    const handleSetHeaderName = (): void => {
        if (!(date && date.getDate())) {
            setHeaderName('')
        }
        switch (currentView) {
            case ViewTypeEnum.days:
                setHeaderName(`${monthsOfYear[date.getMonth()].text} ${date.getFullYear()}`)
                break
            case ViewTypeEnum.months:
                setHeaderName(date.getFullYear().toString())
                break
            default:
                setHeaderName(
                    `${date.getFullYear().toString()} - ${years?.length ? years[3][2].getFullYear().toString() : 2035}`
                )
                break
        }
    }

    const nextNavigation = (): void => {
        switch (currentView) {
            case ViewTypeEnum.days:
                setDate(new Date(date.setMonth(date.getMonth() + 1)))
                break
            case ViewTypeEnum.months:
                setDate(new Date(date.setFullYear(date.getFullYear() + 1)))
                break
            default:
                setDate(new Date(date.setFullYear(date.getFullYear() + 12)))
                break
        }
    }

    const previusNavigation = (): void => {
        switch (currentView) {
            case ViewTypeEnum.days:
                setDate(new Date(date.setMonth(date.getMonth() - 1)))
                break
            case ViewTypeEnum.months:
                setDate(new Date(date.setFullYear(date.getFullYear() - 1)))
                break
            default:
                setDate(new Date(date.setFullYear(date.getFullYear() - 12)))
                break
        }
    }

    const onClickHeaderName = (): void => {
        switch (currentView) {
            case ViewTypeEnum.days:
                setCurrentView(ViewTypeEnum.months)
                break
            case ViewTypeEnum.months:
                setCurrentView(ViewTypeEnum.years)
                break
            default:
                setCurrentView(ViewTypeEnum.days)
                break
        }
    }

    const isFutureTask = (dateItem: Date): boolean => {
        return moment().isAfter(moment(dateItem))
    }

    const isNotCurrentMonth = (dateItem: Date): boolean => {
        return dateItem.getMonth() !== date.getMonth()
    }

    const handleOnSelectedDate = (date: Date) => {
        onSelectDate(date)
        onChange(date)
        closeDatePicker()
    }

    const handleMultipleDateSelected = (date: Date) => {
        if (stateMultipleValues.length) {
            // Si se selecciona una fecha limpio el array que guarda las fechas que vienen por props para mostrar el rango o la fecha seleccionada
            setStateMultipleValues([])
            setDateRange(null)
        }

        if (!selectedDatesRange.length || selectedDatesRange.length > 1) {
            // Si está vacio o es mayor que uno, solo permito que se guarde una fecha
            setSelectedDatesRange([date])

            const newDatesRange = [date, new Date(date)].sort((a, b) => {
                if (a < b) return -1
                return 1
            })
            onChangeMultiple(newDatesRange)
            handleSetThirtyDaysBeforeAndAfter(date)
        }

        if (selectedDatesRange.length === 1) {
            handleSetDateRange([...selectedDatesRange, date])
        }
    }

    const handleSetDateRange = (dateRange: Date[]) => {
        const sortedDates = dateRange.sort((a, b) => {
            if (a < b) return -1
            return 1
        })
        const until = sortedDates.at(0) as Date
        const from = sortedDates.at(1) as Date

        const diffDays = Math.abs(moment(from).diff(moment(until), 'days'))
        const newDate = new Date(until)
        const dates: Date[] = []

        for (let i = 0; i <= diffDays; i++) {
            dates.push(new Date(newDate))
            newDate.setDate(newDate.getDate() + 1)
        }

        onChangeMultiple(sortedDates)
        setSelectedDatesRange(dates)
    }

    const handleSetThirtyDaysBeforeAndAfter = (date: Date) => {
        const dates: Date[] = []
        const dateAMonthBefore = new Date(moment(date).subtract(1, 'month').valueOf()) // Un mes antes de la fecha seleccionada
        const dateAMonthAfter = new Date(moment(date).add(1, 'month').valueOf()) // un mes despues de la fecha seleccionada
        const oneDayAfterSelectedDate = new Date(new Date(date).setDate(date.getDate() + 1))

        const diffDaysAMonthBefore = Math.abs(moment(date).diff(moment(dateAMonthBefore), 'day')) // diferencias de dias entre la fecha seleccionada y un mes antes
        const diffDaysAMonthAfter = Math.abs(moment(date).add(1, 'day').diff(moment(dateAMonthAfter), 'day')) // diferencias de dias entre la fecha seleccionada y un mes despues

        for (let i = 0; i <= diffDaysAMonthBefore; i++) {
            // Guardando las fechas desde un mes antes hasta la seleccionada
            dates.push(new Date(dateAMonthBefore))
            dateAMonthBefore.setDate(dateAMonthBefore.getDate() + 1)
        }

        for (let i = 0; i <= diffDaysAMonthAfter; i++) {
            // Guardando las fechas desde la fecha seleccionada hasta un mes despues
            dates.push(new Date(oneDayAfterSelectedDate))
            oneDayAfterSelectedDate.setDate(oneDayAfterSelectedDate.getDate() + 1)
        }

        setThirtyDaysBeforeAndAfterDate(dates)
    }

    const isDateInRange = (date: Date) => {
        return selectedDatesRange.find(
            item =>
                item.getDate() === date.getDate() &&
                item.getMonth() === date.getMonth() &&
                item.getFullYear() === date.getFullYear()
        )
    }

    const isRangeOfAMonth = (date: Date) => {
        if (!thirtyDaysBeforeAndAfterDate.length) return true
        return thirtyDaysBeforeAndAfterDate.find(
            item =>
                item.getDate() === date.getDate() &&
                item.getMonth() === date.getMonth() &&
                item.getFullYear() === date.getFullYear()
        )
    }

    const getLabelButton = (buttonLabel: string): string => {
        const initDate = selectedDatesRange?.at(0)
        const finishDate = selectedDatesRange.length > 1 ? selectedDatesRange?.at(-1) : null

        if (stateMultipleValues.length) return buttonLabel

        if (initDate && finishDate) {
            return `${monthsOfYear[Number(initDate?.getMonth())].text} ${initDate?.getDate()} - ${
                monthsOfYear[Number(finishDate?.getMonth())].text
            } ${finishDate?.getDate()}`
        }

        if (initDate) {
            return `${monthsOfYear[Number(initDate?.getMonth())].text} ${initDate?.getDate()}`
        }

        return ''
    }

    return {
        setTime,
        getLabel,
        isToday,
        isSelected,
        onSelectDate,
        handleSetHeaderName,
        nextNavigation,
        previusNavigation,
        onClickHeaderName,
        isFutureTask,
        handleOnSelectedDate: isSelectedByRange ? handleMultipleDateSelected : handleOnSelectedDate,
        isDateInRange,
        isRangeOfAMonth,
        getLabelButton,
        isNotCurrentMonth,
        daysOfWeek,
        currentView,
        headerName,
        date,
        selectedDate,
        months,
        weeks,
        years,
    }
}

export default useDatePicker
