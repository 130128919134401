import React from 'react'
import { View, Image } from 'react-native'

const defaultImageUri = require('../../assets/images/profiledefault.png')

export const ImagePreview = ({ imageUri, imageStyle }) => {
    return (
        <View style={imageStyle}>
            <Image
                resizeMode="cover"
                style={{ borderRadius: imageStyle?.borderRadius || 0, width: '100%', height: '100%' }}
                source={{ uri: imageUri }}
            />
        </View>
    )
}
