import moment from 'moment'
import { getStyleSheet } from '../../../assets/theme/styles'
import { View, Text } from 'react-native'
import ItemSkeleton from '../ItemSkeleton'
import { secondsToString } from '../../../helpers/utils'

interface ITaskListHeader {
    loadingTasks: boolean
    taskDate: string
    totalTime: string
}

const TaskListHeader = ({ loadingTasks, taskDate, totalTime }: ITaskListHeader) => {
    const styles = getStyleSheet()

    const getDatePeriod = (date: string): string => {
        const currentDate = moment().format('MMM DD, YYYY')
        const yesterday = moment().subtract(1, 'days').format('MMM DD, YYYY')
        if (currentDate === date) return 'Today'
        if (yesterday === date) return 'Yesterday'
        return date
    }

    return (
        <View style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            {loadingTasks ? (
                <ItemSkeleton height={25} width={100} />
            ) : (
                <View>
                    <Text style={{ fontWeight: '500', color: styles.text }}>
                        {loadingTasks ? '' : getDatePeriod(taskDate)}
                    </Text>
                </View>
            )}

            <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
                {loadingTasks ? (
                    <ItemSkeleton height={25} width={100} />
                ) : (
                    <Text style={{ color: styles.text }}>{loadingTasks ? '' : 'Total time:'}</Text>
                )}

                {loadingTasks ? (
                    <ItemSkeleton height={25} width={100} />
                ) : (
                    <Text style={{ fontSize: 16, fontWeight: '500', color: styles.text }}>
                        {loadingTasks ? '' : secondsToString(+totalTime)}
                    </Text>
                )}
            </View>
        </View>
    )
}

export default TaskListHeader
