import React, { useContext } from 'react'
import { Platform } from 'react-native'
import { ThemeContext } from '../../assets/theme/ThemeProvider'
import { UserService } from '../../services/UserService'
import { useUserProvider } from '../../context/UserProvider'
import AsyncStorage from '@react-native-async-storage/async-storage'
import DropdownNative from './MultiSelectNative/MultiSelect'
import uuid from 'react-native-uuid'

const id = uuid.v4() as string

export const DropdownThemeMode = () => {
    const { theme, setTheme, userSub } = useContext<any>(ThemeContext)
    const { updateUserById } = useUserProvider()

    const setMode = async (mode: 'dark' | 'light') => {
        if (Platform.OS === 'web') document.documentElement.className = mode

        setTheme(mode)
        if (!userSub) return
        const user = await UserService().getUserById(userSub)

        if (!user) return
        const paramsUser: any = {
            id: user?.id,
            themeMode: mode,
            _version: user?._version,
        }

        updateUserById(paramsUser)
            .then(async () => {
                await AsyncStorage.setItem('TimerzTheme', mode)
            })
            .catch(error => {
                console.log(error)
                return
            })
    }

    return (
        <DropdownNative
            indentificator={id}
            placeholder=""
            uniqueSelection
            valueWhenUniqueSelection={theme}
            onChange={setMode}
            hideSearch
            customStyles={{ paddingHorizontal: 16, paddingVertical: 16, paddingLeft: 26 }}
            optionLabel="label"
            optionValue="name"
            options={[
                { name: 'dark', label: 'Darked' },
                { name: 'light', label: 'Lighted' },
            ]}
        />
    )
}
