import { generateClient } from 'aws-amplify/api'
import { useState, useContext, createContext, useEffect } from 'react'
import { StatusInvitation } from '../API'
import { updateOrganizationUser } from '../graphql/mutations'
import { listInvitations, listOrganizationUsers } from '../graphql/queries'
import { IOrganizationProvider } from '../interfaces/organization-provider.interface'
import { UserService } from '../services/UserService'

const OrganizationContext = createContext<IOrganizationProvider>(null as any)

function OrganizationProvider({ children }) {
    const [organizationMembers, setOrganizationMembers] = useState<any>()
    const [membersTotal, setMembersTotal] = useState<number | null>(null)

    const getOrganizationMembers = async (organizationId: string) => {
        const response: any = await generateClient().graphql({
            query: listOrganizationUsers,
            variables: { filter: { organizationID: { eq: organizationId } } },
        })

        const list = await Promise.all(
            response?.data?.listOrganizationUsers?.items.map(async item => {
                const userObject = await UserService().getUserById(item.userID)

                const fullName = userObject?.givenName + ' ' + userObject?.familyName

                return {
                    ...item,
                    userName: userObject?.name ? userObject?.name : fullName,
                    userImage: userObject ? userObject?.image : '',
                    userGroups: userObject?.Groups?.items
                        ?.filter(e => !e._deleted)
                        .map(t => {
                            if (!t.group._deleted && t.group.organizationID === organizationId) {
                                return { code: t.group.id, name: t.group.name }
                            }
                            return
                        }),
                    Groups: userObject?.Groups?.items?.filter(e => !e._deleted),
                }
            })
        )

        const filterUndefined = list.map(e => {
            return {
                ...e,
                userGroups: e.userGroups.filter(x => x !== undefined),
            }
        })

        setOrganizationMembers(filterUndefined)
        return filterUndefined
    }

    const getInvitationsByOrganization = async (organizationId: string, status: StatusInvitation) => {
        const response: any = await generateClient().graphql({
            query: listInvitations,
            variables: { filter: { organizationID: { eq: organizationId }, status: { eq: status } } },
        })

        return response?.data?.listInvitations?.items
    }

    const updateMemberInfo = async (newData: any) => {
        const response: any = await generateClient().graphql({
            query: updateOrganizationUser,
            variables: { input: newData },
        })
        return response
    }

    const updateLocalMember = async (member: any) => {
        const members = organizationMembers.map(memberData => {
            if (memberData.userID === member.userID) {
                return member
            } else {
                return memberData
            }
        })

        setOrganizationMembers([...members])
    }

    return (
        <OrganizationContext.Provider
            value={{
                organizationMembers,
                membersTotal,
                setMembersTotal,
                getOrganizationMembers,
                getInvitationsByOrganization,
                updateMemberInfo,
                updateLocalMember,
            }}
        >
            {children}
        </OrganizationContext.Provider>
    )
}

function useOrganizationProvider() {
    const context = useContext(OrganizationContext)
    if (context === undefined) {
        throw new Error('useOrganizationProvider must be used within a UserProvider')
    }
    return context
}

export { OrganizationProvider, useOrganizationProvider }
