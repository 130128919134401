import React, { useState, useContext, useCallback } from 'react'
import { generateClient } from 'aws-amplify/api'
import { View, StyleSheet, Image, Text } from 'react-native'
import { getStyleSheet } from '../assets/theme/styles'
import { InvitationService } from '../services/InvitationService'
import { StatusInvitation, StatusOrganizationUser } from '../models/index'
import { UserService } from '../services/UserService'
import { OrganizationService } from '../services/OrganizationService'
import { useFocusEffect, useNavigation } from '@react-navigation/native'
import { darkColors, lightColors } from '../assets/theme/colorThemes'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { useUserProvider } from '../context/UserProvider'
import { listOrganizationUsers } from '../graphql/queries'
import ButtonNative from '../components/common/ButtonNative'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { LOCAL_STORAGE } from '../navigation/Navigation'

const iconTimers = require('../assets/images/timerz_orange_logo.png')

export const handleUpdateInvitation = async invitation => {
    const updateObject = {
        id: invitation.id,
        status: StatusInvitation.ACCEPTED,
        _version: invitation._version,
    }

    return await InvitationService()
        .updateInvitationById(updateObject)
        .then(() => {
            return true
        })
        .catch(error => {
            return false
        })
}

export const updateOrganizationUserDefault = async email => {
    return await OrganizationService()
        .getOrganizationUserDefaultByEmail(email)
        .then(async defaultOrganization => {
            const updateOrganizationDefault = {
                id: defaultOrganization.id,
                is_default: false,
                _version: defaultOrganization._version,
            }

            return await OrganizationService()
                .updateOrganizationUserById(updateOrganizationDefault)
                .then(response => {
                    if (response || response !== undefined) {
                        return true
                    }
                    return false
                })
                .catch(() => {
                    return false
                })
        })
        .catch(() => {
            return false
        })
}

export const createOrganizationUser = async (invitation: any) => {
    return await UserService()
        .getUserByEmail(invitation.email)
        .then(async response => {
            const newEntry = {
                organizationID: invitation.organizationID,
                email: invitation.email,
                usersub: response.id,
                role: invitation.role,
                is_default: true,
                status: StatusOrganizationUser.ACTIVE,
                userID: response.id,
            }

            return await OrganizationService()
                .saveOrganizationUser(newEntry)
                .then(() => {
                    return { result: true, userName: response.givenName || 'You' }
                })
        })
        .catch(() => {
            return { result: false, userName: '' }
        })
}

const ConfirmInvitation = ({ route }) => {
    const styles = getStyleSheet()
    const navigation = useNavigation()
    const params = route.params || {}
    const { confirmId } = params
    const { theme, userSub, getUserRole } = useContext<any>(ThemeContext)
    const [confirm, setConfirm] = useState(false)
    const { localUserData, setUserActiveOrganization, setUpdateNameOrganization, setUpdatePlanIdOrganization } =
        useUserProvider()
    const [workspaceName, setWorkspaceName] = useState('')
    const [userName, setUserName] = useState('You')

    let selectedColorText: string = darkColors.reverseText
    if (theme === 'dark') {
        selectedColorText = lightColors.reverseText
    }

    const getParamsPath = async () => {
        const didUserConfirmInvitation = await AsyncStorage.getItem(LOCAL_STORAGE.DID_USER_CONFIRM_INVITATION)

        if ((!confirmId || !userSub) && !didUserConfirmInvitation) return

        InvitationService()
            .getInvitationById(confirmId)
            .then(async invitation => {
                if (!invitation) return setConfirm(false)
                await AsyncStorage.removeItem(LOCAL_STORAGE.DID_USER_CONFIRM_INVITATION)

                if (invitation.status === StatusInvitation.PENDING) {
                    handleUpdateInvitation(invitation).then(responseInvitation => {
                        if (responseInvitation) {
                            updateOrganizationUserDefault(invitation.email).then(responseUpdate => {
                                if (responseUpdate) {
                                    createOrganizationUser(invitation).then(responseOrganization => {
                                        setWorkspaceName(invitation.organization?.name)
                                        setUserName(responseOrganization?.userName + ', you')
                                        setConfirm(responseOrganization.result)
                                    })
                                }
                            })
                        }
                    })
                } else {
                    setConfirm(true)
                }
            })
            .catch(error => {
                setConfirm(false)
            })
    }

    const actionLogIn = () => {
        getUserRole()

        if (localUserData) {
            ;(
                generateClient().graphql({
                    query: listOrganizationUsers,
                    variables: { filter: { usersub: { eq: localUserData.sub }, is_default: { eq: true } } },
                }) as Promise<any>
            ).then(resp => {
                setUserActiveOrganization(resp?.data?.listOrganizationUsers?.items[0])
                setUpdateNameOrganization(resp?.data?.listOrganizationUsers?.items[0]?.organization?.name)
                setUpdatePlanIdOrganization(resp?.data?.listOrganizationUsers?.items[0]?.organization?.planID)

                navigation.navigate('Timer' as never)
            })
        }
    }

    useFocusEffect(
        useCallback(() => {
            getParamsPath()
        }, [])
    )

    useFocusEffect(
        useCallback(() => {
            if (!userSub && confirmId) {
                AsyncStorage.setItem(LOCAL_STORAGE.USER_INVITATION, confirmId)
            }
        }, [userSub, confirmId])
    )

    return (
        <View style={localStyles.container}>
            <View style={localStyles.subcontainer}>
                <View style={[styles.styleBackground.container, { height: '100%' }]}>
                    <View style={[styles.baseStyleContent.container, localStyles.workArea]}>
                        <View style={[localStyles.areaMessage]}>
                            {confirm ? (
                                <>
                                    <Image source={iconTimers} resizeMode="contain" style={localStyles.imageLogo} />

                                    <Text
                                        style={{
                                            fontSize: 26,
                                            fontWeight: '600',
                                            marginVertical: 14,
                                            color: styles.text,
                                        }}
                                    >
                                        Join {workspaceName}
                                    </Text>

                                    <View
                                        style={{
                                            backgroundColor: selectedColorText,
                                            height: 2,
                                            width: '10%',
                                            marginBottom: 10,
                                        }}
                                    />

                                    <Text style={{ textAlign: 'center', color: styles.text, fontSize: 16 }}>
                                        {userName} have successfully verified an email address.
                                    </Text>

                                    <Text style={{ color: styles.text, fontSize: 16 }}>
                                        You now log in to another organization.
                                    </Text>

                                    <View style={{ marginVertical: 10 }} />

                                    {!userSub ? (
                                        <ButtonNative
                                            title="Log in"
                                            primaryTheme
                                            onPress={() => navigation.navigate('Login' as never)}
                                        />
                                    ) : (
                                        <ButtonNative title="Start" primaryTheme onPress={actionLogIn} />
                                    )}
                                </>
                            ) : (
                                <>
                                    <Image source={iconTimers} resizeMode="contain" style={localStyles.imageLogo} />

                                    <Text style={{ color: styles.text, fontSize: 20, marginVertical: 20 }}>
                                        Please Login to accept your invitation!
                                    </Text>

                                    <ButtonNative
                                        title="Log in"
                                        primaryTheme
                                        onPress={() => navigation.navigate('Login' as never)}
                                    />
                                </>
                            )}
                        </View>
                    </View>
                </View>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
    areaMessage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    imageLogo: {
        height: 250,
        width: 300,
    },
})

export default ConfirmInvitation

// Hacer que cuando el usuario se loge la invitacion se acepte automaticamente y el workspace que se muestre a primeras sea el que se acepto, el default
// Al cambiar de workspace, este deberia ser el que siempre aparezca seleccionado
