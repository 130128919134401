export const useBrowserNotifications = () => {
    const permissionBrowserNotification = () => {
        // if (!("Notification" in window)) {
        //     alert("This browser does not support system notifications!");
        // } else if(Notification.permission === "denied" || Notification.permission === "default") {
        //     Notification.requestPermission();
        // }
    }

    const showBrowserNotification = async notify => {
        if (!('Notification' in window)) {
            alert('This browser does not support system notifications!')
        } else if (Notification.permission === 'granted') {
            await showNotification(notify)
        } else if (Notification.permission !== 'denied') {
            await Notification.requestPermission(async permission => {
                if (permission === 'granted') {
                    await showNotification(notify)
                }
            })
        }
    }

    const showNotification = notify => {
        const { title, message, url } = notify

        let urlTimer = url + '/timer'

        const notification = new Notification(title, {
            icon: url + '/favicon-32.png',
            body: `${message}`,
        })
        notification.onclick = () => window.open(urlTimer)
    }

    return {
        showBrowserNotification,
        permissionBrowserNotification,
    }
}
