import React, { useEffect, useState, useContext } from 'react'
import { View, Text, TouchableHighlight, StyleSheet, Pressable, ScrollView } from 'react-native'
import { getStyleSheet } from '../../assets/theme/styles'
import { useNavigation } from '@react-navigation/native'
import { ImagePreview } from '../image/ImagePreview'
import { Hoverable } from 'react-native-web-hover'
import { LoadingSpinner } from './LoadingSpinner'
import { signOut, fetchAuthSession } from 'aws-amplify/auth'
import { ThemeContext } from '../../assets/theme/ThemeProvider'
import { Role } from '../../models'
import { useSidemenuProvider } from '../../context/SidemenuProvider'
import useDimensions from '../../hooks/useDimensions'
import ButtonNative from './ButtonNative'
import { faCaretDown, faGear } from '@fortawesome/free-solid-svg-icons'
import { useOverlayProvider } from '../../context/OverlayProvider'
import uuid from 'react-native-uuid'
import DropdownOrganization from './DropdownOrganization/DropdownOrganization'
import DropdownNative from './MultiSelectNative/MultiSelect'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { outlineStyle } from '../../helpers/utils'
import { useUserProvider } from '../../context/UserProvider'

type ITopProfile = {
    refresh?: number
    isShowSmallAside?: boolean
}

const TOP_BAR_ID = uuid.v4() as string

export const TopProfile: React.FC<ITopProfile> = ({ refresh = 0, isShowSmallAside }) => {
    const styles = getStyleSheet()
    const navigation = useNavigation()
    const { userRole, userSub } = useContext(ThemeContext)
    const { setShowSidebar } = useSidemenuProvider()
    const [isLoading, setIsLoading] = useState(false)
    const { windowWidth } = useDimensions()
    const defaultImageUri = require('../../assets/images/profiledefault.png')
    const { handleShowOverlay, closeShowOverlay } = useOverlayProvider()
    const { localUserData, userCurrentImage, loadPhoto } = useUserProvider()

    const onPressIcon = () => {
        handleShowOverlay(TOP_BAR_ID)
    }

    const logOut = async () => {
        try {
            const { tokens } = await fetchAuthSession()
            const providerName = tokens?.idToken?.payload?.identities
                ? tokens?.idToken?.payload?.identities[0]?.providerName
                : ''

            if (!providerName) {
                await signOut()
                return window.location.reload()
            }

            await signOut()
        } catch (error) {
            console.log(error)
        }
    }

    const onPressOption = (option: string) => {
        closeShowOverlay()
        if (!option) return
        if (option === 'LogOut') {
            logOut()
        } else {
            navigation.navigate(option as never)
            setShowSidebar(false)
        }
    }

    useEffect(() => {
        if (userSub) loadPhoto()
    }, [userSub])

    useEffect(() => {
        loadPhoto()
    }, [refresh])

    return (
        <>
            <View
                style={[
                    windowWidth > 1200
                        ? { marginRight: 20, flexDirection: 'row', alignItems: 'center', gap: 20 }
                        : null,
                    isShowSmallAside ? { margin: 'auto' } : null,
                ]}
            >
                <LoadingSpinner loadVisible={isLoading} />

                {windowWidth > 1200 ? <DropdownOrganization /> : null}

                <TouchableHighlight
                    onPress={onPressIcon}
                    underlayColor="#fff0"
                    style={[
                        {
                            flexGrow: isShowSmallAside ? 1 : 0,
                            alignItems: 'flex-end',
                        },
                    ]}
                >
                    <ScrollView
                        horizontal
                        style={[
                            { width: windowWidth <= 500 ? '90%' : '80%' },
                            windowWidth > 1200 || isShowSmallAside ? { width: 'auto' } : null,
                        ]}
                        contentContainerStyle={{
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: isShowSmallAside ? 'center' : 'flex-start',
                            paddingLeft: !isShowSmallAside ? 5 : 0,
                            flexGrow: 1,
                        }}
                    >
                        <DropdownNative
                            indentificator={TOP_BAR_ID}
                            onChange={() => {}}
                            optionLabel=""
                            optionValue=""
                            options={[]}
                            placeholder=""
                            containerStyles={{ marginTop: 5 }}
                            overlayPanelStyles={windowWidth >= 1200 ? { right: 25, top: 58, left: 'auto' } : null}
                            childrenOverlay
                            customStyles={{
                                flexDirection: 'row',
                                gap: 0,
                                backgroundColor: 'transparent',
                                paddingHorizontal: 0,
                                justifyContent: 'flex-start',
                                paddingLeft: 2,
                            }}
                            valueTemplate={
                                windowWidth <= 1200 ? (
                                    <>
                                        <FontAwesomeIcon color="#969696" style={[outlineStyle as any]} icon={faGear} />
                                        {!isShowSmallAside ? (
                                            <Text
                                                style={{
                                                    color: '#969696',
                                                    paddingLeft: 11,
                                                    fontSize: 16,
                                                }}
                                            >
                                                Settings
                                            </Text>
                                        ) : null}
                                    </>
                                ) : (
                                    <></>
                                )
                            }
                        >
                            <View style={[localStyles.containerProfileOptions, styles.styleTopBar.backgroundTopMenu]}>
                                <Text style={[styles.styleTopBar.labelText, localStyles.paddingProfile]}>
                                    Signed in as{' '}
                                </Text>
                                <Text
                                    style={[
                                        styles.styleTopBar.labelText,
                                        localStyles.paddingProfile,
                                        localStyles.emailProfile,
                                    ]}
                                >
                                    {localUserData?.name || localUserData?.email}
                                </Text>

                                <View style={localStyles.horizontalLine} />

                                <View style={{ paddingTop: 7 }}>
                                    <Hoverable>
                                        {({ hovered }) => (
                                            <View
                                                style={
                                                    hovered
                                                        ? styles.styleTopBar.backgroundTopMenuHover
                                                        : styles.styleTopBar.backgroundTopMenu
                                                }
                                            >
                                                <Pressable onPress={() => onPressOption('Profile')}>
                                                    <Text
                                                        style={[
                                                            styles.styleTopBar.labelText,
                                                            localStyles.paddingProfile,
                                                            styles.styleTopBar.colorText,
                                                        ]}
                                                    >
                                                        Profile
                                                    </Text>
                                                </Pressable>
                                            </View>
                                        )}
                                    </Hoverable>
                                    {userRole === Role.ADMIN ? (
                                        <Hoverable>
                                            {({ hovered }) => (
                                                <View
                                                    style={
                                                        hovered
                                                            ? styles.styleTopBar.backgroundTopMenuHover
                                                            : styles.styleTopBar.backgroundTopMenu
                                                    }
                                                >
                                                    <Pressable onPress={() => onPressOption('Billing')}>
                                                        <Text
                                                            style={[
                                                                styles.styleTopBar.labelText,
                                                                localStyles.paddingProfile,
                                                                styles.styleTopBar.colorText,
                                                            ]}
                                                        >
                                                            Billing
                                                        </Text>
                                                    </Pressable>
                                                </View>
                                            )}
                                        </Hoverable>
                                    ) : null}
                                    <Hoverable>
                                        {({ hovered }) => (
                                            <View
                                                style={
                                                    hovered
                                                        ? styles.styleTopBar.backgroundTopMenuHover
                                                        : styles.styleTopBar.backgroundTopMenu
                                                }
                                            >
                                                <Pressable onPress={() => onPressOption('LogOut')}>
                                                    <Text
                                                        style={[
                                                            styles.styleTopBar.labelText,
                                                            localStyles.paddingProfile,
                                                            styles.styleTopBar.colorText,
                                                        ]}
                                                    >
                                                        Log Out
                                                    </Text>
                                                </Pressable>
                                            </View>
                                        )}
                                    </Hoverable>
                                </View>
                            </View>
                        </DropdownNative>

                        {windowWidth > 1200 ? (
                            <>
                                <ImagePreview
                                    imageUri={userCurrentImage || defaultImageUri}
                                    imageStyle={localStyles.profileImageStyle}
                                />

                                <Text
                                    numberOfLines={1}
                                    ellipsizeMode="tail"
                                    style={[
                                        { color: '#969696', display: windowWidth <= 1200 ? 'flex' : 'none' },
                                        isShowSmallAside ? { display: 'none' } : null,
                                        localStyles.paddingProfile,
                                        localStyles.emailProfile,
                                    ]}
                                >
                                    {localUserData?.name || localUserData?.email}
                                </Text>

                                <ButtonNative
                                    onPress={onPressIcon}
                                    buttonStyle={{ paddingHorizontal: 0, paddingVertical: 0, paddingLeft: 10 }}
                                    showIcon
                                    icon={faCaretDown}
                                />
                            </>
                        ) : null}
                    </ScrollView>
                </TouchableHighlight>
            </View>
        </>
    )
}

const localStyles = StyleSheet.create({
    containerProfileOptions: {
        backgroundColor: '#fff',
        borderColor: 'transparent',
        borderWidth: 1,
        borderRadius: 4,
        padding: 5,
        zIndex: 3,
        shadowColor: 'rgba(0, 0, 0, 0.4)',
        elevation: 5,
        shadowRadius: 8,
        shadowOffset: {
            width: 0,
            height: 0,
        },
    },
    paddingProfile: {
        paddingVertical: 5,
        paddingHorizontal: 5,
    },
    emailProfile: {
        fontWeight: '500',
        paddingBottom: 12,
    },
    horizontalLine: {
        borderBottomColor: 'rgb(150, 150, 150)',
        borderBottomWidth: StyleSheet.hairlineWidth,
    },
    profileImageStyle: {
        width: 38,
        height: 38,
        borderRadius: 6,
        marginVertical: 10,
    },
})
