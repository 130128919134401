import React, { useEffect, useContext, useState } from 'react'
import { View, Text, ViewStyle, StyleProp } from 'react-native'
import { OrganizationService } from '../../../services/OrganizationService'
import { StatusProject } from '../../../models'
import { ProjectService } from '../../../services/ProjectService'
import { UpgradeContext } from '../../../context/upgrade'
import CustomModal from '../CustomModal'
import useDimensions from '../../../hooks/useDimensions'
import InputText from '../InputText'
import ButtonNative from '../ButtonNative'
import { getStyleSheet } from '../../../assets/theme/styles'
import DropdownNative from '../MultiSelectNative/MultiSelect'
import chroma from 'chroma-js'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'

export interface Props {
    onProjectChange: (value) => void
    selectedProject?: any
    allProjects?: any
    allClients?: any
    style?: any
    userSub?: any
    userRole?: any
    disabledEditionFrame?: boolean
    themeColor?: string
    customStyles?: StyleProp<ViewStyle>
}

export interface ProjectAdd {
    projectColor: string
    projectID: string
    projectName: string
}

export interface GroupProject {
    clientName: string
    clientId: string
    projects: ProjectAdd[]
}

export const ListBoxProjects = (props: Props) => {
    const {
        maxProjectsNumber,
        createdProjectsNumber,
        setCreatedProjectsNumber,
        createdClientsNumber,
        setCreatedClientsNumber,
    } = useContext<any>(UpgradeContext)
    const [labelProject, setLabelProject] = useState<any>('')
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState<any>()
    const [disableEdition, setDisableEdition] = useState<boolean>(false)
    const [showNewProject, setShowNewProject] = useState(false)
    const [selectedClient, setSelectedClient] = useState<any>(null)
    const [blockButtonClient, setBlockButtonClient] = useState(false)
    const [blockButtonProject, setBlockButtonProject] = useState(true)
    const [blockInputClientName, setBlockInputClientName] = useState(false)
    const [showRequiredNameProject, setShowRequiredNameProject] = useState(false)
    const [showRequiredNameClient, setShowRequiredNameClient] = useState(false)
    const [upgradePlan, setUpgradePlan] = useState(false)
    const [projectName, setProjectName] = useState('')
    const [organizationId, setOrganizationID] = useState('')
    const [userRole, setUserRole] = useState()
    const [errorMessage, setErrorMessage] = useState('')
    const styles = getStyleSheet()
    const { theme } = useContext(ThemeContext)
    const { windowWidth } = useDimensions()

    const random_hex_color_code = () => {
        const n = (Math.random() * 0xfffff * 1000000).toString(16)
        return n.slice(0, 6).toUpperCase()
    }
    const [color, setColor] = useState<any>(random_hex_color_code)

    useEffect(() => {
        if (props?.allProjects.length > 0) {
            setProjects(props.allProjects)
        }

        if (props?.disabledEditionFrame) {
            setDisableEdition(props.disabledEditionFrame)
        }

        if (props?.userRole) {
            setUserRole(props.userRole)
        }
    }, [props?.allProjects, props?.disabledEditionFrame, props?.userRole])

    useEffect(() => {
        setSelectedProject(props.selectedProject)
        if (!props?.selectedProject) {
            setLabelProject('')
        } else {
            setLabelProject(props?.selectedProject.projectName)
        }
    }, [props.selectedProject])

    useEffect(() => {
        if (props?.userSub) {
            OrganizationService()
                .getDefaultOrganizationId(props.userSub)
                .then(response => {
                    setOrganizationID(response)
                })
        }
    }, [props.userSub])

    useEffect(() => {
        setUpgradePlan(createdProjectsNumber >= maxProjectsNumber)
    }, [maxProjectsNumber, createdProjectsNumber])

    const onProjectChange = e => {
        const findProject = projects
            .map((item: any) => item.projects)
            .flat()
            .find((project: any) => project.projectID === e)

        if (!findProject) return

        setLabelProject(findProject.projectName)
        setSelectedProject(findProject)
        props.onProjectChange(findProject)
    }

    const groupedItemTemplate = (option: any) => {
        const darkLevel = theme === 'dark' ? 1 : 0
        const textColor = chroma(`#${option?.projects?.at(0).projectColor}`)
            .darken(darkLevel)
            .hex()

        return (
            <View
                style={{
                    paddingVertical: 10,
                    paddingHorizontal: 14,
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <Text style={{ color: textColor, fontWeight: '500', fontSize: 15 }}>{option.clientName}</Text>
                <Text style={{ color: styles.text, marginLeft: 8 }}>
                    {option.projects.length} project{option.projects.length > 1 && 's'}
                </Text>
            </View>
        )
    }

    const clearModalComponent = () => {
        setErrorMessage('')
        setProjectName('')
        setSelectedClient(null)
        setBlockButtonClient(false)
        setBlockButtonProject(false)
        setBlockInputClientName(false)
        setShowRequiredNameClient(false)
        setShowRequiredNameProject(false)
    }

    const onClickAddProject = e => {
        clearModalComponent()
        setShowNewProject(true)
    }

    const onClientChange = value => {
        setSelectedClient(value)
        setShowRequiredNameClient(false)
    }

    const validateField = () => {
        if (!projectName || projectName === '') {
            setErrorMessage('Project name is required')
            setShowRequiredNameProject(true)
            return true
        }

        return false
    }

    const handleCreateProject = async () => {
        if (validateField()) {
            return
        }

        setBlockButtonProject(true)

        const projectParams = {
            name: projectName,
            clientID: selectedClient,
            status: StatusProject.ACTIVE,
            groupcolor: color,
            organizationID: organizationId,
        }

        ProjectService()
            .saveProject(projectParams)
            .then(response => {
                setBlockButtonProject(true)

                let newGroupProject: GroupProject
                let newProject: ProjectAdd = {
                    projectColor: response.groupcolor,
                    projectID: response.id,
                    projectName: response.name,
                }

                newGroupProject = {
                    clientName: response.client?.name || '- No client -',
                    clientId: response.client?.id || '- No client -',
                    projects: [newProject],
                }
                const projectsArr: any = [...projects]
                const isFoundIndex = projectsArr.findIndex((element: any) => {
                    if (element.clientId === newGroupProject.clientId) return true
                    return false
                })

                if (isFoundIndex !== -1) {
                    projectsArr[isFoundIndex].projects.push(newGroupProject.projects[0])
                } else {
                    projectsArr.push(newGroupProject)
                }

                setProjects(projectsArr)

                setLabelProject(newGroupProject.projects[0].projectName)
                setSelectedProject(newGroupProject.projects[0])
                props.onProjectChange(newGroupProject.projects[0])

                setCreatedProjectsNumber(createdProjectsNumber + 1)
                setCreatedClientsNumber(createdClientsNumber + 1)
                setShowNewProject(false)
            })
            .catch(error => {
                console.log('error', error)
                setBlockButtonProject(false)
            })
    }

    const handleKeyDownCreateProject = event => {
        if (event.key === 'Enter') {
            handleCreateProject()
        }
    }

    const widthModal = () => {
        if (windowWidth < 400) return 280
        else return 380
    }

    return (
        <>
            <DropdownNative
                uniqueSelection
                hideSearch
                valueWhenUniqueSelection={selectedProject?.projectID}
                placeholder="Project"
                optionLabel="projectName"
                optionValue="code"
                optionGroupLabel="clientName"
                optionGroupValue="projectID"
                optionGroupChildren="projects"
                indentificator="projectProjectBar"
                emptyFilterMessage="No projects added"
                options={projects}
                customStyles={[{ paddingHorizontal: 10, paddingVertical: 10 }]}
                containerStyles={props.customStyles}
                includeHovered
                optionGroupTemplate={groupedItemTemplate}
                onChange={onProjectChange}
                childrenOverlay={
                    <>
                        {userRole === 'ADMIN' && !upgradePlan ? (
                            <View style={{ padding: 10 }}>
                                <Text
                                    style={{ color: '#3B82f6', fontWeight: '500', textAlign: 'right' }}
                                    onPress={e => onClickAddProject(e)}
                                >
                                    Add Project
                                </Text>
                            </View>
                        ) : null}
                    </>
                }
            />

            <CustomModal
                header="Project"
                isVisible={showNewProject}
                onHide={setShowNewProject}
                customStyles={{ width: widthModal() }}
            >
                <View style={{ flexDirection: 'column', marginTop: 30 }}>
                    <View style={{ width: '100%', marginBottom: 10 }}>
                        <InputText
                            placeholder="Project Name"
                            inputStyle={{ height: 53 }}
                            value={projectName}
                            onKeyPress={handleKeyDownCreateProject}
                            onChange={e => {
                                setProjectName(e.valueOf())
                                setErrorMessage('')
                                setShowRequiredNameProject(false)
                            }}
                        />
                        {showRequiredNameProject ? (
                            <View>
                                <Text style={{ color: '#ef9a9a', fontSize: 12, paddingVertical: 10, paddingLeft: 15 }}>
                                    {errorMessage}
                                </Text>
                            </View>
                        ) : null}
                    </View>
                    <View style={{ width: '100%', marginBottom: 10, zIndex: 1000 }}>
                        {props.allClients.length > 0 ? (
                            <DropdownNative
                                valueWhenUniqueSelection={selectedClient}
                                indentificator="clientDropdownBarProject"
                                options={props.allClients as any}
                                customStyles={{
                                    height: 50,
                                    borderWidth: 1,
                                    borderColor: styles.dropdownBorderColor,
                                    paddingHorizontal: 20,
                                }}
                                onChange={onClientChange}
                                optionLabel="name"
                                optionValue="code"
                                placeholder="Client"
                                uniqueSelection
                                emptyFilterMessage="No Client added"
                            />
                        ) : null}
                        {showRequiredNameClient ? (
                            <View>
                                <Text style={{ color: '#ef9a9a', fontSize: 12 }}>{errorMessage}</Text>
                            </View>
                        ) : null}
                    </View>
                    <ButtonNative
                        title="Add"
                        primaryTheme
                        titleStyle={{ fontSize: 16, fontWeight: '600' }}
                        buttonStyle={{ height: 53 }}
                        onPress={handleCreateProject}
                        disabled={blockButtonProject}
                    />
                </View>
            </CustomModal>
        </>
    )
}
