import chroma from 'chroma-js'
import { StyleSheet, Text, View } from 'react-native'
import ButtonNative from '../ButtonNative'
import { ITagFormated } from './MultiselectTags'
import { useContext } from 'react'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { faClose } from '@fortawesome/free-solid-svg-icons'

interface ITagItem {
    handleDelete: (tag: ITagFormated) => void
    tag: ITagFormated
    disabled?: boolean
}

const TagItem = ({ tag, disabled, handleDelete }: ITagItem) => {
    const color = chroma(tag.color)
    const { theme } = useContext<any>(ThemeContext)
    const textColor = theme === 'dark' ? 'white' : tag.color

    return (
        <View
            style={[
                {
                    backgroundColor: color.alpha(0.15).css(),
                },
                localStyles.container,
            ]}
        >
            {!disabled ? (
                <ButtonNative
                    containerStyle={{ paddingRight: 5 }}
                    buttonStyle={{ paddingVertical: 0, paddingHorizontal: 0 }}
                    onPress={() => handleDelete(tag)}
                    title=""
                    showIcon
                    icon={faClose}
                    iconColor={textColor}
                />
            ) : null}
            <Text style={[{ color: textColor }]}>{tag.label}</Text>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        height: 25,
        justifyContent: 'center',
        borderRadius: 4,
        flexDirection: 'row',
        alignItems: 'center',
        paddingHorizontal: 4,
    },
})

export default TagItem
