export const darkColors = {
    backgroundColor: '#383A40',
    borderColor: '#555961',
    separatorColor: '#000000',
    iconColor: '#FFFFFF',
    backgroundColorSidebar: '#2C2E33',
    backgroundColorDashboard: '#202023',
    backgroundColorSwitch: '#202023',
    backgroundColorLogin: '#202023',
    backgroundColorInputLogin: '#555961',
    backgroundColorButtonProviderLogin: '#555961',
    backgroundColorRow: '#555961',
    backgroundColorTopMenu: '#686C76',
    backgroundColorTopMenuHover: '#FFFFFF26',
    backgroundColorInput: '#555961',
    backgroundColorPrimaryButton: '#42C700',
    text: '#FFFFFF',
    reverseText: '#000000',
    primary: '#B39DDB',
    error: '#EF9A9A',
    placeholderText: '#ffffff80',
    buttonLoadMore: '#64748BA9',
    filterBackground: '#555961',
    filterBorderColor: '#555961',
    cardBackground: '#555961',
    dialogBackground: '#383a40',
    loginInputBackground: '#555961',
    backgroundComponent: '#555961',
    selectbuttonBackground: '#202023',
    buttonBackgroundColorPrimary: '#890300',
    multiSelectHeaderBackground: '#383a40',
    dropdownHighlightBackground: '#ffffff26',
    dropdownBorderColor: '#555961',
    countColorRed: '#d00500',
    countColorPurple: '#8f8ce7',
    initialSkeletonColor: '#555961',
    finishSkeletonColor: '#FFFFFF',
    rowExpandBackground: '#55596180',
    brushIconBackground: '#FFFFFF',
    separatorBorder: 'rgb(48, 69, 98)',
    buttonNumberBackground: '#818a94',
}

export const lightColors = {
    backgroundColor: '#FFFFFF',
    borderColor: '#C8D3DC',
    separatorColor: '#C8D3DC',
    iconColor: '#818A94',
    backgroundColorSidebar: '#362C28',
    backgroundColorDashboard: '#F2F6F9',
    backgroundColorSwitch: '#EBEBEB',
    backgroundColorLogin: '#FFFFFF',
    backgroundColorInputLogin: '#EBEBEB',
    backgroundColorButtonProviderLogin: '#FFFFFF',
    backgroundColorRow: '#FFFFFF',
    backgroundColorTopMenu: '#FFFFFF',
    backgroundColorTopMenuHover: '#C8D3DC',
    backgroundColorInput: '#E8EDF1',
    backgroundColorPrimaryButton: '#42C700',
    text: '#000000',
    reverseText: '#FFFFFF',
    primary: '#512DA8',
    error: '#D32F2F',
    placeholderText: '#00000080',
    buttonLoadMore: '#FCFCFC',
    filterBackground: '#ffffff',
    filterBorderColor: '#c8d3dc',
    cardBackground: '#FFFFFF',
    dialogBackground: '#ffffff',
    loginInputBackground: '#e8edf1',
    backgroundComponent: '#FFFFFF',
    selectbuttonBackground: '#e8edf1',
    buttonBackgroundColorPrimary: '#d00500',
    multiSelectHeaderBackground: '#e8edf1',
    dropdownHighlightBackground: '#c8cdd1',
    dropdownBorderColor: '#C6D2D9',
    countColorRed: '#d00500',
    countColorPurple: '#8f8ce7',
    initialSkeletonColor: 'hsl(200, 20%, 98%)',
    finishSkeletonColor: 'hsl(200, 20%, 90%)',
    rowExpandBackground: '#ffffff88',
    brushIconBackground: '#FFFFFF',
    separatorBorder: '#000000',
    buttonNumberBackground: '#818a9451',
}

export type Colors = typeof lightColors
