import { View, Text, StyleSheet, StyleProp, ViewStyle } from 'react-native'
import ButtonNative from './ButtonNative'
import { Modal } from 'react-native'
import { getStyleSheet } from '../../assets/theme/styles'
import { faWindowClose } from '@fortawesome/free-regular-svg-icons'

interface ICustomModal {
    header: string
    isVisible: boolean
    message?: string
    footerModal?: React.JSX.Element
    children?: React.ReactNode
    customStyles?: StyleProp<ViewStyle>
    headerStyles?: StyleProp<ViewStyle>
    onHide: React.Dispatch<React.SetStateAction<boolean>>
}

export default function CustomModal({
    header,
    isVisible,
    message,
    onHide,
    footerModal,
    children,
    customStyles,
    headerStyles,
}: ICustomModal) {
    const styles = getStyleSheet()

    return (
        <Modal animationType="fade" transparent visible={isVisible}>
            <View style={localStyles.container}>
                <View style={[localStyles.subContainer, customStyles, { backgroundColor: styles.dialogBackground }]}>
                    <View style={[localStyles.header, headerStyles]}>
                        <Text style={[localStyles.titleHeader, { color: styles.text }]}>{header}</Text>
                        <ButtonNative
                            onPress={() => onHide(false)}
                            showIcon
                            iconSize={25}
                            title=""
                            buttonStyle={{ paddingVertical: 0, paddingHorizontal: 0 }}
                            iconColor={styles.text}
                            icon={faWindowClose}
                        />
                    </View>

                    {message ? <Text style={[localStyles.titleMessage, { color: styles.text }]}>{message}</Text> : null}

                    {footerModal ? <View style={{ marginTop: 26, marginBottom: 6 }}>{footerModal}</View> : null}

                    {children}
                </View>
            </View>
        </Modal>
    )
}

const localStyles = StyleSheet.create({
    modal: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    subContainer: {
        paddingVertical: 20,
        paddingHorizontal: 24,
        borderRadius: 4,
        shadowColor: 'rgba(0, 0, 0, 0.4)',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowRadius: 25,
        elevation: 5,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 20,
    },
    titleHeader: {
        fontSize: 20,
        fontWeight: '600',
    },
    titleMessage: {
        marginVertical: 20,
        fontSize: 16,
    },
})
