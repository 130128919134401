import React, { useState } from 'react'
import { ImageBackground, StyleSheet, Text, View, Image } from 'react-native'
import { Button } from '@rneui/base'
import { getStyleSheet } from '../../assets/theme/styles'
import useDimensions from '../../hooks/useDimensions'
import { ReportIssueModal } from './ReportIssueModal'

const backgroundPattern = require('../../assets/images/tomato_middle_background.png')
const logoRed = require('../../assets/images/timerz-3.png')

const ColumnSignUp = ({ navigation }) => {
    const [reportIssueModalVisible, setReportIssueModalVisible] = useState(false)
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()

    return (
        <View
            style={[
                { backgroundColor: styles.buttonBackgroundColorPrimary },
                localStyles.container,
                windowWidth > 768 && windowWidth < 992 ? { width: '45%' } : null,
                windowWidth >= 992 ? { width: '33%' } : null,
            ]}
        >
            <ImageBackground source={backgroundPattern} resizeMode="stretch" style={localStyles.imageBackground}>
                <View style={[localStyles.contentBackground]}>
                    <Image source={logoRed} resizeMode="contain" style={localStyles.imageLogo} />
                    <Text
                        style={[
                            localStyles.textTitleLeft,
                            windowWidth > 768 ? { fontSize: 24, fontWeight: '700' } : null,
                        ]}
                    >
                        Don’t have an Account yet?
                    </Text>

                    <Button
                        buttonStyle={[localStyles.signupButton, windowWidth > 768 ? { backgroundColor: '#FFF' } : null]}
                        titleStyle={[localStyles.signupTitle, windowWidth > 768 ? { color: '#D00500' } : null]}
                        containerStyle={[localStyles.signupContainer, windowWidth > 768 ? { marginTop: 40 } : null]}
                        title="SIGN UP"
                        onPress={() => {
                            navigation.navigate('Signup')
                        }}
                    />
                    <ReportIssueModal visible={reportIssueModalVisible} setShowModal={setReportIssueModalVisible} />
                </View>
            </ImageBackground>

            <Text
                style={[
                    localStyles.textDescription,
                    {
                        padding: 20,
                        position: 'absolute',
                        bottom: 0,
                        right: 0,
                    },
                ]}
            >
                Problem logging in?{' '}
                <Text style={{ textDecorationLine: 'underline' }} onPress={() => setReportIssueModalVisible(true)}>
                    report it
                </Text>
            </Text>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        paddingTop: 30,
        paddingBottom: 20,
    },
    imageBackground: {
        flexGrow: 1,
        justifyContent: 'flex-end',
    },
    contentBackground: {
        flexGrow: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    imageLogo: {
        width: 125,
        height: 41,
        marginTop: 10,
        marginBottom: 20,
    },
    buttonStyle: {
        backgroundColor: '#D00500',
        height: 58,
        borderRadius: 4,
        textTransform: 'uppercase',
        fontWeight: '700',
        fontSize: 16,
    },
    textTitleLeft: {
        color: '#ffffff',
        fontSize: 16,
        lineHeight: 28,
        fontWeight: '300',
    },
    textDescription: {
        color: '#ffffff',
        fontSize: 16,
        lineHeight: 18.75,
        textAlign: 'center',
        fontWeight: '400',
    },
    signupButton: {
        backgroundColor: 'transparent',
        height: 58,
        borderRadius: 4,
        margin: 0,
    },
    signupTitle: {
        color: '#FFF',
        fontWeight: 'bold',
        fontSize: 16,
    },
    signupContainer: {
        width: 164,
        marginBottom: 20,
    },
})

export default ColumnSignUp
