import React, { useEffect, useState } from 'react'
import { AuthService } from '../../services/AuthService';
import { ImagePreview } from './ImagePreview';

const defaultImageUri = require('../../assets/images/profiledefault.png');
const stylePhoto = {
    width: 28,
    height: 28,
    borderRadius: 6
};

export const GroupProfilePhoto = ({ image }) => {
    const [imageUrl, setImageUrl] = useState(defaultImageUri);

    useEffect(() => {
        if (image) {
            AuthService().getProfilePhoto(image).then((urlPhoto) => {
                setImageUrl(urlPhoto);
            });
        }

    }, [image])

    return (
        <ImagePreview imageUri={imageUrl} imageStyle={stylePhoto} />
    )
}
