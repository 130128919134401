import React, { useContext, useRef, useState } from 'react'
import { View, ScrollView, StyleSheet, Animated } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { useFocusEffect } from '@react-navigation/native'
import { LoadingSpinner } from '../components/common/LoadingSpinner'
import { getStyleSheet } from '../assets/theme/styles'
import { ClientService } from '../services/ClientService'
import { TagsService } from '../services/TagsService'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { Role } from '../models'
import { StatusClient } from '../API'
import { UpgradeContext } from '../context/upgrade'
import CreateClientBar from '../components/common/CreateClientBar/CreateClientBar'
import Searchbar from '../components/common/Searchbar/Searchbar'
import DataTable from '../components/common/DataTable'
import RowClient from '../components/common/RowClient'
import SkeletonTable from '../components/common/SkeletonTable'
import FooterDialog from '../components/common/FooterDialog'
import CustomModal from '../components/common/CustomModal'
import useDimensions from '../hooks/useDimensions'
import useSearchBar from '../components/common/Searchbar/useSearchBar'

const headerColumns = [
    { label: 'CLIENT', fieldToSorteable: 'name', isSorteable: true, styles: { flexGrow: 1 } },
    { label: 'TAGS', fieldToSorteable: '', isSorteable: false, styles: { width: '35%' } },
    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: 26 } },
]

const Clients = ({ ...props }) => {
    const current = 'Clients'
    const styles = getStyleSheet()
    const {
        getClientsActive,
        saveClient,
        saveClientTags,
        deleteClientById,
        deleteAllTagsInClient,
        createTagsInClient,
    } = ClientService()
    const { userRole, userSub } = useContext<any>(ThemeContext)
    const { createdClientsNumber, setCreatedClientsNumber } = useContext<any>(UpgradeContext)
    const { getAllTags } = TagsService()
    const [clients, setClients] = useState<any[]>([])
    const [allClients, setAllClients] = useState<any[]>([])
    const [tags, setTags] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
    const [currentDelete, setCurrentDelete] = useState<any>(null)
    const { windowWidth } = useDimensions()
    const { isCollapsed, toggleSetIsCollapsed, fadeAnim } = useSearchBar()

    const onNewClient = async newClient => {
        let newEntry = {
            name: newClient.name,
            status: newClient.status,
            organizationID: newClient.organizationID,
        }

        saveClient(newEntry).then(element => {
            saveClientTags(newClient.tags, element?.id).then(response => {
                if (response === 'SUCCESS') {
                    setCreatedClientsNumber(createdClientsNumber + 1)
                    getAllClient()
                }
            })
        })
    }

    const handleChangeTag = async (selectedTags: any, client: any) => {
        if (client.TagsClient.items.length > 0) {
            await deleteAllTagsInClient(client.TagsClient.items)
        }

        if (selectedTags.length > 0) {
            for (const tagTask of selectedTags) {
                const tagsTask = { clientID: client.id, tagID: tagTask.value }
                await createTagsInClient(tagsTask)
            }
        }
        getAllClient()
    }

    const onDeleteRow = async () => {
        if (!currentDelete) {
            return
        }

        const row = clients.find((item: any) => item.id === currentDelete) as any

        if (!row) {
            return
        }

        setShowConfirmationDelete(false)
        await deleteClientById(row.id, row._version)
        getAllClient()
    }

    const onUpdateRow = (id: string | null, newObject?: any) => {
        if (id) {
            const row = clients.find((item: any) => item.id === id) as any
            if (!row) return

            newObject = {
                id: row.id,
                status: StatusClient.ARCHIVED,
                _version: row._version,
            }
        }

        ClientService()
            .updateClientById(newObject)
            .then(() => {
                setCreatedClientsNumber(createdClientsNumber - 1)
                getAllClient()
            })
            .catch(() => {})
    }

    const getAllClient = () => {
        setLoading(true)
        if (userSub === '') return
        getClientsActive(userSub)
            .then(data => {
                data = data.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                })

                setAllClients(data)
                setClients(data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.log(error)
            })
    }

    const onHideConfirmationDelete = () => {
        setShowConfirmationDelete(false)
    }

    const onShowConfirmationDelete = (id: any) => {
        setCurrentDelete(id)
        setShowConfirmationDelete(true)
    }

    useFocusEffect(
        React.useCallback(() => {
            getAllClient()

            if (userSub && userSub !== '') {
                getAllTags(userSub).then(data => {
                    setTags(data)
                })
            }
        }, [userRole, userSub])
    )

    return (
        <View style={localStyles.container}>
            <LoadingSpinner loadVisible={isLoading} />
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current }} />
                    <View
                        style={[
                            styles.baseStyleContent.container,
                            localStyles.workArea,
                            windowWidth > 1000 ? { marginHorizontal: 50 } : null,
                        ]}
                    >
                        <Searchbar
                            isCollapsed={isCollapsed}
                            setIsCollapsed={toggleSetIsCollapsed}
                            items={allClients}
                            onFilteredItems={filteredItems => {
                                setClients(filteredItems)
                            }}
                        />

                        {userRole === Role.ADMIN ? (
                            <Animated.View
                                style={{
                                    transform: [{ translateY: fadeAnim ?? 0 }],
                                    display: isCollapsed ? 'flex' : 'none',
                                }}
                            >
                                <CreateClientBar allTags={tags} onCreateRow={onNewClient} />
                            </Animated.View>
                        ) : null}

                        {loading && !clients.length ? (
                            <SkeletonTable rowsQuantity={8} />
                        ) : (
                            <DataTable
                                customStyles={{ marginTop: 30 }}
                                headerCustomStyles={{
                                    padding: 10,
                                    gap: 6,
                                    display: windowWidth > 900 ? 'flex' : 'none',
                                }}
                                headerColumns={headerColumns as any}
                                itemsList={clients}
                                setItemsList={setClients}
                                emptyMessage="Create a new client."
                                isShowEmptyMessage={clients.length === 0}
                            >
                                {clients.map(client => {
                                    return (
                                        <RowClient
                                            loadingClients={loading}
                                            userRole={userRole}
                                            key={client.id}
                                            client={client}
                                            tags={tags}
                                            handleChangeTag={handleChangeTag}
                                            onUpdateRow={onUpdateRow}
                                        />
                                    )
                                })}
                            </DataTable>
                        )}
                    </View>
                </ScrollView>
            </View>

            <CustomModal
                header="Delete"
                message="Are you sure you want to delete entry?"
                isVisible={showConfirmationDelete}
                footerModal={<FooterDialog handleOnNo={onHideConfirmationDelete} handleOnYes={onDeleteRow} />}
                onHide={setShowConfirmationDelete}
            />
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
    searchContainer: {
        zIndex: 5005,
        flexDirection: 'row',
        marginBottom: 12,
        height: 41,
    },
    textinputSearch: {
        paddingLeft: 10,
        width: 295,
        height: 40,
        borderColor: 'gray',
        borderRadius: 6,
        fontStyle: 'italic',
    },
    iconSearch: {
        width: 22,
        height: 22,
        position: 'absolute',
        marginTop: 10,
        right: 10,
    },
})

export default Clients
