import React, { useContext, useEffect, useRef, useState } from 'react'
import { Platform, StyleSheet, Text, View } from 'react-native'
import { getStyleSheet } from '../../assets/theme/styles'
import { OrganizationService } from '../../services/OrganizationService'
import { Status } from '../../models'
import { GroupService } from '../../services/GroupService'
import { isEmpty } from 'lodash'
import { ThemeContext } from '../../assets/theme/ThemeProvider'
import { UpgradeContext } from '../../context/upgrade'
import MultiselectMembers from './MultiSelectMembers/MultiselectMembers'
import InputText from './InputText'
import ButtonNative from './ButtonNative'
import { useNavigation } from '@react-navigation/native'
import { MomentaryView } from './DataTable'
import useDimensions from '../../hooks/useDimensions'

export const NewGroupModal: React.FC<any> = (props: any) => {
    const styles = getStyleSheet()
    const { userSub } = useContext<any>(ThemeContext)
    const { groupUpdate } = props.children
    const { setCreatedGroupsNumber, createdGroupsNumber } = useContext<any>(UpgradeContext)
    const nameRef = useRef<any>(null)
    const [showRequiredName, setShowRequiredName] = useState(false)
    const [blockButtonNew, setBlockButtonNew] = useState(false)
    const [titleButton, setTitleButton] = useState('Add a new group')
    const [organizationID, setOrganizationID] = useState('CREATE GROUP')
    const [name, setName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [usersSelected, setUsersSelected] = useState<any[]>([])
    const navigation = useNavigation()
    const { windowWidth } = useDimensions()

    const requiredStyleComponent = (elementRef: any) => {
        if (elementRef?.current) {
            elementRef.current.focus()

            if (Platform.OS === 'web') {
                elementRef.current.style.marginBottom = 0
                elementRef.current.style.borderColor = '#E24C4C'
                elementRef.current.style.transitionDuration = '1s'

                setTimeout(() => {
                    elementRef.current.style.borderColor = 'transparent'
                }, 3000)
            }
        }
    }

    const clearComponents = () => {
        setName('')
        setUsersSelected([])
        setShowRequiredName(false)
        setBlockButtonNew(false)
        setErrorMessage('')
    }

    const validateFields = () => {
        if (isEmpty(name)) {
            requiredStyleComponent(nameRef)
            setShowRequiredName(true)
            setErrorMessage('Name is required.')
            return true
        }
        return false
    }

    const handleSelectMembers = values => {
        setUsersSelected(values.map(member => ({ code: member })))
    }

    const getGroupName = value => {
        setShowRequiredName(false)
        setName(value)
    }

    const showCatchError = () => {
        setBlockButtonNew(false)
    }

    const updateGroup = () => {
        setBlockButtonNew(true)

        const updateObject = {
            id: groupUpdate.id,
            name: name,
            _version: groupUpdate._version,
        }

        GroupService()
            .updateGroupById(updateObject)
            .then((groupUpdated: any) => {
                const arrayUsersGroup = groupUpdated?.data.updateGroup.UsersGroup.items.map(item => {
                    return {
                        id: item.id,
                        _version: item._version,
                    }
                })
                GroupService()
                    .deleteUsersGroup(arrayUsersGroup, groupUpdate.id)
                    .then(() => {
                        GroupService()
                            .saveUsersGroup(usersSelected, groupUpdate.id)
                            .then(response => {
                                setBlockButtonNew(false)
                                props.onRefreshGroups()
                                clearComponents()
                                props.setUserCreateGroupWhenOpenModal(true)
                            })
                            .catch(error => {
                                showCatchError()
                            })
                    })
                    .catch(error => {
                        showCatchError()
                    })
            })
            .catch(error => {
                showCatchError()
            })
    }

    const createGroup = () => {
        setBlockButtonNew(true)

        const newObject = {
            name: name,
            status: Status.ACTIVE,
            organizationID: organizationID,
        }

        GroupService()
            .saveGroup(newObject)
            .then(groupCreated => {
                GroupService()
                    .saveUsersGroup(usersSelected, groupCreated.id)
                    .then(response => {
                        setCreatedGroupsNumber(createdGroupsNumber + 1)
                        props.onRefreshGroups()
                        clearComponents()
                        props.setUserCreateGroupWhenOpenModal(true)
                    })
                    .catch(error => {
                        showCatchError()
                    })
            })
            .catch(error => {
                showCatchError()
            })
    }

    const createUpdateGroup = () => {
        if (validateFields()) return

        if (groupUpdate) {
            updateGroup()
        } else {
            createGroup()
        }
    }

    const loadDataModal = groupUpdate => {
        setName(groupUpdate.name)

        const usersIdArray = groupUpdate.arrayUsers.map(item => {
            return { label: item.name, code: item.id }
        })

        setUsersSelected(usersIdArray)
    }

    const handleKeyDownNewGroup = event => {
        if (event.key === 'Enter') {
            createUpdateGroup()
        }
    }

    const getOrganizationId = () => {
        OrganizationService()
            .getDefaultOrganizationId(userSub)
            .then(response => {
                setOrganizationID(response)
            })
    }

    useEffect(() => {
        if (groupUpdate) {
            setTitleButton('SAVE')
            loadDataModal(groupUpdate)
        } else {
            setTitleButton('CREATE')
        }
    }, [groupUpdate])

    useEffect(() => {
        clearComponents()
        getOrganizationId()
    }, [userSub])

    return (
        <View
            style={[
                {
                    flexDirection: windowWidth <= 600 ? 'column' : 'row',
                    gap: 20,
                    alignItems: 'center',
                    backgroundColor: styles.backgroundColorRow,
                    marginTop: 20,
                    paddingVertical: 12,
                    paddingHorizontal: 20,
                    borderRadius: 10,
                },
                styles.stylesBar.boxShadow,
            ]}
        >
            <InputText
                placeholder="Group Name"
                ref={nameRef}
                value={name}
                containerStyle={{ flexGrow: 1, width: '100%' }}
                inputStyle={{
                    borderWidth: 1,
                    borderColor: '#afa8a8',
                }}
                onKeyPress={handleKeyDownNewGroup}
                onChange={e => {
                    getGroupName(e.valueOf())
                }}
            />

            {showRequiredName ? <Text style={{ color: '#ef9a9a', fontSize: 12 }}>{errorMessage}</Text> : null}

            <MomentaryView customStyles={{ flexDirection: 'row', gap: 10, width: '100%' }} showViewUp={600}>
                <MultiselectMembers
                    onMemberChange={handleSelectMembers}
                    selectedMember={usersSelected}
                    containerStyle={{ flexGrow: windowWidth <= 600 ? 1 : 0 }}
                    buttomStyle={{ paddingVertical: 10, paddingHorizontal: 10 }}
                    includeHovered
                />

                <ButtonNative
                    title={titleButton}
                    primaryTheme
                    onPress={createUpdateGroup}
                    disabled={Boolean(props.isUpgrade && !groupUpdate) || blockButtonNew}
                />

                {props.isUpgrade ? (
                    <ButtonNative
                        title="Upgrade"
                        buttonStyle={{ backgroundColor: '#3B82f6' }}
                        onPress={() => navigation.navigate('Upgrade' as never)}
                    />
                ) : null}
            </MomentaryView>
        </View>
    )
}
