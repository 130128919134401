import React, { useContext, useState, useEffect, useRef } from 'react';
import { ThemeContext } from '../../../assets/theme/ThemeProvider';
import { Status } from '../../../models';
import { OrganizationService } from '../../../services/OrganizationService';
import { UpgradeContext } from '../../../context/upgrade';

export interface Props {
    onCreateRow: (newRow: any) => void,
    allTags?: any,
    style?: any,
}

const useCreateClientBar = (props: Props) => {
    const { theme, userSub } = useContext<any>(ThemeContext);
    const { maxClientsNumber, createdClientsNumber } = useContext<any>(UpgradeContext);
    const [name, setName] = useState('');
    const [allTags, setAllTags] = useState<any>([]);
    const [selectTags, setSelectTags] = useState<any>([]);
    const [tagsSelected, setSelectedTags] = useState([]);
    const [showUpgradeButton, setShowUpgradeButton] = useState(false);
    const clientNameRef = useRef<any>(null);

    const checkClientName = () => {
        if (clientNameRef?.current && !name) {
            clientNameRef.current.focus();
            clientNameRef.current.style.borderColor = '#D00500';
            clientNameRef.current.style.transitionDuration = '1s';

            setTimeout(() => {
                clientNameRef.current.style.borderColor = '#C6D2D9';
            }, 3000);
            return false;
        }

        return true;
    };

    const onTagsChange = (value) => {        
        const tagsvalue = value.map(e => { return e['value'] });        
        setSelectTags(tagsvalue)
        setSelectedTags(value)
    }

    const onHandleCreate = async () => {
        if (!checkClientName()) return;

        let organizationId = await OrganizationService().getDefaultOrganizationId(userSub);
        const clientParams = {
            name: name,
            tags: selectTags,
            status: Status.ACTIVE,
            organizationID: organizationId
        };
        
        resetRow();
        props.onCreateRow(clientParams);
    }

    const resetRow = () => {
        setName('');
        setSelectTags([]);
        setSelectedTags([]);
    }

    const handleKeyDownCreateClient = (event) => {
        if (event.key === 'Enter') {
            onHandleCreate();
        }
    };

    useEffect(() => {
        setAllTags(props.allTags);
    }, [props]);

    useEffect(() => {
        setShowUpgradeButton(createdClientsNumber >= maxClientsNumber);
    }, [maxClientsNumber, createdClientsNumber]);

    return [{ theme, clientNameRef, name, allTags, tagsSelected, showUpgradeButton, 
        setName, setSelectedTags, onTagsChange, onHandleCreate, handleKeyDownCreateClient }];
}

export default useCreateClientBar;
