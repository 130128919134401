import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { StyleProp, ViewStyle } from 'react-native'

export interface Props {
    onProjectChange: (value) => void
    onPress?: () => void
    selectedProject?: any
    allProjects?: any
    style?: StyleProp<ViewStyle>
    disabledEditionFrame?: boolean
    className?: string
    task: any
}

const useDropdownProjects = (props: Props) => {
    const { theme } = useContext<any>(ThemeContext)
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState<any>(null)
    const [disableEdition, setDisableEdition] = useState<boolean>(false)

    const onProjectChange = (value: string) => {
        setSelectedProject(value)
        props.onProjectChange(value)
    }

    useEffect(() => {
        setSelectedProject(props.selectedProject)

        if (props?.allProjects) {
            setProjects(props.allProjects)
        }

        if (props?.disabledEditionFrame) {
            setDisableEdition(props.disabledEditionFrame)
        }
    }, [props])

    return [{ theme, projects, selectedProject, disableEdition, task: props.task, onProjectChange }]
}

export default useDropdownProjects
