import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import useCreateProjectBar, { Props } from './useCreateProjectBar'
import ButtonNative from '../ButtonNative'
import InputText from '../InputText'
import { View } from 'react-native'
import ColorPickerNative from '../ColorPicker'
import DropdownNative from '../MultiSelectNative/MultiSelect'
import useDimensions from '../../../hooks/useDimensions'
import { getStyleSheet } from '../../../assets/theme/styles'
import { MomentaryView } from '../DataTable'

export const CreateProjectBar: React.FC<Props> = props => {
    const {
        projectNameRef,
        name,
        color,
        selectedClient,
        selectedGroup,
        allClients,
        allGroups,
        upgradePlan,
        setName,
        setColor,
        onClientChange,
        onGroupChange,
        onHandleCreate,
    } = useCreateProjectBar(props)

    const navigation = useNavigation()
    const { windowWidth } = useDimensions()
    const styles = getStyleSheet()
    const [barWidth, setBarWidth] = useState(0)

    const LeftContents = () => {
        return (
            <>
                <View
                    style={[
                        {
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: 10,
                            padding: 10,
                        },
                        windowWidth > 1000 ? { flexDirection: 'row' } : null,
                    ]}
                >
                    <MomentaryView
                        customStyles={{ flexDirection: 'row', width: '100%', alignItems: 'center' }}
                        showViewUp={1000}
                    >
                        <InputText
                            containerStyle={{ flexGrow: 1 }}
                            placeholder="Project"
                            value={name}
                            ref={projectNameRef}
                            onChange={e => setName(e.valueOf())}
                        />

                        <ColorPickerNative
                            customStyles={[
                                { paddingHorizontal: windowWidth > 1000 ? 20 : 0, zIndex: 300 },
                                upgradePlan ? { opacity: 0.4 } : {},
                            ]}
                            identificator="pickerColorBar"
                            value={color}
                            onChange={color => setColor(color.replace('#', ''))}
                        />
                    </MomentaryView>

                    <MomentaryView customStyles={{ flexDirection: 'row', width: '100%' }} showViewUp={1000}>
                        <DropdownNative
                            containerStyles={[
                                { width: '33%' },
                                windowWidth > 1000 ? { width: 'auto', flexGrow: 0, maxWidth: '20%' } : null,
                            ]}
                            valueWhenUniqueSelection={selectedClient}
                            indentificator="clientDropdownBarProject"
                            options={allClients as any}
                            onChange={onClientChange}
                            optionLabel="name"
                            optionValue="code"
                            placeholder="Client"
                            uniqueSelection
                            emptyFilterMessage="No Client added"
                            customStyles={upgradePlan ? { opacity: 0.4 } : null}
                            includeHovered
                        />

                        <DropdownNative
                            containerStyles={[
                                { width: '33%', paddingLeft: 10 },
                                windowWidth > 1000
                                    ? { paddingLeft: 0, width: 'auto', flexGrow: 0, maxWidth: '20%' }
                                    : null,
                            ]}
                            valueWhenUniqueSelection={selectedGroup}
                            indentificator="groupDropdownBarProject"
                            options={allGroups}
                            onChange={onGroupChange}
                            optionLabel="name"
                            optionValue="code"
                            placeholder="Group"
                            uniqueSelection
                            emptyFilterMessage="No groups added"
                            customStyles={upgradePlan ? { opacity: 0.4 } : null}
                            includeHovered
                        />

                        <View style={{ width: windowWidth <= 1000 ? '33%' : 'auto', alignItems: 'flex-end' }}>
                            <ButtonNative
                                titleStyle={{ color: '#FFF' }}
                                containerStyle={{ minWidth: windowWidth <= 500 ? 'auto' : 100 }}
                                title="Create"
                                primaryTheme
                                onPress={onHandleCreate}
                            />
                        </View>
                    </MomentaryView>
                </View>
            </>
        )
    }

    const leftContentsUpgrade = () => {
        return (
            <View
                style={{
                    padding: 10,
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                }}
            >
                <ButtonNative
                    title="Upgrade"
                    titleStyle={{ color: '#FFF' }}
                    buttonStyle={{ backgroundColor: '#3B82f6' }}
                    primaryTheme
                    onPress={() => navigation.navigate('Upgrade' as never)}
                />
            </View>
        )
    }

    return (
        <View
            style={[styles.stylesBar.boxShadow, { backgroundColor: styles.backgroundColorRow, borderRadius: 6 }]}
            onLayout={({ nativeEvent: { layout } }) => setBarWidth(layout.width)}
        >
            <View style={{ width: barWidth, display: barWidth ? 'flex' : 'none' }}>
                {upgradePlan ? <>{leftContentsUpgrade()}</> : <>{LeftContents()}</>}
            </View>
        </View>
    )
}
