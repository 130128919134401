import React, { useState, useContext } from 'react'
import { View, ScrollView, StyleSheet, Animated } from 'react-native'
import { getStyleSheet } from '../assets/theme/styles'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { Role } from '../models'
import { useFocusEffect } from '@react-navigation/native'
import { TagsService } from '../services/TagsService'
import { CreateTagBar } from '../components/common/CreateTagBar/CreateTagBar'
import { StatusTag } from '../models'
import Searchbar from '../components/common/Searchbar/Searchbar'
import DataTable from '../components/common/DataTable'
import RowTag from '../components/common/RowTag'
import SkeletonTable from '../components/common/SkeletonTable'
import FooterDialog from '../components/common/FooterDialog'
import CustomModal from '../components/common/CustomModal'
import { localStylesTag } from '../components/common/RowTag'
import useDimensions from '../hooks/useDimensions'
import useSearchBar from '../components/common/Searchbar/useSearchBar'

const headerColumns = [
    { label: 'NAME', fieldToSorteable: 'name', isSorteable: true, styles: { flexGrow: 1 } },
    { label: 'COLOR', fieldToSorteable: 'groupcolor', isSorteable: false, styles: { width: 36, marginRight: 20 } },
    { label: 'STATUS', fieldToSorteable: 'status', isSorteable: false, styles: { width: 112 } },
    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: 26 } },
]

const Tags = () => {
    const current = 'Tags'
    const styles = getStyleSheet()
    const { userRole, userSub } = useContext<any>(ThemeContext)
    const [loading, setLoading] = useState(true)
    const [allTags, setAllTags] = useState<any>([])
    const [allTmpTags, setAllTmpTags] = useState<any>([])
    const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
    const [currentDelete, setCurrentDelete] = useState<any>(null)
    const [occupiedWidth, setOccupiedWidth] = useState(0)
    const { windowWidth } = useDimensions()
    const { isCollapsed, toggleSetIsCollapsed, fadeAnim } = useSearchBar()

    const onDeleteRow = async () => {
        if (!currentDelete) return

        const row = allTags.find((item: any) => item.id === currentDelete) as any

        if (!row) {
            return
        }

        setShowConfirmationDelete(false)
        const isDeleteOk = await TagsService().deleteTagById(row.id, row._version)
        isDeleteOk && getAllTags()
    }

    const onUpdateRow = (id, newObject, type = null) => {
        if (type) {
            if (type === 'status') {
                let updateObject = {
                    id: newObject.id,
                    _version: newObject._version,
                }

                let selected = id
                if (selected) updateObject['status'] = StatusTag.ACTIVE
                else updateObject['status'] = StatusTag.ARCHIVED

                newObject = updateObject
            }
        } else {
            if (id) {
                const row = allTags.find((item: any) => item.id === id) as any

                if (!row) return

                newObject = {
                    id: row.id,
                    status: StatusTag.ARCHIVED,
                    _version: row._version,
                }
            }

            if (newObject?.groupcolor) {
                newObject['color'] = newObject.groupcolor
                delete newObject.groupcolor
            }
        }

        TagsService()
            .updateTagById(newObject)
            .then(result => {
                getAllTags()
            })
            .catch(error => {})
    }

    const onCreateRow = (newTag: any) => {
        TagsService()
            .saveTag(newTag)
            .then(response => {
                getAllTags()
            })
            .catch(error => {})
    }

    const getAllTags = () => {
        setLoading(true)
        if (userSub === '') return
        TagsService()
            .getAllTagsByOrganizationId(userSub)
            .then(listTags => {
                setAllTags(listTags)
                setAllTmpTags(listTags)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const onHideConfirmationDelete = () => {
        setShowConfirmationDelete(false)
    }

    const onShowConfirmationDelete = (id: string) => {
        setCurrentDelete(id)
        setShowConfirmationDelete(true)
    }

    useFocusEffect(
        React.useCallback(() => {
            if (userSub) getAllTags()
        }, [userRole, userSub])
    )

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current, initial: 0 }} />
                    <View
                        onLayout={({ nativeEvent: { layout } }) => setOccupiedWidth(layout.width)}
                        style={[
                            styles.baseStyleContent.container,
                            localStyles.workArea,
                            windowWidth > 1000 ? { marginHorizontal: 50 } : null,
                        ]}
                    >
                        <Searchbar
                            isCollapsed={isCollapsed}
                            setIsCollapsed={toggleSetIsCollapsed}
                            items={allTmpTags}
                            onFilteredItems={filteredItems => {
                                setAllTags(filteredItems)
                            }}
                        />

                        {userRole === Role.ADMIN ? (
                            <Animated.View
                                style={{
                                    transform: [{ translateY: fadeAnim ?? 0 }],
                                    display: isCollapsed ? 'flex' : 'none',
                                }}
                            >
                                <CreateTagBar allTags={allTags} onCreateRow={onCreateRow} />
                            </Animated.View>
                        ) : null}

                        {loading && !allTags?.length ? (
                            <SkeletonTable rowsQuantity={8} />
                        ) : (
                            <DataTable
                                customStyles={{ marginTop: 40 }}
                                headerCustomStyles={[
                                    localStylesTag.containerWhenThereOneFile,
                                    { display: windowWidth > 700 ? 'flex' : 'none' },
                                ]}
                                headerColumns={headerColumns}
                                isShowEmptyMessage={allTags?.length === 0}
                                itemsList={allTags}
                                setItemsList={setAllTags}
                                emptyMessage="Create a new tag."
                            >
                                {allTags?.map((tag: any) => {
                                    return (
                                        <RowTag
                                            style={{ width: occupiedWidth }}
                                            loadingTags={loading}
                                            key={tag.id}
                                            onUpdateRow={onUpdateRow}
                                            onShowConfirmationDelete={onShowConfirmationDelete}
                                            userRole={userRole}
                                            tag={tag}
                                        />
                                    )
                                })}
                            </DataTable>
                        )}
                    </View>
                </ScrollView>
            </View>

            <CustomModal
                header="Delete"
                message="Are you sure you want to delete entry?"
                isVisible={showConfirmationDelete}
                footerModal={<FooterDialog handleOnNo={onHideConfirmationDelete} handleOnYes={onDeleteRow} />}
                onHide={setShowConfirmationDelete}
            />
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
    searchContainer: {
        zIndex: 5005,
        flexDirection: 'row',
        marginBottom: 12,
        height: 41,
    },
    textinputSearch: {
        paddingLeft: 10,
        width: 295,
        height: 40,
        borderColor: 'gray',
        borderRadius: 6,
        fontStyle: 'italic',
    },
    iconSearch: {
        width: 22,
        height: 22,
        position: 'absolute',
        marginTop: 10,
        right: 10,
    },
})

export default Tags
