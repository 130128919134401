import React, { useContext } from 'react';
import { ThemeContext } from '../assets/theme/ThemeProvider';
import moment from 'moment';

export const useControlEditionTasks = () => {
    const { editionTimeFrame } = useContext<any>(ThemeContext);

    const validationEditionTimeFrame = (createdDateTask) => {
        switch (editionTimeFrame) {
            case 'day':
                let createdDay = moment(createdDateTask, 'YYYY MM DD hh:mm:ss').format("YYYY-MM-DD");
                let currentDay = moment().format("YYYY-MM-DD");
                return currentDay !== createdDay;
                break;
            case 'week':
                let createdWeek = moment(createdDateTask, 'YYYY MM DD hh:mm:ss').week();
                let currentWeek = moment().week();
                return currentWeek !== createdWeek;
                break;
            case 'month':
                let createdMonth = moment(createdDateTask, 'YYYY MM DD hh:mm:ss').month();
                let currentMonth = moment().month();
                return currentMonth !== createdMonth;
                break;
            default:
                return false;
        }
    }

    return {
        validationEditionTimeFrame
    }
}
