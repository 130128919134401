import React, { useMemo, useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { useFormik } from 'formik'
import { Text } from 'react-native'
import { getStyleSheet } from '../../assets/theme/styles'
import { generateClient } from 'aws-amplify/api'
import { createProblemReport } from '../../graphql/mutations'
import { ReportProblemService } from '../../services/ReportProblemService'
import { useUserProvider } from '../../context/UserProvider'
import { DropFileInput, CustomFile } from './DropFileInput/DropFileInput'
import CustomModal from './CustomModal'
import useDimensions from '../../hooks/useDimensions'
import InputText from './InputText'
import ButtonNative from './ButtonNative'

interface Props {
    visible: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const ReportIssueModal = ({ visible, setShowModal }: Props) => {
    const styles = getStyleSheet()
    const [uploadProgress, setUploadProgress] = useState<{ [id: string]: number }>({})
    const [showAlert, setShowAlert] = useState(false)
    const [completeUpload, setCompleteUpload] = useState(false)
    const [fileList, setFileList] = useState<{ file: CustomFile; id: string }[]>([])
    const { dbUserData } = useUserProvider()
    const { windowWidth } = useDimensions()

    const formik = useFormik({
        initialValues: {
            textareaValue: '',
            email: '',
        },
        validate: data => {
            const errors: any = {}
            if (!data.textareaValue) {
                errors.textareaValue = 'Issue Description is required.'
            }

            if (data.email) {
                if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
                    errors.email = 'Invalid email'
                    return errors
                }
            }

            return errors
        },
        onSubmit: async data => {
            const files: { key: string; contentType: string }[] = []

            if (fileList.length > 0) {
                const progressObj: { [id: string]: number } = {}

                for await (const fileObj of fileList) {
                    const fileResult = await ReportProblemService().putStorageFile(
                        fileObj.id,
                        fileObj.file,
                        progress => {
                            progressObj[fileObj.id] = progress
                            setUploadProgress({ ...progressObj })
                        }
                    )
                    if (fileResult) {
                        const fileKey = await ReportProblemService().getFileByName(fileResult.key)
                        files.push({
                            key: fileKey.url as unknown as string,
                            contentType: fileObj.file.type,
                        })
                    }
                }
            }

            await createJiraTicket(files, dbUserData?.email || data.email).then(
                () => {
                    setCompleteUpload(true)
                    setFileList([])
                    setShowModal(false)
                    setShowAlert(true)
                },
                err => console.log(err)
            )
            formik.resetForm()
        },
    })

    const createJiraTicket = async (
        files: { key: string; contentType: string }[],
        email: string
    ): Promise<string | null> => {
        const result: any = await generateClient().graphql({
            query: createProblemReport,
            variables: {
                input: {
                    description: formik.values.textareaValue,
                    email,
                    files: JSON.stringify(files),
                },
            },
        })

        return JSON.parse(result?.data?.createProblemReport.body)
    }

    const onSelectFile = async (files: { file: CustomFile; id: string }[]) => {
        setFileList(files)
    }

    const isFormFieldInvalid = (name: string) => !!(formik.touched[name] && formik.errors[name])

    const getFormErrorMessage = (name: string) => {
        return isFormFieldInvalid(name) ? (
            <Text style={{ color: '#ef9a9a', fontSize: 12 }}>{formik.errors[name]}</Text>
        ) : null
    }

    const DropFileInputChild = useMemo(() => {
        return (
            <DropFileInput
                onFileChange={onSelectFile}
                setCompleteUpload={setCompleteUpload}
                completeUpload={completeUpload}
                uploadProgress={uploadProgress}
            />
        )
    }, [completeUpload, uploadProgress])

    return (
        <>
            <CustomModal
                header="Report Issue"
                isVisible={visible}
                onHide={setShowModal}
                customStyles={{ width: windowWidth < 500 ? '85%' : 450 }}
            >
                <View>
                    <Text style={[styles.styleRow.colorText, stylesModal.textLabel]}>
                        Describe the issue or suggestion
                    </Text>
                    <InputText
                        value={formik.values.textareaValue}
                        placeholder="Describe the issue or suggestion"
                        multiLine
                        inputStyle={{
                            height: 'auto',
                            paddingVertical: 5,
                            backgroundColor: styles.loginInputBackground,
                        }}
                        numberOfLines={4}
                        onChange={e => {
                            formik.setFieldValue('textareaValue', e.valueOf())
                        }}
                    />
                    <View>{getFormErrorMessage('textareaValue')}</View>

                    {!dbUserData?.email && (
                        <>
                            <Text style={[styles.styleRow.colorText, stylesModal.textLabel]}>Enter your email</Text>
                            <InputText
                                id="email"
                                name="email"
                                inputMode="email"
                                value={formik.values.email}
                                inputStyle={{ backgroundColor: styles.loginInputBackground }}
                                onChange={e => {
                                    formik.setFieldValue('email', e.valueOf())
                                }}
                                placeholder={'Enter your email'}
                            />
                            <View>{getFormErrorMessage('email')}</View>
                        </>
                    )}

                    <Text style={[styles.styleRow.colorText, stylesModal.textLabel, { marginTop: 20, width: '100%' }]}>
                        Related Attachment
                    </Text>

                    {DropFileInputChild}

                    <ButtonNative
                        title="Report"
                        primaryTheme
                        disabled={!formik.values.textareaValue.trim() || formik.isSubmitting}
                        loading={formik.isSubmitting}
                        buttonStyle={{ height: 53 }}
                        onPress={() => formik.submitForm()}
                    />
                </View>
            </CustomModal>

            <CustomModal
                header="Thank you for using timerz"
                isVisible={showAlert}
                onHide={setShowAlert}
                customStyles={{ width: windowWidth < 500 ? '85%' : 450 }}
            >
                <Text
                    style={{
                        color: styles.text,
                        fontSize: 18,
                        lineHeight: 25,
                        marginTop: 30,
                    }}
                >
                    Thank you for contacting us. We are reviewing your request.
                </Text>
            </CustomModal>
        </>
    )
}

const stylesModal = StyleSheet.create({
    textLabel: {
        width: '100%',
        textAlign: 'left',
        marginVertical: 20,
        textTransform: 'uppercase',
        display: 'flex',
    },
    imagePreview: {
        borderRadius: 6,
        maxWidth: 450,
        width: '40%',
        minWidth: 260,
        height: 'auto',
        backgroundColor: '#000000',
    },
})
