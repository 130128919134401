import useDimensions from '../../hooks/useDimensions'
import { getStyleSheet } from '../../assets/theme/styles'
import { Platform, View } from 'react-native'

let Victory: any = null

if (Platform.OS === 'web') {
    const victory = require('victory')
    Victory = victory
} else {
    const victory = require('victory-native')
    Victory = victory
}

interface IChart {
    data: { axisX: string; axisY: number }[]
    axisX: string
    axisY: string
    chartContainerWidth: number
}

const Chart = ({ data, axisX, axisY, chartContainerWidth }: IChart) => {
    const { windowWidth } = useDimensions()
    const styles = getStyleSheet()
    const noValueExceds0 = data.some(value => value.axisY > 0)
    const dataNotAvailableInIos =
        (!data.length || chartContainerWidth <= 0) && (Platform.OS === 'ios' || Platform.OS === 'android')

    if (Victory === null || dataNotAvailableInIos) return <View />

    return (
        <Victory.VictoryChart
            width={chartContainerWidth}
            height={windowWidth > 600 ? 400 : 350}
            theme={Victory.VictoryTheme.material}
            domainPadding={{ x: windowWidth > 600 ? 100 : 30 }}
        >
            {/* Legenda */}
            <Victory.VictoryLegend
                x={10}
                y={2}
                orientation="horizontal"
                style={{ labels: { fontSize: 16 } }}
                data={[{ name: 'Summary', symbol: { fill: styles.backgroundColorPrimaryButton } }]}
            />

            {/* Eje Y */}
            <Victory.VictoryAxis
                domain={!noValueExceds0 ? { y: [1, 10] } : {}}
                dependentAxis
                tickLabelComponent={<Victory.VictoryLabel textAnchor="end" />}
            />

            {/* Eje X */}
            <Victory.VictoryAxis
                // invertAxis
                tickLabelComponent={
                    <Victory.VictoryLabel
                        style={[
                            {
                                fontWeight: '600',
                                fontFamily: 'Roboto-Regular',
                            },
                            windowWidth > 1000 ? { fontSize: 16 } : {},
                        ]}
                        textAnchor="end"
                        angle={-45}
                    />
                }
            />

            <Victory.VictoryBar
                alignment="middle"
                data={data}
                x={axisX}
                y={axisY}
                style={{
                    labels: { fill: '#000', fontWeight: 600 },
                    data: {
                        fill: ({ datum }) => datum.color,
                        width: data.length <= 3 && windowWidth > 600 ? 100 : 0,
                    },
                }}
                labelComponent={<Victory.VictoryLabel dy={-2} />}
                labels={({ datum }) => (datum.hours > 0 ? `${datum.pomodoroPorcentage}%` : '')}
                animate={{ duration: 500 }}
            />
        </Victory.VictoryChart>
    )
}

export default Chart
