import React from 'react'
import { View, StyleSheet } from 'react-native'
import * as Progress from 'react-native-progress'

export const LoadingSpinner = ({ loadVisible }) => {
    return (
        <View
            style={[
                loadVisible
                    ? [stylesLoading.displayModalFlex, stylesLoading.centeredView, stylesLoading.modalCenter]
                    : stylesLoading.displayModalNone,
            ]}
        >
            <Progress.Circle color="#D00500" size={120} borderWidth={14} indeterminate={true} />
        </View>
    )
}

const stylesLoading = StyleSheet.create({
    displayModalNone: {
        display: 'none',
    },
    displayModalFlex: {
        display: 'flex',
    },
    centeredView: {
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        width: '100%',
        height: '100%',
        zIndex: 999999,
    },
    modalCenter: {
        alignItems: 'center',
        justifyContent: 'center',
        borderColor: 'transparent',
    },
})
