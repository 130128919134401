import { useContext, useEffect, useRef, useState } from 'react'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { StatusProject } from '../../../models'
import { OrganizationService } from '../../../services/OrganizationService'
import { UpgradeContext } from '../../../context/upgrade'

export interface Props {
    onCreateRow: (newRow: any) => void
    allClients?: any
    allGroups?: any
    style?: any
}

const useCreateProjectBar = (props: Props) => {
    const { theme, userSub } = useContext<any>(ThemeContext)
    const { maxProjectsNumber, createdProjectsNumber } = useContext<any>(UpgradeContext)
    const projectNameRef = useRef<any>(null)
    const [name, setName] = useState('')
    const [selectedClient, setSelectedClient] = useState<any>(null)
    const [selectedGroup, setSelectedGroup] = useState<any>(null)
    const [allClients, setAllClients] = useState(null)
    const [allGroups, setAllGroups] = useState([])
    const [maxProjects, setMaxProjects] = useState(0)
    const [createdProjects, setCreatedProjects] = useState(0)
    const [upgradePlan, setUpgradePlan] = useState(false)

    const random_hex_color_code = () => {
        let n = (Math.random() * 0xfffff * 1000000).toString(16)
        return n.slice(0, 6).toUpperCase()
    }
    const [color, setColor] = useState<any>(random_hex_color_code)

    const onClientChange = value => {
        setSelectedClient(value)
    }

    const onGroupChange = value => {
        setSelectedGroup(value)
    }

    const checkProjectName = () => {
        if (projectNameRef?.current && !name) {
            projectNameRef.current.focus()
            projectNameRef.current.style.borderColor = '#D00500'
            projectNameRef.current.style.transitionDuration = '1s'

            setTimeout(() => {
                projectNameRef.current.style.borderColor = '#C6D2D9'
            }, 3000)
            return false
        }

        return true
    }

    const checkClientSelected = () => {
        if (!selectedClient) {
            console.info('Select client')
            return false
        }
        return true
    }

    const checkUpgradePlan = () => {
        let validate = createdProjectsNumber >= maxProjectsNumber
        if (validate) {
            console.info('Maximum number of projects reached. Upgrade your plan to improve your service.')
            return false
        }
        return true
    }

    const onHandleCreate = async () => {
        if (!checkProjectName()) return
        if (!checkUpgradePlan()) return

        let organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
        const projectParams = {
            name: name,
            clientID: selectedClient,
            status: StatusProject.ACTIVE,
            groupcolor: color,
            groupID: selectedGroup,
            organizationID: organizationId,
        }

        props.onCreateRow(projectParams)
        resetRow()
    }

    const resetRow = () => {
        setName('')
        setColor(random_hex_color_code())
        setSelectedClient(null)
        setSelectedGroup(null)
    }

    useEffect(() => {
        setAllClients(props.allClients)
        setAllGroups(props.allGroups)
    }, [props])

    useEffect(() => {
        setMaxProjects(maxProjectsNumber)
        setCreatedProjects(createdProjectsNumber)
        setUpgradePlan(createdProjectsNumber >= maxProjectsNumber)
    }, [maxProjectsNumber, createdProjectsNumber])

    return {
        theme,
        projectNameRef,
        name,
        color,
        selectedClient,
        selectedGroup,
        allClients,
        allGroups,
        upgradePlan,
        setName,
        setColor,
        onClientChange,
        onGroupChange,
        onHandleCreate,
    }
}

export default useCreateProjectBar
