import { View } from 'react-native'
import { useState, useEffect } from 'react'
import useDimensions from '../../hooks/useDimensions'
import { getStyleSheet } from '../../assets/theme/styles'
import ItemSkeleton from './ItemSkeleton'

type Props = {
    rowsQuantity: number
}

const SkeletonTable = ({ rowsQuantity }: Props) => {
    const [columnsQuantity, setColumnsQuantity] = useState(3)
    const styles = getStyleSheet()

    const { windowWidth } = useDimensions()

    useEffect(() => {
        if (windowWidth >= 500 && windowWidth <= 799) setColumnsQuantity(5)
        if (windowWidth >= 800 && windowWidth <= 999) setColumnsQuantity(7)
        if (windowWidth > 1000) setColumnsQuantity(8)
    }, [])

    return (
        <View
            style={{
                width: '100%',
                marginTop: 40,
                gap: 10,
                justifyContent: 'center',
            }}
        >
            {new Array(rowsQuantity).fill(null).map(() => (
                <View
                    style={{
                        width: '100%',
                        backgroundColor: styles.backgroundColorRow,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        gap: 10,
                        padding: 14,
                        borderRadius: 6,
                    }}
                >
                    {new Array(columnsQuantity).fill(null).map(() => (
                        <ItemSkeleton customStyles={{ flexGrow: 1, flexShrink: 1 }} height={25} />
                    ))}
                </View>
            ))}
        </View>
    )
}

export default SkeletonTable
