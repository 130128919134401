import { useState, useEffect, useContext } from 'react'
import { NavigationContainer } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { useAuthenticator } from '@aws-amplify/ui-react-native'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import Login from '../screens/Login'
import Signup from '../screens/Signup'
import ForgotPassword from '../screens/ForgotPassword'
import Reports from '../screens/Reports'
import ReportDetails from '../screens/ReportDetails'
import Clients from '../screens/Clients'
import Profile from '../screens/Profile'
import Projects from '../screens/Projects'
import ProjectsArchived from '../screens/ProjectsArchived'
import Team from '../screens/Team'
import TeamGroup from '../screens/TeamGroup'
// import TeamActivity from '../screens/TeamActivity'
// import TimerDone from '../screens/TimerDone'
// import TermsConditions from '../screens/TermsConditions'
import TeamInvitation from '../screens/TeamInvitation'
import Timer from '../screens/Timer/Timer'
import UpgradePlan from '../screens/UpgradePlan'
import { SafeAreaView } from 'react-native-safe-area-context'
import { StatusBar } from 'expo-status-bar'
// import CheckoutFromMobile from '../components/common/CheckoutFormMobile'
// import { Sidebar } from '../components/sections/Sidebar'
// import { useSidemenuProvider } from '../context/SidemenuProvider'
// import { TopBar } from '../components/sections/TopBar'
// import ButtonNative from '../components/common/ButtonNative'
// import AsyncStorage from '@react-native-async-storage/async-storage'
// import GroupedRecordMenu from '../components/common/GroupedRecordMenu/GroupedRecordMenu'
// import RenderOverlayPanel from '../components/common/RenderOverlayPanel'
// import MultiselectTags from '../components/common/MultiselectTags/MultiselectTags'
import Tags from '../screens/Tags'
import Settings from '../screens/Settings'
import ConfirmInvitation, {
    createOrganizationUser,
    handleUpdateInvitation,
    updateOrganizationUserDefault,
} from '../screens/ConfirmInvitation'
import Checkout from '../screens/Checkout'
import Billing from '../screens/Billing'
import ClientsArchived from '../screens/ClientsArchived'
import SuccessChanges from '../screens/SuccessChanges'
import { InvitationService } from '../services/InvitationService'
import { StatusInvitation } from '../API'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { createNavigationContainerRef } from '@react-navigation/native'
import { useUserProvider } from '../context/UserProvider'
import { fetchAuthSession } from 'aws-amplify/auth'

export enum LOCAL_STORAGE {
    USER_INVITATION = 'INVITATION_ID',
    DID_USER_CONFIRM_INVITATION = 'DID_USER_CONFIRM_INVITATION',
}

const Stack = createNativeStackNavigator()
const navigationRef = createNavigationContainerRef()

const linking = {
    prefixes: [],
    config: {
        screens: {
            Timer: 'timer',
            Profile: 'profile',
            TeamGroup: 'team-group',
            Team: 'team',
            TeamActivity: 'team-activity',
            Clients: 'clients',
            ClientsArchived: 'clients-archived',
            Projects: 'projects',
            ProjectsArchived: 'projects-archived',
            TimerDone: 'timer-done',
            Reports: 'reports',
            ReportDetails: 'report-details',
            Signup: 'signup',
            Login: 'login',
            ForgotPassword: 'forgot-password',
            Upgrade: 'upgrade',
            ConfirmInvitation: '/confirm/invitation/:confirmId',
            Tags: 'tags',
            Settings: 'settings',
            Checkout: 'checkout',
            Billing: 'billing',
            Terms: 'terms',
            SuccessChanges: 'success-changes',
            TeamInvitation: 'team-invitation',
        },
    },
}

const Navigation = () => {
    const { route } = useAuthenticator(context => [context.route])
    const { userRole, isTheRoleLoaded } = useContext<any>(ThemeContext)
    const [aviableRoutes, setAviableRoutes] = useState<JSX.Element>()
    const { setArePrivatePagesAvailable } = useUserProvider()

    const notRequireAuth = () => {
        return (
            <>
                <Stack.Screen name="Login" component={Login} options={{ headerShown: false, title: 'Login' }} />
                <Stack.Screen name="Signup" component={Signup} options={{ headerShown: false, title: 'Signup' }} />
                <Stack.Screen
                    name="ForgotPassword"
                    component={ForgotPassword}
                    options={{ headerShown: false, title: 'Forgot Password' }}
                />
                <Stack.Screen
                    name="ConfirmInvitation"
                    component={ConfirmInvitation}
                    options={{ headerShown: false, title: 'Confirm Invitation' }}
                />
            </>
        )
    }

    const requireAuth = () => (
        <>
            <Stack.Screen name="Timer" component={Timer} options={{ headerShown: false, title: 'Timer' }} />
            <Stack.Screen
                name="Settings"
                component={Settings}
                options={{ headerShown: false, title: 'Timerz | Settings' }}
            />
            <Stack.Screen
                name="Profile"
                component={Profile}
                options={{ headerShown: false, title: 'Timerz | Profile' }}
            />
            <Stack.Screen name="Tags" component={Tags} options={{ headerShown: false, title: 'Timerz | Tags' }} />
            <Stack.Screen
                name="Projects"
                component={Projects}
                options={{ headerShown: false, title: 'Timerz | Projects' }}
            />
            <Stack.Screen
                name="Clients"
                component={Clients}
                options={{ headerShown: false, title: 'Timerz | Clients' }}
            />
            <Stack.Screen
                name="Reports"
                component={Reports}
                options={{ headerShown: false, title: 'Timerz | Reports' }}
            />
            <Stack.Screen
                name="ProjectsArchived"
                component={ProjectsArchived}
                options={{ headerShown: false, title: 'Timerz | Projects Archived' }}
            />
            <Stack.Screen
                name="SuccessChanges"
                component={SuccessChanges}
                options={{ headerShown: false, title: 'Timerz | Success' }}
            />
            <Stack.Screen
                name="ReportDetails"
                component={ReportDetails}
                options={{ headerShown: false, title: 'Timerz | Report Details' }}
            />
            <Stack.Screen
                name="Upgrade"
                component={UpgradePlan}
                options={{ headerShown: false, title: 'Timerz | Upgrade' }}
            />
            <Stack.Screen name="Team" component={Team} options={{ headerShown: false, title: 'Timerz | Team' }} />
            <Stack.Screen
                name="TeamGroup"
                component={TeamGroup}
                options={{ headerShown: false, title: 'Timerz | Team Group' }}
            />
            <Stack.Screen
                name="ClientsArchived"
                component={ClientsArchived}
                options={{ headerShown: false, title: 'Timerz | Clients Archived' }}
            />
            <Stack.Screen
                name="TeamInvitation"
                component={TeamInvitation}
                options={{ headerShown: false, title: 'Timerz | Invitations' }}
            />
            <Stack.Screen
                name="Billing"
                component={Billing}
                options={{ headerShown: false, title: 'Timerz | Billing' }}
            />
            <Stack.Screen
                name="Checkout"
                component={Checkout}
                options={{ headerShown: false, title: 'Timerz | Checkout' }}
            />
            <Stack.Screen
                name="ConfirmInvitation"
                component={ConfirmInvitation}
                options={{ headerShown: false, title: 'Confirm Invitation' }}
            />
            <Stack.Screen name="Login" component={Login} options={{ headerShown: false, title: 'Login' }} />
        </>
    )

    const requireAuthUserRole = () => (
        <>
            <Stack.Screen name="Timer" component={Timer} options={{ headerShown: false, title: 'Timer' }} />
            <Stack.Screen
                name="Profile"
                component={Profile}
                options={{ headerShown: false, title: 'Timerz | Profile' }}
            />
            <Stack.Screen
                name="Projects"
                component={Projects}
                options={{ headerShown: false, title: 'Timerz | Projects' }}
            />
            <Stack.Screen
                name="ProjectsArchived"
                component={ProjectsArchived}
                options={{ headerShown: false, title: 'Timerz | Projects Archived' }}
            />
            <Stack.Screen
                name="Reports"
                component={Reports}
                options={{ headerShown: false, title: 'Timerz | Reports' }}
            />
            <Stack.Screen
                name="ReportDetails"
                component={ReportDetails}
                options={{ headerShown: false, title: 'Timerz | Report Details' }}
            />
            <Stack.Screen
                name="SuccessChanges"
                component={SuccessChanges}
                options={{ headerShown: false, title: 'Timerz | Success' }}
            />
            <Stack.Screen
                name="ConfirmInvitation"
                component={ConfirmInvitation}
                options={{ headerShown: false, title: 'Confirm Invitation' }}
            />
            <Stack.Screen name="Login" component={Login} options={{ headerShown: false, title: 'Login' }} />
        </>
    )

    const handleConfirmInvitation = async (userInvitation: string) => {
        return await InvitationService()
            .getInvitationById(userInvitation)
            .then(async invitation => {
                if (!invitation) return

                const userData = (await fetchAuthSession().then(res => res.tokens?.idToken?.payload)) as any

                if (userData.email !== invitation.email) throw new Error('The user dont has not this invitation')

                if (invitation.status === StatusInvitation.PENDING) {
                    await handleUpdateInvitation(invitation).then(async responseInvitation => {
                        if (responseInvitation) {
                            await updateOrganizationUserDefault(invitation.email).then(async responseUpdate => {
                                if (responseUpdate) {
                                    await createOrganizationUser(invitation)
                                }
                            })
                        }
                    })
                }
            })
    }

    const handleVerifyInvitationWhenUserIsNew = async (routes: any) => {
        const userInvitationId = await AsyncStorage.getItem(LOCAL_STORAGE.USER_INVITATION)
        setArePrivatePagesAvailable(true)

        if (userInvitationId)
            handleConfirmInvitation(userInvitationId).then(async () => {
                setAviableRoutes(routes)

                if (navigationRef.isReady()) {
                    //@ts-ignore "Solamente se entra este if, si se hace la autenticaciòn con email y passowrd", porque la google auth cambia la pagina
                    // Y cuando se ejecuta esta funciòn, el navigation aun no esta ready...
                    navigationRef.navigate('ConfirmInvitation', { confirmId: userInvitationId })
                    await AsyncStorage.setItem(LOCAL_STORAGE.DID_USER_CONFIRM_INVITATION, 'true')
                }

                await AsyncStorage.removeItem(LOCAL_STORAGE.USER_INVITATION)
            })
        else setAviableRoutes(routes)
    }

    useEffect(() => {
        if (!isTheRoleLoaded) return
        const routes = ['setup', 'authenticated']

        if (routes.includes(route)) {
            if (userRole) {
                if (userRole === 'USER') {
                    handleVerifyInvitationWhenUserIsNew(requireAuthUserRole)
                } else {
                    handleVerifyInvitationWhenUserIsNew(requireAuth)
                }
            } else {
                setAviableRoutes(notRequireAuth)
            }
        }
    }, [route, userRole, isTheRoleLoaded])

    return (
        <>
            {aviableRoutes ? (
                <>
                    <StatusBar animated networkActivityIndicatorVisible />
                    <SafeAreaView style={{ flex: 1, width: '100%' }}>
                        <NavigationContainer ref={navigationRef} linking={linking}>
                            <Stack.Navigator>{aviableRoutes}</Stack.Navigator>
                        </NavigationContainer>
                    </SafeAreaView>
                </>
            ) : null}
        </>
    )
}

export default Navigation
