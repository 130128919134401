import { View, Text, StyleSheet, Platform } from 'react-native'
import MessageNative from '../../MessageNative'
import { getStyleSheet } from '../../../../assets/theme/styles'
import InputText from '../../InputText'
import ButtonNative from '../../ButtonNative'
import CheckoutFormMobile from '../CheckoutFormMobile'
// import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { IPaymentMethod } from '../../../../services/SubscriptionService'

let CardCvcElement: any,
    CardExpiryElement: any,
    CardNumberElement: any = null

if (Platform.OS === 'web') {
    const {
        CardCvcElement: CardCvcelement,
        CardExpiryElement: CardExpiryelement,
        CardNumberElement: CardNumberelement,
    } = require('@stripe/react-stripe-js')
    CardCvcElement = CardCvcelement
    CardExpiryElement = CardExpiryelement
    CardNumberElement = CardNumberelement
}

interface IFormCheckout {
    error: string | null
    nameCheckout: string
    loading: boolean
    disabledPay: boolean
    totalPrice: number
    inputSeat: string
    isUpdateOnlySeats: boolean
    isUpdatePaymentMethod: boolean
    currenntPaymentMethod: IPaymentMethod | undefined
    hasErrorCouponCode: boolean
    subscriptionId: string | undefined
    handleUpdateSeats: () => Promise<void>
    setShowSuccessPay: (value: boolean) => void
    setError: (error: string) => void
    clearComponents: () => void
    handleChange: ((event: any) => any) | undefined
    handleSubmit: (event: any) => void
    setNameCheckout: (value: string) => void
}

const inputStyleStripe = {
    iconColor: '#818A94',
    margin: '0 15px 30px',
    fontweight: '500',
    fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
    fontSize: '14px',
    fontSmoothing: 'antialiased',
    ':-webkit-autofill': {
        color: '#fce883',
    },
    '::placeholder': {
        color: '#818A94',
    },
}

const FormCheckout = ({
    error,
    nameCheckout,
    disabledPay,
    loading,
    totalPrice,
    inputSeat,
    isUpdateOnlySeats,
    currenntPaymentMethod,
    isUpdatePaymentMethod,
    hasErrorCouponCode,
    subscriptionId,
    handleUpdateSeats,
    clearComponents,
    setError,
    setNameCheckout,
    handleSubmit,
    handleChange,
    setShowSuccessPay,
}: IFormCheckout) => {
    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })
    const styles = getStyleSheet()

    const payButtonTitle = isUpdatePaymentMethod ? 'UPDATE' : `PAY ${USDollar.format(totalPrice)}`
    const exp = `${currenntPaymentMethod?.card.exp_month}/${currenntPaymentMethod?.card?.exp_year
        .toString()
        .substring(2)}`

    const cardNumberPlaceholder =
        isUpdateOnlySeats && currenntPaymentMethod
            ? `XXXX XXXX XXXX ${currenntPaymentMethod?.card.last4}`
            : '1234 1234 1234 1234'

    const cvcPlaceholder = isUpdateOnlySeats && currenntPaymentMethod ? exp : 'MM/AA'

    return (
        <View style={{ gap: 14 }}>
            {error ? (
                <View style={{ width: '100%' }}>
                    <MessageNative severity="error" text={error} />
                </View>
            ) : null}
            <Text style={{ fontSize: 14, color: styles.text }}>Placeholder card</Text>

            <InputText
                placeholder="Name"
                disabled={isUpdateOnlySeats}
                onChange={e => setNameCheckout(e.valueOf())}
                value={
                    isUpdateOnlySeats && currenntPaymentMethod
                        ? String(currenntPaymentMethod?.billing_details.name)
                        : nameCheckout
                }
                inputStyle={{
                    backgroundColor: 'transparent',
                    borderWidth: 1,
                    borderColor: '#C6D2D9',
                    height: 50,
                }}
            />

            <Text style={{ fontSize: 14, color: styles.text }}>Credit card details</Text>

            {Platform.OS === 'web' ? (
                <>
                    <View style={localStyles.inputContainer}>
                        <CardNumberElement
                            id="stripe-card-number"
                            onChange={handleChange}
                            options={{
                                disabled: isUpdateOnlySeats,
                                showIcon: true,
                                placeholder: cardNumberPlaceholder,
                                style: {
                                    base: { ...inputStyleStripe, color: styles.text },
                                },
                            }}
                        />
                    </View>

                    <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                        <View style={[{ flex: 0.5 }, localStyles.inputContainer]}>
                            <CardExpiryElement
                                onChange={handleChange}
                                options={{
                                    placeholder: cvcPlaceholder,
                                    disabled: isUpdateOnlySeats,
                                    style: {
                                        base: { ...inputStyleStripe, color: styles.text },
                                    },
                                }}
                            />
                        </View>

                        <View style={[{ flex: 0.45 }, localStyles.inputContainer]}>
                            <CardCvcElement
                                onChange={handleChange}
                                options={{
                                    disabled: isUpdateOnlySeats,
                                    style: {
                                        base: { ...inputStyleStripe, color: styles.text },
                                    },
                                }}
                            />
                        </View>
                    </View>

                    <ButtonNative
                        title={payButtonTitle}
                        primaryTheme
                        titleStyle={{ fontSize: 16, fontWeight: '600' }}
                        buttonStyle={{ height: 50 }}
                        onPress={handleSubmit}
                        loading={loading}
                        disabled={disabledPay || hasErrorCouponCode}
                    />
                </>
            ) : (
                <CheckoutFormMobile
                    subscriptionId={subscriptionId}
                    isUpdateOnlySeats={isUpdateOnlySeats}
                    isUpdatePaymentMethod={isUpdatePaymentMethod}
                    cardNumberPlaceholder={cardNumberPlaceholder}
                    cvcPlaceholder={cvcPlaceholder}
                    clearComponents={clearComponents}
                    setError={setError}
                    setShowSuccessPay={setShowSuccessPay}
                    handleUpdateSeats={handleUpdateSeats}
                    inputSeat={inputSeat}
                    nameCheckout={nameCheckout}
                    totalPrice={totalPrice}
                />
            )}
        </View>
    )
}

const localStyles = StyleSheet.create({
    inputContainer: { height: 50, justifyContent: 'center', borderColor: '#C6D2D9', borderWidth: 1, padding: 15 },
})

export default FormCheckout
