import React, { useContext, useState, useEffect, useRef } from 'react'
import { Animated } from 'react-native'

const SidemenuContext = React.createContext<any>([])

const SidemenuProvider = ({ children }) => {
    const [showSidebar, setShowSidebar] = useState(false)
    const fadeAnim = useRef(new Animated.Value(-229)).current

    useEffect(() => {
        if (showSidebar) {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 500,
                useNativeDriver: true,
            }).start()
        } else {
            Animated.timing(fadeAnim, {
                toValue: -229,
                duration: 500,
                useNativeDriver: true,
            }).start()
        }
    }, [showSidebar, fadeAnim])

    return (
        <SidemenuContext.Provider
            value={{
                showSidebar,
                fadeAnim,
                setShowSidebar,
            }}
        >
            {children}
        </SidemenuContext.Provider>
    )
}

function useSidemenuProvider() {
    const context = useContext(SidemenuContext)
    if (context === undefined) {
        throw new Error('useSidemenuProvider must be used within a useSidemenuProvider')
    }
    return context
}

export { SidemenuProvider, useSidemenuProvider }
