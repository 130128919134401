import { generateClient } from 'aws-amplify/api'
import { listClients } from '../graphql/queries'
import { createClient, createClientTag, deleteClient, updateClient } from '../graphql/mutations'
import { OrganizationService } from './OrganizationService'
import { StatusClient } from '../API'
import { AuthService } from './AuthService'

export const ClientService = () => {
    const getClientsDropDown = async (userSub: string) => {
        try {
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            const response: any = await generateClient().graphql({
                query: listClients,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })
            let list = response?.data?.listClients?.items
            list.sort((a, b) => {
                return a.name - b.name
            })
            list = list.filter(f => {
                return f._deleted !== true && f.status === StatusClient.ACTIVE
            })

            const items = list.map(item => {
                return {
                    name: item.name,
                    code: item.id,
                }
            })
            return items
        } catch (error) {
            return
        }
    }

    const getClientsActive = async (userSub: string) => {
        try {
            if (userSub === '') return []
            let organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            if (organizationId === undefined) return []

            const response: any = await generateClient().graphql({
                query: listClients,
                variables: {
                    filter: { organizationID: { eq: organizationId } },
                },
            })

            return response?.data?.listClients?.items
                .filter(t => !t._deleted && t.status === StatusClient.ACTIVE)
                .map(item => {
                    return {
                        ...item,
                        TagsClient: { items: item.TagsClient.items.filter(t => !t._deleted) },
                        arrayTags: item.TagsClient.items
                            .filter(t => !t._deleted)
                            .map(t => {
                                return { id: t.tag.id, name: t.tag.name, code: t.tag.name, color: t.tag.color }
                            }),
                    }
                })
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const getClientsArchived = async (userSub: string) => {
        try {
            if (userSub === '') return []
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            if (organizationId === undefined) return []

            const response: any = await generateClient().graphql({
                query: listClients,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })

            return response?.data?.listClients?.items
                .filter(t => !t._deleted && t.status === StatusClient.ARCHIVED)
                .map(item => {
                    return {
                        ...item,
                        arrayTags: item.TagsClient.items
                            .filter(t => !t._deleted)
                            .map(t => {
                                return { id: t.tag.id, name: t.tag.name, code: t.tag.name, color: t.tag.color }
                            }),
                    }
                })
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const saveClient = async newEntry => {
        try {
            const response: any = await generateClient().graphql({
                query: createClient,
                variables: { input: newEntry },
            })
            return response?.data?.createClient
        } catch (error) {
            console.log(error)
            return
        }
    }

    const saveClientTags = async (tags, clientId) => {
        try {
            for (const tag of tags) {
                let inputClientTag = {
                    tagID: tag,
                    clientID: clientId,
                }
                await generateClient().graphql({ query: createClientTag, variables: { input: inputClientTag } })
            }
            return 'SUCCESS'
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getClientById = async clientId => {
        try {
            const response: any = await generateClient().graphql({
                query: listClients,
                variables: { filter: { id: { eq: clientId } } },
            })

            return response.data?.listClients?.items
        } catch (error) {
            console.log(error)
            return
        }
    }

    const deleteClientById = async (clientId: string, version) => {
        try {
            await generateClient().graphql({
                query: deleteClient,
                variables: { input: { id: clientId, _version: version } },
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const deleteAllTagsInClient = async (tags: any) => {
        const txnMutation: any = tags.map((txn, i) => {
            return `mutation${i}: deleteClientTag(input: {id: "${txn.id}", _version: ${txn._version}}) { id }`
        })
        try {
            await generateClient().graphql({ query: `mutation batchMutation {${txnMutation}}` })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const createTagsInClient = async (tagsTask: object) => {
        try {
            return await generateClient().graphql({ query: createClientTag, variables: { input: tagsTask } })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const updateClientById = async (newObject: any) => {
        try {
            return await generateClient().graphql({ query: updateClient, variables: { input: newObject } })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const updateClientStatus = async (clientId: string, clientStatus: StatusClient, version) => {
        try {
            return await generateClient().graphql({
                query: updateClient,
                variables: { input: { id: clientId, status: clientStatus, _version: version } },
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getAllClientsByOrganization = async () => {
        try {
            const userSub = (await AuthService().getUserSub()) ?? ''
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            const response: any = await generateClient().graphql({
                query: listClients,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })
            const list = response?.data?.listClients?.items.filter(f => {
                return f._deleted !== true
            })
            return list
        } catch (error) {
            return []
        }
    }

    return {
        getAllClientsByOrganization,
        getClientsActive,
        getClientsArchived,
        getClientsDropDown,
        getClientById,
        saveClient,
        saveClientTags,
        deleteClientById,
        deleteAllTagsInClient,
        createTagsInClient,
        updateClientById,
        updateClientStatus,
    }
}
