import React from 'react'
import { View, Text, ViewStyle, StyleProp } from 'react-native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import {
    faCheck,
    faRemove,
    faInfoCircle,
    faExclamationTriangle,
    IconDefinition,
} from '@fortawesome/free-solid-svg-icons'

type Severity = 'warn' | 'success' | 'error' | 'info'

interface IMessageNative {
    text: string
    severity: Severity
    customStyles?: StyleProp<ViewStyle>
}

const THEME: {
    [key in Severity]: { background: string; color: string; icon: IconDefinition }
} = {
    success: {
        background: '#C8E6C9',
        color: '#224a23',
        icon: faCheck,
    },
    error: {
        background: '#FFCDD2',
        color: '#73000c',
        icon: faRemove,
    },
    info: {
        background: '#B3E5FC',
        color: '#044868',
        icon: faInfoCircle,
    },
    warn: {
        background: '#FFECB3',
        color: '#6d5100',
        icon: faExclamationTriangle,
    },
}

const MessageNative = ({ text, severity, customStyles }: IMessageNative) => {
    return (
        <View
            style={[
                {
                    width: '100%',
                    backgroundColor: THEME[severity].background,
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 16,
                    borderRadius: 4,
                    padding: 8,
                    marginBottom: 10,
                },
                customStyles,
            ]}
        >
            <FontAwesomeIcon icon={THEME[severity].icon} size={18} color={THEME[severity].color} />
            <Text style={{ color: THEME[severity].color, fontSize: 14 }}>{text}</Text>
        </View>
    )
}

export default MessageNative
