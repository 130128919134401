import { useEffect, useRef, useState } from 'react'
import { Animated } from 'react-native'

const useSearchBar = () => {
    const [isCollapsed, setIsCollapsed] = useState(false)
    const fadeAnim = useRef(new Animated.Value(-500)).current

    const toggleSetIsCollapsed = () => setIsCollapsed(!isCollapsed)
    const handlerBarExpand = () => setIsCollapsed(true)

    useEffect(() => {
        if (isCollapsed) {
            Animated.timing(fadeAnim, {
                toValue: 0,
                duration: 200,
                useNativeDriver: true,
            }).start()
        } else {
            Animated.timing(fadeAnim, {
                toValue: -500,
                duration: 200,
                useNativeDriver: true,
            }).start()
        }
    }, [isCollapsed, fadeAnim])

    return {
        toggleSetIsCollapsed,
        handlerBarExpand,
        isCollapsed,
        fadeAnim,
    }
}

export default useSearchBar
