import React, { FC, ReactElement, useContext, useState, useEffect, useRef, useLayoutEffect } from 'react'
import { View, Text, Platform, StyleProp, ViewStyle } from 'react-native'
import { TopProfile } from '../common/TopProfile'
import { getStyleSheet } from '../../assets/theme/styles'
import { useNavigation } from '@react-navigation/native'
import { ThemeContext } from '../../assets/theme/ThemeProvider'
import { useUserProvider } from '../../context/UserProvider'
import { PlanContext } from '../../context/plan'
import { useSidemenuProvider } from '../../context/SidemenuProvider'
import useDimensions from '../../hooks/useDimensions'
import ButtonNative from '../common/ButtonNative'
import TabNative from '../common/Tab'
import { faArrowLeft, faBars } from '@fortawesome/free-solid-svg-icons'
import Banner from '../common/Banner'

export const TopBar: FC<any> = ({ onClickButton, children }: any) => {
    const styles = getStyleSheet()
    const navigation = useNavigation()
    const refTopBarTeam = useRef(null)
    const { active, initial, refresh } = children
    const { theme } = useContext<any>(ThemeContext)
    const { planFreeId } = useContext(PlanContext)
    const { updatePlanIdOrganization } = useUserProvider()
    const { setShowSidebar } = useSidemenuProvider()
    const [organizationPlanId, setOrganizationPlanId] = useState('')
    const { windowWidth } = useDimensions()
    const [valueSelectButton, setValueSelectButton] = useState(active)
    const { dbUserData } = useUserProvider()

    const projectsOptions = [
        { name: 'Active', value: 'Projects' },
        { name: 'Archived', value: 'ProjectsArchived' },
    ]

    const teamsOptions = [
        { name: 'Members', value: 'Team' },
        { name: 'Group', value: 'TeamGroup' },
        { name: 'Invitations', value: 'TeamInvitation' },
        // { name: "Activity", value: 'TeamActivity' }
    ]

    const reportsOptions = [
        { name: 'Summary', value: 'Reports' },
        { name: 'Detailed', value: 'ReportDetails' },
    ]

    const reportSummaryOptions = [{ name: 'Summary', value: 'Reports' }]

    const clientsOptions = [
        { name: 'Active', value: 'Clients' },
        { name: 'Archived', value: 'ClientsArchived' },
    ]

    const onChangeSelectButton = (value: any) => {
        if (value) {
            navigation.navigate(value as never)
        }
    }

    const goBackPage = () => {
        navigation.canGoBack() ? navigation.goBack() : navigation.navigate('Timer' as never)
    }

    useEffect(() => {
        if (updatePlanIdOrganization) {
            setOrganizationPlanId(updatePlanIdOrganization)
        }
    }, [updatePlanIdOrganization])

    useLayoutEffect(() => {
        if (refTopBarTeam.current && Platform.OS === 'web') {
            refTopBarTeam.current.style?.setProperty('min-width', 'fit-content', 'important')
        }
    }, [refTopBarTeam.current])

    let tobbarContent: ReactElement<any, any> = <></>

    const stylesForSubmenu: StyleProp<ViewStyle> = {
        flexGrow: 1,
        width: windowWidth < 1200 ? '100%' : 'auto',
        paddingRight: windowWidth < 1200 ? 20 : 0,
        justifyContent: windowWidth < 600 ? 'space-between' : 'flex-start',
        flexDirection: 'row',
        gap: windowWidth < 600 ? 0 : 70,
        alignItems: 'center',
    }

    const ContentButtons = ({ current, customStyles }: { current: string; customStyles?: StyleProp<ViewStyle> }) => {
        return (
            <View
                style={[
                    {
                        paddingLeft: 29,
                        minWidth: 127,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        transform: [{ translateX: -10 }],
                    },
                    customStyles,
                ]}
            >
                <ButtonNative
                    showIcon
                    containerStyle={{ display: windowWidth < 500 ? 'flex' : 'none', marginRight: 10 }}
                    icon={faBars}
                    onPress={() => setShowSidebar(true)}
                    titleStyle={{ width: 32 }}
                    buttonStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
                />

                <View
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                        flex: 1,
                    }}
                >
                    <ButtonNative
                        showIcon
                        icon={faArrowLeft}
                        containerStyle={{ display: windowWidth >= 500 ? 'flex' : 'none', marginRight: 10 }}
                        buttonStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
                        onPress={() => goBackPage()}
                        titleStyle={{ width: 32 }}
                    />

                    <Text style={[styles.styleTopBar.labelText, { fontSize: 18 }]}>{current}</Text>
                </View>
            </View>
        )
    }

    if (active === 'Reports' || active === 'ReportDetails') {
        tobbarContent = (
            <View style={stylesForSubmenu}>
                <ContentButtons current="Reports" />
                <TabNative
                    value={valueSelectButton}
                    onChange={onChangeSelectButton}
                    options={organizationPlanId === planFreeId ? reportSummaryOptions : reportsOptions}
                />
            </View>
        )
    } else if (active === 'Clients' || active === 'ClientsArchived') {
        tobbarContent = (
            <View style={stylesForSubmenu}>
                <ContentButtons current="Clients" />
                <TabNative value={valueSelectButton} onChange={onChangeSelectButton} options={clientsOptions} />
            </View>
        )
    } else if (active === 'Projects' || active === 'ProjectsArchived') {
        tobbarContent = (
            <View style={stylesForSubmenu}>
                <ContentButtons current="Projects" />
                <TabNative value={valueSelectButton} onChange={onChangeSelectButton} options={projectsOptions} />
            </View>
        )
    } else if (
        active === 'Timer' ||
        active === 'Tags' ||
        active === 'Upgrade' ||
        active === 'Checkout' ||
        active === 'Billing'
    ) {
        tobbarContent = <ContentButtons customStyles={{ flexGrow: 1 }} current={active} />
    } else if (active === 'Success') {
        tobbarContent = <ContentButtons customStyles={{ flexGrow: 1 }} current="Success" />
    } else if (active === 'Settings') {
        tobbarContent = <ContentButtons customStyles={{ flexGrow: 1 }} current="Settings" />
    } else if (active === 'Team') {
        tobbarContent = (
            <View style={stylesForSubmenu}>
                <ContentButtons current="Teams" />
                <TabNative value={valueSelectButton} onChange={onChangeSelectButton} options={teamsOptions} />
            </View>
        )
    } else if (active === 'TeamGroup') {
        tobbarContent = (
            <View style={stylesForSubmenu}>
                <ContentButtons current="Teams" />
                <TabNative value={valueSelectButton} onChange={onChangeSelectButton} options={teamsOptions} />
            </View>
        )
    } else if (active === 'TeamInvitation') {
        tobbarContent = (
            <View style={stylesForSubmenu}>
                <ContentButtons current="Teams" />
                <TabNative value={valueSelectButton} onChange={onChangeSelectButton} options={teamsOptions} />
            </View>
        )
    } else if (active === 'TeamActivity') {
        tobbarContent = (
            <View style={stylesForSubmenu}>
                <ContentButtons current="Teams" />
                <TabNative value={valueSelectButton} onChange={onChangeSelectButton} options={teamsOptions} />
            </View>
        )
    } else if (active === 'Profile') {
        tobbarContent = <ContentButtons customStyles={{ flexGrow: 1 }} current="Profile" />
    }

    return (
        <>
            <View style={{ zIndex: 30 }}>
                {dbUserData?.disabled ? <Banner /> : null}

                <View style={[{ flexDirection: 'row', backgroundColor: styles.backgroundColor }]}>
                    {tobbarContent}

                    {windowWidth > 1200 ? (
                        <>{active === 'Profile' ? <TopProfile refresh={refresh} /> : <TopProfile />}</>
                    ) : null}
                </View>
            </View>
        </>
    )
}
