import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { Platform, ScrollView, StyleSheet, Text, View, Image } from 'react-native'
import { Button, CheckBox } from '@rneui/base'
import { signIn, signInWithRedirect } from 'aws-amplify/auth'
import { isEmpty } from 'lodash'
import { LoadingSpinner } from '../components/common/LoadingSpinner'
import { getStyleSheet } from '../assets/theme/styles'
import { useFocusEffect } from '@react-navigation/native'
import { useThemeColor } from '../hooks/useThemeColor'
import Icon from 'react-native-vector-icons/FontAwesome'
import ColumnSignUp from '../components/common/ColumnSignUp'
import { OrganizationService } from '../services/OrganizationService'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import InputText from '../components/common/InputText'
import MessageNative from '../components/common/MessageNative'
import useDimensions from '../hooks/useDimensions'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { LOCAL_STORAGE } from '../navigation/Navigation'
import { useUserProvider } from '../context/UserProvider'
const logoRed = require('../assets/images/timerz_orange.png')
const logoBlack = require('../assets/images/timerz_color.png')

const Login = ({ navigation }) => {
    const styles = getStyleSheet()
    const emailRef = useRef<any>(null)
    const passRef = useRef<any>(null)
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [checkRemember, setCheckRemember] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showRequiredEmail, setShowRequiredEmail] = useState(false)
    const [showRequiredPass, setShowRequiredPass] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const { selectedReverseColorText } = useThemeColor()
    const { theme, setUserRole, setUserSub } = useContext<any>(ThemeContext)
    const { windowWidth } = useDimensions()
    const { arePrivatePagesAvailable } = useUserProvider()

    const login = async () => {
        if (!validateFields()) {
            setIsLoading(true)
            signIn({ username: email, password: pass })
                .then(() => {
                    OrganizationService()
                        .getDefaultOrganizationRole()
                        .then(async data => {
                            setUserRole(data?.role)
                            setUserSub(data?.userSub)
                            setShowError(false)
                            setIsLoading(false)

                            await AsyncStorage.removeItem('rememberEmail')

                            if (checkRemember) {
                                await AsyncStorage.setItem('rememberEmail', email)
                            }
                        })
                        .catch(error => {
                            console.log(error)
                        })
                })
                .catch(error => {
                    setIsLoading(false)
                    setShowError(true)
                    // console.log(error.message)
                    if (typeof error === 'object') setErrorMessage(error.message)
                    else setErrorMessage('User and password incorrect.')
                })
        }
    }

    useEffect(() => {
        // When an user is loggeds with email and password, the state arePrivatePagesAvailable is setting as true in navigation page
        // If there is USER_INVITATION when user log in this is redirected a Confirm Inviation page
        if (arePrivatePagesAvailable) {
            AsyncStorage.getItem(LOCAL_STORAGE.USER_INVITATION).then(userInvitation => {
                if (!userInvitation) navigation.navigate('Timer')
            })
        }
    }, [arePrivatePagesAvailable])

    const clearComponents = () => {
        setEmail('')
        setPass('')
        setCheckRemember(false)
        setShowRequiredEmail(false)
        setShowRequiredPass(false)
        setShowError(false)
    }

    const requiredStyleComponent = (elementRef: any) => {
        if (elementRef?.current) {
            elementRef.current.focus()
            if (Platform.OS === 'web') {
                elementRef.current.style.borderColor = '#E24C4C'
                elementRef.current.style.transitionDuration = '1s'
            }

            Platform.OS === 'web' &&
                setTimeout(() => {
                    elementRef.current.style.borderColor = 'transparent'
                }, 3000)
        }
    }

    const validateFields = () => {
        setShowError(false)
        if (isEmpty(email)) {
            requiredStyleComponent(emailRef)
            setShowRequiredEmail(true)
            setShowRequiredPass(false)
            setErrorMessage('Email is required.')
            return true
        }
        if (isEmpty(pass)) {
            requiredStyleComponent(passRef)
            setShowRequiredPass(true)
            setShowRequiredEmail(false)
            setErrorMessage('Password is required.')
            return true
        }
        return false
    }

    const signinGoogleProvider = () => {
        setIsLoading(true)
        signInWithRedirect({ provider: 'Google' })
            .then(() => setIsLoading(false))
            .catch(error => {
                setIsLoading(false)
                setShowError(true)
                if (typeof error === 'object') setErrorMessage(error.message)
                else setErrorMessage('Process could not be completed.')
            })
    }

    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            login()
        }
    }

    useFocusEffect(
        useCallback(() => {
            clearComponents()

            const getRememberEmail = async () => {
                const rememberEmail = await AsyncStorage.getItem('rememberEmail')
                if (rememberEmail) {
                    setEmail(rememberEmail)
                }
            }

            getRememberEmail()
        }, [])
    )

    return (
        <ScrollView
            contentContainerStyle={{
                width: '100%',
                flexDirection: windowWidth > 768 ? 'row' : 'column',
                flexGrow: 1,
            }}
        >
            <LoadingSpinner loadVisible={isLoading} />

            <View style={[styles.styleLogin.container, { flexGrow: 1 }]}>
                <View style={localStyles.container}>
                    <View style={localStyles.containerForm}>
                        {theme === 'dark' ? (
                            <Image resizeMode="contain" source={logoRed} style={localStyles.imageLogo} />
                        ) : (
                            <Image resizeMode="contain" source={logoBlack} style={localStyles.imageLogo} />
                        )}
                        <Text style={[localStyles.textTitle, styles.styleLogin.colorText]}>Log In to your Account</Text>

                        {showError ? (
                            <View style={{ width: '100%' }}>
                                <MessageNative severity="error" text={errorMessage} />
                            </View>
                        ) : null}
                        <Text style={[localStyles.textLabel, styles.styleLogin.colorText]}>Your Email</Text>
                        <View style={{ width: '100%', marginBottom: 20 }}>
                            <InputText
                                placeholder="Email"
                                inputStyle={{ backgroundColor: styles.loginInputBackground }}
                                ref={emailRef}
                                value={email}
                                onKeyPress={handleKeyDown}
                                onChange={e => {
                                    setShowRequiredEmail(false)
                                    setEmail(e.valueOf())
                                }}
                            />

                            {showRequiredEmail ? (
                                <Text style={{ color: '#ef9a9a', fontSize: 12 }}>{errorMessage}</Text>
                            ) : null}
                        </View>
                        <Text style={[localStyles.textLabel, styles.styleLogin.colorText]}>Password</Text>
                        <View style={{ width: '100%', marginBottom: 20 }}>
                            <InputText
                                inputStyle={{ backgroundColor: styles.loginInputBackground }}
                                secureTextEntry
                                placeholder="Password"
                                ref={passRef}
                                value={pass}
                                onKeyPress={handleKeyDown}
                                onChange={e => {
                                    setShowRequiredPass(false)
                                    setPass(e.valueOf())
                                }}
                            />

                            {showRequiredPass ? (
                                <Text style={{ color: '#ef9a9a', fontSize: 12 }}>{errorMessage}</Text>
                            ) : null}
                        </View>
                        <View style={localStyles.containerCheckForgot}>
                            <CheckBox
                                title="Remember Me"
                                checked={checkRemember}
                                containerStyle={[localStyles.checkboxContainer, styles.styleLogin.container]}
                                textStyle={styles.styleLogin.colorText}
                                onPress={() => setCheckRemember(!checkRemember)}
                                checkedColor={selectedReverseColorText}
                            />
                            <Text
                                style={[
                                    localStyles.labelButton,
                                    localStyles.textForgotPass,
                                    styles.styleLogin.colorText,
                                ]}
                                onPress={() => navigation.navigate('ForgotPassword')}
                            >
                                Forgot Password
                            </Text>
                        </View>
                        <Button
                            buttonStyle={[localStyles.buttonLogin, styles.styleBackground.button]}
                            titleStyle={localStyles.buttonLoginTitle}
                            containerStyle={localStyles.buttonLoginContainer}
                            title="LOG IN"
                            onPress={login}
                        />
                        <Text style={localStyles.textLabelCenter}>or log in using</Text>
                        <View style={localStyles.containerLoginButtons}>
                            <Button
                                buttonStyle={[localStyles.buttonProvider, styles.styleLogin.colorButtom]}
                                titleStyle={[localStyles.buttonProviderTitle, styles.styleLogin.colorText]}
                                containerStyle={localStyles.buttonProviderContainer}
                                icon={
                                    // @ts-ignore
                                    <Icon
                                        name="google"
                                        size={25}
                                        style={[localStyles.buttonIcon, styles.styleLogin.colorText]}
                                    />
                                }
                                title="GOOGLE"
                                onPress={signinGoogleProvider}
                                // onPress={signinGoogleProvider}
                            />
                            {/* <Button
                                    buttonStyle={[localStyles.buttonProvider, styles.styleLogin.colorButtom]}
                                    titleStyle={[localStyles.buttonProviderTitle, styles.styleLogin.colorText]}
                                    containerStyle={localStyles.buttonProviderContainer}
                                    icon={
                                        <Icon
                                            name='apple'
                                            size={25}
                                            style={[localStyles.buttonIcon, styles.styleLogin.colorText]} />
                                    }
                                    title="APPLE"
                                    onPress={() => Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple })}
                                /> */}
                        </View>
                    </View>
                </View>
            </View>

            <ColumnSignUp navigation={navigation} />
        </ScrollView>
    )
}

const localStyles = StyleSheet.create({
    containerForm: {
        maxWidth: 378,
        width: '90%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
    },
    labelButton: {
        fontSize: 14,
        lineHeight: 16.41,
        fontWeight: '500',
        marginTop: 5,
    },
    imageLogo: {
        width: 125,
        height: 41,
        marginTop: 20,
        marginBottom: 20,
    },
    containerLoginButtons: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
    },
    containerCheckForgot: {
        width: '100%',
        justifyContent: 'space-between',
        flexDirection: 'row',
        marginBottom: 20,
        zIndex: 2000,
    },
    buttonIcon: {
        fontSize: 22,
        color: '#ffffff',
        marginRight: 13,
    },
    textTitle: {
        color: '#ffffff',
        fontSize: 24,
        lineHeight: 28,
        marginBottom: 30,
        fontWeight: '700',
    },
    textLabelCenter: {
        width: '100%',
        color: '#C8D3DC',
        textAlign: 'center',
        marginBottom: 22,
        textTransform: 'lowercase',
    },
    textLabel: {
        width: '100%',
        color: '#ffffff',
        textAlign: 'left',
        marginBottom: 8,
        textTransform: 'uppercase',
    },
    textForgotPass: {
        marginTop: 0,
        lineHeight: 24,
        paddingBottom: 0,
        textDecorationLine: 'underline',
    },
    inputText: {
        height: 50,
        color: '#ffffff',
        fontSize: 14,
        borderWidth: 0,
        outlineStyle: 'none',
    },
    buttonProvider: {
        height: 58,
        borderRadius: 4,
        marginLeft: 6,
        borderStyle: 'solid',
        borderColor: '#555961',
        borderWidth: 1,
    },
    buttonProviderTitle: {
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: 16,
    },
    buttonProviderContainer: {
        marginBottom: 23,
        flex: 1,
    },
    checkboxContainer: {
        flex: 1,
        alignContent: 'flex-start',
        backgroundColor: '#202023',
        marginVertical: 0,
        marginLeft: 0,
        marginRight: 0,
        padding: 0,
        borderWidth: 0,
    },
    buttonLogin: {
        backgroundColor: '#D00500',
        height: 58,
        borderRadius: 4,
    },
    buttonLoginTitle: {
        color: '#FFF',
        fontWeight: 'bold',
        fontSize: 16,
    },
    buttonLoginContainer: {
        width: '100%',
        marginBottom: 23,
    },
})

export default Login
