import { generateClient } from 'aws-amplify/api'
import { createUser, updateUser } from '../graphql/mutations'
import { getUser, listUsers } from '../graphql/queries'
import { Role, StatusOrganizationUser } from '../models'
import { AuthService } from './AuthService'

export const UserService = () => {
    const saveUser = async (newUser: any) => {
        try {
            const response: any = await generateClient().graphql({
                query: createUser,
                variables: {
                    input: newUser,
                },
            })
            return response?.data?.createProject
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getUserById = async (userId: string) => {
        try {
            const response: any = await generateClient().graphql({ query: getUser, variables: { id: userId } })
            return response.data?.getUser
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getAllUsers = async () => {
        try {
            const response: any = await generateClient().graphql({ query: listUsers })
            return response?.data?.listUsers?.items.map(item => {
                return {
                    ...item,
                    name: item.name || item.givenName + ' ' + item.familyName,
                }
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getDropDownRoles = () => {
        const roleArray: {
            key: string
            value: string
        }[] = Object.entries(Role).map(([key, value]) => ({ key, value }))

        return roleArray.map(item => {
            return {
                name: item.value,
                code: item.value,
            }
        })
    }

    const getDropdownStatusOrganizationUser = () => {
        const statusArray: {
            key: string
            value: string
        }[] = Object.entries(StatusOrganizationUser).map(([key, value]) => ({ key, value }))

        return statusArray.map(item => {
            return {
                name: item.value,
                code: item.value,
            }
        })
    }

    const getUserByEmail = async (email: string) => {
        try {
            const response: any = await generateClient().graphql({
                query: listUsers,
                variables: {
                    filter: { email: { eq: email } },
                },
            })

            return response?.data?.listUsers?.items[0]
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getGroupsByUser = async () => {
        const userSub = await AuthService().getUserSub()
        if (!userSub) return

        return getUserById(userSub).then(response => {
            return response.Groups?.items
                ?.filter(group => !group._deleted)
                .map(item => {
                    return { groupID: item.group.id, groupName: item.group.name }
                })
        })
    }

    return {
        getAllUsers,
        getUserById,
        saveUser,
        getDropDownRoles,
        getDropdownStatusOrganizationUser,
        getUserByEmail,
        getGroupsByUser,
    }
}
