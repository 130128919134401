import { useState } from 'react'
import { useTaskProvider } from '../context/TaskProvider'
import { ITask } from '../interfaces/task.interface'
import { IUserOrganization } from '../interfaces/user-organization.interface'

export const useSearchTask = () => {
    const { getFilteredTask } = useTaskProvider()
    const [searchResults, setSearchResults] = useState<ITask[]>([])

    const search = async (q: string, userActiveOrganization: IUserOrganization | undefined) => {
        if (userActiveOrganization) {
            const filters = {
                filter: {
                    usersub: {
                        eq: userActiveOrganization.usersub,
                    },
                    organizationID: {
                        eq: userActiveOrganization.organizationID,
                    },
                },
                limit: 500,
            }

            if (q) {
                filters['filter']['searchableText'] = { contains: q.toLowerCase() }
            }

            const tasks = await getFilteredTask(filters)

            const tasksKey: string[] = []

            const formatedTask = tasks
                .slice(0, 20)
                .map(item => {
                    item.TagsTask.items = item.TagsTask.items.filter(t => !t._deleted)

                    const arrayTags = item.TagsTask.items
                        .filter(t => !t._deleted)
                        .map(t => {
                            return { id: t.tag.id, name: t.tag.name, code: t.tag.name, color: t.tag.color }
                        })
                        .sort((a, b) => a.name.normalize().localeCompare(b.name.normalize()))

                    const arrayTagsName = item.TagsTask.items
                        .filter(t => !t._deleted)
                        .map(t => {
                            return t.tag.name
                        })
                        .sort()
                        .join('-')

                    return {
                        ...item,
                        arrayTags,
                        arrayTagsName,
                        taskKey: item.name + '-' + item.projectID + '-' + arrayTagsName,
                    }
                })
                .filter(t => {
                    if (t._deleted || tasksKey.includes(t.taskKey) || !t.name) {
                        return false
                    } else {
                        tasksKey.push(t.taskKey)
                        return true
                    }
                })

            setSearchResults(formatedTask)
        }
    }

    return {
        searchResults,
        search,
    }
}
