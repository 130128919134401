import React, { useState, useContext } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { getStyleSheet } from '../assets/theme/styles'
import CardUpgradePlans from '../components/common/CardUpgradePlans/CardUpgradePlans'
import { CartContext } from '../context/cart'
import { useFocusEffect } from '@react-navigation/native'
import TabNative from '../components/common/Tab'
import { PlanContext } from '../context/plan'
import { useUserProvider } from '../context/UserProvider'
import useCheckoutSubmit from '../components/common/Checkout/useCheckoutSubmit'
import SubscriptionService from '../services/SubscriptionService'

const UpgradePlan = () => {
    const current = 'Upgrade'
    const styles = getStyleSheet()
    const { clearCart, setMemberShipTime } = useContext(CartContext)

    const { plans } = useContext(PlanContext)
    const { dbUserData } = useUserProvider()
    const { setSubscription, subscription } = useCheckoutSubmit()

    const [membership, setMembership] = useState(0)
    const membershipOptions = [
        { name: 'Monthly Billing', value: 0 },
        { name: 'Annual Billing', value: 1 },
    ]

    const changeMemberShip = value => {
        setMembership(value)
        setMemberShipTime(value)
    }

    useFocusEffect(
        React.useCallback(() => {
            clearCart()
            changeMemberShip(0)
            handleGetSubscription()
        }, [plans, dbUserData])
    )

    const handleGetSubscription = () => {
        if (!dbUserData?.customerIdStripe) return

        SubscriptionService(plans)
            .getSubscriptionByUser(dbUserData?.customerIdStripe)
            .then(response => {
                if (!response) return
                setSubscription({ ...response.subscription })
            })
            .catch(error => {
                console.error('Error', error)
            })
    }

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={[styles.styleBackground.container, { paddingBottom: 20 }]}
                    stickyHeaderIndices={[0]}
                >
                    <TopBar children={{ active: current, initial: 0 }} />
                    <View style={[localStyles.workArea]}>
                        <View style={{ width: '100%', alignItems: 'center', marginBottom: 24 }}>
                            <TabNative
                                buttonStyle={{ width: 150 }}
                                subContainerStyle={{ maxWidth: 300 }}
                                titleStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
                                value={membership}
                                onChange={changeMemberShip}
                                options={membershipOptions}
                            />
                        </View>
                        <CardUpgradePlans subscription={subscription} children={{ membership }} />
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
    },
    workArea: {
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
})

export default UpgradePlan
