/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://htqubt5dxd.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        },
        {
            "name": "stripeHandlerApi",
            "endpoint": "https://vm7pivl5ki.execute-api.us-east-1.amazonaws.com/staging",
            "region": "us-east-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://j3yjk6y2efdd3fpoaqqcpjfecq.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-5u6auc3mzbfydht4dr6htxe4a4",
    "aws_cognito_identity_pool_id": "us-east-1:d3893c9d-6748-4930-950b-93b26ac213fb",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_6vPOnx3dT",
    "aws_user_pools_web_client_id": "69m8m9j2enfk4vcan1ks7lq4th",
    "oauth": {
        "domain": "timersrn912676d2-912676d2-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:19006/,https://www.dev.app.timerz.io/,https://www.staging.app.timerz.io/",
        "redirectSignOut": "http://localhost:19006/,https://www.dev.app.timerz.io/,https://www.staging.app.timerz.io/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "timersrn85e5a921bae548a290c1c15cbcbc8a684a5ed-staging",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
