import { useEffect, useState } from 'react'
import { Dimensions } from 'react-native'

const windowDimensions = Dimensions.get('window')
const screenDimensions = Dimensions.get('screen')

const useDimensions = () => {
    const [dimensions, setDimensions] = useState({
        window: windowDimensions,
        screen: screenDimensions,
    })

    useEffect(() => {
        const subscription = Dimensions.addEventListener('change', ({ window, screen }) => {
            setDimensions({ window, screen })
        })
        return () => subscription?.remove()
    }, [])

    return {
        windowWidth: Math.round(dimensions.window.width),
        windowHeight: Math.round(dimensions.window.height),
        dimensions,
    }
}

export default useDimensions
