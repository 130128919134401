import React, { useState, useEffect } from 'react'
import { generateClient } from 'aws-amplify/api'
import { v4 as uuidv4 } from 'uuid'
import { processOrder } from '../graphql/mutations'
import { listPlans } from '../graphql/queries'
import { TypeMembership } from '../models'

const PlanContext = React.createContext<any>({})

const PlanProvider = ({ children }) => {
    const [plans, setPlans] = useState([])
    const [loading, setLoading] = useState(false)
    const [planFreeId, setPlanFreeId] = useState<any>('')

    useEffect(() => {
        fetchPlans()
    }, [])

    const checkout = async (orderDetails: any, couponCode: string) => {
        const payload = {
            id: uuidv4(),
            couponCode,
            ...orderDetails,
        }

        try {
            const result: any = await generateClient().graphql({ query: processOrder, variables: { input: payload } })
            return result?.data?.processOrder
        } catch (err) {
            console.log({ err })
            return null
        }
    }

    const getPlanFree = allPlans => {
        if (allPlans?.length > 0) {
            let planFree = allPlans?.find(plan => {
                if (plan.membership === TypeMembership.FREE) return plan
            })

            setPlanFreeId(planFree?.id)
        }
    }

    const fetchPlans = async () => {
        try {
            setLoading(true)
            const result: any = await generateClient().graphql({ query: listPlans })
            const plans = result?.data?.listPlans?.items

            getPlanFree(plans)
            setPlans(plans)
            setLoading(false)
        } catch (err) {
            console.log(err)
        }
    }

    return <PlanContext.Provider value={{ plans, loading, planFreeId, checkout }}>{children}</PlanContext.Provider>
}

export { PlanContext, PlanProvider }
