import React, { useEffect, useState } from 'react'

interface CartItem {
    id: string
    title: string
    price: number
    amount: number
}

const CartContext = React.createContext<any>([])

const CartProvider = ({ children }) => {
    const [cart, setCart] = useState<any>([])
    const [total, setTotal] = useState(0)
    const [memberShipTime, setMemberShipTime] = useState(0)
    const [priceId, setPriceId] = useState<string>()
    const [isUpdateOnlySeats, setIsUpdateOnlySeats] = useState(false)
    const [isUpdatePayment, setIsPaymentMethod] = useState(false)

    const activeUpdateOnlySeats = () => {
        setIsUpdateOnlySeats(true)
        setIsPaymentMethod(false)
    }

    const disableUpdateOnlySeats = () => {
        setIsUpdateOnlySeats(false)
    }

    const activeModePaymentMethod = () => {
        setIsPaymentMethod(true)
        setIsUpdateOnlySeats(false)
    }

    const disableModePaymentMethod = () => {
        setIsPaymentMethod(false)
    }

    useEffect(() => {
        const totalsum = [...cart].reduce((total, { amount, price }) => {
            return (total += amount * price)
        }, 0)

        setTotal(parseFloat(totalsum.toFixed(2)))
    }, [cart])

    const changeAmount = (id, amountNew) => {
        const updatedCart = [...cart].map((item: CartItem) => {
            return item?.id === id ? { ...item, amount: amountNew } : item
        })

        setCart(updatedCart)
    }

    const increaseAmount = id => {
        const updatedCart = [...cart].map((item: CartItem) => {
            return item?.id === id ? { ...item, amount: item.amount + 1 } : item
        })

        setCart(updatedCart)
    }

    const decreaseAmount = (id, amount) => {
        let updatedCart: any[] = []
        if (amount === 1) {
            updatedCart = [...cart].filter((item: CartItem) => item.id !== id)
        } else {
            updatedCart = [...cart].map((item: CartItem) => {
                return item.id === id ? { ...item, amount: item.amount - 1 } : item
            })
        }
        setCart(updatedCart)
    }

    const addToCart = plan => {
        const { id, name, price, membershipPrice } = plan
        const cartItem = [...cart].find(item => item.id === id)
        if (cartItem) {
            //increaseAmount(id);
        } else {
            const cartItems = [...cart, { id, title: name, price: membershipPrice, amount: 1 }]
            setCart(cartItems)
        }
    }

    const clearCart = () => {
        setCart([])
    }

    return (
        <CartContext.Provider
            value={{
                cart,
                total,
                memberShipTime,
                priceId,
                isUpdateOnlySeats,
                isUpdatePayment,
                disableModePaymentMethod,
                activeUpdateOnlySeats,
                disableUpdateOnlySeats,
                addToCart,
                changeAmount,
                increaseAmount,
                decreaseAmount,
                clearCart,
                setMemberShipTime,
                setPriceId,
                activeModePaymentMethod,
            }}
        >
            {children}
        </CartContext.Provider>
    )
}

export { CartProvider, CartContext }
