import { useEffect, useState, useContext } from 'react'
import { CartContext } from '../../../context/cart'
import { useNavigation } from '@react-navigation/native'
import { PlanContext } from '../../../context/plan'
import { TypeMembership } from '../../../models'
import { useUserProvider } from '../../../context/UserProvider'
import { UpgradeContext } from '../../../context/upgrade'

export const useCardUpgradePlans = props => {
    const navigation = useNavigation()
    let { membership } = props.children
    const { addToCart, setPriceId, activeUpdateOnlySeats, disableUpdateOnlySeats, disableModePaymentMethod } =
        useContext(CartContext)
    const { plans, planFreeId } = useContext(PlanContext)
    const { createdProjectsNumber, createdClientsNumber, createdGroupsNumber } = useContext<any>(UpgradeContext)
    const { updatePlanIdOrganization, setUpdatePlanIdOrganization } = useUserProvider()
    const [upgradePlans, setupgradePlans] = useState<any>([])
    const [organizationPlanId, setOrganizationPlanId] = useState<any>({})
    const [showModalConditions, setShowModalConditions] = useState(false)
    const [planPriceOrganization, setPlanPriceOrganizatin] = useState(0)

    const findPlan = (id: string) => {
        return plans.find(plan => {
            return plan.id === id
        })
    }

    const handleUpgrade = (idProduct: string) => {
        const foundPlan = findPlan(idProduct)
        if (
            createdProjectsNumber > foundPlan.max_projects ||
            createdClientsNumber > foundPlan.max_clients ||
            createdGroupsNumber > foundPlan.max_groups
        ) {
            setShowModalConditions(true)
            return
        }

        if (foundPlan) {
            disableUpdateOnlySeats()
            disableModePaymentMethod()
            foundPlan.membershipPrice = foundPlan.price
            setPriceId(foundPlan.priceIdStripe)
            addToCart(foundPlan)
            //@ts-ignore
            navigation.navigate('Checkout', { planId: idProduct } as never)
        }
    }

    const handleUpgradeSeats = (planId: string) => {
        const foundPlan = findPlan(planId)

        if (foundPlan) {
            activeUpdateOnlySeats()
            foundPlan.membershipPrice = foundPlan.price
            setPriceId(foundPlan.priceIdStripe)
            addToCart(foundPlan)
            //@ts-ignore
            navigation.navigate('Checkout', { planId, isUpdateOnlySeats: 1 } as never)
        }
    }

    const filterPlansByMembership = () => {
        let membershipType = TypeMembership.MONTHLY
        if (membership === 1) {
            membershipType = TypeMembership.YEARLY
        }

        let filterPlans = plans.filter(item => {
            return item.membership === membershipType || item.membership === TypeMembership.FREE
        })

        filterPlans.sort((s1, s2) => {
            return s1.price - s2.price
        })

        setupgradePlans(filterPlans)
    }

    useEffect(() => {
        filterPlansByMembership()
    }, [plans, membership])

    useEffect(() => {
        if (updatePlanIdOrganization) {
            setOrganizationPlanId(updatePlanIdOrganization)
        }
        if (updatePlanIdOrganization && plans.length > 0) {
            let planObject = plans.find(item => {
                return item.id === updatePlanIdOrganization
            })
            setPlanPriceOrganizatin(planObject?.price)
        }
    }, [updatePlanIdOrganization, plans])

    return [
        {
            plans,
            upgradePlans,
            membership,
            organizationPlanId,
            planFreeId,
            showModalConditions,
            planPriceOrganization,
            handleUpgrade,
            setShowModalConditions,
            handleUpgradeSeats,
        },
    ]
}
