import React, { useState, useCallback, useContext } from 'react'
import { View, ScrollView, Text, TextInput, StyleSheet } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { Button } from 'react-native-elements'
import { ProfileImage } from '../components/image/ProfileImage'
import { timezoneList } from '../utils/constants'
import { getStyleSheet } from '../assets/theme/styles'
import { useDefaultLocale } from '../hooks/useDefaultLocale'
import { AuthService } from '../services/AuthService'
import { UserService } from '../services/UserService'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { useFocusEffect, useNavigation, useRoute } from '@react-navigation/native'
import { useUserProvider } from '../context/UserProvider'
import useDimensions from '../hooks/useDimensions'
import DropdownNative from '../components/common/MultiSelectNative/MultiSelect'
import { DropdownThemeMode } from '../components/common/DropdownThemeMode'

const Profile = () => {
    const current = 'Profile'
    const styles = getStyleSheet()
    const { userRole, userSub } = useContext<any>(ThemeContext)
    const { getLocalFormat } = useDefaultLocale()
    const routeNavigation = useRoute()
    const navigation = useNavigation()
    const { windowWidth } = useDimensions()
    const [email, setEmail] = useState<string>('')
    const [givenName, setGivenName] = useState('')
    const [familyName, setFamilyName] = useState('')
    const [refresh, setRefresh] = useState(0)
    const [itemsTimeZone, setItemsTimeZone] = useState(timezoneList)
    const [selectedImage, setSelectedImage] = useState<any>(null)
    const [blockButtonSave, setBlockButtonSave] = useState(false)

    const [valueTimeZone, setValueTimeZone] = useState('')
    const [valueTimeFormat, setValueTimeFormat] = useState('')
    const [valueDateFormat, setValueDateFormat] = useState('')
    const { updateUserById } = useUserProvider()

    const [itemsDateFormat, setItemsDateFormat] = useState([
        { label: 'MM/DD/YYYY', value: 'MM/DD/YYYY' },
        { label: 'DD/MM/YYYY', value: 'DD/MM/YYYY' },
        { label: 'YYYY/MM/DD', value: 'YYYY/MM/DD' },
    ])

    const [itemsTimeFormat, setItemsTimeFormat] = useState([
        { label: '12-HOUR', value: 'false' },
        { label: '24-HOUR', value: 'true' },
    ])

    const exportProfileCsv = () => {
        const profileData = [
            { title: 'GivenName', value: givenName },
            { title: 'FamilyName', value: familyName },
            { title: 'Email', value: email },
            { title: 'TimeZone', value: valueTimeZone },
            { title: 'DateFormat', value: valueDateFormat },
            { title: 'TimeFormat', value: valueTimeFormat === 'false' ? '12-HOUR' : '24-HOUR' },
        ]
        return profileData
    }

    const updateUserProfile = async () => {
        if (!userSub) return

        setBlockButtonSave(true)
        const user = await UserService().getUserById(userSub)

        const paramsUser: any = {
            id: user?.id,
            givenName: givenName,
            familyName: familyName,
            name: givenName + ' ' + familyName,
            email: email,
            timeZone: valueTimeZone,
            dateFormat: valueDateFormat,
            timeFormat: valueTimeFormat,
            _version: user?._version,
        }

        if (selectedImage && typeof selectedImage !== 'string') {
            paramsUser['image'] = selectedImage.id
        }

        const userSave = await updateUserById(paramsUser)
        const userSaveCognito = (await AuthService().updateNameProfile(givenName, familyName)) as unknown as string

        if (selectedImage && typeof selectedImage !== 'string') {
            await AuthService().putStorageFile(selectedImage.id, selectedImage)
        }

        if (userSave !== undefined && userSaveCognito) {
            getAttributesUser()
            setRefresh(Math.random())

            const params: null | { isRedirectToTimer: boolean } = routeNavigation.params as any

            if (params?.isRedirectToTimer) {
                navigation.navigate('Timer' as never)
            }
        }

        setBlockButtonSave(false)
    }

    const getAttributesUser = () => {
        if (!userSub) return

        UserService()
            .getUserById(userSub)
            .then(user => {
                setUserAttributes(user)
                const imageName = user?.image

                if (imageName) {
                    setImageProfile(imageName).then(async file => {
                        if (file) {
                            setSelectedImage({ uri: file })
                        }
                    })
                }
            })
            .catch(() => {})
    }

    const setImageProfile = async imageName => {
        try {
            const filesList = await AuthService().getAllStorageFile()
            const elementFound = filesList?.find(e => e.key === imageName)

            if (elementFound) {
                const file = await AuthService().getStorageFileByName(imageName)
                return file
            }
            return
        } catch (error) {
            console.log(error)
            return
        }
    }

    const setUserAttributes = attributes => {
        const { dateFormatLocal, timeFormatLocal, timeZoneLocal } = getLocalFormat()
        const { givenName = '', familyName = '', email } = attributes
        const timeZone = attributes['timeZone'] || timeZoneLocal
        const dateFormat = attributes['dateFormat'] || dateFormatLocal
        const timeFormat = attributes['timeFormat'] || timeFormatLocal

        setEmail(email)
        setGivenName(givenName)
        setFamilyName(familyName)
        setValueDateFormat(dateFormat)
        setValueTimeFormat(timeFormat)
        setValueTimeZone(timeZone)
    }

    useFocusEffect(
        React.useCallback(() => {
            if (userSub && userSub !== '') {
                getAttributesUser()
            }
        }, [userRole, userSub])
    )

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current, refresh: refresh }} />

                    <View style={styles.baseStyleContent.container}>
                        <View
                            style={[
                                localStyles.profileDataContainer,
                                windowWidth > 900 ? { flexDirection: 'row' } : null,
                                windowWidth > 1200 ? { gap: 40 } : null,
                            ]}
                        >
                            <ProfileImage selectedImage={selectedImage} setSelectedImage={setSelectedImage} />

                            <View style={{ flexDirection: 'column', flexGrow: 1 }}>
                                <Text style={[localStyles.textLabel, styles.styleRow.colorText]}>First name</Text>
                                <View style={[localStyles.inputView, styles.styleRow.container]}>
                                    <TextInput
                                        style={[localStyles.inputText, styles.styleRow.colorText]}
                                        value={givenName}
                                        placeholder="First Name"
                                        placeholderTextColor={'rgb(150, 150, 150)'}
                                        onChangeText={setGivenName}
                                    />
                                </View>
                                <Text style={[localStyles.textLabel, styles.styleRow.colorText]}>Email</Text>
                                <View style={[localStyles.inputView, styles.styleRow.container]}>
                                    <TextInput
                                        style={[localStyles.inputText, styles.styleRow.colorText]}
                                        placeholder="Your Email"
                                        value={email}
                                        textContentType={'emailAddress'}
                                        placeholderTextColor={'rgb(150, 150, 150)'}
                                        editable={false}
                                    />
                                </View>
                            </View>

                            <View style={{ flexDirection: 'column', flexGrow: 1 }}>
                                <Text style={[localStyles.textLabel, styles.styleRow.colorText]}>Last name</Text>
                                <View style={[localStyles.inputView, styles.styleRow.container]}>
                                    <TextInput
                                        style={[localStyles.inputText, styles.styleRow.colorText]}
                                        value={familyName}
                                        textContentType={'familyName'}
                                        placeholder="Last Name"
                                        placeholderTextColor={'rgb(150, 150, 150)'}
                                        onChangeText={setFamilyName}
                                    />
                                </View>

                                <View
                                    style={[
                                        { gap: 20 },
                                        windowWidth > 900 ? { flexDirection: 'row', alignItems: 'flex-end' } : null,
                                    ]}
                                >
                                    <DropdownThemeMode />

                                    <Button
                                        buttonStyle={[
                                            formStyles.buttonSave,
                                            { backgroundColor: styles.backgroundColorPrimaryButton },
                                        ]}
                                        titleStyle={formStyles.buttonSaveTitle}
                                        containerStyle={{ flexGrow: 1 }}
                                        title="SAVE"
                                        onPress={async () => await updateUserProfile()}
                                        disabled={blockButtonSave}
                                    />
                                </View>
                            </View>
                        </View>

                        <Text style={[localStyles.timeDateTitle, styles.styleRow.colorText]}>Time and date</Text>

                        <View
                            style={[
                                localStyles.timeDateContainer,
                                windowWidth > 1000 ? { flexDirection: 'row' } : null,
                            ]}
                        >
                            <View style={{ flexDirection: 'column', flexGrow: windowWidth > 1000 ? 1 : 0 }}>
                                <Text style={[localStyles.textLabel, styles.styleRow.colorText]}>time zone</Text>
                                <DropdownNative
                                    indentificator="dropdownTimeZone"
                                    uniqueSelection
                                    valueWhenUniqueSelection={valueTimeZone}
                                    onChange={setValueTimeZone}
                                    customStyles={{ paddingHorizontal: 16, paddingVertical: 16, paddingLeft: 26 }}
                                    options={itemsTimeZone}
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder=""
                                />
                            </View>
                            <View style={{ flexDirection: 'column', flexGrow: windowWidth > 1000 ? 1 : 0 }}>
                                <Text style={[localStyles.textLabel, styles.styleRow.colorText]}>date format</Text>
                                <DropdownNative
                                    indentificator="dropdownDateFormat"
                                    uniqueSelection
                                    hideSearch
                                    valueWhenUniqueSelection={valueDateFormat}
                                    onChange={setValueDateFormat}
                                    customStyles={{ paddingHorizontal: 16, paddingVertical: 16, paddingLeft: 26 }}
                                    options={itemsDateFormat}
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder="mm/dd/yyyy"
                                />
                            </View>
                            <View style={{ flexDirection: 'column', flexGrow: windowWidth > 1000 ? 1 : 0 }}>
                                <Text style={[localStyles.textLabel, styles.styleRow.colorText]}>time format</Text>
                                <DropdownNative
                                    indentificator="dropdownTimeFormat"
                                    uniqueSelection
                                    hideSearch
                                    valueWhenUniqueSelection={valueTimeFormat}
                                    onChange={setValueTimeFormat}
                                    customStyles={{ paddingHorizontal: 16, paddingVertical: 16, paddingLeft: 26 }}
                                    options={itemsTimeFormat}
                                    optionLabel="label"
                                    optionValue="value"
                                    placeholder=""
                                />
                            </View>
                            {/* <View style={{ flexDirection: 'column', justifyContent: 'flex-end' }}>
                                <CSVLink
                                    className="btn btn-export"
                                    filename={'Profile-data.csv'}
                                    data={exportProfileCsv()}
                                >
                                    Export Account Data
                                </CSVLink>
                            </View> */}
                        </View>
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: { flex: 1, flexDirection: 'row' },
    subcontainer: { flex: 1, flexDirection: 'column', height: '100%' },
    profileDataContainer: { width: '100%', padding: 29, gap: 20 },
    timeDateContainer: { paddingLeft: 29, paddingRight: 29, zIndex: -1, gap: 20 },
    textLabel: {
        width: '100%',
        color: '#ffffff',
        fontSize: 10,
        textAlign: 'left',
        marginBottom: 8,
        textTransform: 'uppercase',
    },
    inputView: {
        width: '100%',
        backgroundColor: '#555961',
        borderRadius: 4,
        height: 50,
        marginBottom: 20,
        justifyContent: 'center',
        padding: 25,
    },
    inputText: {
        height: 50,
        color: '#ffffff',
        fontSize: 14,
        borderWidth: 0,
        outlineStyle: 'none',
    },
    timeDateTitle: { padding: 29, fontSize: 16, color: '#BBB', fontWeight: 600 },
    timerSettingTitle: { padding: 29, fontSize: 16, color: '#BBB' },
})

const formStyles = StyleSheet.create({
    dropDownPickerContainer: {
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        height: 50,
        backgroundColor: '#555961',
        borderRadius: 4,
    },
    dropDownPicker: {
        width: '100%',
        borderWidth: 0,
        flexDirection: 'row',
    },
    dropDownPickerItem: {
        width: '100%',
        justifyContent: 'flex-start',
    },
    dropDownPickerStyle: {
        width: '100%',
        backgroundColor: '#fafafa',
        top: 40,
    },
    inputView: {
        width: '100%',
        backgroundColor: '#555961',
        borderRadius: 4,
        height: 50,
        marginBottom: 23,
        justifyContent: 'center',
        padding: 25,
    },
    inputText: {
        height: 50,
        color: '#ffffff',
        fontSize: 14,
        fontStyle: 'italic',
        borderWidth: 0,
    },
    placeHolderPicker: {
        paddingLeft: 25,
        color: '#ffffff',
        fontStyle: 'italic',
    },
    selectedItemPicker: {
        paddingLeft: 25,
        color: '#000',
        fontStyle: 'italic',
    },
    buttonSave: {
        backgroundColor: '#D00500',
        marginTop: 20,
        height: 50,
        borderRadius: 4,
    },
    buttonSaveTitle: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 16,
    },
})

export default Profile
