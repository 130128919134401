import { generateClient } from 'aws-amplify/api'
import { cancelSubscriptionById, getInvoicesByCustomer } from '../graphql/mutations'
import moment from 'moment'

export interface Invoice {
    created: number
    createdDateFormat: string
    id: string
    invoice_pdf: string
    priceFormat: string
    seats: number
    total: number
}

export interface ISuscription {
    id: string
    planId: string
    amount: number
    planName: string
    priceFormat: string
    seats: number
    interval: string
    subscriptionsItems: {
        id: string
        quantity: string
    }
}

export interface IPaymentMethod {
    id: string
    billing_details: {
        name: string
    }
    card: {
        last4: string
        brand: string
        exp_year: number
        exp_month: number
    }
}

interface IgetSubscriptionByUser {
    invoices: Invoice[]
    nextPage: string | null
    paymentMethod: IPaymentMethod
    subscription: ISuscription
}

const SubscriptionService = (plans?) => {
    const getSubscriptionByUser = async (
        customerId: string,
        invoicesNextPage?: string | null
    ): Promise<IgetSubscriptionByUser | null> => {
        try {
            const result: any = await generateClient().graphql({
                query: getInvoicesByCustomer,
                variables: { input: { customerId, invoiceLimit: 6, lastInvoice: invoicesNextPage } },
            })

            if (!result?.data?.getInvoicesByCustomer) return null

            const { subscription, invoices, nextPage, paymentMethod } = result.data.getInvoicesByCustomer

            const newInvoices = invoices?.map(item => {
                return {
                    ...item,
                    priceFormat: '$' + (item.total / 100).toFixed(2),
                    createdDateFormat: moment.unix(item.created).format('MMM DD, YYYY'),
                }
            })

            const planName = plans.find(itemPlan => itemPlan.priceIdStripe === subscription?.planId)?.name

            return {
                invoices: newInvoices,
                nextPage,
                paymentMethod,
                subscription: subscription
                    ? {
                          ...subscription,
                          seats: subscription?.seats || 1,
                          priceFormat: '$' + (subscription.amount / 100).toFixed(2),
                          planName: subscription?.interval ? `${planName} / ${subscription?.interval}` : planName,
                      }
                    : null,
            }
        } catch (err) {
            console.log(err)
            return null
        }
    }

    const cancelSubscription = async subscriptionId => {
        const customerInput = {
            subscription: subscriptionId,
            customer: '',
            value: 0,
        }
        try {
            const result: any = await generateClient().graphql({
                query: cancelSubscriptionById,
                variables: { input: customerInput },
            })

            const bodySubscription = JSON.parse(result?.data?.cancelSubscriptionById?.body)

            return bodySubscription
        } catch (err) {
            console.log(err)
            return {}
        }
    }

    return {
        getSubscriptionByUser,
        cancelSubscription,
    }
}

export default SubscriptionService
