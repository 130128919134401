import { generateClient } from 'aws-amplify/api'
import { listTasks, getTask } from '../graphql/queries'
import moment from 'moment'
import { createTask, createTaskTag, deleteTask, deleteTaskTag, updateTask } from '../graphql/mutations'
import { OrganizationService } from './OrganizationService'
import { TypeTask } from '../models'

export const TaskService = () => {
    const groupTasks = tasks => {
        let taskGroup = new Array<any>()
        for (const task of tasks) {
            const taskFound = taskGroup.find(e => e.name === task.name && e.projectID === task.projectID)
            if (taskFound) {
                taskFound.duration =
                    task.type === TypeTask.FOCUS
                        ? moment.duration(task.time).add(taskFound.duration)
                        : moment.duration('00:00:00').add(taskFound.duration)
                taskFound.tasks.push(task)
            } else {
                const newTask = {
                    id: task.id,
                    name: task.name || '-Task no name-',
                    organizationID: task.organizationID,
                    projectID: task.projectID,
                    projectName: task.project?.name || task.projectName,
                    arrayTags: task.arrayTags,
                    pomodoro: 0,
                    duration: task.type === TypeTask.FOCUS ? moment.duration(task.time) : moment.duration('00:00:00'),
                    billedTime: '',
                    tasks: new Array<any>(),
                    hasTimeEdited: task.hasTimeEdited,
                    _version: task._version,
                }

                newTask.tasks.push(task)
                taskGroup.push(newTask)
            }
        }

        taskGroup = taskGroup.map(p => {
            return {
                ...p,
                billedTime: moment.utc(p.duration.asMilliseconds()).format('HH:mm:ss'),
                pomodoro: p.tasks.filter(e => e.status === 'COMPLETED').length,
            }
        })

        return taskGroup
    }

    const getAllTaskByUser = async (userSub: string, dateRange: any) => {
        if (!userSub) return

        let organizationId = await OrganizationService().getDefaultOrganizationId(userSub)

        try {
            const response: any = await generateClient().graphql({
                query: listTasks,
                variables: {
                    limit: 1300,
                    filter: {
                        usersub: { eq: userSub },
                        organizationID: { eq: organizationId },
                        createdAt: { between: dateRange },
                    },
                },
            })

            return response?.data?.listTasks?.items
                .filter(t => !t._deleted)
                .map(item => {
                    let billedTime = moment.duration('00:00:00')
                    item.TagsTask.items = item.TagsTask.items.filter(t => !t._deleted)

                    return {
                        ...item,
                        arrayTags: item.TagsTask.items
                            .filter(t => !t._deleted)
                            .map(t => {
                                return { id: t.tag.id, name: t.tag.name, code: t.tag.name, color: t.tag.color }
                            })
                            .sort((a, b) => a.name.normalize().localeCompare(b.name.normalize())),
                        arrayTagsName: item.TagsTask.items
                            .filter(t => !t._deleted)
                            .map(t => {
                                return t.tag.name
                            })
                            .sort()
                            .join('-'),
                        billedTime: moment.utc(billedTime.as('milliseconds')).format('HH:mm:ss'),
                    }
                })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const createNewTask = async (task: object) => {
        try {
            return await generateClient().graphql({ query: createTask, variables: { input: task } })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const createTagsInTask = async (tagsTask: object) => {
        try {
            return await generateClient().graphql({ query: createTaskTag, variables: { input: tagsTask } })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const updateTaskById = async (task: object) => {
        try {
            return await generateClient().graphql({ query: updateTask, variables: { input: task } })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const deleteTaskById = async (id: string, version) => {
        try {
            return await generateClient().graphql({
                query: deleteTask,
                variables: { input: { id: id, _version: version } },
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const deleteTagsInTask = async (id: string) => {
        try {
            return await generateClient().graphql({ query: deleteTaskTag, variables: { input: { id: id } } })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const deleteAllTagsInTask = async (tags: any) => {
        const txnMutation: any = tags.map((txn, i) => {
            return `mutation${i}: deleteTaskTag(input: {id: "${txn.id}", _version: ${txn._version}}) { id }`
        })

        try {
            await generateClient().graphql({ query: `mutation batchMutation {${txnMutation}}` })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getTaskById = async taskId => {
        try {
            const response: any = await generateClient().graphql({ query: getTask, variables: { id: taskId } })
            return response?.data?.getTask
        } catch (error) {
            console.log(error)
            return
        }
    }

    return {
        getAllTaskByUser,
        deleteTaskById,
        createTagsInTask,
        updateTaskById,
        deleteTagsInTask,
        deleteAllTagsInTask,
        createNewTask,
        groupTasks,
        getTaskById,
    }
}
