import React, { useEffect, useState, useContext } from 'react'
import { ScrollView, View, StyleSheet, Text, Dimensions } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { getStyleSheet } from '../assets/theme/styles'
import { PlanContext } from '../context/plan'
import { useUserProvider } from '../context/UserProvider'
import { CartContext } from '../context/cart'
import { useFocusEffect } from '@react-navigation/native'
import { OrganizationService } from '../services/OrganizationService'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { UpgradeContext } from '../context/upgrade'
import SubscriptionService from '../services/SubscriptionService'
import DataTable from '../components/common/DataTable'
import RowBilling from '../components/common/RowBilling'
import SkeletonTable from '../components/common/SkeletonTable'
import CustomModal from '../components/common/CustomModal'
import FooterDialog from '../components/common/FooterDialog'
import useCheckoutSubmit from '../components/common/Checkout/useCheckoutSubmit'
import ButtonNative from '../components/common/ButtonNative'
import { TypeMembership } from '../API'
import useDimensions from '../hooks/useDimensions'

const staticWindowWidth = Dimensions.get('screen').width

const headerColumns = [
    {
        label: 'DATE',
        fieldToSorteable: '',
        isSorteable: false,
        styles: { width: staticWindowWidth > 600 ? '32%' : 'auto', flexGrow: 1, paddingLeft: 5 },
    },
    {
        label: 'SEATS',
        fieldToSorteable: '',
        isSorteable: false,
        styles: { width: staticWindowWidth > 600 ? '32%' : 'auto', flexGrow: 1, justifyContent: 'center' },
    },
    {
        label: 'TOTAL',
        fieldToSorteable: '',
        isSorteable: false,
        styles: {
            width: staticWindowWidth > 600 ? '32%' : 'auto',
            flexGrow: 1,
            justifyContent: 'flex-end',
            paddingRight: 40,
        },
    },
    {
        label: '',
        fieldToSorteable: '',
        isSorteable: false,
        styles: { marginRight: staticWindowWidth > 600 ? 20 : 0, width: 46 },
    },
]

const Billing = ({ navigation }) => {
    const current = 'Billing'
    const styles = getStyleSheet()
    const { plans, planFreeId } = useContext(PlanContext)
    const { userSub, userRole } = useContext(ThemeContext)
    const [invoices, setInvoices] = useState<any>([])
    const [loading, setLoading] = useState(true)
    const [loadingMore, setLoadingMore] = useState(true)
    const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
    const [currentDelete, setCurrentDelete] = useState<any>(null)
    const { dbUserData, setUpdatePlanIdOrganization } = useUserProvider()
    const { setMaxUsersNumber, setMaxProjectsNumber, setMaxClientsNumber, setMaxGroupsNumber } =
        useContext<any>(UpgradeContext)
    const { clearCart } = useContext(CartContext)
    const { setSubscription, subscription } = useCheckoutSubmit()
    const [organization, setOrganization] = useState<any>(null)
    const [invoicesNextPage, setInvoicesNextPage] = useState<string | null>(null)
    const { windowWidth } = useDimensions()

    const onDeleteRow = async () => {
        if (!currentDelete) return
        setShowConfirmationDelete(false)

        SubscriptionService()
            .cancelSubscription(currentDelete)
            .then(response => {
                saveChangesOrganization().then(responseOrganization => {
                    if (responseOrganization) {
                        getSubscriptions(true)
                    }
                })
            })
            .catch(error => {
                console.error('Cancel', error)
            })
    }

    const onHideConfirmationDelete = () => {
        setShowConfirmationDelete(false)
    }

    const onShowConfirmationDelete = (id: any) => {
        setCurrentDelete(id)
        setShowConfirmationDelete(true)
    }

    const getSubscriptions = async (isResetInvoices?: boolean) => {
        if (!invoices.length) {
            setLoading(true)
        } else setLoadingMore(true)

        if (!dbUserData?.customerIdStripe) {
            setLoading(false)
            setLoadingMore(false)
            return
        }

        SubscriptionService(plans)
            .getSubscriptionByUser(dbUserData?.customerIdStripe, isResetInvoices ? null : invoicesNextPage)
            .then(response => {
                setLoading(false)
                setLoadingMore(false)
                console.log({ response })

                if (!response) return
                setInvoices(isResetInvoices ? response?.invoices : [...invoices, ...response?.invoices])
                setSubscription({ ...response.subscription })
            })
            .catch(error => {
                setLoading(false)
                setLoadingMore(false)
                console.error('Error', { error })
            })
    }

    useEffect(() => {
        setInvoicesNextPage(invoices?.at(-1)?.id)
    }, [invoices])

    const saveChangesOrganization = async (planID: string = planFreeId) => {
        const updateObject = {
            id: organization.id,
            planID,
            _version: organization._version,
        }

        try {
            const response = await OrganizationService().updateOrganizationById(updateObject)

            if (response) {
                setUpdatePlanIdOrganization(response?.planID)
                setMaxUsersNumber(response?.plan?.max_users)
                setMaxProjectsNumber(response?.plan?.max_projects)
                setMaxClientsNumber(response?.plan?.max_clients)
                setMaxGroupsNumber(response?.plan?.max_groups)
                return true
            } else {
                return false
            }
        } catch (e) {
            console.log('error', e)
            return false
        }
    }

    const getOrganizationDefault = () => {
        OrganizationService()
            .getDefaultOrganizationName(userSub)
            .then(organizationUser => {
                setOrganization(organizationUser?.organization)
            })
            .catch(error => {})
    }

    useEffect(() => {
        if (userSub) {
            getOrganizationDefault()
        }
    }, [userSub])

    useFocusEffect(
        React.useCallback(() => {
            clearCart()

            if (dbUserData) {
                setLoading(true)
                getSubscriptions()
            }
        }, [dbUserData])
    )

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current, initial: 0 }} />
                    <View style={[styles.baseStyleContent.container, localStyles.workArea]}>
                        <View
                            style={[
                                { display: !loading ? 'flex' : 'none', flexDirection: 'row' },
                                windowWidth <= 880 ? { flexDirection: 'column', gap: 20 } : null,
                            ]}
                        >
                            <View style={{ gap: 4, flexGrow: 1 }}>
                                <View
                                    style={{
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontWeight: '700',
                                            color: styles.text,
                                            fontSize: 16,
                                        }}
                                    >
                                        {'SUBSCRIPTION: '}
                                    </Text>
                                    <Text style={{ color: styles.text, fontSize: 16 }}>
                                        {subscription?.planName || '- No subscription -'}
                                    </Text>
                                </View>

                                <View
                                    style={{
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                    }}
                                >
                                    <Text
                                        style={{
                                            fontWeight: '700',
                                            color: styles.text,
                                            fontSize: 16,
                                        }}
                                    >
                                        {'PRICE: '}
                                    </Text>
                                    <Text style={{ color: styles.text, fontSize: 16 }}>
                                        {subscription?.priceFormat || '- No subscription -'}
                                    </Text>
                                </View>
                            </View>

                            <View style={{ flexDirection: 'row', gap: 10 }}>
                                <ButtonNative
                                    title="Change PLan"
                                    titleStyle={{ fontSize: windowWidth <= 800 ? 11 : 14 }}
                                    buttonStyle={[
                                        { borderColor: 'transparent', borderWidth: 1 },
                                        windowWidth <= 800 ? { paddingHorizontal: 5, paddingVertical: 5 } : null,
                                    ]}
                                    primaryTheme
                                    onPress={() => navigation.navigate('Upgrade' as never)}
                                />
                                <ButtonNative
                                    title="Edit Payment"
                                    titleStyle={{ color: '#000', fontSize: windowWidth <= 800 ? 11 : 14 }}
                                    buttonStyle={[
                                        { backgroundColor: '#fff', borderColor: 'gray', borderWidth: 1 },
                                        windowWidth <= 800 ? { paddingHorizontal: 5, paddingVertical: 5 } : null,
                                    ]}
                                    disabled={subscription?.planName === TypeMembership.FREE}
                                    onPress={() => navigation.navigate('Checkout', { isUpdatePayment: 1 } as never)}
                                />
                                <ButtonNative
                                    titleStyle={{ color: '#000', fontSize: windowWidth <= 800 ? 11 : 14 }}
                                    title="Cancel Subscription"
                                    buttonStyle={[
                                        { backgroundColor: '#fff', borderColor: 'gray', borderWidth: 1 },
                                        windowWidth <= 800 ? { paddingHorizontal: 5, paddingVertical: 5 } : null,
                                    ]}
                                    disabled={subscription?.planName === TypeMembership.FREE}
                                    onPress={() => onShowConfirmationDelete(subscription?.id)}
                                />
                            </View>
                        </View>

                        <Text style={{ color: styles.text, fontSize: 12, marginTop: 30, paddingLeft: 10 }}>
                            INVOICES:
                        </Text>

                        {loading && !invoices?.length ? (
                            <SkeletonTable rowsQuantity={5} />
                        ) : (
                            <DataTable
                                emptyMessage="No invoices found."
                                customStyles={{ marginTop: 15 }}
                                headerColumns={headerColumns as any}
                                headerCustomStyles={{ padding: 15, display: windowWidth >= 600 ? 'flex' : 'none' }}
                                isShowEmptyMessage={invoices?.length === 0}
                                itemsList={invoices}
                                setItemsList={setInvoices}
                            >
                                {invoices?.map(suscription => (
                                    <RowBilling
                                        key={suscription.id}
                                        onShowConfirmationDelete={onShowConfirmationDelete}
                                        userRole={userRole}
                                        billing={suscription}
                                    />
                                ))}
                            </DataTable>
                        )}

                        <View style={{ alignItems: 'center' }}>
                            <ButtonNative
                                title="Load more..."
                                containerStyle={{
                                    borderWidth: 1,
                                    borderColor: '#64748ba9',
                                    width: 120,
                                    marginTop: 30,
                                    marginBottom: 10,
                                }}
                                disabled={loadingMore}
                                buttonStyle={{ backgroundColor: styles.buttonLoadMore }}
                                titleStyle={{ color: styles.text }}
                                onPress={() => getSubscriptions()}
                            />
                        </View>
                    </View>
                </ScrollView>
            </View>

            <CustomModal
                header="Cancel"
                message="Are you sure you want to cancel subscription?"
                isVisible={showConfirmationDelete}
                footerModal={<FooterDialog handleOnNo={onHideConfirmationDelete} handleOnYes={onDeleteRow} />}
                onHide={() => onHideConfirmationDelete()}
            />

            {/* <CustomModal header="Change Plan" isVisible={showEditPlan} onHide={setShowEditPlan}>
                <View style={[styles.baseStyleContent.container, localStyles.workArea]}>
                    <View style={{ width: '100%', alignItems: 'center', marginBottom: 24 }}>
                        <TabNative
                            buttonStyle={{ width: 150 }}
                            titleStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
                            value={membership}
                            onChange={changeMemberShip}
                            options={membershipOptions}
                        />
                    </View>
                    <View style={{ alignItems: 'center' }}>
                        <CardPlans
                            membership={membership}
                            selectedSubscription={subscription}
                            onSelectPlan={planId => {
                                updateOrganizationPlan(planId)
                            }}
                        />
                    </View>
                </View>
            </CustomModal> */}
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
        height: '100%',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
})

export default Billing
