import moment from 'moment'
import { StatusTask, TypeTask } from '../API'
import { ITask } from '../interfaces/task.interface'
import { Platform } from 'react-native'
import uuid from 'react-native-uuid'

const checkPomodoroCycleByTime = (taskTime, pomodoroTime) => {
    return moment.duration(taskTime).asSeconds() >= pomodoroTime
}

export const outlineStyle = Platform.OS === 'web' ? { outline: 0 } : null

export const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp', 'svg']
export const videoExtensions = ['mp4', 'mov', 'avi', 'mkv', 'wmv', 'flv', 'webm']

export const allExtensions = [...imageExtensions, ...videoExtensions]

export function secondsToString(miliseconds: number) {
    const seconds = miliseconds / 1000

    const hour = Math.floor(seconds / 3600)
    const hourStr = hour < 10 ? '0' + hour : hour
    const minute = Math.floor((seconds / 60) % 60)
    const minuteStr = minute < 10 ? '0' + minute : minute
    const second = seconds % 60
    const secondStr = second < 10 ? '0' + second : second
    return hourStr + ':' + minuteStr + ':' + secondStr
}

export const formatTasksForTable = (
    tasksData: ITask[],
    timeFormat: string,
    changeTimeFormat?: boolean,
    pomodoroTime?: number
) => {
    let formatedTaskArr: any[] = []

    if (changeTimeFormat) {
        formatedTaskArr = tasksData
    } else {
        const formatedTask = tasksData
            ?.filter(t => t && !t._deleted)
            .map(item => {
                const billedTime = moment.duration('00:00:00')
                item.TagsTask.items = item.TagsTask.items.filter(t => !t._deleted)

                return {
                    ...item,
                    arrayTags: item.TagsTask.items
                        .filter(t => !t._deleted)
                        .map(t => {
                            return { id: t.tag.id, name: t.tag.name, code: t.tag.name, color: t.tag.color }
                        })
                        .sort((a, b) => a.name.normalize().localeCompare(b.name.normalize())),
                    arrayTagsName: item.TagsTask.items
                        .filter(t => !t._deleted)
                        .map(t => {
                            return t.tag.name
                        })
                        .sort()
                        .join('-'),
                    billedTime: moment.utc(billedTime.as('milliseconds')).format('HH:mm:ss'),
                }
            })

        formatedTaskArr = formatedTask
    }

    let tasks = formatedTaskArr
        ?.filter(item => item.type === TypeTask.FOCUS)
        .map((item: any, index: number) => {
            let project = {}
            if (item.project) {
                project = {
                    clientId: item?.project?.clientID || item?.project?.clientId,
                    clientName: item?.project?.client?.name || item?.project?.clientName,
                    projectID: item?.project?.id || item?.project?.projectID,
                    projectName: item?.project?.name || item?.project?.projectName,
                    projectColor: item?.project?.groupcolor || item?.project?.projectColor,
                }
            }

            return {
                id: item.id,
                indexId: index,
                staticId: uuid.v4(),
                indexName:
                    moment(item.createdAt, 'YYYY MM DD hh:mm:ss Z').format('DD') + '_' + item.name.replace(/\s+/g, ''),
                name: item.name,
                type: item.type,
                projectID: item.projectID,
                project,
                arrayTags: item.arrayTags,
                pomodoro:
                    item.status === StatusTask.COMPLETED || checkPomodoroCycleByTime(item.time, pomodoroTime) ? 1 : 0,
                groupTask: [],
                billedTime: item.time,
                status: item.status,
                TagsTask: item.TagsTask,
                TagsName: item.arrayTagsName,
                hasTimeEdited: item.hasTimeEdited,
                time: item.time,
                created: item.createdAt,
                createdAt: item.createdAt,
                createdNewDate: new Date(item.createdAt),
                createdTypeDate: moment(item.createdAt),
                createdDateFormat: moment(item.createdAt, 'YYYY MM DD hh:mm:ss Z').format('MMM DD, YYYY'),
                createdTimeFormat: moment.utc(item.createdAt, 'YYYY MM DD hh:mm:ss').format(timeFormat),
                createdTimeEndFormat: moment
                    .utc(item.createdAt, 'YYYY MM DD hh:mm:ss')
                    .add(moment.duration(item.time))
                    .format(timeFormat),
                _version: item._version,
                _lastChangedAt: item._lastChangedAt,
            }
        })

    if (!tasks) return { tasks: [], tasksGroup: [] }

    tasks = tasks.sort((a, b) => {
        return moment(b.createdTypeDate).diff(a.createdTypeDate)
    })

    const tasksGroup = groupTasks(tasks).map(task => {
        const { duration, createdTimeEndFormat } = task.groupTask.reduce(
            (acc, currentTask) => {
                acc.duration += moment.duration(currentTask.time)

                if (!acc?.createdTimeEndFormat.length) {
                    acc.createdTimeEndFormat = currentTask.createdTimeEndFormat
                    return acc
                }

                if (acc.createdTimeEndFormat > currentTask.createdTimeEndFormat) return acc
                else {
                    acc.createdTimeEndFormat = currentTask.createdTimeEndFormat
                    return acc
                }
            },
            { duration: 0, createdTimeEndFormat: '' }
        )

        return {
            ...task,
            createdTimeEndFormat,
            totalTime: secondsToString(duration),
            time: secondsToString(duration),
        }
    })

    return tasksGroup
}

export const handleRecalculateDates = (taskList: any, taskToModified: any): any[] => {
    const parentTask = taskList.find(parentTask => parentTask.id.split(',').includes(taskToModified.id))

    const { createdTimeEndFormat } = parentTask.groupTask.reduce(
        (acc: any, currentTask: any) => {
            if (!acc?.createdTimeEndFormat.length) {
                acc.createdTimeEndFormat = currentTask.createdTimeEndFormat
                return acc
            }

            if (acc.createdTimeEndFormat > currentTask.createdTimeEndFormat) return acc
            else {
                acc.createdTimeEndFormat = currentTask.createdTimeEndFormat
                return acc
            }
        },
        { createdTimeEndFormat: '', createdTimeFormat: '' }
    )

    const { createdTimeFormat } = parentTask.groupTask.reduce(
        (acc: any, currentTask: any) => {
            if (!acc?.createdTimeFormat.length) {
                acc.createdTimeFormat = currentTask.createdTimeFormat
                return acc
            }

            if (acc.createdTimeFormat < currentTask.createdTimeFormat) return acc
            else {
                acc.createdTimeFormat = currentTask.createdTimeFormat
                return acc
            }
        },
        { createdTimeFormat: '' }
    )

    const newData = taskList.map(task => {
        const newTask = {
            ...task,
            groupTask: task.groupTask.sort((a, b) => {
                return moment(b.createdTypeDate).diff(a.createdTypeDate)
            }),
        }

        if (task.id.split(',').includes(taskToModified.id)) {
            return {
                ...newTask,
                createdTimeEndFormat,
                createdTimeFormat,
            }
        }
        return newTask
    })

    return newData
}

const groupTasks = tasks => {
    let helper = {}
    return tasks.reduce((r, o) => {
        const key = o.name + '-' + o.projectID + '-' + o.TagsName + '-' + o.createdDateFormat

        if (!helper[key]) {
            helper[key] = Object.assign({}, o)
            helper[key].totalTime =
                o.type === TypeTask.FOCUS
                    ? moment.utc(moment.duration(o.billedTime).asMilliseconds()).format('HH:mm:ss')
                    : moment.duration('00:00:00')
            helper[key].createdTimeEndFormat = o.createdTimeEndFormat
            helper[key].createdTimeFormat = o.createdTimeFormat
            o.parentId = helper[key].id
            helper[key].groupTask.push(o)
            r.push(helper[key])
        } else {
            helper[key].pomodoro += o.pomodoro
            helper[key].totalTime =
                o.type === TypeTask.FOCUS
                    ? moment
                          .utc(moment.duration(o.billedTime).add(helper[key].totalTime).asMilliseconds())
                          .format('HH:mm:ss')
                    : moment
                          .utc(moment.duration('00:00:00').add(helper[key].totalTime).asMilliseconds())
                          .format('HH:mm:ss')
            helper[key].createdTimeFormat = o.createdTimeFormat
            o.parentId = helper[key].id
            helper[key].groupTask.push(o)
            helper[key].id = helper[key].id.concat(',', o.id)
        }

        return r
    }, [])
}

export const validationEditionTimeFrame = (editionTimeFrame: string, createdDateTask: string) => {
    switch (editionTimeFrame) {
        case 'day':
            let createdDay = moment(createdDateTask, 'YYYY MM DD hh:mm:ss').format('YYYY-MM-DD')
            let currentDay = moment().format('YYYY-MM-DD')
            return currentDay !== createdDay
            break
        case 'week':
            let createdWeek = moment(createdDateTask, 'YYYY MM DD hh:mm:ss').week()
            let currentWeek = moment().week()
            return currentWeek !== createdWeek
            break
        case 'month':
            let createdMonth = moment(createdDateTask, 'YYYY MM DD hh:mm:ss').month()
            let currentMonth = moment().month()
            return currentMonth !== createdMonth
            break
        default:
            return false
    }
}

export function transformNumberToTime(num: number) {
    if (num > 3599) {
        let sec_num = parseInt(num.toString(), 10)
        let hours = Math.floor(sec_num / 3600)
        let minutes = Math.floor((sec_num - hours * 3600) / 60)
        let seconds = sec_num - hours * 3600 - minutes * 60

        let hourString = hours > 9 ? hours.toString() : '0' + hours.toString()
        let minuteString = minutes > 9 ? minutes.toString() : '0' + minutes.toString()
        let secondString = seconds > 9 ? seconds.toString() : '0' + seconds.toString()
        return hourString + ':' + minuteString + ':' + secondString
    } else {
        if (num > 59) {
            let minutes = Math.floor(num / 60)
            let seconds = num % 60

            let minuteString = minutes > 9 ? minutes.toString() : '0' + minutes.toString()
            let secondString = seconds > 9 ? seconds.toString() : '0' + seconds.toString()
            return '00' + ':' + minuteString + ':' + secondString
        } else {
            let seconds = Math.trunc(num)
            let secondString = seconds > 9 ? seconds.toString() : '0' + seconds.toString()

            return '00' + ':' + '00' + ':' + secondString
        }
    }
}

export const mocReportData = [
    { hours: 4, minutes: 45, pomodoroTime: 10, axisX: 'jun 12' },
    { hours: 2, minutes: 30, pomodoroTime: 1.7, axisX: 'may 10' },
    { hours: 10, minutes: 10, pomodoroTime: 19, axisX: 'jun 7' },
]
