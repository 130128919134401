import {
    View,
    Text,
    Pressable,
    GestureResponderEvent,
    TextInput,
    StyleSheet,
    Platform,
    ViewStyle,
    StyleProp,
    ScrollView,
} from 'react-native'
import { Hoverable } from 'react-native-web-hover'
import TagItem from './Tag'
import { useContext, useEffect, useRef, useState } from 'react'
import { getStyleSheet } from '../../../assets/theme/styles'
import { useOverlayProvider } from '../../../context/OverlayProvider'
import chroma from 'chroma-js'
import { inputStyle } from '../InputText'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import useDimensions from '../../../hooks/useDimensions'
import ModalOverlay from '../ModalOverlay'
import ItemSkeleton from '../ItemSkeleton'

export interface ITagFormated {
    color: string
    label: string
    value: string
}

export interface ITag {
    color: string
    code: string
    name: string
    id: string
}

interface IMultiSelectTag {
    tags: ITag[]
    value: ITagFormated[] | null
    maximunTag?: number
    disabled?: boolean
    indentificator: string
    customStyles?: StyleProp<ViewStyle>
    skeletonStyles?: StyleProp<ViewStyle>
    loading?: boolean
    onPress?: () => void
    onChangeTag: (tags: ITagFormated[]) => Promise<void>
}

export const overFlowStyle = Platform.OS === 'web' && { overflow: 'auto' }

const MultiselectTags = ({
    tags,
    value,
    maximunTag,
    disabled,
    indentificator,
    customStyles,
    loading,
    skeletonStyles,
    onPress = () => {},
    onChangeTag,
}: IMultiSelectTag) => {
    const [selectedTags, setSelectedTags] = useState<ITagFormated[] | []>([])
    const [tagList, setTagList] = useState<ITagFormated[] | []>([])
    const styles = getStyleSheet()
    const [isNotSelectTags, setIsNotSelectTags] = useState(false)
    const { activeId, isShowOverlay, handleShowOverlay, closeShowOverlay, handleSetActiveId } = useOverlayProvider()
    const [tagSearchText, setTagSearchText] = useState('')
    const searchInputwidth = !tagSearchText.length ? 6 : tagSearchText.length * 10
    const [tagsBeforeSearch, setTagsBeforeSearch] = useState<ITagFormated[] | []>([])
    const { theme } = useContext<any>(ThemeContext)
    const tagSearchInputRef = useRef<TextInput>(null)
    const itemListRef = useRef<View>(null)
    const containerInputRef = useRef<View>(null)
    const [isRenderedInput, setIsRenderedInput] = useState(false)
    const [isRenderedOverlay, setIsRenderedOverlay] = useState(false)
    const [overlayLayout, setOverlayLayout] = useState({ width: 0, height: 0 })
    const [overlayFinalDimension, setOverlayFinalDimension] = useState({ pageX: 0, pageY: 0 })
    const { windowWidth, windowHeight } = useDimensions()

    const handleFormatedTags = (tags: ITag[]) =>
        tags.map(tag => ({
            value: tag.id,
            label: tag.name,
            color: tag.color.includes('#') ? tag.color : '#' + tag.color,
        }))

    useEffect(() => {
        // Eliminando el active id del overlayProvider cuando este se cierra
        if (activeId === indentificator && !isShowOverlay) handleSetActiveId(null)
    }, [isShowOverlay, activeId])

    useEffect(() => {
        const isLoadedDimensions = overlayLayout.width && overlayLayout.height

        if (isRenderedInput && isLoadedDimensions && activeId === indentificator && isShowOverlay) {
            containerInputRef.current?.measureInWindow((pageX, pageY, width, height) => {
                const spaceClearence = 10 // Holgura de espacio para que no quede pegado al botón

                // Calculando si el overlay se mostrara por debajo o encima del botón
                const positionEjeY =
                    pageY + overlayLayout.height + height + spaceClearence > windowHeight
                        ? pageY - overlayLayout.height
                        : pageY + height + spaceClearence

                const positionEjeX =
                    pageX + overlayLayout.width > windowWidth ? pageX + width - overlayLayout.width : pageX

                setOverlayFinalDimension({ pageX: positionEjeX, pageY: positionEjeY })
            })
        }
    }, [activeId, overlayLayout, isRenderedInput])

    useEffect(() => {
        if (isRenderedOverlay) {
            // Guardando el ancho y el alto de la lista de elementos cuando este se renderiza
            itemListRef.current?.measureInWindow((_pageX, _pageY, width, height) => {
                setOverlayLayout({ height, width })
            })
        }
        // Cada vez que se renderize la lista de tags se ejecuta este evento para saber el ancho de este
    }, [isRenderedOverlay, tagList])

    useEffect(() => {
        if (value?.length && tags) {
            const tagsSelected = tags?.filter(currentValue => value.some(tag => tag.value === currentValue.id))
            setSelectedTags(handleFormatedTags(tagsSelected))

            const newTagList = tags.filter(tag => !tagsSelected.some(selectedTag => selectedTag.id === tag.id))
            setTagList(handleFormatedTags(newTagList))
            setTagsBeforeSearch(handleFormatedTags(newTagList))
        }
        if (!value?.length && tags) {
            setTagList(handleFormatedTags(tags))
            setTagsBeforeSearch(handleFormatedTags(tags))
            setSelectedTags([])
        }
    }, [value, tags])

    useEffect(() => setIsNotSelectTags(selectedTags.length === maximunTag), [selectedTags, maximunTag])

    useEffect(() => {
        if (!tagSearchText.length) setTagList(tagsBeforeSearch)
        const filterTags = tagsBeforeSearch.filter(tag => tag.label.includes(tagSearchText))
        setTagList(filterTags)
    }, [tagSearchText, tagsBeforeSearch])

    const handleDeleteTag = (tag: ITagFormated) => {
        const newTags = selectedTags?.filter(currentTag => currentTag.value !== tag.value)
        onChangeTag(newTags)
        setSelectedTags(newTags)
        setTagList([...tagList, tag])
        setTagsBeforeSearch([...tagList, tag])
    }

    const handleAddTag = async (e: GestureResponderEvent, tag: ITagFormated) => {
        e.stopPropagation()
        const newTagList = tagList?.filter(currentTag => currentTag.value !== tag.value)
        closeShowOverlay()
        setTagSearchText('')
        await onChangeTag([...selectedTags, tag])
        setSelectedTags([...selectedTags, tag])
        setTagList(newTagList)
        setTagsBeforeSearch(newTagList)
    }

    const tagShowToggle = () => {
        if (disabled || isNotSelectTags) return
        if (tagSearchInputRef?.current && Platform.OS === 'web') tagSearchInputRef.current.focus()
        handleShowOverlay(indentificator)
    }

    if (loading) return <ItemSkeleton customStyles={skeletonStyles} />

    return (
        <View style={customStyles}>
            <Pressable
                ref={containerInputRef}
                onLayout={() => setIsRenderedInput(true)}
                onPress={() => {
                    onPress()
                    tagShowToggle()
                }}
            >
                <Hoverable>
                    {({ hovered }) => (
                        <ScrollView
                            horizontal
                            scrollEnabled={false}
                            contentContainerStyle={[
                                localStyles.selectedTagList,
                                hovered ? localStyles.selectedTagListHover : null,
                            ]}
                        >
                            {selectedTags?.map((tag: ITagFormated) => (
                                <TagItem disabled={disabled} handleDelete={handleDeleteTag} key={tag.value} tag={tag} />
                            ))}

                            {!selectedTags?.length && !tagSearchText.length ? (
                                <Text style={{ color: styles.placeholderText }}>Tags</Text>
                            ) : null}

                            {!disabled && !isNotSelectTags ? (
                                <TextInput
                                    ref={tagSearchInputRef}
                                    style={[
                                        {
                                            width: searchInputwidth,
                                            color: styles.text,
                                            position:
                                                !selectedTags.length && !tagSearchText.length ? 'absolute' : 'relative',
                                        },
                                        inputStyle as any,
                                    ]}
                                    value={tagSearchText}
                                    onChangeText={setTagSearchText}
                                />
                            ) : null}
                        </ScrollView>
                    )}
                </Hoverable>
            </Pressable>

            <ModalOverlay visible={isShowOverlay && activeId === indentificator}>
                <View style={Platform.OS === 'ios' ? localStyles.boxShadow : null}>
                    <ScrollView
                        style={[
                            localStyles.tagList,
                            localStyles.boxShadow,
                            {
                                opacity: overlayFinalDimension.pageX && overlayFinalDimension.pageY ? 1 : 0,
                                backgroundColor: styles.filterBackground,
                                maxWidth: windowWidth < 600 ? 200 : 300,
                                left: overlayFinalDimension.pageX,
                                top: overlayFinalDimension.pageY,
                            },
                        ]}
                    >
                        <View style={{ maxHeight: 200 }} ref={itemListRef} onLayout={() => setIsRenderedOverlay(true)}>
                            {tagList.map((tag: ITagFormated) => (
                                <Hoverable>
                                    {({ hovered }) => (
                                        <Pressable
                                            onPress={e => handleAddTag(e, tag)}
                                            style={{
                                                backgroundColor: hovered
                                                    ? chroma(tag.color).alpha(0.15).css()
                                                    : 'transparent',
                                                borderRadius: 4,
                                                paddingVertical: 4,
                                            }}
                                        >
                                            <Text
                                                numberOfLines={1}
                                                style={[
                                                    { color: theme === 'dark' ? '#fff' : tag.color || '#fff' },
                                                    localStyles.tagListItem,
                                                ]}
                                            >
                                                {tag.label}
                                            </Text>
                                        </Pressable>
                                    )}
                                </Hoverable>
                            ))}

                            {!tagList.length && !tagSearchText.length ? (
                                <Text
                                    style={{
                                        height: 40,
                                        lineHeight: 40,
                                        marginHorizontal: 20,
                                        color: 'rgb(255 21 21)',
                                    }}
                                >
                                    You don't have more tags
                                </Text>
                            ) : null}

                            {!tagList.length && tagSearchText.length ? (
                                <Text style={{ height: 40, lineHeight: 40, marginHorizontal: 20, color: styles.text }}>
                                    No options
                                </Text>
                            ) : null}
                        </View>
                    </ScrollView>
                </View>
            </ModalOverlay>
        </View>
    )
}

const localStyles = StyleSheet.create({
    selectedTagList: {
        paddingVertical: 7,
        paddingHorizontal: 10,
        alignItems: 'center',
        flexDirection: 'row',
        gap: 10,
        borderRadius: 4,
        flexGrow: 1,
    },
    selectedTagListHover: {
        borderWidth: 1,
        borderColor: 'rgb(198, 210, 217)',
    },
    tagList: {
        borderRadius: 4,
        maxWidth: 300,
        position: 'absolute',
    },
    tagListItem: {
        fontSize: 16,
        height: 40,
        lineHeight: 40,
        marginLeft: 10,
        marginRight: 20,
    },
    boxShadow: {
        shadowColor: 'rgba(0, 0, 0, 0.4)',
        shadowOpacity: 0.4,
        elevation: 5,
        shadowRadius: 8,
        shadowOffset: {
            width: 0,
            height: 2,
        },
    },
})

export default MultiselectTags
