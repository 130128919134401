import { generateClient } from 'aws-amplify/api'
import { listTasks } from '../graphql/queries'
import { TypeTask } from '../models'
import { StatusTask } from '../models'
import { OrganizationService } from './OrganizationService'
import moment from 'moment'
import { FORMAT } from '../interfaces/user-provider.interface'
import { getReportPdfMutation } from '../graphql/mutations'
import { createFileOnMobile } from '../hooks/handleMobileDownload'
import { Platform } from 'react-native'

export const ReportDetailService = () => {
    const headersExportPdf = [
        { header: 'Date', dataKey: 'date' },
        { header: 'Name', dataKey: 'name_project' },
        { header: 'Duration', dataKey: 'range_duration' },
        { header: 'Pomodoro', dataKey: 'pomodoro' },
    ]
    const headersExportCsv = [
        { label: 'Date', key: 'date' },
        { label: 'Name', key: 'name' },
        { label: 'Project', key: 'project' },
        { label: 'Time', key: 'time' },
        { label: 'Duration', key: 'duration' },
        { label: 'Pomodoro', key: 'pomodoro' },
    ]

    const getTasksByDateRange = async (dateRange: any, userSub: string) => {
        try {
            let organizationId = await OrganizationService().getDefaultOrganizationId(userSub)

            const tasks: any = await generateClient().graphql({
                query: listTasks,
                variables: {
                    limit: 2000,
                    filter: { organizationID: { eq: organizationId }, createdAt: { between: dateRange } },
                },
            })
            return tasks?.data?.listTasks?.items
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const timeToSecond = (time: string) => {
        const [hours, minutes, seconds] = time.split(':')
        return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)
    }

    const getTasksReportDetails = async (dateRange: any, userSub: string, timeFormat: FORMAT) => {
        try {
            let tasks = await getTasksByDateRange(dateRange, userSub)

            let newList = tasks
                ?.filter(t => !t._deleted && t.type === TypeTask.FOCUS)
                .map(item => {
                    return {
                        id: item.id,
                        name: item.name,
                        type: item.type,
                        createdDate: item.createdAt,
                        createdDateFormat: new Date(item.createdAt),
                        createdOnlyDate: moment(item.createdAt).format('YYYY-MM-DD'),
                        projectID: item.projectID,
                        projectName: item.project?.name || '',
                        clientID: item.project?.client?.id,
                        clientName: item.project?.client?.name,
                        userSub: item.usersub,
                        arrayTags: item.TagsTask.items
                            .filter(e => !e._deleted)
                            .map(e => {
                                return { id: e.tag.id, name: e.tag.name, code: e.tag.name, color: e.tag.color }
                            }),
                        pomodoroTime: item.status === StatusTask.COMPLETED ? 1 : 0,
                        time: item.time,
                        status: item.status,
                        timeRange:
                            moment(item.createdAt).format(timeFormat) +
                            ' - ' +
                            moment(item.createdAt).add(timeToSecond(item.time), 'seconds').format(timeFormat),
                        hasTimeEdited: item.hasTimeEdited,
                        _version: item._version,
                        _lastChangedAt: item._lastChangedAt,
                    }
                })

            newList.sort((a, b) => {
                return moment(b._lastChangedAt).diff(a._lastChangedAt)
            })

            return newList
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const groupedTask = (tasks: Array<any>, format: FORMAT) => {
        try {
            if (!tasks.length) return []

            let taskGroup = new Array<any>()

            for (const task of tasks) {
                const taskFound = taskGroup.find(
                    e =>
                        e.name === task.name &&
                        e.projectName === task.projectName &&
                        e.createdOnlyDate === task.createdOnlyDate
                )

                if (taskFound) {
                    taskFound.duration =
                        task.type === TypeTask.FOCUS
                            ? moment.duration(task.time).add(taskFound.duration)
                            : moment.duration('00:00:00').add(taskFound.duration)
                    taskFound.durationbreak =
                        task.type !== TypeTask.FOCUS
                            ? moment.duration(task.time).add(taskFound.durationbreak)
                            : moment.duration('00:00:00').add(taskFound.durationbreak)
                    taskFound.hasTimeEdited = !taskFound.hasTimeEdited ? task.hasTimeEdited : true
                    taskFound.tasks.push(task)
                } else {
                    const newGroup: any = {
                        id: task.id,
                        name: task.name,
                        createdDate: task.createdDate,
                        projectID: task.projectID,
                        projectName: task.projectName ?? '',
                        _lastChangedAt: task.lastChangedAt,
                        createdOnlyDate: task.createdOnlyDate,
                        createdDateFormat: moment(task.createdDate).format('MMM DD, YYYY'),
                        pomodoroTime: 0,
                        breakTime: 0,
                        duration:
                            task.type === TypeTask.FOCUS ? moment.duration(task.time) : moment.duration('00:00:00'),
                        durationbreak:
                            task.type !== TypeTask.FOCUS ? moment.duration(task.time) : moment.duration('00:00:00'),
                        durationFormat: '',
                        durationbreakFormat: '',
                        tasks: [],
                        timeRange: '',
                        hasTimeEdited: task.hasTimeEdited,
                    }
                    newGroup.tasks.push(task)
                    taskGroup.push(newGroup)
                }
            }

            taskGroup = taskGroup.map(p => {
                return {
                    ...p,
                    durationFormat: moment.utc(p.duration.asMilliseconds()).format('HH:mm:ss'),
                    durationbreakFormat: moment.utc(p.durationbreak.asMilliseconds()).format('HH:mm:ss'),
                    pomodoroTime: p.tasks.filter(e => e.type === TypeTask.FOCUS && e.status === StatusTask.COMPLETED)
                        .length,
                    breakTime: p.tasks?.filter(e => e.type !== TypeTask.FOCUS).length,
                    tasks:
                        p.tasks.length <= 1 /// Si solo hay una tarea esta misma se renderiza 2 veces, por eso si hay 1 devuelvo el [] vacio
                            ? []
                            : p.tasks?.sort((a, b) =>
                                  a.createdDateFormat > b.createdDateFormat
                                      ? 1
                                      : a.createdDateFormat < b.createdDateFormat
                                      ? -1
                                      : 0
                              ),
                    timeRange: `${moment(p.createdDate).format(format)} - ${moment(p.tasks.at(-1).createdDate)
                        .add(timeToSecond(p.tasks.at(-1).time), 'seconds')
                        .format(format)}`,
                }
            })
            return taskGroup
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const resumeReportDetails = groupedTasks => {
        let totalTime
        let totalPomodoro = 0

        for (const groupTask of groupedTasks) {
            totalTime = moment.duration(groupTask.durationFormat).add(totalTime)
            totalPomodoro += groupTask.pomodoroTime
        }

        return {
            taskTotalTime:
                groupedTasks.length > 0 ? moment.utc(totalTime.asMilliseconds()).format('HH:mm:ss') : '00:00:00',
            totalPomodoro,
        }
    }

    const transformDataToExport = groupedTasks => {
        const tasksDetails = [] as any[]
        groupedTasks.map(item => {
            const newItem = {
                date: item.createdDateFormat,
                name: item.name,
                name_project: item.projectName ? item.name + '\n~' + item.projectName : item.name,
                duration: item.durationFormat,
                range_duration: item.durationFormat + '\n' + item.timeRange,
                pomodoro: item.pomodoroTime,
            }

            tasksDetails.push(newItem)

            item.tasks.map(subItem => {
                const newItem = {
                    date: '',
                    name: subItem.name,
                    name_project: subItem.projectName ? subItem.name + '\n~' + subItem.projectName : subItem.name,
                    duration: subItem.time,
                    range_duration: subItem.time + '\n' + subItem.timeRange,
                    pomodoro: subItem.pomodoroTime,
                }

                tasksDetails.push(newItem)
            })
        })
        return tasksDetails
    }

    const handleWebDownload = (data: string) => {
        const blob = new Blob([data], { type: 'application/pdf' })

        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `Report_detailed_${moment().format('MM/DD/YYYY')}.pdf`
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const exportDetailsPdf = async (exportData: any, dateRange: any, totalTime: any, workSpaceName: string) => {
        const request: any = await generateClient().graphql({
            query: getReportPdfMutation,
            variables: {
                input: {
                    reportData: exportData,
                    dateRange,
                    totalTime,
                    workSpaceName,
                    headerTitle: 'Detailed report',
                    isDetailed: true,
                },
            },
        })

        const data = request?.data?.getReportPdfMutation

        if (Platform.OS === 'web') {
            handleWebDownload(data)
        } else
            createFileOnMobile(
                data,
                `Report_detailed_${moment().format('MM/DD/YYYY')}.pdf`,
                'Report download successfully'
            )
    }

    const exportDetailCsv = (exportData: any) => {
        return {
            data: exportData,
            headers: headersExportCsv,
            filename: 'Report_Details.csv',
        }
    }

    return {
        getTasksByDateRange,
        getTasksReportDetails,
        groupedTask,
        resumeReportDetails,
        transformDataToExport,
        exportDetailsPdf,
        exportDetailCsv,
    }
}
