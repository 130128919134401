import { updateUserAttributes, fetchUserAttributes } from 'aws-amplify/auth'
import { uploadData, downloadData, list } from 'aws-amplify/storage'

export const AuthService = () => {
    const getUserSub = async () => {
        return await fetchUserAttributes()
            .then(user => {
                return user?.sub
            })
            .catch(error => {
                return null
            })
    }

    const updateThemeProfile = async (selectTheme: string) => {
        return fetchUserAttributes().then(user => {
            const paramsUser = {
                'custom:thememode': selectTheme,
            }

            return updateUserAttributes({ userAttributes: user as any, options: paramsUser }).then(result => {
                return result
            })
        })
    }

    const updateNameProfile = async (givenName: string, familyName: string) => {
        return fetchUserAttributes()
            .then(() => {
                const paramUser = {
                    given_name: givenName,
                    family_name: familyName,
                    name: givenName + ' ' + familyName,
                }
                return updateUserAttributes({ userAttributes: paramUser }).then(result => {
                    return result
                })
            })
            .catch(error => {
                console.log(error)
                return
            })
    }

    const getAllStorageFile = async () => {
        return (await list()).items
    }

    const getStorageFileByName = async (fileName: string) => {
        const downloadResult = await downloadData({ key: fileName }).result
        return await downloadResult.body.text()
    }

    const putStorageFile = async (fileName: string, selectedFile: any) => {
        try {
            return await uploadData({
                key: fileName,
                data: selectedFile.uri,
                options: {
                    contentType: selectedFile.type,
                },
            }).result
        } catch (e) {
            console.log(e)
        }
    }

    const getProfilePhoto = async (fileName: string) => {
        const filesList = await getAllStorageFile()
        const elementFound = filesList?.find(e => e.key === fileName)
        if (elementFound) {
            const file = getStorageFileByName(fileName)
            return file
        }
    }

    return {
        getUserSub,
        updateThemeProfile,
        updateNameProfile,
        getAllStorageFile,
        getStorageFileByName,
        putStorageFile,
        getProfilePhoto,
    }
}
