import { useContext, useEffect, useState } from 'react'
import { ThemeContext } from '../../../assets/theme/ThemeProvider'
import { OrganizationService } from '../../../services/OrganizationService'
import { StatusTag } from '../../../models/index'

export interface Props {
    onCreateRow: (newRow: any) => void
    allTags?: any
    style?: any
}

const useCreateTagBar = (props: Props) => {
    const { theme, userSub } = useContext<any>(ThemeContext)
    const [name, setName] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const allTags = props.allTags

    const random_hex_color_code = () => {
        const n = (Math.random() * 0xfffff * 1000000).toString(16)
        return n.slice(0, 6).toUpperCase()
    }

    const [color, setColor] = useState<any>(random_hex_color_code)

    useEffect(() => {
        if (name.includes(' ')) return setErrorMessage('Tag should not include spaces')
        if (name.length > 8) return setErrorMessage('Tag should not exceed a maximum of 8 letters')
        setErrorMessage('')
    }, [name])

    const clearComponents = () => {
        setName('')
        setColor(random_hex_color_code)
    }

    const onHandleCreate = async () => {
        if (!name) return
        const nameFind = allTags.find((f: any) => f.name.toLowerCase().trim() === name.toLowerCase().trim())

        if (nameFind) return

        const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
        const tagParams = {
            name: name,
            color: color,
            status: StatusTag.ACTIVE,
            organizationID: organizationId,
        }

        props.onCreateRow(tagParams)
        clearComponents()
    }

    const handleKeyDownCreateTag = event => {
        if (event.key === 'Enter') {
            onHandleCreate()
        }
    }

    return [{ theme, name, color, errorMessage, setName, setColor, onHandleCreate, handleKeyDownCreateTag }]
}

export default useCreateTagBar
