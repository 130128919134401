import { ReactNode, createContext, useContext, useState } from 'react'
import { GestureResponderEvent } from 'react-native'
import { useOverlayProvider } from './OverlayProvider'
import useDimensions from '../hooks/useDimensions'

interface IPickerProvider {
    isShowPicker: boolean
    dimensions: { vertical: number; horizontal: number }
    toggleShowPicker: (
        e: GestureResponderEvent,
        currentData: PROP_DATA,
        currentView: RENDERED_COMPONENT_DATE_PICKER
    ) => void
    closeDatePicker: () => void
    dateValue: string | null
    currentRenderedComponent: RENDERED_COMPONENT_DATE_PICKER
    dataDatePicker: PROP_DATA | null
}

export type RENDERED_COMPONENT_DATE_PICKER = 'timer' | 'createTaskBar'

type PROP_DATA = { id: string; date?: string; version?: string }

const DatePickerContext = createContext<IPickerProvider>(null as any)

const DatePickerProvider = ({ children }: { children: ReactNode }) => {
    const { isShowOverlay, closeShowOverlay, handleShowOverlay, activeId } = useOverlayProvider()
    const [dimensions, setDimensions] = useState({ vertical: 0, horizontal: 0 })
    const [data, setData] = useState<PROP_DATA>({ id: '' })
    const { windowHeight, windowWidth } = useDimensions()
    const [currentRenderedComponent, setCurrentRenderedComponent] = useState<RENDERED_COMPONENT_DATE_PICKER>('timer')

    const closeDatePicker = () => {
        closeShowOverlay()
    }

    const toggleShowPicker = (
        { nativeEvent }: GestureResponderEvent,
        currentData: PROP_DATA,
        renderedComponent: RENDERED_COMPONENT_DATE_PICKER
    ) => {
        const heightDatePicker = 250
        const widthDatePicker = 320
        const marginHeight = 30

        const vertical =
            nativeEvent.pageY + marginHeight + heightDatePicker > windowHeight
                ? windowHeight - marginHeight - heightDatePicker
                : nativeEvent.pageY + marginHeight

        const horizontal =
            nativeEvent.pageX + widthDatePicker > windowWidth ? windowWidth - widthDatePicker : nativeEvent.pageX

        setDimensions({ horizontal: horizontal, vertical })
        setData(currentData)
        setCurrentRenderedComponent(renderedComponent)
        if (isShowOverlay && currentData?.id !== data?.id) return // when choose another task
        if (isShowOverlay && currentData?.id === data?.id) return closeDatePicker() // when it is the same task
        handleShowOverlay(currentData?.id) // When is first time
    }

    return (
        <DatePickerContext.Provider
            value={{
                isShowPicker: isShowOverlay && activeId === data.id,
                dimensions,
                dateValue: data?.date || null,
                currentRenderedComponent,
                dataDatePicker: data,
                closeDatePicker,
                toggleShowPicker,
            }}
        >
            {children}
        </DatePickerContext.Provider>
    )
}

function useDataPickerProvider() {
    const context = useContext(DatePickerContext)
    if (context === undefined) {
        throw new Error('usePickerProvider must be used within a DatePickerProvider')
    }
    return context
}

export { DatePickerProvider, useDataPickerProvider, DatePickerContext }
