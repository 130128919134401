import { useContext, createContext } from 'react'
import { IStripeProvider } from '../interfaces/stripe-provider.interface'
import { generateClient } from 'aws-amplify/api'
import { Platform } from 'react-native'
import { updateSubscriptionPaymentMethod, updateSubscriptionPlan } from '../graphql/mutations'
import moment from 'moment'
import { handleMobileDownload } from '../hooks/handleMobileDownload'

const StripeContext = createContext<IStripeProvider>(null as any)

function StripeProvider({ children }) {
    async function downloadInvoicePdf(invoiceId: string): Promise<void> {
        try {
            if (invoiceId && Platform.OS === 'web') {
                const link = document.createElement('a')
                link.target = '_blank'
                link.href = invoiceId
                link.download = `invoice-${moment().format('MM/DD/YYYY')}.pdf`
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }

            if (Platform.OS !== 'web') {
                handleMobileDownload(
                    invoiceId,
                    `invoice-${moment().format('MM/DD/YYYY')}.pdf`,
                    'Report Downloaded Successfully'
                )
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    const updateSubscriptionPaymentMethodFn = async (
        customerId: string,
        subscriptionId: string,
        newPaymentMethod: string
    ) => {
        const result: any = await generateClient().graphql({
            query: updateSubscriptionPaymentMethod,
            variables: {
                input: {
                    customerId,
                    subscriptionId,
                    paymentMethodId: newPaymentMethod,
                },
            },
        })

        return result?.data?.updateSubscriptionPaymentMethod
    }

    const updateSubscriptionPlanFn = async (
        subscriptionId: string,
        subscriptionItemId: string,
        newPlanId: string,
        subscriptionSeats: number
    ) => {
        const result: any = await generateClient().graphql({
            query: updateSubscriptionPlan,
            variables: {
                input: {
                    subscriptionId,
                    subscriptionItemId,
                    newPlanId,
                    subscriptionSeats,
                },
            },
        })

        return result?.data?.updateSubscriptionPlan
    }

    return (
        <StripeContext.Provider
            value={{
                downloadInvoicePdf,
                updateSubscriptionPaymentMethodFn,
                updateSubscriptionPlanFn,
            }}
        >
            {children}
        </StripeContext.Provider>
    )
}

function useStripeProvider() {
    const context = useContext(StripeContext)
    if (context === undefined) {
        throw new Error('useStripeProvider must be used within a StripeProvider')
    }
    return context
}

export { StripeProvider, useStripeProvider }
