import { View, Text, Switch, ViewStyle, StyleProp } from 'react-native'
import { getStyleSheet } from '../../../assets/theme/styles'

const SwitchRoleAdmin = ({
    onRoleHandle,
    customStyles,
    currentRole,
}: {
    onRoleHandle: (e: any) => void
    customStyles?: StyleProp<ViewStyle>
    currentRole?: string
}) => {
    const styles = getStyleSheet()

    const onStatusChange = (e: boolean) => {
        onRoleHandle(e)
    }

    return (
        <View style={[{ flexDirection: 'row', gap: 5 }, customStyles]}>
            <Switch value={currentRole === 'ADMIN'} onValueChange={onStatusChange} />
            <Text style={{ marginLeft: 10, textTransform: 'uppercase', color: styles.text }}>Admin</Text>
        </View>
    )
}

export default SwitchRoleAdmin
