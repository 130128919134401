import { Button } from '@rneui/base'
import { GestureResponderEvent, LayoutChangeEvent, StyleProp, TextStyle, ViewStyle, Text, Platform } from 'react-native'
import { getStyleSheet } from '../../assets/theme/styles'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'

interface IButtonNative {
    onPress?: ((event: GestureResponderEvent) => void) | undefined
    onLayout?: ((event: LayoutChangeEvent) => void) | undefined
    icon?: IconProp
    iconSize?: number
    iconName?: string
    titleStyle?: StyleProp<TextStyle>
    containerStyle?: StyleProp<ViewStyle>
    buttonStyle?: StyleProp<ViewStyle>
    style?: StyleProp<ViewStyle>
    title?: string
    showIcon?: boolean
    iconColor?: string
    loading?: boolean
    disabled?: boolean
    primaryTheme?: boolean
    disabledStyle?: StyleProp<ViewStyle>
    disabledTitleStyle?: StyleProp<TextStyle>
    children?: React.ReactNode
}

const ButtonNative = ({
    iconSize = 20,
    titleStyle,
    containerStyle,
    buttonStyle,
    style,
    title,
    showIcon,
    iconColor,
    loading,
    disabled,
    primaryTheme,
    disabledStyle,
    disabledTitleStyle,
    children,
    icon,
    onPress,
    onLayout,
}: IButtonNative) => {
    const styles = getStyleSheet()
    const outlineStyle = Platform.OS === 'web' ? { outline: 0 } : null

    return (
        <Button
            loading={loading}
            disabledStyle={[
                { backgroundColor: primaryTheme ? styles.backgroundColorPrimaryButton : 'transparent', opacity: 0.6 },
                disabledStyle,
            ]}
            style={style}
            onPress={onPress}
            onLayout={onLayout}
            disabledTitleStyle={[{ color: '#FFF' }, disabledTitleStyle]}
            containerStyle={[{ borderRadius: 5 }, primaryTheme ? { minWidth: 100 } : null, containerStyle]}
            buttonStyle={[
                { backgroundColor: 'transparent', borderRadius: 5 },
                primaryTheme ? { backgroundColor: styles.backgroundColorPrimaryButton } : null,
                buttonStyle,
            ]}
            disabled={disabled}
        >
            {children}

            {!children ? (
                <>
                    {icon && showIcon ? (
                        <FontAwesomeIcon
                            style={[outlineStyle as any, { minWidth: 14 }]}
                            icon={icon}
                            color={iconColor ?? styles.text}
                            size={iconSize}
                        />
                    ) : null}
                    {title ? (
                        <Text
                            numberOfLines={1}
                            style={[{ fontSize: 14, color: '#FFF', fontWeight: '500' }, titleStyle]}
                        >
                            {title}
                        </Text>
                    ) : null}
                </>
            ) : null}
        </Button>
    )
}

export default ButtonNative
