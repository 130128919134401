import { createContext, useEffect, useContext } from 'react'
import { Platform } from 'react-native'
import { ITask } from '../interfaces/task.interface'
import { ITaskActiveProvider } from '../interfaces/task-active-provider.interface'
import { LocalStoreName } from '../components/enum/localStore-name.enum'
import useState from 'react-usestateref'
import AsyncStorage from '@react-native-async-storage/async-storage'

const TaskActiveContext = createContext<ITaskActiveProvider>(null as any)

function TaskActiveProvider({ children }) {
    const [activeTask, setActiveTask, activeTaskRef] = useState<ITask>()
    const [activeTaskTags, setActiveTaskTags, activeTaskTagsRef] = useState<any>()
    const [activeTaskProject, setActiveTaskProject, activeTaskProjectRef] = useState<any>()
    const [taskCountDown, setTaskCountDown] = useState('00:00:00')

    const getTaskActiveLocalStorage = async () => {
        const activeTaskStorage = await AsyncStorage.getItem(LocalStoreName.ACTIVE_TASK)
        if (activeTaskStorage) {
            setActiveTask(JSON.parse(activeTaskStorage))
        }

        const activeTaskTagsStorage = await AsyncStorage.getItem(LocalStoreName.ACTIVE_TASK_TAGS)
        if (activeTaskTagsStorage) {
            setActiveTaskTags(JSON.parse(activeTaskTagsStorage))
        }

        const activeTaskProjectStorage = await AsyncStorage.getItem(LocalStoreName.ACTIVE_TASK_PROJECT)
        if (activeTaskProjectStorage) {
            setActiveTaskProject(JSON.parse(activeTaskProjectStorage))
        }
    }

    useEffect(() => {
        getTaskActiveLocalStorage()
        if (Platform.OS === 'web') window.addEventListener('storage', getTaskActiveLocalStorage)
    }, [])

    const handleSetActiveTask = async () => {
        if (activeTaskRef?.current) {
            await AsyncStorage.setItem(LocalStoreName.ACTIVE_TASK, JSON.stringify(activeTaskRef.current))
            setTaskCountDown(activeTaskRef.current?.countDown || '00:00:00')
        }

        if (activeTaskTagsRef?.current) {
            await AsyncStorage.setItem(LocalStoreName.ACTIVE_TASK_TAGS, JSON.stringify(activeTaskTagsRef.current))
        }

        if (activeTaskProjectRef?.current) {
            await AsyncStorage.setItem(LocalStoreName.ACTIVE_TASK_PROJECT, JSON.stringify(activeTaskProjectRef.current))
        }

        if (activeTaskRef?.current === undefined) {
            setTaskCountDown('00:00:00')
        }
    }

    useEffect(() => {
        handleSetActiveTask()
    }, [activeTaskRef.current, activeTaskTagsRef.current, activeTaskProjectRef.current])

    useEffect(() => {
        if (Platform.OS !== 'web') return
        if ('00:00:00' === taskCountDown || !taskCountDown) {
            const indexSlice = document.title.indexOf('|')

            if (indexSlice > 0) {
                document.title = document.title.slice(indexSlice + 2)
            }
        } else {
            const indexSlice = document.title.indexOf('|')
            let documentTitle = document.title

            if (indexSlice > 0) {
                documentTitle = document.title.slice(indexSlice + 2)
            }

            document.title = `${taskCountDown} | ${documentTitle}`
        }
    }, [taskCountDown])

    const removeActiveTask = () => {
        AsyncStorage.multiRemove([
            LocalStoreName.ACTIVE_TASK,
            LocalStoreName.ACTIVE_TASK_PROJECT,
            LocalStoreName.ACTIVE_TASK_TAGS,
        ])

        setActiveTask(undefined)
        setActiveTaskTags(undefined)
        setActiveTaskProject(undefined)
    }

    return (
        <TaskActiveContext.Provider
            value={{
                activeTask: activeTaskRef.current,
                taskCountDown,
                activeTaskTags: activeTaskTagsRef.current,
                activeTaskProject: activeTaskProjectRef.current,
                setActiveTask,
                setTaskCountDown,
                setActiveTaskTags,
                setActiveTaskProject,
                removeActiveTask,
            }}
        >
            {children}
        </TaskActiveContext.Provider>
    )
}

function useTaskActiveProvider() {
    const context = useContext(TaskActiveContext)
    if (context === undefined) {
        throw new Error('useTaskActiveProvider must be used within a TaskActiveProvider')
    }
    return context
}

export { TaskActiveProvider, useTaskActiveProvider }
