import { generateClient } from 'aws-amplify/api'
import { createTag, updateTag, deleteTag, deleteTaskTag, deleteClientTag } from '../graphql/mutations'
import { getTag, listClientTags, listTags, listTaskTags } from '../graphql/queries'
import { OrganizationService } from './OrganizationService'

export const TagsService = () => {
    const getAllTags = async (userSub: string) => {
        try {
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)

            const response: any = await generateClient().graphql({
                query: listTags,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })
            return response?.data?.listTags?.items
                .filter(t => !t._deleted)
                .map(item => {
                    return { id: item.id, code: item.name, name: item.name, color: item.color }
                })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getTagByName = async (name: string) => {
        try {
            const response = await generateClient().graphql({ query: getTag, variables: { name } })
            return response
        } catch (error) {
            console.log(error)
            return
        }
    }

    const getAllTagsDropDown = async (userSub: string) => {
        try {
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            const response: any = await generateClient().graphql({
                query: listTags,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })

            return response?.data?.listTags?.items.map(item => {
                return { label: item.name, value: item.id, color: item.color }
            })
        } catch (error) {
            console.log(error)
            return
        }
    }

    const updateTagById = async (updateObject: any) => {
        try {
            return await generateClient().graphql({ query: updateTag, variables: { input: updateObject } })
        } catch (error) {
            console.log(error)
            return {}
        }
    }

    const getAllTagsByOrganizationId = async (userSub: string) => {
        try {
            if (userSub === '') return []
            const organizationId = await OrganizationService().getDefaultOrganizationId(userSub)
            const response: any = await generateClient().graphql({
                query: listTags,
                variables: { filter: { organizationID: { eq: organizationId } } },
            })

            return response?.data?.listTags?.items
                .filter(t => !t._deleted)
                .map(item => {
                    return {
                        ...item,
                        groupcolor: item.color,
                    }
                })
        } catch (error) {
            console.log(error)
            return []
        }
    }

    const saveTag = async (newEntry: any) => {
        try {
            const response: any = await generateClient().graphql({ query: createTag, variables: { input: newEntry } })
            return response?.data?.createTag
        } catch (error) {
            console.log(error)
            return {}
        }
    }

    const deleteTagById = async (tagId: string, version: number): Promise<boolean | undefined> => {
        try {
            await generateClient().graphql({ query: deleteTag, variables: { input: { id: tagId, _version: version } } })
            return true
        } catch (error) {
            console.log(error)
            return false
        }
    }

    return {
        getAllTags,
        getTagByName,
        getAllTagsDropDown,
        updateTagById,
        getAllTagsByOrganizationId,
        saveTag,
        deleteTagById,
    }
}
