import { View, Text, StyleProp, ViewStyle } from 'react-native'
import { getStyleSheet } from '../../../assets/theme/styles'
import moment from 'moment'
import { useContext } from 'react'
import { CartContext } from '../../../context/cart'
import InputText from '../InputText'
import ButtonNative from '../ButtonNative'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import useDimensions from '../../../hooks/useDimensions'

interface IUserSeats {
    priceMembership: number
    totalPrice: number
    inputSeat: string
    total: number
    customStyles?: StyleProp<ViewStyle>
    discountByCouponCode: null | number
    totalPriceCheckout: (amount: number) => void
}

const UserSeats = ({
    priceMembership,
    totalPrice,
    inputSeat,
    customStyles,
    total,
    discountByCouponCode,
    totalPriceCheckout,
}: IUserSeats) => {
    const styles = getStyleSheet()
    const initDate = moment()
    const { memberShipTime } = useContext(CartContext)
    const { windowWidth } = useDimensions()
    const endDate = moment().add(1, memberShipTime === 1 ? 'years' : 'months')
    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    })

    return (
        <View
            style={[
                {
                    borderColor: '#C6D2D9',
                    borderWidth: 1,
                    borderRadius: 6,
                    padding: 24,
                },
                customStyles,
            ]}
        >
            <Text style={{ color: styles.text, fontSize: 14, opacity: 0.4 }}>
                {initDate.format('MMM DD').toUpperCase()} - {endDate.format('MMM DD, YYYY').toUpperCase()}
            </Text>

            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginBottom: 5,
                }}
            >
                <View
                    style={{
                        flexDirection: 'row',
                        marginVertical: 14,
                        alignItems: 'center',
                    }}
                >
                    <Text style={{ color: styles.text, fontSize: 15, marginRight: 10 }}>User seats</Text>

                    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                        <InputText
                            inputStyle={{ width: 50, backgroundColor: styles.backgroundComponent }}
                            value={String(inputSeat)}
                            disabled
                        />
                        <View>
                            <ButtonNative
                                containerStyle={{
                                    backgroundColor: styles.buttonNumberBackground,
                                    borderRadius: 0,
                                    borderTopRightRadius: 4,
                                }}
                                buttonStyle={{
                                    paddingHorizontal: 0,
                                    paddingVertical: 0,
                                    height: 20,
                                    width: 30,
                                }}
                                iconSize={14}
                                showIcon
                                icon={faPlus}
                                onPress={() => totalPriceCheckout(+inputSeat + 1)}
                            />
                            <ButtonNative
                                containerStyle={{
                                    backgroundColor: styles.buttonNumberBackground,
                                    borderRadius: 0,
                                    borderBottomRightRadius: 4,
                                }}
                                buttonStyle={{
                                    paddingHorizontal: 0,
                                    paddingVertical: 0,
                                    height: 20,
                                    width: 30,
                                }}
                                iconSize={14}
                                showIcon
                                icon={faMinus}
                                onPress={() => (inputSeat !== '1' ? totalPriceCheckout(+inputSeat - 1) : null)}
                            />
                        </View>
                    </View>
                    <Text style={{ color: styles.text, fontSize: 15, marginLeft: 16 }}>
                        x {USDollar.format(priceMembership)}
                    </Text>
                </View>

                {windowWidth > 600 ? (
                    <Text style={{ color: styles.text, fontSize: 15 }}>{USDollar.format(totalPrice)}</Text>
                ) : null}
            </View>

            <View style={{ borderColor: styles.text, borderWidth: 1, marginBottom: 20 }} />

            <View style={{ gap: 16 }}>
                <View style={{ flexDirection: 'row' }}>
                    <Text style={{ textTransform: 'none', fontSize: 18, fontWeight: '500', color: styles.text }}>
                        Total price
                    </Text>
                    <Text
                        style={{
                            marginLeft: 'auto',
                            fontSize: 18,
                            fontWeight: '500',
                            color: discountByCouponCode ? styles.backgroundColorPrimaryButton : styles.text,
                        }}
                    >
                        {USDollar.format(totalPrice)}
                    </Text>
                </View>

                {discountByCouponCode ? (
                    <Text
                        style={{
                            textAlign: 'right',
                            fontSize: 15,
                            textDecorationLine: 'line-through',
                            color: styles.text,
                        }}
                    >
                        {USDollar.format(total)}
                    </Text>
                ) : null}
            </View>
        </View>
    )
}

export default UserSeats
