import { fetchUserAttributes } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import { generateClient } from 'aws-amplify/api'
import { useState, useContext, createContext, useEffect } from 'react'
import { getUser, listOrganizationUsers } from '../graphql/queries'
import { FORMAT, IUserProvider } from '../interfaces/user-provider.interface'
import { ILocalUser } from '../interfaces/local-user.interface'
import { IUser } from '../interfaces/user.interface'
import { updateUser } from '../graphql/mutations'
import { IUserOrganization } from '../interfaces/user-organization.interface'
import { AuthService } from '../services/AuthService'
import { UserService } from '../services/UserService'

const UserContext = createContext<IUserProvider>(null as any)

function UserProvider({ children }) {
    const [localUserData, setLocalUserData] = useState<ILocalUser>()
    const [dbUserData, setDbUserData] = useState<IUser>()
    const [userActiveOrganization, setUserActiveOrganization] = useState<IUserOrganization>()
    const [updateNameOrganization, setUpdateNameOrganization] = useState('')
    const [updatePlanIdOrganization, setUpdatePlanIdOrganization] = useState('')
    const [timeFormat, setTimeFormat] = useState<FORMAT>('LT')
    const [userCurrentImage, setUserCurrentImage] = useState<any>(null)
    const [arePrivatePagesAvailable, setArePrivatePagesAvailable] = useState(false)

    const getUserLocalData = () => {
        fetchUserAttributes()
            .then(user => {
                if (user) {
                    getDbUserData(user.sub || '')
                    setLocalUserData(user as unknown as ILocalUser)
                }
            })
            .catch(error => {
                console.log(error)
            })
    }

    const getDbUserData = async (userId: string): Promise<IUser> => {
        const response: any = await generateClient().graphql({ query: getUser, variables: { id: userId } })
        setDbUserData(response?.data?.getUser)
        return response?.data?.getUser
    }

    const updateUserById = async (newObject: IUser) => {
        const response: any = await generateClient().graphql({ query: updateUser, variables: { input: newObject } })
        setDbUserData(response.data.updateUser)
        return response
    }

    const getPhotoProfile = async (imageName: string) => {
        try {
            const filesList = await AuthService().getAllStorageFile()
            const elementFound = filesList?.find(e => e.key === imageName)
            if (elementFound) {
                const file = await AuthService().getStorageFileByName(imageName)
                if (file) setUserCurrentImage(file)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const loadPhoto = () => {
        if (!localUserData?.sub) return

        UserService()
            .getUserById(localUserData?.sub)
            .then(userResponse => {
                const imageName = userResponse.image
                if (imageName) {
                    getPhotoProfile(imageName)
                }
            })
            .catch(error => console.log(error))
    }

    useEffect(() => {
        const hub = Hub.listen('auth', ({ payload }) => {
            if (payload.event === 'tokenRefresh') {
                fetchUserAttributes().then(user => {
                    if (user.sub) getDbUserData(user?.sub)
                })
            }

            if (payload.event === 'signedIn') getUserLocalData()
        })
        getUserLocalData()
        return () => hub()
    }, [])

    useEffect(() => {
        if (localUserData && !userActiveOrganization) {
            loadPhoto()
            ;(
                generateClient().graphql({
                    query: listOrganizationUsers,
                    variables: { filter: { usersub: { eq: localUserData.sub }, is_default: { eq: true } } },
                }) as Promise<any>
            ).then(
                resp => {
                    setUserActiveOrganization(resp?.data?.listOrganizationUsers?.items[0])
                    setUpdateNameOrganization(resp?.data?.listOrganizationUsers?.items[0]?.organization?.name)
                    setUpdatePlanIdOrganization(resp?.data?.listOrganizationUsers?.items[0]?.organization?.planID)
                },
                err => {
                    console.log(err)
                }
            )
        }
    }, [localUserData, userActiveOrganization])

    useEffect(() => {
        if (dbUserData) {
            if (dbUserData.timeFormat?.length && dbUserData.timeFormat !== 'false') {
                setTimeFormat('HH:mm')
            } else {
                setTimeFormat('LT')
            }
        }
    }, [dbUserData])

    return (
        <UserContext.Provider
            value={{
                dbUserData,
                timeFormat,
                localUserData,
                userActiveOrganization,
                updateNameOrganization,
                updatePlanIdOrganization,
                userCurrentImage,
                arePrivatePagesAvailable,
                setArePrivatePagesAvailable,
                loadPhoto,
                setUserActiveOrganization,
                setUpdateNameOrganization,
                setUpdatePlanIdOrganization,
                setDbUserData,
                getDbUserData,
                updateUserById,
            }}
        >
            {children}
        </UserContext.Provider>
    )
}

function useUserProvider() {
    const context = useContext(UserContext)
    if (context === undefined) {
        throw new Error('useUserProvider must be used within a UserProvider')
    }
    return context
}

export { UserProvider, useUserProvider }
