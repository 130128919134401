import React from 'react'
import { View, Text } from 'react-native'
import useCreateTagBar, { Props } from './useCreateTagBar'
import ButtonNative from '../ButtonNative'
import InputText from '../InputText'
import ColorPickerNative from '../ColorPicker'
import { getStyleSheet } from '../../../assets/theme/styles'

export const CreateTagBar: React.FC<Props> = props => {
    const [{ name, color, errorMessage, setName, setColor, onHandleCreate, handleKeyDownCreateTag }] =
        useCreateTagBar(props)
    const styles = getStyleSheet()

    return (
        <View
            style={[
                {
                    gap: 20,
                    flexDirection: 'row',
                    alignItems: 'center',
                    backgroundColor: styles.backgroundColorRow,
                    borderRadius: 6,
                    padding: 10,
                    marginTop: 10,
                },
                styles.stylesBar.boxShadow,
            ]}
        >
            <InputText
                containerStyle={{ flexGrow: 1 }}
                inputStyle={[
                    errorMessage.length ? { color: 'rgb(255, 111, 111)', borderColor: 'rgb(255, 111, 111)' } : null,
                ]}
                placeholder="Tag"
                value={name}
                onChange={e => setName(e.valueOf())}
                onKeyPress={handleKeyDownCreateTag}
            />

            <ColorPickerNative
                identificator="pickerColorBar"
                value={color}
                onChange={color => setColor(color.replace('#', ''))}
            />

            <ButtonNative
                containerStyle={{ minWidth: 'auto' }}
                titleStyle={{ color: '#FFF' }}
                title="Create"
                primaryTheme
                disabled={!!errorMessage.length}
                onPress={onHandleCreate}
            />

            {errorMessage.length ? (
                <Text style={{ color: 'rgb(255, 111, 111)', position: 'absolute', left: 25, bottom: -30 }}>
                    {errorMessage}
                </Text>
            ) : null}
        </View>
    )
}
