import React, { memo, useEffect, useState } from 'react'
import { View, Text, Pressable, Platform, Image, ScrollView } from 'react-native'
import { Video, ResizeMode } from 'expo-av'
import { ProgressBar } from 'react-native-paper'
import ButtonNative from '../ButtonNative'
import { getStyleSheet } from '../../../assets/theme/styles'
import * as ImagePicker from 'expo-image-picker'
import { faClose } from '@fortawesome/free-solid-svg-icons'
import { Hoverable } from 'react-native-web-hover'
import { cursorPointerStyle } from '../MultiSelectNative/ItemMultiple'
import uuid from 'react-native-uuid'
import { allExtensions, imageExtensions, videoExtensions } from '../../../helpers/utils'

const uploadImg = require('../../../assets/images/cloud-upload-regular-240.png')

interface DropFileInputProps {
    onFileChange: (event: { file: CustomFile; id: string }[]) => Promise<void>
    uploadProgress: {
        [id: string]: number
    }
    completeUpload: boolean
    setCompleteUpload: React.Dispatch<React.SetStateAction<boolean>>
}

export type CustomFile = ImagePicker.ImagePickerAsset & { type: string }

export const DropFileInput: React.FC<DropFileInputProps> = ({
    onFileChange,
    completeUpload,
    setCompleteUpload,
    uploadProgress,
}) => {
    const [fileList, setFileList] = useState<{ file: CustomFile; id: string }[]>([])
    const styles = getStyleSheet()

    useEffect(() => {
        if (completeUpload) {
            setFileList([])
        }
    }, [completeUpload])

    const fileRemove = (file: { file: CustomFile; id: string }) => {
        if (completeUpload) {
            setCompleteUpload(false)
        }
        const updatedList = [...fileList]
        updatedList.splice(fileList.indexOf(file), 1)
        setFileList(updatedList)
        onFileChange(updatedList)
    }

    const onFileDrop = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            quality: 1,
            videoQuality: 1,
            allowsMultipleSelection: true,
        })

        if (!result.canceled) {
            if (completeUpload) {
                setCompleteUpload(false)
            }

            const files = result.assets.map(asset => {
                let type = ''

                if (Platform.OS === 'ios' || Platform.OS === 'android') {
                    type = asset.uri.split('.').find(e => allExtensions.includes(e)) || ''
                } else type = asset.uri.split(';')[0].split(':')[1]

                const file = { ...asset, type } as CustomFile
                return { file, id: String(uuid.v4()) }
            })

            const updatedList = [...fileList, ...files]
            setFileList(updatedList)
            onFileChange(updatedList)
        }
    }

    return (
        <>
            <Hoverable>
                {({ hovered }) => (
                    <Pressable
                        onPress={onFileDrop}
                        style={[
                            {
                                marginBottom: 20,
                                borderRadius: 20,
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderStyle: 'dashed',
                                borderWidth: 2,
                                borderColor: '#555961',
                                padding: 24,
                                opacity: hovered ? 0.6 : 1,
                            },
                            cursorPointerStyle as any,
                        ]}
                    >
                        <View style={{ alignItems: 'center', gap: 5 }}>
                            <Image source={uploadImg} style={{ width: 60, height: 60 }} />
                            <Text style={{ color: '#ccc', fontSize: 16 }}>Choose your files</Text>
                        </View>
                    </Pressable>
                )}
            </Hoverable>

            {fileList.length > 0 ? (
                <ScrollView
                    horizontal
                    contentContainerStyle={{ columnGap: 10 }}
                    style={{
                        flexDirection: 'row',
                        marginVertical: 10,
                        height: 100,
                    }}
                >
                    {fileList.map((item, index) => {
                        return (
                            <View key={index} style={{ width: 100, height: 120 }}>
                                <FilePreview file={item.file} />

                                {Object.values(uploadProgress).length < 1 && (
                                    <ButtonNative
                                        showIcon
                                        icon={faClose}
                                        onPress={() => fileRemove(item)}
                                        containerStyle={{
                                            top: 0,
                                            right: -5,
                                            position: 'absolute',
                                            zIndex: 2,
                                            minWidth: 20,
                                            margin: 0,
                                        }}
                                        buttonStyle={{
                                            paddingHorizontal: 0,
                                            paddingVertical: 0,
                                            backgroundColor: styles.backgroundColorPrimaryButton,
                                        }}
                                    />
                                )}

                                {uploadProgress[item.id] && (
                                    <ProgressBar
                                        progress={uploadProgress[item.id] / 100}
                                        color="#D00500"
                                        style={{
                                            width: '100%',
                                            marginTop: 8,
                                            height: 5,
                                        }}
                                    />
                                )}
                            </View>
                        )
                    })}
                </ScrollView>
            ) : null}
        </>
    )
}

interface FilePreviewProps {
    file: CustomFile
}

const FilePreview: React.FC<FilePreviewProps> = memo(({ file }) => {
    if (file.type.includes('image') || imageExtensions.includes(file.type)) {
        return (
            <Image
                resizeMode="cover"
                source={{ uri: file.uri }}
                style={{ width: '100%', maxWidth: '100%', height: 100, flex: 1 }}
            />
        )
    }

    if (file.type.includes('video') || videoExtensions.includes(file.type)) {
        return (
            <Video
                source={{ uri: file.uri }}
                resizeMode={ResizeMode.COVER}
                isLooping
                style={{ width: '100%', maxWidth: '100%', height: 100, flex: 1 }}
            />
        )
    }

    return <View />
})
