import { useState } from 'react'
import { View, Text, Switch, ViewStyle, StyleProp } from 'react-native'
import { getStyleSheet } from '../../../assets/theme/styles'

export interface Props {
    onStatusHandle: (value: boolean) => void
    currentStatus: string
    customStyles?: StyleProp<ViewStyle>
    allStatus: {
        active: string
        inactive: string
    }
}

const DropdownStatusOrgUser: React.FC<Props> = props => {
    const [currentStatus, setCurrentStatus] = useState(props?.currentStatus)
    const styles = getStyleSheet()

    const onStatusChange = (e: boolean) => {
        props.onStatusHandle(e)
        if (e) {
            setCurrentStatus(props.allStatus.active)
        } else {
            setCurrentStatus(props.allStatus.inactive)
        }
    }

    return (
        <View style={[{ flexDirection: 'row', gap: 5 }, props.customStyles]}>
            <Switch value={props?.currentStatus === 'ACTIVE'} onValueChange={value => onStatusChange(value)} />
            <Text style={[currentStatus !== 'ACTIVE' ? { marginLeft: 6 } : { marginLeft: 10 }, { color: styles.text }]}>
                {currentStatus}
            </Text>
        </View>
    )
}

export default DropdownStatusOrgUser
