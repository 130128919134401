import React, { useRef, useState } from 'react'
import { ImageBackground, StyleSheet, Text, View, Linking, ScrollView, Platform, Image } from 'react-native'
import { Button, CheckBox } from '@rneui/base'
import { isEmpty } from 'lodash'
import { signUp, resendSignUpCode, confirmSignUp, signInWithRedirect } from 'aws-amplify/auth'
import { getStyleSheet } from '../assets/theme/styles'
import { useThemeColor } from '../hooks/useThemeColor'
import { useFocusEffect } from '@react-navigation/native'
import Icon from 'react-native-vector-icons/FontAwesome'
import TermsModal from '../components/common/TermsModal'
import { UserService } from '../services/UserService'
import { ReportIssueModal } from '../components/common/ReportIssueModal'
import InputText from '../components/common/InputText'
import MessageNative from '../components/common/MessageNative'
import useDimensions from '../hooks/useDimensions'

const backgroundPattern = require('../assets/images/tomato_middle_background.png')
const logo = require('../assets/images/timerz-3.png')

const Signup = ({ navigation }) => {
    const styles = getStyleSheet()
    const verifyMessage = 'Verification code was sent to your email'
    const firstNameRef = useRef<any>(null)
    const lastNameRef = useRef<any>(null)
    const emailRef = useRef<any>(null)
    const passRef = useRef<any>(null)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [pass, setPass] = useState('')
    const [code, setCode] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [checkTerm, setCheckTerm] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const [showError, setShowError] = useState(false)
    const [showRequiredName, setShowRequiredName] = useState(false)
    const [showRequiredEmail, setShowRequiredEmail] = useState(false)
    const [showRequiredPass, setShowRequiredPass] = useState(false)
    const [showValidatePass, setShowValidatePass] = useState(false)
    const [showConfirmSign, setShowConfirmSign] = useState(false)
    const [showConfirmCode, setShowConfirmCode] = useState(false)
    const [successMessage, setSuccessMessage] = useState('')
    const [reportIssueModalVisible, setReportIssueModalVisible] = useState(false)
    const { windowWidth } = useDimensions()

    const { selectedReverseColorText } = useThemeColor()

    const signup = async () => {
        if (!validateFields()) {
            const signupParams = {
                username: email,
                password: pass,
                options: {
                    userAttributes: {
                        email: email,
                        given_name: firstName,
                        family_name: lastName,
                        'custom:acceptterms': checkTerm.toString(),
                    },
                    autoSignIn: true,
                },
            }

            await signUp(signupParams)
                .then(response => {
                    setSuccessMessage('An email was sent with the verification code.')
                    setShowConfirmSign(true)
                    setShowConfirm(true)
                })
                .catch(error => {
                    if (error.message.includes('PreSignUp')) {
                        setShowConfirmSign(false)
                        setShowConfirmCode(false)
                        setShowError(true)
                        let getMessageError = error?.message.split('error')
                        setErrorMessage(getMessageError[1])
                    } else {
                        UserService()
                            .getUserByEmail(email)
                            .then(resp => {
                                if (resp) {
                                    setShowConfirmSign(false)
                                    setShowConfirmCode(false)
                                    setShowError(true)
                                    if (typeof error === 'object') setErrorMessage(error.message)
                                    else setErrorMessage('Process could not be completed.')
                                } else {
                                    resentCode()
                                }
                            })
                            .catch(err => console.log(err.message))
                    }
                })
        }
    }

    const resentCode = async () => {
        if (!validateFields()) {
            await resendSignUpCode({ username: email })
                .then(response => {
                    setSuccessMessage('An email was sent with the verification code.')
                    setShowConfirmSign(true)
                    setShowConfirm(true)
                })
                .catch(error => {
                    setShowConfirmSign(false)
                    setShowConfirmCode(false)
                    setShowError(true)
                    setErrorMessage(error.message)
                })
        }
    }

    const confirmCode = () => {
        setShowError(false)

        confirmSignUp({ username: email, confirmationCode: code })
            .then(response => {
                if (response.isSignUpComplete) {
                    setSuccessMessage('Confirmed code. Sign in with your email.')
                    setShowConfirmSign(false)
                    setShowConfirmCode(true)

                    setTimeout(() => {
                        navigation.navigate('Login')
                    }, 3000)
                }
            })
            .catch(error => {
                setShowConfirmSign(false)
                setShowConfirmCode(false)
                setShowError(true)
                if (typeof error === 'object') setErrorMessage(error.message)
                else setErrorMessage('Process could not be completed.')
            })
    }

    const requiredStyleComponent = (elementRef: any) => {
        if (elementRef?.current) {
            elementRef.current.focus()

            if (Platform.OS === 'web') {
                elementRef.current.style.borderColor = '#E24C4C'
                elementRef.current.style.transitionDuration = '1s'

                setTimeout(() => {
                    elementRef.current.style.borderColor = 'transparent'
                }, 3000)
            }
        }
    }

    const validateFields = () => {
        setShowError(false)
        setShowConfirmSign(false)
        setShowRequiredPass(false)
        setShowRequiredEmail(false)
        setShowRequiredName(false)
        setShowConfirmSign(false)
        setShowConfirmCode(false)
        if (isEmpty(firstName)) {
            requiredStyleComponent(firstNameRef)
            setShowRequiredName(true)
            setErrorMessage('First name field is required')
            return true
        }
        if (isEmpty(lastName)) {
            requiredStyleComponent(lastNameRef)
            setShowRequiredName(true)
            setErrorMessage('Last name field is required')
            return true
        }
        if (isEmpty(email)) {
            requiredStyleComponent(emailRef)
            setShowRequiredEmail(true)
            setErrorMessage('Email field is required')
            return true
        }
        if (isEmpty(pass)) {
            requiredStyleComponent(passRef)
            setShowRequiredPass(true)
            setErrorMessage('Password field is required')
            return true
        }
        if (showValidatePass) {
            setErrorMessage('Minimun 8 characters, at least: one lowercase, one uppercase, one numeric')
            return true
        }
        if (!checkTerm) {
            setShowError(true)
            setErrorMessage('Accept Terms & conditions')
            return true
        }

        return false
    }

    const validatePass = (value: string) => {
        setShowError(false)
        setPass(value)
        let regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/
        const isValidate = value.match(regex)
        if (!isValidate) {
            setShowValidatePass(true)
            setErrorMessage('Minimun 8 characters, at least: one lowercase, one uppercase, one numeric')
        } else {
            setShowValidatePass(false)
            setErrorMessage('')
        }
    }

    const handleKeyDownSignUp = event => {
        if (event.key === 'Enter') {
            signup()
        }
    }

    const handleKeyDownConfirmCode = event => {
        if (event.key === 'Enter') {
            confirmCode()
        }
    }

    const clearComponents = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setPass('')
        setCode('')
        setErrorMessage('')
        setCheckTerm(false)
        setShowConfirm(false)
        setShowError(false)
        setShowConfirmSign(false)
        setShowConfirmCode(false)
    }

    useFocusEffect(
        React.useCallback(() => {
            clearComponents()
        }, [])
    )

    return (
        <ScrollView contentContainerStyle={{ flexGrow: 1, flexDirection: windowWidth > 768 ? 'row' : 'column' }}>
            <View
                style={{
                    width: windowWidth > 768 ? '35%' : '100%',
                    backgroundColor: styles.buttonBackgroundColorPrimary,
                    display: 'flex',
                    flexDirection: 'column',
                    paddingHorizontal: 20,
                    paddingTop: 20,
                    paddingBottom: 4,
                    flexGrow: 1,
                }}
            >
                <ImageBackground source={backgroundPattern} resizeMode="stretch" style={localStyles.imageBackground}>
                    <View style={localStyles.container}>
                        <Image source={logo} resizeMode="contain" style={localStyles.imageLogo} />

                        <Text
                            style={[
                                localStyles.textTitleLeft,
                                windowWidth > 768 ? { fontWeight: '700', fontSize: 24 } : null,
                            ]}
                        >
                            Already Signed Up?
                        </Text>

                        <Button
                            buttonStyle={[
                                localStyles.loginbButtonNavigate,
                                { backgroundColor: windowWidth > 768 ? '#FFF' : 'transparent' },
                            ]}
                            titleStyle={[
                                localStyles.loginTitleNavigate,
                                { color: windowWidth > 768 ? '#D00500' : '#FFF' },
                            ]}
                            containerStyle={[
                                localStyles.loginContainerNavigate,
                                { marginTop: windowWidth > 768 ? 40 : 0 },
                            ]}
                            title="LOG IN"
                            onPress={() => navigation.navigate('Login')}
                        />
                    </View>
                </ImageBackground>

                <Text
                    style={[
                        localStyles.textDescription,
                        {
                            padding: 20,
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            display: windowWidth > 768 ? 'flex' : 'none',
                        },
                    ]}
                >
                    Problems signing up? {` `}
                    <Text
                        style={{
                            textDecorationLine: 'underline',
                        }}
                        onPress={() => setReportIssueModalVisible(true)}
                    >
                        report it
                    </Text>
                </Text>

                <ReportIssueModal visible={reportIssueModalVisible} setShowModal={setReportIssueModalVisible} />
            </View>

            <View
                style={[
                    localStyles.container,
                    styles.styleLogin.container,
                    { width: windowWidth > 768 ? '65%' : '100%' },
                ]}
            >
                <View style={localStyles.containerForm}>
                    <Text style={[localStyles.textTitle, styles.styleLogin.colorText]}>Sign Up for an Account</Text>

                    {showConfirmSign ? (
                        <View style={{ width: '100%' }}>
                            <MessageNative severity="success" text={successMessage} />
                        </View>
                    ) : null}
                    {showConfirmCode ? (
                        <View style={{ width: '100%' }}>
                            <MessageNative severity="success" text={successMessage} />
                        </View>
                    ) : null}
                    {showError ? (
                        <View style={{ width: '100%' }}>
                            <MessageNative severity="error" text={errorMessage} />
                        </View>
                    ) : null}
                    <Text style={[localStyles.textLabel, styles.styleLogin.colorText]}>Enter your name</Text>
                    <View style={{ marginBottom: 20 }}>
                        <View
                            style={{
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                            }}
                        >
                            <View style={{ width: '48%' }}>
                                <InputText
                                    placeholder="First Name"
                                    ref={firstNameRef}
                                    value={firstName}
                                    onChange={e => setFirstName(e.valueOf())}
                                    onKeyPress={handleKeyDownSignUp}
                                    inputStyle={{ backgroundColor: styles.loginInputBackground }}
                                />
                            </View>
                            <View style={{ width: '48%' }}>
                                <InputText
                                    placeholder="Last Name"
                                    ref={lastNameRef}
                                    value={lastName}
                                    onChange={e => setLastName(e.valueOf())}
                                    onKeyPress={handleKeyDownSignUp}
                                    inputStyle={{ backgroundColor: styles.loginInputBackground }}
                                />
                            </View>
                        </View>
                        {showRequiredName ? (
                            <View>
                                <Text style={{ color: '#ef9a9a', fontSize: 12 }}>{errorMessage}</Text>
                            </View>
                        ) : null}
                    </View>
                    <Text style={[localStyles.textLabel, styles.styleLogin.colorText]}>Email Address</Text>
                    <View style={{ width: '100%', marginBottom: 20 }}>
                        <InputText
                            placeholder="Your email"
                            ref={emailRef}
                            value={email}
                            onChange={e => setEmail(e.valueOf())}
                            onKeyPress={handleKeyDownSignUp}
                            inputStyle={{ backgroundColor: styles.loginInputBackground }}
                        />
                        {showRequiredEmail ? (
                            <Text style={{ color: '#ef9a9a', fontSize: 12 }}>{errorMessage}</Text>
                        ) : null}
                    </View>
                    <Text style={[localStyles.textLabel, styles.styleLogin.colorText]}>Set a password</Text>
                    <View style={{ width: '100%', marginBottom: 20 }}>
                        <InputText
                            secureTextEntry
                            placeholder="Password"
                            ref={passRef}
                            value={pass}
                            onChange={e => validatePass(e.valueOf())}
                            onKeyPress={handleKeyDownSignUp}
                            inputStyle={{ backgroundColor: styles.loginInputBackground }}
                        />

                        {showRequiredPass || showValidatePass ? (
                            <Text style={{ color: '#ef9a9a', fontSize: 12 }}>{errorMessage}</Text>
                        ) : null}
                    </View>
                    <View style={localStyles.containerLoginButtons}>
                        <CheckBox
                            title=""
                            checked={checkTerm}
                            wrapperStyle={{ width: 30 }}
                            containerStyle={[localStyles.checkboxTermCondition, styles.styleLogin.container]}
                            textStyle={styles.styleLogin.colorText}
                            onPress={() => {
                                setCheckTerm(!checkTerm)
                            }}
                            checkedColor={selectedReverseColorText}
                        />
                        <Text style={[{ marginTop: 2 }, styles.styleLogin.colorText]}>I accept Timerz’s </Text>
                        <Text
                            style={[styles.styleLogin.colorText, localStyles.labelTermsConditions]}
                            onPress={() => {
                                Linking.openURL('https://dev.timerz.io/terms/')
                            }}
                        >
                            Terms & conditions
                        </Text>
                    </View>
                    {showConfirm ? (
                        <View style={{ width: '100%' }}>
                            <Text style={[localStyles.textVerify, styles.styleLogin.colorText]}>{verifyMessage}</Text>
                            <View style={{ width: '100%', marginBottom: 20 }}>
                                <InputText
                                    placeholder="Verification code"
                                    ref={emailRef}
                                    value={code}
                                    inputStyle={{ backgroundColor: styles.loginInputBackground }}
                                    onChange={e => {
                                        setCode(e.valueOf())
                                    }}
                                    onKeyPress={handleKeyDownConfirmCode}
                                />
                            </View>
                            <Button
                                buttonStyle={[localStyles.buttonSubmit, styles.styleBackground.button]}
                                titleStyle={localStyles.buttonSubmitTitle}
                                containerStyle={localStyles.buttonSubmitContainer}
                                title="CONFIRM CODE"
                                onPress={() => confirmCode()}
                            />
                            <Text
                                style={
                                    [
                                        styles.styleLogin.colorText,
                                        localStyles.labelTermsConditions,
                                        localStyles.resentCode,
                                    ] as any
                                }
                                onPress={() => {
                                    resentCode()
                                }}
                            >
                                Resent verification code
                            </Text>
                        </View>
                    ) : (
                        <Button
                            buttonStyle={[localStyles.buttonSubmit, styles.styleBackground.button]}
                            titleStyle={localStyles.buttonSubmitTitle}
                            containerStyle={localStyles.buttonSubmitContainer}
                            title="SIGN UP"
                            onPress={() => signup()}
                        />
                    )}
                    <Text style={localStyles.textLabelCenter}>or sign up using</Text>
                    <View style={localStyles.containerLoginButtons}>
                        <Button
                            buttonStyle={[localStyles.buttonProvider, styles.styleLogin.colorButtom]}
                            titleStyle={[localStyles.buttonProviderTitle, styles.styleLogin.colorText]}
                            containerStyle={localStyles.buttonProviderContainer}
                            icon={
                                <Icon
                                    name="google"
                                    size={25}
                                    style={[localStyles.buttonIcon, styles.styleLogin.colorText]}
                                />
                            }
                            title="GOOGLE"
                            onPress={() => signInWithRedirect({ provider: 'Google' })}
                        />
                        {/* <Button
                                buttonStyle={[localStyles.buttonProvider, styles.styleLogin.colorButtom]}
                                titleStyle={[localStyles.buttonProviderTitle, styles.styleLogin.colorText]}
                                containerStyle={localStyles.buttonProviderContainer}
                                icon={
                                    <Icon
                                        name="apple"
                                        size={25}
                                        style={[localStyles.buttonIcon, styles.styleLogin.colorText]}
                                    />
                                }
                                title="APPLE"
                                onPress={() => console.log('hello apple')}
                            /> */}
                    </View>
                </View>
            </View>
        </ScrollView>
    )
}

const localStyles = StyleSheet.create({
    containerForm: {
        maxWidth: 378,
        width: '90%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flexGrow: 1,
    },
    imageBackground: {
        flexGrow: 1,
        justifyContent: 'center',
    },
    imageLogo: {
        width: 125,
        height: 41,
        marginBottom: 20,
    },
    containerLoginButtons: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    buttonSubmit: {
        backgroundColor: '#D00500',
        height: 58,
        borderRadius: 4,
    },
    buttonSubmitTitle: {
        color: '#FFF',
        fontWeight: 'bold',
        fontSize: 16,
    },
    buttonSubmitContainer: {
        width: '100%',
        marginBottom: 23,
    },
    buttonIcon: {
        color: '#ffffff',
        marginRight: 13,
    },
    labelTermsConditions: {
        textDecorationLine: 'underline',
        fontSize: 14,
        lineHeight: 16.41,
        fontWeight: '500',
        marginTop: 4,
        flex: 1,
    },
    resentCode: {
        width: '100%',
        textAlign: 'end',
        marginBottom: 25,
        marginTop: -13,
    },
    textTitle: {
        color: '#ffffff',
        fontSize: 24,
        lineHeight: 28,
        fontWeight: '700',
        paddingVertical: 20,
    },
    textError: {
        color: '#cc0000',
        width: '100%',
        textAlign: 'left',
        marginBottom: 8,
    },
    textVerify: {
        width: '100%',
        textAlign: 'center',
        marginBottom: 8,
    },
    textTitleLeft: {
        color: '#ffffff',
        lineHeight: 28,
        fontSize: 16,
    },
    textDescription: {
        color: '#ffffff',
        fontSize: 16,
        lineHeight: 18.75,
        textAlign: 'center',
        fontWeight: '400',
        paddingLeft: 64,
        paddingRight: 64,
    },
    textLabelCenter: {
        width: '100%',
        color: '#C8D3DC',
        textAlign: 'center',
        marginBottom: 22,
        textTransform: 'lowercase',
    },
    textLabel: {
        width: '100%',
        color: '#ffffff',
        textAlign: 'left',
        marginBottom: 8,
        textTransform: 'uppercase',
    },
    inputText: {
        height: 50,
        color: '#ffffff',
        fontSize: 14,
        borderWidth: 0,
        outlineStyle: 'none',
    },
    placeHolderPicker: {
        paddingLeft: 25,
        color: 'rgb(150, 150, 150)',
        fontStyle: 'italic',
    },
    checkboxTermCondition: {
        width: 30,
        alignContent: 'flex-start',
        backgroundColor: '#202023',
        marginBottom: 21,
        marginTop: -10,
        marginLeft: -12,
        borderWidth: 0,
    },
    loginbButtonNavigate: {
        backgroundColor: '#ffffff',
        height: 58,
        borderRadius: 4,
    },
    loginTitleNavigate: {
        color: '#D00500',
        fontWeight: 'bold',
        fontSize: 16,
    },
    loginContainerNavigate: {
        width: 164,
    },
    buttonProvider: {
        height: 58,
        borderRadius: 4,
        marginLeft: 6,
        borderStyle: 'solid',
        borderColor: '#555961',
        borderWidth: 1,
    },
    buttonProviderTitle: {
        color: '#ffffff',
        fontWeight: 'bold',
        fontSize: 16,
    },
    buttonProviderContainer: {
        marginBottom: 23,
        flex: 1,
    },
})

export default Signup
