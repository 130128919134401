import React, { useContext, useEffect, useRef, useState } from 'react'
import { View, ScrollView, StyleSheet, Animated } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { getStyleSheet } from '../assets/theme/styles'
import { ProjectService } from '../services/ProjectService'
import { useFocusEffect } from '@react-navigation/native'
import { CreateProjectBar } from '../components/common/CreateProjectBar/CreateProjectBar'
import { StatusProject } from '../models'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { ClientService } from '../services/ClientService'
import { GroupService } from '../services/GroupService'
import { UpgradeContext } from '../context/upgrade'
import DataTable from '../components/common/DataTable'
import Searchbar from '../components/common/Searchbar/Searchbar'
import RowProject, { localStylesProject } from '../components/common/RowProject'
import { IProject } from '../interfaces/project.interface'
import SkeletonTable from '../components/common/SkeletonTable'
import { Role } from '../models'
import useDimensions from '../hooks/useDimensions'
import useSearchBar from '../components/common/Searchbar/useSearchBar'

const headerColumns = [
    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: 36 } },
    {
        label: 'PROJET',
        fieldToSorteable: 'name',
        isSorteable: true,
        styles: { flexGrow: 1 },
    },
    { label: 'CLIENT', fieldToSorteable: '', isSorteable: false, styles: { width: '20%' } },
    { label: 'GROUP', fieldToSorteable: '', isSorteable: false, styles: { width: '20%' } },

    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: 26 } },
]

const Projects = ({ ...props }) => {
    const current = 'Projects'
    const styles = getStyleSheet()
    const { route } = props
    const { userRole, userSub } = useContext<any>(ThemeContext)
    const { createdProjectsNumber, setCreatedProjectsNumber } = useContext<any>(UpgradeContext)
    const { getProjectsActive, saveProject, deleteProjectById } = ProjectService()
    const [projects, setProjects] = useState<IProject[]>([])
    const [allProjects, setAllProjects] = useState<IProject[]>([])
    const [loading, setLoading] = useState(false)
    const [allClients, setAllClients] = useState(null)
    const [allGroups, setAllGroups] = useState([])
    const [occupiedWidth, setOccupiedWidth] = useState(0)
    const { windowWidth } = useDimensions()
    const { isCollapsed, toggleSetIsCollapsed, fadeAnim } = useSearchBar()

    const getAllProjects = () => {
        setLoading(true)
        getProjectsActive(userRole, userSub)
            .then(data => {
                data = data.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    return 0
                })

                setAllProjects(data)
                setProjects(data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.log(error)
            })
    }

    const onNewProject = async (newProject: object) => {
        saveProject(newProject).then(response => {
            if (response) {
                getAllProjects()
                setCreatedProjectsNumber(createdProjectsNumber + 1)
            }
        })
    }

    const onDeleteRow = async (id: any) => {
        if (!id) {
            return
        }

        const row = projects.find((item: any) => item.id === id) as any

        if (!row) {
            return
        }

        await deleteProjectById(row.id, row._version)
        getAllProjects()
    }

    const onUpdateRow = (id: any, newObject: any = null) => {
        if (id) {
            const row = projects.find((item: any) => item.id === id) as any

            if (!row) return

            newObject = {
                id: row.id,
                status: StatusProject.ARCHIVED,
                _version: row._version,
            }
        }

        ProjectService()
            .updateProjectById(newObject)
            .then(() => {
                setCreatedProjectsNumber(createdProjectsNumber - 1)
                getAllProjects()
            })
            .catch(() => {})
    }

    const getClients = () => {
        ClientService()
            .getClientsDropDown(userSub)
            .then(data => {
                setAllClients(data)
            })
    }

    const getGroups = () => {
        GroupService()
            .getGroupsDropdown(userSub)
            .then(data => {
                setAllGroups(data)
            })
    }

    useFocusEffect(
        React.useCallback(() => {
            getAllProjects()
            getClients()
            getGroups()
        }, [userRole])
    )

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current, initial: 0 }} />
                    <View
                        onLayout={({ nativeEvent: { layout } }) => setOccupiedWidth(layout.width)}
                        style={[
                            styles.baseStyleContent.container,
                            localStyles.workArea,
                            windowWidth > 1000 ? { marginHorizontal: 50 } : null,
                        ]}
                    >
                        <Searchbar
                            items={allProjects}
                            isCollapsed={isCollapsed}
                            setIsCollapsed={toggleSetIsCollapsed}
                            onFilteredItems={filteredItems => {
                                setProjects(filteredItems)
                            }}
                        />

                        {userRole === Role.ADMIN ? (
                            <Animated.View
                                style={{
                                    transform: [{ translateY: fadeAnim ?? 0 }],
                                    display: isCollapsed ? 'flex' : 'none',
                                }}
                            >
                                <CreateProjectBar
                                    allClients={allClients}
                                    allGroups={allGroups}
                                    onCreateRow={onNewProject}
                                />
                            </Animated.View>
                        ) : null}

                        {(loading && !projects.length) || !occupiedWidth ? (
                            <SkeletonTable rowsQuantity={7} />
                        ) : (
                            <DataTable
                                headerCustomStyles={[
                                    localStylesProject.containerWhenThereOneFile,
                                    { width: occupiedWidth, display: windowWidth > 1200 ? 'flex' : 'none' },
                                ]}
                                isShowEmptyMessage={projects.length === 0}
                                emptyMessage="Create a new project."
                                itemsList={projects}
                                setItemsList={setProjects}
                                headerColumns={headerColumns as any}
                            >
                                {projects?.map((project: IProject) => {
                                    return (
                                        <RowProject
                                            style={{ width: occupiedWidth }}
                                            key={project.id}
                                            userRole={userRole}
                                            allClients={allClients}
                                            allGroups={allGroups}
                                            project={project}
                                            onUpdateRow={onUpdateRow}
                                            isReadOnly={false}
                                            loading={loading}
                                        />
                                    )
                                })}
                            </DataTable>
                        )}
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
    searchContainer: {
        zIndex: 5010,
        flexDirection: 'row',
        marginBottom: 12,
        height: 41,
    },
    textInputSearch: {
        paddingLeft: 10,
        width: 295,
        height: 40,
        borderColor: 'gray',
        borderRadius: 6,
        fontStyle: 'italic',
    },
    iconSearch: {
        width: 22,
        height: 22,
        position: 'absolute',
        marginTop: 10,
        right: 10,
    },
})

export default Projects
