import React from 'react'
import useMultiselectClients, { Props } from './useMultiselectClients'
import DropdownNative from '../MultiSelectNative/MultiSelect'
import useDimensions from '../../../hooks/useDimensions'

const MultiselectClients: React.FC<Props> = props => {
    const [{ clients, selectedClient, handleChange }] = useMultiselectClients(props)
    const { windowWidth } = useDimensions()

    return (
        <DropdownNative
            value={selectedClient as any}
            placeholder="Clients"
            optionLabel="label"
            optionValue="code"
            indentificator="multiClientReportBar"
            emptyFilterMessage="No clients added"
            containerStyles={props.containerStyle}
            customStyles={windowWidth < 600 ? { paddingHorizontal: 10, paddingVertical: 10 } : null}
            options={clients}
            onChange={handleChange}
        />
    )
}

export default MultiselectClients
