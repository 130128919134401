import { Skeleton } from '@rneui/themed'
import { getStyleSheet } from '../../assets/theme/styles'
import { StyleProp, ViewStyle } from 'react-native'

interface ItemSkeleton {
    width?: number | string
    height?: number | string
    customStyles?: StyleProp<ViewStyle>
}

const ItemSkeleton = ({ height = 30, width, customStyles }: ItemSkeleton) => {
    const styles = getStyleSheet()

    return (
        <Skeleton
            animation="pulse"
            skeletonStyle={{ backgroundColor: styles.initialSkeletonColor }}
            style={[{ backgroundColor: styles.finishSkeletonColor, borderRadius: 0, flexShrink: 1 }, customStyles]}
            width={width}
            height={height}
        />
    )
}

export default ItemSkeleton
