import { View, Text } from 'react-native'
import ButtonNative from './ButtonNative'
import { memo } from 'react'
import { faChevronDown, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { getStyleSheet } from '../../assets/theme/styles'

interface IRowReport {
    data: any
    isChildRow?: boolean
    isRowExpand: boolean
    toggleRowExpand: () => void
}

const RowReport = memo(
    ({ data, isRowExpand, toggleRowExpand, isChildRow }: IRowReport) => {
        const styles = getStyleSheet()

        return (
            <View
                style={[
                    {
                        backgroundColor: isChildRow ? styles.rowExpandBackground : styles.backgroundColorRow,
                        paddingVertical: 14,
                        paddingHorizontal: 20,
                        gap: 20,
                        borderRadius: 6,
                        flexDirection: 'row',
                        width: isChildRow ? '98%' : 'auto',
                    },
                ]}
            >
                {!isChildRow ? (
                    <ButtonNative
                        title=""
                        showIcon
                        buttonStyle={{ padding: 0 }}
                        icon={isRowExpand ? faChevronDown : faChevronRight}
                        onPress={toggleRowExpand}
                    />
                ) : (
                    <View />
                )}
                <Text style={{ flexGrow: 1, flexShrink: 1, color: styles.text }}>{data.name}</Text>
                <Text style={{ width: 50, color: styles.text }}>{data.totalTasks}</Text>
                <Text style={{ width: 60, color: styles.text }}>
                    {isChildRow ? data.billedTime : data.durationFormat}
                </Text>
            </View>
        )
    },
    (prev, next) => {
        if (next.isChildRow) {
            return prev.isChildRow === next.isChildRow
        }
        return false
    }
)

export default RowReport
