import React from 'react'
import useMultiselectProjects, { Props } from './useMultiselectProjects'
import DropdownNative from '../MultiSelectNative/MultiSelect'
import useDimensions from '../../../hooks/useDimensions'

const MultiselectProjects: React.FC<Props> = props => {
    const [{ projects, selectedProject, handleChange }] = useMultiselectProjects(props)
    const { windowWidth } = useDimensions()

    return (
        <DropdownNative
            value={selectedProject as any}
            placeholder="Projects"
            optionLabel="label"
            optionValue="code"
            optionGroupLabel="label"
            optionGroupValue="value"
            optionGroupChildren="items"
            indentificator="multiProjectReportBar"
            emptyFilterMessage="No projects added"
            options={projects}
            containerStyles={props.containerStyle}
            onChange={handleChange}
            customStyles={[
                props.buttomStyle,
                windowWidth < 600 ? { paddingHorizontal: 10, paddingVertical: 10 } : null,
            ]}
        />
    )
}

export default MultiselectProjects
