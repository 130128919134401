import React, { useContext, useRef, useState } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { useFocusEffect } from '@react-navigation/native'
import { LoadingSpinner } from '../components/common/LoadingSpinner'
import { getStyleSheet } from '../assets/theme/styles'
import { ClientService } from '../services/ClientService'
import { TagsService } from '../services/TagsService'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { StatusClient } from '../API'
import { UpgradeContext } from '../context/upgrade'
import Searchbar from '../components/common/Searchbar/Searchbar'
import SkeletonTable from '../components/common/SkeletonTable'
import DataTable from '../components/common/DataTable'
import RowClient from '../components/common/RowClient'
import useDimensions from '../hooks/useDimensions'

const headerColumns: any = [
    { label: 'CLIENT', fieldToSorteable: 'name', isSorteable: true, styles: { flexGrow: 1 } },
    { label: 'CREATION DATE', fieldToSorteable: 'createdAt', isSorteable: true, styles: { width: '35%' } },
    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: 26 } },
]

const ClientsArchived = ({ ...props }) => {
    const current = 'ClientsArchived'
    const styles = getStyleSheet()
    const { getClientsArchived, deleteAllTagsInClient, createTagsInClient, updateClientStatus } = ClientService()
    const { userRole, userSub } = useContext<any>(ThemeContext)
    const { createdClientsNumber, setCreatedClientsNumber } = useContext<any>(UpgradeContext)
    const { getAllTags } = TagsService()
    const [clients, setClients] = useState<any[]>([])
    const [allClients, setAllClients] = useState<any[]>([])
    const [tags, setTags] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [loading, setLoading] = useState(true)
    const [showConfirmationDelete, setShowConfirmationDelete] = useState(false)
    const [currentDelete, setCurrentDelete] = useState<any>(null)
    const { windowWidth } = useDimensions()

    const handleChangeTag = async (selectedTags: any, client: any) => {
        setLoading(true)

        if (client.TagsClient.items.length > 0) {
            await deleteAllTagsInClient(client.TagsClient.items)
        }

        if (selectedTags.length > 0) {
            for (const tagTask of selectedTags) {
                const tagsTask = { clientID: client.id, tagID: tagTask.id }
                await createTagsInClient(tagsTask)
            }
        }

        getAllClient()
    }

    const onUpdateStatusRow = async (id: any) => {
        if (!id) return

        const row = clients.find((item: any) => item.id === id) as any

        if (!row) {
            return
        }

        updateClientStatus(row.id, StatusClient.ACTIVE, row._version).then(() => {
            setCreatedClientsNumber(createdClientsNumber + 1)
            getAllClient()
        })
    }

    const getAllClient = () => {
        setLoading(true)
        if (userSub === '') return
        getClientsArchived(userSub)
            .then(data => {
                data = data.sort((a, b) => {
                    if (a.name < b.name) {
                        return -1
                    }
                    if (a.name > b.name) {
                        return 1
                    }
                    return 0
                })

                setClients(data)
                setAllClients(data)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
                console.log(error)
            })
    }

    useFocusEffect(
        React.useCallback(() => {
            getAllClient()

            if (userSub && userSub !== '') {
                getAllTags(userSub).then(data => {
                    setTags(data)
                })
            }
        }, [userRole, userSub])
    )

    return (
        <View style={localStyles.container}>
            <LoadingSpinner loadVisible={isLoading} />
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current }} />
                    <View style={[styles.baseStyleContent.container, localStyles.workArea]}>
                        <Searchbar
                            isCollapsed={false}
                            setIsCollapsed={() => {}}
                            doNotCollapse
                            items={allClients}
                            onFilteredItems={filteredItems => {
                                setClients(filteredItems)
                            }}
                        />

                        {loading ? (
                            <SkeletonTable rowsQuantity={8} />
                        ) : (
                            <DataTable
                                customStyles={{ marginTop: 30 }}
                                headerColumns={headerColumns}
                                itemsList={clients}
                                setItemsList={setClients}
                                emptyMessage="No archived clients."
                                isShowEmptyMessage={clients.length === 0}
                                headerCustomStyles={{
                                    padding: 10,
                                    gap: 6,
                                    display: windowWidth > 900 ? 'flex' : 'none',
                                }}
                            >
                                {clients.map(client => (
                                    <RowClient
                                        isReadOnly
                                        clientsLoading={loading}
                                        userRole={userRole}
                                        key={client.id}
                                        client={client}
                                        tags={tags}
                                        handleChangeTag={handleChangeTag}
                                        onUpdateRow={onUpdateStatusRow}
                                    />
                                ))}
                            </DataTable>
                        )}
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
    searchContainer: {
        zIndex: 5005,
        flexDirection: 'row',
        marginBottom: 12,
        height: 41,
    },
    textinputSearch: {
        paddingLeft: 10,
        width: 295,
        height: 40,
        borderColor: 'gray',
        borderRadius: 6,
        fontStyle: 'italic',
    },
    iconSearch: {
        width: 22,
        height: 22,
        position: 'absolute',
        marginTop: 10,
        right: 10,
    },
})

export default ClientsArchived
