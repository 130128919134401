import React from 'react'
import { Pressable, Text, StyleSheet, Platform } from 'react-native'
import { Hoverable } from 'react-native-web-hover'
import { CheckBox } from 'react-native-elements'
import { getStyleSheet } from '../../../assets/theme/styles'

type IItemMultiple = {
    option: any
    selectedItems: string[]
    optionValue: string
    optionLabel: string
    uniqueSelection?: boolean
    itemSelected: any
    handleCheckItem: (option: any) => void
}

export const cursorPointerStyle = Platform.OS === 'web' && { cursor: 'pointer', transition: 'all .1s ease-in' }

const ItemMultiple = ({
    option,
    selectedItems,
    optionLabel,
    optionValue,
    uniqueSelection,
    itemSelected,
    handleCheckItem,
}: IItemMultiple) => {
    const styles = getStyleSheet()

    return (
        <Hoverable>
            {({ hovered }) => (
                <Pressable
                    onPress={() => handleCheckItem(option)}
                    style={[
                        localStyles.item,
                        { backgroundColor: styles.backgroundComponent },
                        hovered || (itemSelected && itemSelected[optionValue] === option[optionValue])
                            ? [
                                  {
                                      backgroundColor: uniqueSelection
                                          ? styles.dropdownHighlightBackground
                                          : styles.multiSelectHeaderBackground,
                                  },
                                  cursorPointerStyle as any,
                              ]
                            : null,
                    ]}
                >
                    {!uniqueSelection ? (
                        <CheckBox
                            onPress={() => handleCheckItem(option)}
                            style={{ backgroundColor: styles.multiSelectHeaderBackground }}
                            containerStyle={{ margin: 0, padding: 0 }}
                            checked={selectedItems.includes(option[optionValue])}
                        />
                    ) : null}

                    <Text numberOfLines={1} style={{ color: styles.text }}>
                        {option[optionLabel]}
                    </Text>
                </Pressable>
            )}
        </Hoverable>
    )
}

const localStyles = StyleSheet.create({
    item: {
        flexDirection: 'row',
        gap: 6,
        alignItems: 'center',
        paddingVertical: 10,
        paddingHorizontal: 16,
    },
})

export default ItemMultiple
