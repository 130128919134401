// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const TypeMembership = {
  "FREE": "FREE",
  "MONTHLY": "MONTHLY",
  "YEARLY": "YEARLY"
};

const OrderStatus = {
  "SUCCESS": "SUCCESS",
  "FAILED": "FAILED"
};

const StatusTag = {
  "ACTIVE": "ACTIVE",
  "ARCHIVED": "ARCHIVED"
};

const StatusInvitation = {
  "PENDING": "PENDING",
  "ACCEPTED": "ACCEPTED"
};

const StatusTask = {
  "FINISHED": "FINISHED",
  "COMPLETED": "COMPLETED",
  "RUNNING": "RUNNING"
};

const Role = {
  "USER": "USER",
  "ADMIN": "ADMIN"
};

const StatusOrganizationUser = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const StatusClient = {
  "ACTIVE": "ACTIVE",
  "ARCHIVED": "ARCHIVED"
};

const StatusProject = {
  "ACTIVE": "ACTIVE",
  "ARCHIVED": "ARCHIVED"
};

const TypeTask = {
  "FOCUS": "FOCUS",
  "SBREAK": "SBREAK",
  "LBREAK": "LBREAK"
};

const Status = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE"
};

const { Order, Invitation, User, OrganizationUser, Organization, Plan, Tag, Group, Client, Project, Task, ByOrder, UserGroup, ClientTag, TaskTag, subscriptionResponse, SubscriptionItem, Invoice, Billing_details, Billing_card, PaymentMethod, CurrentSubscription, InvoicesByCustomer, StripeCoupon, OrderResult } = initSchema(schema);

export {
  Order,
  Invitation,
  User,
  OrganizationUser,
  Organization,
  Plan,
  Tag,
  Group,
  Client,
  Project,
  Task,
  ByOrder,
  UserGroup,
  ClientTag,
  TaskTag,
  TypeMembership,
  OrderStatus,
  StatusTag,
  StatusInvitation,
  StatusTask,
  Role,
  StatusOrganizationUser,
  StatusClient,
  StatusProject,
  TypeTask,
  Status,
  subscriptionResponse,
  SubscriptionItem,
  Invoice,
  Billing_details,
  Billing_card,
  PaymentMethod,
  CurrentSubscription,
  InvoicesByCustomer,
  StripeCoupon,
  OrderResult
};