import React, { useContext, useEffect } from 'react'
import { View, ScrollView, StyleSheet, Animated } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { InviteMembersModal } from '../components/common/InviteMembersModal'
import { getStyleSheet } from '../assets/theme/styles'
import { UserService } from '../services/UserService'
import { useFocusEffect } from '@react-navigation/native'
import { Role, StatusOrganizationUser } from '../models'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { StatusInvitation } from '../API'
import { GroupService } from '../services/GroupService'
import { useOrganizationProvider } from '../context/OrganizationProvider'
import { useUserProvider } from '../context/UserProvider'
import { useGroupProvider } from '../context/GroupProvider'
import useState from 'react-usestateref'
import Searchbar from '../components/common/Searchbar/Searchbar'
import DataTable from '../components/common/DataTable'
import RowTeam from '../components/common/RowTeam'
import SkeletonTable from '../components/common/SkeletonTable'
import useSearchBar from '../components/common/Searchbar/useSearchBar'
import useDimensions from '../hooks/useDimensions'

const Team = () => {
    const current = 'Team'
    const styles = getStyleSheet()
    const { userRole, userSub } = useContext<any>(ThemeContext)
    const [loading, setLoading] = useState(false)
    const [allMembers, setAllMembers] = useState<any>([])
    const [members, setMembers] = useState<any>([])
    const [allRoles, setAllRoles] = useState<any>([])
    const [allGroups, setAllGroups] = useState([])
    const [allStatusUser, setAllStatusUser] = useState<any>([])
    const [numberUsers, setNumberUsers] = useState(0)
    const {
        getOrganizationMembers,
        getInvitationsByOrganization,
        updateMemberInfo,
        updateLocalMember,
        organizationMembers,
        setMembersTotal,
    } = useOrganizationProvider()
    const { userActiveOrganization } = useUserProvider()
    const { deleteAllGroupsInUser, saveGroupToUser } = useGroupProvider()
    const { windowWidth } = useDimensions()
    const { isCollapsed, toggleSetIsCollapsed, fadeAnim } = useSearchBar()

    const headerColumns = [
        { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: '5%' } },
        { label: 'NAME', fieldToSorteable: 'userName', isSorteable: true, styles: { width: '20%' } },
        { label: 'EMAIL', fieldToSorteable: 'email', isSorteable: true, styles: { width: '25%' } },
        { label: 'GROUP', fieldToSorteable: '', isSorteable: false, styles: { width: '20%' } },
        { label: 'ACCESS', fieldToSorteable: '', isSorteable: false, styles: { width: '15%' } },
        { label: 'STATUS', fieldToSorteable: 'status', isSorteable: true, styles: { width: '15%' } },
    ]

    const notifyNoModify = () => {
        console.info('You cannot modify your data')
    }

    const operationNumberUser = (listMembers: any, listInvitations: any) => {
        let membersNumber = listMembers.filter(item => item.status === StatusOrganizationUser.ACTIVE).length
        let invitationNumber = listInvitations.length
        setNumberUsers(membersNumber + invitationNumber)
        setMembersTotal(membersNumber + invitationNumber)
    }

    const onUpdateRow = (selected, row, type) => {
        if (row.usersub === userSub) {
            notifyNoModify()
            return
        }

        let updateObject = {
            id: row.id,
            _version: row._version,
        }

        if (type === 'role') updateObject['role'] = selected
        if (type === 'status') {
            if (selected) updateObject['status'] = StatusOrganizationUser.ACTIVE
            else updateObject['status'] = StatusOrganizationUser.INACTIVE
        }

        updateMemberInfo(updateObject)
            .then(resp => {
                if (userActiveOrganization) {
                    getOrganizationMembers(userActiveOrganization.organizationID).then(listMembers => {
                        setMembers(listMembers)
                        setAllMembers(listMembers)
                    })
                }
            })
            .catch(error => {})
    }

    const handleChangeGroup = async (selectedGroups: any, user: any) => {
        try {
            const memberData = organizationMembers.find(member => member.userID === user.userID)

            let newMemberData = {
                ...memberData,
            }

            if (user.Groups.length > 0) {
                await deleteAllGroupsInUser(user.Groups)
                newMemberData.Groups = []
                newMemberData.userGroups = []
            }

            if (selectedGroups.length > 0) {
                const groupData = await saveGroupToUser(selectedGroups, user.userID)

                const groupDataFormated = groupData.map(data => {
                    return {
                        code: data.id,
                        name: data.name,
                    }
                })

                const groupsFormated = groupData.map(data => {
                    return {
                        id: data.id,
                        _version: data._version,
                    }
                })

                newMemberData.Groups = groupsFormated
                newMemberData.userGroups = groupDataFormated

                if (userActiveOrganization) {
                    const listMembers = await getOrganizationMembers(userActiveOrganization.organizationID)
                    const listInvitations = await getInvitationsByOrganization(
                        userActiveOrganization.organizationID,
                        StatusInvitation.PENDING
                    )
                    operationNumberUser(listMembers, listInvitations)
                    setMembers(listMembers)
                    setAllMembers(listMembers)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getUserRoles = () => {
        const allRoles = UserService().getDropDownRoles()
        setAllRoles(allRoles)
    }

    const getOrganizationUserStatus = () => {
        const allStatus = UserService().getDropdownStatusOrganizationUser()
        setAllStatusUser(allStatus)
    }

    const getGroups = () => {
        if (userSub === '') return
        GroupService()
            .getGroupsDropdown(userSub)
            .then(data => {
                setAllGroups(data)
            })
    }

    // const getUserSub = () => {
    //     AuthService()
    //         .getUserSub()
    //         .then(response => {
    //             setUserSubId(response as string)
    //         })
    // }

    useEffect(() => {
        setLoading(true)
        if (userActiveOrganization) {
            getOrganizationMembers(userActiveOrganization.organizationID).then(
                listMembers => {
                    getInvitationsByOrganization(userActiveOrganization.organizationID, StatusInvitation.PENDING).then(
                        listInvitations => {
                            operationNumberUser(listMembers, listInvitations)
                            setMembers(listMembers)
                            setAllMembers(listMembers)
                            setLoading(false)
                        }
                    )
                },
                err => {
                    console.log(err)
                    setLoading(false)
                }
            )
        }
    }, [userActiveOrganization])

    useEffect(() => {
        if (organizationMembers) {
            setMembers([...organizationMembers])
        }
    }, [organizationMembers])

    useFocusEffect(
        React.useCallback(() => {
            // getUserSub()
            getUserRoles()
            getGroups()
            getOrganizationUserStatus()
        }, [userRole])
    )

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView style={styles.styleBackground.container} stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current, initial: 0 }} />
                    <View style={[styles.baseStyleContent.container, localStyles.workArea]}>
                        <Searchbar
                            items={allMembers}
                            isCollapsed={isCollapsed}
                            setIsCollapsed={toggleSetIsCollapsed}
                            filterField="userName"
                            onFilteredItems={setMembers}
                        />

                        {userRole === Role.ADMIN ? (
                            <Animated.View
                                style={{
                                    transform: [{ translateY: fadeAnim ?? 0 }],
                                    display: isCollapsed ? 'flex' : 'none',
                                }}
                            >
                                <InviteMembersModal />
                            </Animated.View>
                        ) : null}

                        {loading ? (
                            <SkeletonTable rowsQuantity={8} />
                        ) : (
                            <DataTable
                                headerColumns={headerColumns as any}
                                isShowEmptyMessage={!members.length}
                                itemsList={members}
                                setItemsList={setMembers}
                                headerCustomStyles={{ display: windowWidth > 1100 ? 'flex' : 'none', padding: 14 }}
                                emptyMessage="Invite new members."
                            >
                                {members.map((member: any) => (
                                    <RowTeam
                                        allGroups={allGroups}
                                        onUpdateRow={onUpdateRow}
                                        handleChangeGroup={handleChangeGroup}
                                        key={member.id}
                                        team={member}
                                    />
                                ))}
                            </DataTable>
                        )}
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flex: 1,
        flexDirection: 'column',
        height: '100%',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
    },
    searchContainer: {
        zIndex: 5005,
        flexDirection: 'row',
        marginBottom: 12,
        height: 41,
    },
    textinputSearch: {
        paddingLeft: 10,
        width: 295,
        height: 40,
        borderColor: 'gray',
        borderRadius: 6,
        fontStyle: 'italic',
    },
    iconSearch: {
        width: 22,
        height: 22,
        position: 'absolute',
        marginTop: 10,
        right: 10,
    },
})

export default Team
