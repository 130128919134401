import * as React from 'react'
import { AuthService } from '../../services/AuthService'
import { OrganizationService } from '../../services/OrganizationService'
import { UserService } from '../../services/UserService'
import useState from 'react-usestateref'
import { Appearance, Platform } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'

export const ThemeContext = React.createContext<any>({})

export const ThemeProvider = ({ children }) => {
    const colorScheme = Appearance.getColorScheme()
    const [theme, setTheme] = React.useState(colorScheme)
    const [userRole, setUserRole, userRoleRef] = useState(null)
    const [userName, setUserName, userNameRef] = useState('')
    const [userEmail, setUserEmail, userEmailRef] = useState('')
    const [userSub, setUserSub, userSubRef] = useState('')
    const [userCustomerId, setUserCustomerId, userCustomerIdRef] = useState('')
    const [pomodoroTime, setPomodoroTime] = useState<number | null>(0)
    const [shortBreakTime, setShortBreakTime] = useState<number | null>(0)
    const [longBreakTime, setLongBreakTime] = useState<number | null>(0)
    const [automaticStartTime, setAutomaticStartTime] = useState<number | null>(0)
    const [subscribedSeats, setSubscribedSeats] = useState<number | null>(0)
    const [editionTimeFrame, setEditionTimeFrame] = useState<string>('always')
    const [isTheRoleLoaded, setIstheRoledLoaded] = useState(false)

    const getUserRole = () => {
        OrganizationService()
            .getDefaultOrganizationRole(setIstheRoledLoaded)
            .then(role => {
                setUserRole(role?.role)
            })
            .catch(error => console.log(error))
    }

    const getUserSub = () => {
        AuthService()
            .getUserSub()
            .then(response => {
                setUserSub(response as any)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const getUserProperties = async () => {
        if (!userSubRef.current) {
            const themeStorage = ((await AsyncStorage.getItem('TimerzTheme')) as any) || colorScheme
            setTheme(themeStorage)
            Platform.OS === 'web' ? (document.documentElement.className = themeStorage) : null
            return
        }

        UserService()
            .getUserById(userSubRef.current)
            .then(async user => {
                const themeColor = user?.themeMode || colorScheme
                await AsyncStorage.setItem('TimerzTheme', themeColor)
                setTheme(themeColor)
                const fullName = user?.givenName + ' ' + user?.familyName
                setUserName(user?.name || fullName)
                setUserEmail(user?.email)
                setUserCustomerId(user?.customerIdStripe)
                Platform.OS === 'web' ? (document.documentElement.className = themeColor) : null
            })
            .catch(async error => {
                const themeStorage = ((await AsyncStorage.getItem('TimerzTheme')) as any) || colorScheme
                setTheme(themeStorage)
                Platform.OS === 'web' ? (document.documentElement.className = themeStorage) : null
            })
    }

    const getOrganizationDefault = () => {
        if (!userSubRef.current) return

        OrganizationService()
            .getDefaultOrganizationName(userSubRef.current)
            .then(organizationUser => {
                setPomodoroTime(organizationUser?.organization?.pomodoroTime)
                setShortBreakTime(organizationUser?.organization?.shortBreakTime)
                setLongBreakTime(organizationUser?.organization?.longBreakTime)
                setAutomaticStartTime(organizationUser?.organization?.automaticStartTime)
                setEditionTimeFrame(organizationUser?.organization?.editionTimeFrame)
                setSubscribedSeats(organizationUser?.organization?.subscribedSeats)
            })
            .catch(error => {
                console.log(error)
            })
    }

    React.useEffect(() => {
        getUserSub()
        getUserRole()
    }, [])

    React.useEffect(() => {
        getUserRole()
        getUserProperties()
        getOrganizationDefault()
    }, [userSubRef.current])

    React.useEffect(() => {
        getUserProperties()
    }, [colorScheme])

    return (
        <ThemeContext.Provider
            value={{
                theme,
                userRole: userRoleRef.current,
                userName: userNameRef.current,
                userEmail: userEmailRef.current,
                userSub: userSubRef.current,
                userCustomerId: userCustomerIdRef.current,
                pomodoroTime,
                shortBreakTime,
                longBreakTime,
                editionTimeFrame,
                subscribedSeats,
                automaticStartTime,
                isTheRoleLoaded,
                setUserSub,
                getUserSub,
                setTheme,
                setUserRole,
                setEditionTimeFrame,
                setPomodoroTime,
                setShortBreakTime,
                setLongBreakTime,
                setSubscribedSeats,
                getUserRole,
                setAutomaticStartTime,
            }}
        >
            {children}
        </ThemeContext.Provider>
    )
}

export const useTheme = () => React.useContext(ThemeContext)
