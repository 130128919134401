import React, { useState, useContext, useEffect, useRef } from 'react'
import { View, StyleSheet, Text, Platform, StyleProp, TextStyle, ScrollView } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { getStyleSheet } from '../assets/theme/styles'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { Role } from '../models'
import { OrganizationService } from '../services/OrganizationService'
import { useUserProvider } from '../context/UserProvider'
import { useNavigation } from '@react-navigation/native'
import InputText from '../components/common/InputText'
import { TextInputMask } from 'react-native-masked-text'
import { Hoverable } from 'react-native-web-hover'
import ButtonNative from '../components/common/ButtonNative'
import MessageNative from '../components/common/MessageNative'
import DropdownNative from '../components/common/MultiSelectNative/MultiSelect'
import GenerateUUID from 'react-native-uuid'
import useDimensions from '../hooks/useDimensions'
import RenderOverlayPanel from '../components/common/RenderOverlayPanel'

const inputStyle = Platform.OS === 'web' && { outline: 0, transition: 'all .2s ease-in-out' }
const id = GenerateUUID.v4()

const Settings = () => {
    const current = 'Settings'
    const styles = getStyleSheet()
    const navigation = useNavigation()
    const { userRole, userSub, setEditionTimeFrame, setPomodoroTime, setShortBreakTime, setLongBreakTime } =
        useContext<any>(ThemeContext)
    const { setUpdateNameOrganization } = useUserProvider()
    const [organization, setOrganization] = useState<any>(null)
    const [pomodoroCycleTime, setPomodoroCycleTime] = useState<string>('')
    const [shortBreak, setShortBreak] = useState<string>('')
    const [longBreak, setLongBreak] = useState<string>('')
    const [editionFrame, setEditionFrame] = useState<string>('always')
    const [loading, setLoading] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [wsName, setWsName] = useState('')
    const { windowWidth } = useDimensions()

    const timeFrames = [
        { name: 'Always', code: 'always' },
        { name: 'Same day', code: 'day' },
        { name: 'Same week', code: 'week' },
        { name: 'Same month', code: 'month' },
    ]
    const regexTime = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))/

    const validateTime = () => {
        if (pomodoroCycleTime === '' || shortBreak === '' || longBreak === '') {
            return true
        }
        let isValidateP = pomodoroCycleTime.toString().match(regexTime)
        let isValidateS = shortBreak.toString().match(regexTime)
        let isValidateL = longBreak.toString().match(regexTime)
        if (!isValidateP || !isValidateS || !isValidateL) {
            return true
        }
        return false
    }

    const transformTimeToDecimal = (time: string) => {
        if (!time.includes(':')) return
        let splitTime = time.split(':')
        let hoursDecimal = parseInt(splitTime[0]) * 3600
        let minutesDecimal = parseInt(splitTime[1]) * 60
        let secondsDecimal = parseInt(splitTime[2])
        let totalTime = hoursDecimal + minutesDecimal + secondsDecimal
        return totalTime
    }

    function transformNumberToTime(num: number) {
        if (num > 3599) {
            let sec_num = parseInt(num.toString(), 10)
            let hours = Math.floor(sec_num / 3600)
            let minutes = Math.floor((sec_num - hours * 3600) / 60)
            let seconds = sec_num - hours * 3600 - minutes * 60

            let hourString = hours > 9 ? hours.toString() : '0' + hours.toString()
            let minuteString = minutes > 9 ? minutes.toString() : '0' + minutes.toString()
            let secondString = seconds > 9 ? seconds.toString() : '0' + seconds.toString()
            return hourString + ':' + minuteString + ':' + secondString
        } else {
            if (num > 59) {
                let minutes = Math.floor(num / 60)
                let seconds = num % 60

                let minuteString = minutes > 9 ? minutes.toString() : '0' + minutes.toString()
                let secondString = seconds > 9 ? seconds.toString() : '0' + seconds.toString()
                return '00' + ':' + minuteString + ':' + secondString
            } else {
                let seconds = Math.trunc(num)
                let secondString = seconds > 9 ? seconds.toString() : '0' + seconds.toString()

                return '00' + ':' + '00' + ':' + secondString
            }
        }
    }

    const validateMinimunTime = () => {
        let minimumPomodoro = transformTimeToDecimal(pomodoroCycleTime) || 0
        let minimumShortB = transformTimeToDecimal(shortBreak) || 0
        let minimumLongB = transformTimeToDecimal(longBreak) || 0
        if (minimumPomodoro < 900) {
            setErrorMessage('Minimum pomodoro time: 15 minutes')
            return true
        }

        if (minimumShortB < 300) {
            setErrorMessage('Minimum short break time: 5 minutes')
            return true
        }

        if (minimumLongB < 600) {
            setErrorMessage('Minimum long break time: 10 minutes')
            return true
        }

        return false
    }

    const saveChangesOrganization = () => {
        if (validateTime()) {
            return
        }
        if (validateMinimunTime()) {
            return
        }

        setLoading(true)

        let updateObject = {
            id: organization.id,
            name: wsName,
            pomodoroTime: transformTimeToDecimal(pomodoroCycleTime),
            shortBreakTime: transformTimeToDecimal(shortBreak),
            longBreakTime: transformTimeToDecimal(longBreak),
            editionTimeFrame: editionFrame,
            _version: organization._version,
        }

        OrganizationService()
            .updateOrganizationById(updateObject)
            .then((response: any) => {
                setEditionTimeFrame(response?.data?.updateOrganization?.editionTimeFrame)
                setPomodoroTime(response?.data?.updateOrganization?.pomodoroTime)
                setShortBreakTime(response?.data?.updateOrganization?.shortBreakTime)
                setLongBreakTime(response?.data?.updateOrganization?.longBreakTime)
                setUpdateNameOrganization(response?.data?.updateOrganization?.name)
                setLoading(false)
                navigation.navigate('SuccessChanges' as never)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getOrganizationDefault = () => {
        OrganizationService()
            .getDefaultOrganizationName(userSub)
            .then(organizationUser => {
                setPomodoroCycleTime(transformNumberToTime(organizationUser?.organization?.pomodoroTime))
                setShortBreak(transformNumberToTime(organizationUser?.organization?.shortBreakTime))
                setLongBreak(transformNumberToTime(organizationUser?.organization?.longBreakTime))
                setEditionFrame(organizationUser?.organization?.editionTimeFrame)
                setOrganization(organizationUser?.organization)
                setWsName(organizationUser?.organization?.name)
            })
            .catch(error => {})
    }

    const handleOnBlurPomodoroTime = () => {
        let splitTime = pomodoroCycleTime.split(':')
        let hoursInput = splitTime[0] && splitTime[0] !== '__' ? splitTime[0] : '00'
        let minutesInput = splitTime[1] && splitTime[1] !== '__' ? splitTime[1] : '00'
        let secondsInput = splitTime[2] && splitTime[2] !== '__' ? splitTime[2] : '00'

        setPomodoroCycleTime(hoursInput + ':' + minutesInput + ':' + secondsInput)
    }

    const handleOnBlurShortTime = () => {
        let splitTime = shortBreak.split(':')
        let hoursInput = splitTime[0] && splitTime[0] !== '__' ? splitTime[0] : '00'
        let minutesInput = splitTime[1] && splitTime[1] !== '__' ? splitTime[1] : '00'
        let secondsInput = splitTime[2] && splitTime[2] !== '__' ? splitTime[2] : '00'

        setShortBreak(hoursInput + ':' + minutesInput + ':' + secondsInput)
    }

    const handleOnBlurLongTime = () => {
        let splitTime = longBreak.split(':')
        let hoursInput = splitTime[0] && splitTime[0] !== '__' ? splitTime[0] : '00'
        let minutesInput = splitTime[1] && splitTime[1] !== '__' ? splitTime[1] : '00'
        let secondsInput = splitTime[2] && splitTime[2] !== '__' ? splitTime[2] : '00'

        setLongBreak(hoursInput + ':' + minutesInput + ':' + secondsInput)
    }

    useEffect(() => {
        if (userSub) {
            getOrganizationDefault()
        }
    }, [userRole, userSub])

    return (
        <View style={[localStyles.container, styles.styleBackground.container]}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subcontainer}>
                <ScrollView stickyHeaderIndices={[0]}>
                    <TopBar children={{ active: current }} />
                    <View style={[styles.baseStyleContent.container, localStyles.workArea]}>
                        <View style={localStyles.rowContainer}>
                            <Text style={[localStyles.timerSettingsTitle, styles.styleRow.colorText]}>
                                Workspace name
                            </Text>
                        </View>
                        <View style={{ flexDirection: 'row', columnGap: 15, marginBottom: 20 }}>
                            <View
                                style={{
                                    flexDirection: 'column',
                                    width: windowWidth <= 600 ? 'auto' : '33%',
                                    flexGrow: windowWidth <= 600 ? 1 : 0,
                                }}
                            >
                                <InputText
                                    value={wsName}
                                    onChange={e => setWsName(e.valueOf())}
                                    placeholder="Workspace name"
                                    inputStyle={{ height: 50 }}
                                />
                            </View>
                        </View>
                        <View style={localStyles.rowContainer}>
                            <Text style={[localStyles.timerSettingsTitle, styles.styleRow.colorText]}>
                                Timer settings (hh:mm:ss)
                            </Text>
                        </View>

                        <View
                            style={{ flexDirection: windowWidth <= 700 ? 'column' : 'row', gap: 20, marginBottom: 20 }}
                        >
                            <View style={{ flexGrow: 1, flexDirection: 'column' }}>
                                <Text style={[localStyles.textLabel, styles.styleRow.colorText]}>pomodoro</Text>
                                <Hoverable>
                                    {({ hovered }) => (
                                        <TextInputMask
                                            value={pomodoroCycleTime}
                                            onChangeText={e => {
                                                setPomodoroCycleTime(e.valueOf())
                                                setErrorMessage('')
                                            }}
                                            type="custom"
                                            options={{ mask: '99:99:99' }}
                                            placeholder="23:59:59"
                                            style={[
                                                localStyles.inputMask,
                                                { backgroundColor: styles.backgroundComponent, color: styles.text },
                                                inputStyle as any,
                                                hovered ? localStyles.hover : null,
                                            ]}
                                            onBlur={() => handleOnBlurPomodoroTime()}
                                        />
                                    )}
                                </Hoverable>
                            </View>
                            <View style={{ flexGrow: 1, flexDirection: 'column' }}>
                                <Text style={[localStyles.textLabel, styles.styleRow.colorText]}>short break</Text>
                                <Hoverable>
                                    {({ hovered }) => (
                                        <TextInputMask
                                            value={shortBreak}
                                            onChangeText={e => {
                                                setShortBreak(e.valueOf())
                                                setErrorMessage('')
                                            }}
                                            type="custom"
                                            options={{ mask: '99:99:99' }}
                                            placeholder="23:59:59"
                                            style={[
                                                localStyles.inputMask,
                                                { backgroundColor: styles.backgroundComponent, color: styles.text },
                                                inputStyle as any,
                                                hovered ? localStyles.hover : null,
                                            ]}
                                            onBlur={() => handleOnBlurShortTime()}
                                        />
                                    )}
                                </Hoverable>
                            </View>
                            <View style={{ flexGrow: 1, flexDirection: 'column' }}>
                                <Text style={[localStyles.textLabel, styles.styleRow.colorText]}>long break</Text>
                                <Hoverable>
                                    {({ hovered }) => (
                                        <TextInputMask
                                            value={longBreak}
                                            onChangeText={e => {
                                                setLongBreak(e.valueOf())
                                                setErrorMessage('')
                                            }}
                                            type="custom"
                                            options={{ mask: '99:99:99' }}
                                            placeholder="23:59:59"
                                            style={[
                                                localStyles.inputMask,
                                                { backgroundColor: styles.backgroundComponent, color: styles.text },
                                                inputStyle as any,
                                                hovered ? localStyles.hover : null,
                                            ]}
                                            onBlur={() => handleOnBlurLongTime()}
                                        />
                                    )}
                                </Hoverable>
                            </View>
                        </View>
                        <View style={{ flexDirection: 'row', columnGap: 15, marginBottom: 20, zIndex: 100 }}>
                            <View
                                style={{
                                    flexDirection: 'column',
                                    flexGrow: windowWidth <= 600 ? 1 : 0,
                                    width: windowWidth <= 600 ? 'auto' : '33%',
                                }}
                            >
                                <Text style={[localStyles.textLabel, styles.styleRow.colorText]}>
                                    edition time frame
                                </Text>
                                <DropdownNative
                                    valueWhenUniqueSelection={editionFrame}
                                    uniqueSelection
                                    indentificator={id as string}
                                    onChange={value => setEditionFrame(value)}
                                    optionLabel="name"
                                    optionValue="code"
                                    options={timeFrames}
                                    hideSearch
                                    customStyles={{ height: 50 }}
                                    placeholder=""
                                />
                            </View>
                        </View>
                        <View
                            style={{
                                flexDirection: 'row',
                                columnGap: 15,
                                marginBottom: 20,
                                justifyContent: 'flex-end',
                            }}
                        >
                            <View style={{ flexGrow: windowWidth <= 600 ? 1 : 0, flexDirection: 'column' }}>
                                {errorMessage ? (
                                    <View style={{ width: '100%' }}>
                                        <MessageNative severity="error" text={errorMessage} />
                                    </View>
                                ) : null}

                                {userRole === Role.ADMIN ? (
                                    <ButtonNative
                                        title="SAVE"
                                        primaryTheme
                                        titleStyle={{ fontWeight: '600', fontSize: 15 }}
                                        buttonStyle={{ height: 50 }}
                                        onPress={saveChangesOrganization}
                                        disabled={userRole !== Role.ADMIN || loading}
                                    />
                                ) : null}
                            </View>
                        </View>
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

export default Settings

const localStyles = StyleSheet.create({
    container: {
        flexGrow: 1,
        flexDirection: 'row',
    },
    subcontainer: {
        flexGrow: 1,
        flexDirection: 'column',
    },
    workArea: {
        width: 'auto',
        marginLeft: 29,
        marginRight: 29,
        marginTop: 29,
        zIndex: -1,
    },
    rowContainer: {
        width: '100%',
        flexDirection: 'row',
    },
    timerSettingsTitle: {
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        marginBottom: 20,
    },
    textLabel: {
        width: '100%',
        fontSize: 10,
        textAlign: 'left',
        marginBottom: 8,
        textTransform: 'uppercase',
    },
    inputMask: {
        height: 50,
        paddingHorizontal: 15,
        borderColor: 'transparent',
        fontSize: 14,
        width: '100%',
        borderRadius: 4,
    },
    hover: {
        borderColor: '#C6D2D9',
        borderWidth: 1,
    },
})
