import { View, StyleSheet } from 'react-native'
import { Button } from 'react-native-elements'
import { getStyleSheet } from '../../assets/theme/styles'

const FooterDialog = ({ handleOnNo, handleOnYes }: { handleOnNo: () => void; handleOnYes: () => void }) => {
    const styles = getStyleSheet()

    return (
        <View style={localStyles.container}>
            <Button
                title="NO"
                containerStyle={[localStyles.buttonYesNoContainer, { borderColor: styles.text }]}
                titleStyle={[localStyles.buttonYesNoTitle, { color: styles.text }]}
                buttonStyle={localStyles.buttonYesNo}
                onPress={handleOnNo}
            />
            <Button
                title="YES"
                containerStyle={[
                    localStyles.buttonYesNoContainer,
                    { backgroundColor: styles.backgroundColorPrimaryButton, borderColor: 'transparent' },
                ]}
                titleStyle={[localStyles.buttonYesNoTitle]}
                buttonStyle={localStyles.buttonYesNo}
                onPress={handleOnYes}
            />
        </View>
    )
}

const localStyles = StyleSheet.create({
    container: { flexDirection: 'row', gap: 10, justifyContent: 'flex-end' },
    buttonYesNoContainer: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderRadius: 4,
        width: 100,
    },
    buttonYesNoTitle: {
        color: '#fff',
        fontFamily: 'Roboto-Regular',
        fontSize: 16,
        lineHeight: 19,
        letterSpacing: 0.25,
    },
    buttonYesNo: {
        backgroundColor: 'transparent',
        height: 58,
    },
})

export default FooterDialog
