import DropdownProjects from '../DropdownProjects/DropdownProjects'
import MultiselectTags from '../MultiselectTags/MultiselectTags'
import { View, Text, ViewStyle, StyleProp, TextStyle, Image, TouchableWithoutFeedback, Pressable } from 'react-native'
import GroupedRecordMenu from '../GroupedRecordMenu/GroupedRecordMenu'
import { useTaskProvider } from '../../../context/TaskProvider'
import moment from 'moment'
import { useEffect, useState, memo, useMemo } from 'react'
import ButtonNative from '../ButtonNative'
import { FORMAT } from '../../../interfaces/user-provider.interface'
import { IUserOrganization } from '../../../interfaces/user-organization.interface'
import { TagsService } from '../../../services/TagsService'
import InputText from '../InputText'
import useDimensions from '../../../hooks/useDimensions'
import { isEqual } from 'lodash'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { getStyleSheet } from '../../../assets/theme/styles'
import { faPlay, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import DatePicker from '../DatePicker/DatePicker'
import uuid from 'react-native-uuid'

const timeFormatRegExp = /^[0-9]{2}\:[0-9]{2}?$/
const ltRegex = /^(1[0-2]|[1-9]|0[1-9]):[0-5][0-9] (AM|PM|am|pm)$/

interface ITaskRow {
    task: any
    disableEdition: boolean
    rowsToSpand: string[]
    projects: any[]
    tags: any[]
    isInsideTask?: boolean
    isParentTask?: boolean
    loadingTasks: boolean
    idSendToExpand?: string
    timeFormat: FORMAT
    userActiveOrganization: IUserOrganization | undefined
    customStyles?: StyleProp<ViewStyle>
    rowWidth: number
    setRowsToSpand: React.Dispatch<React.SetStateAction<string[]>>
    onUpdateMultipleTask: () => Promise<void>
    onShowConfirmationDelete: (id: string) => void
}

const FULL_TASK_WIDTH = 1400
type FocusedInput = 'initial' | 'end' | 'voice' | null
type TypesSendDate = 'initial' | 'end'

const inputEndDateId = uuid.v4() as string
const inputInitialDateId = uuid.v4() as string

const TaskRow = memo(
    ({
        task,
        disableEdition,
        rowsToSpand,
        projects,
        setRowsToSpand,
        tags,
        isInsideTask,
        isParentTask,
        onShowConfirmationDelete,
        loadingTasks,
        rowWidth,
        idSendToExpand,
        timeFormat,
        userActiveOrganization,
        onUpdateMultipleTask,
        customStyles,
    }: ITaskRow) => {
        const { playTask, onUpdateRow, deleteAllTagsInTask, createTagsInTask, duplicateTask } = useTaskProvider()
        const [valueName, setValueName] = useState<string>('')
        const [createdTimeInitial, setCreatedTimeInitial] = useState<string>('')
        const [createdTimeEnd, setCreatedTimeEnd] = useState<string>('')
        const [isLoading, setIsLoading] = useState(false)
        const [sendDateObjects, setSendDateObjects] = useState<{
            [key in TypesSendDate]: {
                createdAt: string
            }
        }>({
            end: { createdAt: '' },
            initial: { createdAt: '' },
        })
        const [isShowDetails, setIsShowDetails] = useState(false)
        const [focusedInput, setFocusedInput] = useState<FocusedInput>(null)
        const { dimensions } = useDimensions()
        const styles = getStyleSheet()
        const { windowWidth } = useDimensions()
        const regex: RegExp = timeFormat === 'LT' ? ltRegex : timeFormatRegExp

        useEffect(() => {
            if (createdTimeInitial !== task.createdTimeFormat) setCreatedTimeInitial(task.createdTimeFormat)
            if (createdTimeEnd !== task.createdTimeEndFormat) setCreatedTimeEnd(task.createdTimeEndFormat)
            if (valueName !== task.name) setValueName(task.name)
        }, [task])

        const selectedTagsFormat = task.arrayTags.map(item => {
            return {
                value: item.id,
                label: item.name,
                color: item.color.includes('#') ? item.color : '#' + item.color,
            }
        })

        const onChangeTags = async (tags: any) => {
            const newTags: any[] = [] // Variable que guardará los tasks no creados

            const areThereTagsCreate = tags
                .filter((tag: any) => !tag.value) // Filtrando los tags que no estan creados
                .map((tag: any) => {
                    const newTag = {
                        color: '808080',
                        name: tag.label,
                        organizationID: userActiveOrganization?.organizationID,
                        status: 'ACTIVE',
                    }
                    return newTag
                })

            if (areThereTagsCreate.length) {
                for (const tag of areThereTagsCreate) {
                    const newTag = await TagsService().saveTag(tag) // Creando los tags no creados
                    newTags.push(newTag)
                }
            }

            const dataToSend = newTags.length // Todos los tasks ya creados
                ? tags.map((tag: any) => {
                      const findTag = newTags.find(newTag => newTag.name === tag.label)
                      if (findTag) return { color: findTag.color, label: findTag.name, value: findTag.id }
                      return tag
                  })
                : tags

            setIsLoading(true)
            await handleChangeTag(dataToSend)
            await onUpdateMultipleTask()
            setIsLoading(false)
        }

        const handleChangeTag = async (selectedTags: any) => {
            if (task.id.includes(',')) {
                const arrayIds = task.id.split(',')
                task.groupTask.map(async item => {
                    if (item.TagsTask.items.length > 0) {
                        await deleteAllTagsInTask(item.TagsTask.items)
                    }
                })

                await addMultipleTagsTask(arrayIds, selectedTags)
            } else {
                if (task.TagsTask.items.length > 0) {
                    await deleteAllTagsInTask(task.TagsTask.items)
                }

                if (selectedTags.length > 0) {
                    for (const tagTask of selectedTags) {
                        const tagsTask = { taskID: task.id, tagID: tagTask.value }
                        await createTagsInTask(tagsTask)
                    }
                }
            }
        }

        const addMultipleTagsTask = async (arrayIds, selectedTags) => {
            for (const taskId of arrayIds) {
                for (const tagTask of selectedTags) {
                    const tagsTask = { taskID: taskId, tagID: tagTask.value }
                    await createTagsInTask(tagsTask)
                }
            }
        }

        const handleUpdateRow = async (newValue: any, isSortedEndDate?: boolean) => {
            await onUpdateRow(newValue, isSortedEndDate)
        }

        const onProjectChange = (projectID: string, row: any) => {
            const objectUpdate = {
                id: row.id,
                name: row.name,
                createdAt: row.createdAt,
                projectID: projectID,
                _version: row._version,
            }

            handleUpdateRow(objectUpdate).then(async () => {
                if (isInsideTask) await onUpdateMultipleTask()
            })
        }

        const handleDateEdition = async (date: Date) => {
            const currentDate = moment(task.date, 'HH:mm:ss A')

            const newCreatedDate = moment(date).set({
                hour: currentDate.hour(),
                minute: currentDate.minute(),
                second: currentDate.second(),
            })

            const objectUpdate = {
                id: task.id,
                createdAt: newCreatedDate.format('YYYY-MM-DDTHH:mmZ'),
                hasTimeEdited: true,
                _version: task._version,
            }

            if (currentDate.format('YYYY-MM-DD') === newCreatedDate.format('YYYY-MM-DD')) return

            await onUpdateRow(objectUpdate as any)
            onUpdateMultipleTask()
        }

        const onCellEditComplete = ({
            newValue,
            field,
        }: {
            newValue: string
            field: 'name' | 'createdTimeFormat' | 'createdTimeEndFormat'
        }) => {
            const newDataTask = { ...task }
            const newValueFormated = moment(newValue, 'HH:mm A').format(timeFormat)

            if (field === 'name') {
                if (newValue.trim().length > 0) {
                    if (newValue.trim() !== newDataTask[field].trim()) {
                        newDataTask[field] = newValue
                        const objectUpdate = {
                            id: newDataTask['id'],
                            name: newValue,
                            createdAt: newDataTask['created'],
                            hasTimeEdited: newDataTask['hasTimeEdited'],
                            _version: newDataTask['_version'],
                        }

                        handleUpdateRow(objectUpdate).then(async () => {
                            if (isInsideTask) await onUpdateMultipleTask()
                        })
                    }
                }
            }

            if (field === 'createdTimeFormat') {
                const startTime = moment(newValueFormated, 'HH:mm:ss A')

                setSendDateObjects({
                    ...sendDateObjects,
                    initial: { createdAt: startTime.format() },
                })
            }

            if (field === 'createdTimeEndFormat') {
                const endTimeFormated = moment(newValueFormated, timeFormat)
                const endDate = moment(endTimeFormated, 'hh:mm:ss A')

                setSendDateObjects({
                    ...sendDateObjects,
                    end: { createdAt: endDate.format() },
                })
            }
        }

        const handleOnPLay = () => {
            const taskContinue = {
                name: task.name,
                project: task.project,
                arrayTags: task.arrayTags,
                continue: true,
            }

            playTask(taskContinue)
        }

        const memoActions = useMemo(() => {
            if (dimensions.window.width < 850) {
                return [
                    { label: 'Play', icon: 'play', action: handleOnPLay },
                    { label: 'Delete', icon: 'delete', action: () => onShowConfirmationDelete(task.id) },
                    {
                        label: 'Duplicate',
                        icon: 'content-duplicate',
                        action: () => duplicateTask(task, selectedTagsFormat),
                    },
                ]
            }
            return [
                { label: 'Delete', icon: 'delete', action: () => onShowConfirmationDelete(task.id) },
                {
                    label: 'Duplicate',
                    icon: 'content-duplicate',
                    action: () => duplicateTask(task, selectedTagsFormat),
                },
            ]
        }, [dimensions, task, selectedTagsFormat])

        const handleModifyDate = ({
            valueInitialDate,
            valueEndDate,
        }: {
            valueInitialDate?: string
            valueEndDate?: string
        }) => {
            const { end, initial } = sendDateObjects
            const isValidatedStartDate =
                regex.test(createdTimeInitial) && createdTimeInitial.trim() !== task.createdTimeFormat.trim()

            const isValidatedEndDate =
                regex.test(createdTimeEnd) && createdTimeEnd.trim() !== task.createdTimeEndFormat.trim()

            if (focusedInput === null && (isValidatedStartDate || isValidatedEndDate)) {
                const defaultStartDate = moment(valueInitialDate || task.createdTimeFormat, 'hh:mm:ss A').format()
                const defaultEndDate = moment(valueEndDate || task.createdTimeEndFormat, 'hh:mm:ss A').format()

                const startDate = moment(initial.createdAt || defaultStartDate)
                const endDate = moment(end.createdAt || defaultEndDate)

                const totalDuration = moment.duration(endDate.diff(startDate))
                const calculatedTime = moment.utc(totalDuration.asMilliseconds()).format('HH:mm:ss')

                const newCreatedDate = moment(task.created).set({
                    hour: startDate.hour(),
                    minute: startDate.minute(),
                })

                const objectUpdate = {
                    id: task.id,
                    time: calculatedTime,
                    createdAt: newCreatedDate.format(),
                    hasTimeEdited: true,
                    _version: task._version,
                }

                setSendDateObjects({
                    end: { createdAt: '' },
                    initial: { createdAt: '' },
                })

                handleUpdateRow(objectUpdate, true)
            }
        }

        const DateRangeComponent = () => {
            return (
                <View style={{ flexDirection: 'row', alignItems: 'center', gap: 6 }}>
                    <InputText
                        id={inputInitialDateId}
                        placeholder=""
                        containerStyle={{ width: 80 }}
                        inputStyle={{ textAlign: 'center', paddingHorizontal: 0 }}
                        value={createdTimeInitial}
                        onChange={e => setCreatedTimeInitial(e.valueOf())}
                        onBlur={(e: any) => {
                            if (e.relatedTarget?.id === inputEndDateId) {
                                onCellEditComplete({
                                    field: 'createdTimeFormat',
                                    newValue: createdTimeInitial,
                                })
                            } else handleModifyDate({ valueInitialDate: createdTimeInitial })
                        }}
                        disabled={disableEdition || isParentTask}
                    />

                    <FontAwesomeIcon color={styles.text} size={12} icon={faMinus} />

                    <InputText
                        id={inputEndDateId}
                        placeholder=""
                        containerStyle={{ width: 80 }}
                        inputStyle={{ textAlign: 'center', paddingHorizontal: 0 }}
                        value={createdTimeEnd}
                        onChange={e => setCreatedTimeEnd(e.valueOf())}
                        onBlur={(e: any) => {
                            if (e.relatedTarget?.id === inputInitialDateId) {
                                onCellEditComplete({
                                    field: 'createdTimeEndFormat',
                                    newValue: createdTimeEnd,
                                })
                            } else handleModifyDate({ valueEndDate: createdTimeEnd })
                        }}
                        disabled={disableEdition || isParentTask}
                    />
                </View>
            )
        }

        if (rowWidth === 0) return <View style={{ height: 50, width: '100%' }}></View>

        return (
            <TouchableWithoutFeedback onPress={() => setFocusedInput(null)}>
                <View
                    style={[
                        {
                            backgroundColor: styles.backgroundColorRow,
                            borderRadius: 6,
                            minHeight: 56,
                            rowGap: 10,
                            padding: 8,
                            width: rowWidth,
                        },
                        customStyles,
                        isInsideTask
                            ? { width: (rowWidth * 99) / 100, backgroundColor: styles.rowExpandBackground }
                            : null,
                    ]}
                >
                    {/* Primera fila que se muestra hasta los ${FULL_TASK_WIDTH} de width */}
                    {windowWidth <= FULL_TASK_WIDTH ? (
                        <View style={[{ flexGrow: 1, alignItems: 'center', flexDirection: 'row' }]}>
                            {isParentTask ? (
                                <ButtonNative
                                    title=""
                                    showIcon
                                    buttonStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
                                    containerStyle={{ width: '10%' }}
                                    icon={rowsToSpand.includes(idSendToExpand || '') ? faChevronDown : faChevronRight}
                                    onPress={() => {
                                        setFocusedInput(null)
                                        if (rowsToSpand.includes(idSendToExpand || ''))
                                            return setRowsToSpand(ids => ids.filter(id => id !== idSendToExpand))
                                        setRowsToSpand([...rowsToSpand, idSendToExpand || ''])
                                    }}
                                />
                            ) : (
                                <View style={{ width: '10%' }}>
                                    <DatePicker
                                        onPress={() => setFocusedInput(null)}
                                        disabled={disableEdition}
                                        identificator={`${task.id}-identificator`}
                                        value={task.createdNewDate}
                                        onChange={handleDateEdition}
                                    />
                                </View>
                            )}

                            <InputText
                                value={valueName}
                                containerStyle={[{ width: '40%' }, windowWidth > 850 ? { width: '30%' } : null]}
                                inputStyle={{ backgroundColor: 'transparent' }}
                                onChange={e => {
                                    setValueName(e.valueOf())
                                }}
                                disabled={disableEdition}
                                onBlur={() => {
                                    onCellEditComplete({
                                        field: 'name',
                                        newValue: valueName,
                                    })
                                }}
                            />

                            {windowWidth > 850 ? (
                                <CustomDropdownProjects
                                    customStyles={{ width: '30%' }}
                                    projects={projects}
                                    onProjectChange={onProjectChange}
                                    disableEdition={disableEdition}
                                    task={task}
                                    onPress={() => setFocusedInput(null)}
                                />
                            ) : null}

                            <View
                                style={[
                                    {
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'flex-end',
                                        width: '50%',
                                        gap: windowWidth > 600 ? 10 : 0,
                                    },
                                    windowWidth > 850 ? { width: '30%' } : null,
                                ]}
                            >
                                <PomodoroQuantity
                                    styles={{ paddingHorizontal: 14, flexGrow: windowWidth <= 400 ? 1 : 0 }}
                                    loadingTasks={false}
                                    quantity={task?.pomodoro || 0}
                                />

                                {(windowWidth > 600 && windowWidth <= 850 && isShowDetails) || windowWidth > 1000 ? (
                                    <BilletTime time={isParentTask ? task.totalTime : task.billedTime} />
                                ) : null}

                                {windowWidth > 850 && !isInsideTask ? (
                                    <ButtonNative
                                        disabledStyle={{ backgroundColor: 'transparent' }}
                                        disabled={disableEdition}
                                        title=""
                                        iconSize={15}
                                        showIcon
                                        icon={faPlay}
                                        onPress={() => {
                                            handleOnPLay()
                                            setFocusedInput(null)
                                        }}
                                    />
                                ) : null}

                                <ButtonNative
                                    onPress={() => {
                                        setIsShowDetails(!isShowDetails)
                                        setFocusedInput(null)
                                    }}
                                    showIcon
                                    icon={isShowDetails ? faMinus : faPlus}
                                    iconColor={styles.text}
                                    buttonStyle={{ paddingHorizontal: 0, paddingVertical: 0 }}
                                    iconSize={23}
                                />
                            </View>
                        </View>
                    ) : null}

                    {/* Fila que se muestra después de los ${FULL_TASK_WIDTH} de width */}
                    {windowWidth > FULL_TASK_WIDTH ? (
                        <View style={{ alignItems: 'center', flexDirection: 'row' }}>
                            {isParentTask ? (
                                <ButtonNative
                                    title=""
                                    showIcon
                                    containerStyle={{ width: '5%' }}
                                    icon={rowsToSpand.includes(idSendToExpand || '') ? faChevronDown : faChevronRight}
                                    onPress={() => {
                                        setFocusedInput(null)
                                        if (rowsToSpand.includes(idSendToExpand || ''))
                                            return setRowsToSpand(ids => ids.filter(id => id !== idSendToExpand))
                                        setRowsToSpand([...rowsToSpand, idSendToExpand || ''])
                                    }}
                                />
                            ) : (
                                <View style={{ width: '5%', height: 36 }} />
                            )}

                            <InputText
                                value={valueName}
                                containerStyle={{ flexGrow: 1 }}
                                inputStyle={[{ backgroundColor: 'transparent' }]}
                                onChange={e => {
                                    setValueName(e.valueOf())
                                }}
                                onBlur={() => {
                                    onCellEditComplete({
                                        field: 'name',
                                        newValue: valueName,
                                    })
                                }}
                                disabled={disableEdition}
                            />

                            <CustomDropdownProjects
                                customStyles={{ width: '15%', flexGrow: 0, paddingHorizontal: 10 }}
                                projects={projects}
                                onProjectChange={onProjectChange}
                                disableEdition={disableEdition}
                                task={task}
                                onPress={() => setFocusedInput(null)}
                            />

                            <DropdownTag
                                id={task.id}
                                tags={tags}
                                selectedTagsFormat={selectedTagsFormat}
                                customStyles={{ width: '15%', paddingRight: 10 }}
                                disabled={disableEdition || isLoading}
                                onChangeTags={onChangeTags}
                                onPress={() => setFocusedInput(null)}
                            />

                            <View
                                style={{
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    gap: 14,
                                }}
                            >
                                {DateRangeComponent()}

                                <DatePicker
                                    disabled={disableEdition}
                                    identificator={`${task.id}-identificator`}
                                    value={task.createdNewDate}
                                    onChange={handleDateEdition}
                                    onPress={() => setFocusedInput(null)}
                                />

                                <PomodoroQuantity loadingTasks={false} quantity={task?.pomodoro || 0} />

                                <BilletTime time={isParentTask ? task.totalTime : task.billedTime} />

                                {windowWidth > 850 && !isInsideTask ? (
                                    <ButtonNative
                                        disabledStyle={{ backgroundColor: 'transparent' }}
                                        disabled={disableEdition}
                                        title=""
                                        iconSize={15}
                                        showIcon
                                        icon={faPlay}
                                        onPress={() => {
                                            handleOnPLay()
                                            setFocusedInput(null)
                                        }}
                                    />
                                ) : (
                                    <View style={{ width: 35, height: 31 }} />
                                )}

                                <GroupedRecordMenu
                                    identificator={task.id}
                                    disabled={disableEdition}
                                    items={memoActions}
                                    onPress={() => setFocusedInput(null)}
                                />
                            </View>
                        </View>
                    ) : null}

                    {/* Filas que se muestran cuando la tarea está en mood expansive */}
                    {isShowDetails && windowWidth <= FULL_TASK_WIDTH ? (
                        <View style={{ display: isShowDetails && windowWidth <= FULL_TASK_WIDTH ? 'flex' : 'none' }}>
                            {/* Segunda row */}
                            <View
                                style={{
                                    alignItems: 'center',
                                    flexDirection: 'row',
                                    zIndex: 100,
                                    flexGrow: 1,
                                }}
                            >
                                {windowWidth > 600 ? (
                                    <DropdownTag
                                        id={task.id}
                                        tags={tags}
                                        selectedTagsFormat={selectedTagsFormat}
                                        customStyles={{
                                            width: windowWidth <= 850 ? '20%' : '50%',
                                        }}
                                        disabled={disableEdition || isLoading}
                                        onChangeTags={onChangeTags}
                                        onPress={() => setFocusedInput(null)}
                                    />
                                ) : null}

                                {windowWidth <= 850 ? (
                                    <CustomDropdownProjects
                                        customStyles={{
                                            width: windowWidth <= 600 ? '50%' : '30%',
                                        }}
                                        projects={projects}
                                        onProjectChange={onProjectChange}
                                        disableEdition={disableEdition}
                                        task={task}
                                        onPress={() => setFocusedInput(null)}
                                    />
                                ) : null}

                                {windowWidth <= 600 ? (
                                    <BilletTime
                                        styles={{ flexGrow: 1, textAlign: 'center' }}
                                        time={isParentTask ? task.totalTime : task.billedTime}
                                    />
                                ) : null}

                                {windowWidth > 600 ? (
                                    <View
                                        style={{
                                            flexGrow: windowWidth > 850 ? 1 : 0,
                                            alignItems: 'center',
                                            flexDirection: 'row',
                                            justifyContent: 'flex-end',
                                        }}
                                    >
                                        {DateRangeComponent()}
                                        {windowWidth > 900 && windowWidth < 1000 ? (
                                            <BilletTime
                                                styles={{ marginRight: 10 }}
                                                time={isParentTask ? task.totalTime : task.billedTime}
                                            />
                                        ) : null}
                                    </View>
                                ) : null}

                                <GroupedRecordMenu
                                    identificator={task.id}
                                    disabled={disableEdition}
                                    items={memoActions}
                                    onPress={() => setFocusedInput(null)}
                                />
                            </View>

                            {/* Tercera row */}
                            {windowWidth <= 600 ? (
                                <View style={{ flexDirection: 'row' }}>
                                    <DropdownTag
                                        id={task.id}
                                        tags={tags}
                                        selectedTagsFormat={selectedTagsFormat}
                                        customStyles={{ maxWidth: '40%', flexGrow: 1 }}
                                        disabled={disableEdition || isLoading}
                                        onChangeTags={onChangeTags}
                                        onPress={() => setFocusedInput(null)}
                                    />
                                    <View style={{ flexGrow: 1, alignItems: 'flex-end' }}>{DateRangeComponent()}</View>
                                </View>
                            ) : null}
                        </View>
                    ) : null}
                </View>
            </TouchableWithoutFeedback>
        )
    },
    (prev, next) => {
        // Para pasarle props a este componente estas se necesitan validar aqui,
        // para que el componente solo se vuelva a renderizar cuando estas cambien
        const validationPreRender =
            prev.tags?.length === next.tags?.length &&
            prev.loadingTasks === next.loadingTasks &&
            prev.rowWidth === next.rowWidth &&
            prev.timeFormat === next.timeFormat &&
            isEqual(prev.task, next.task)

        if (!next.idSendToExpand) return validationPreRender

        return (
            prev.rowsToSpand.includes(prev.idSendToExpand || '') ===
                next.rowsToSpand.includes(next.idSendToExpand || '') &&
            prev.rowsToSpand === next.rowsToSpand &&
            validationPreRender
        )
    }
)

const CustomDropdownProjects = ({ customStyles, projects, onProjectChange, task, disableEdition, onPress }) => {
    return (
        <DropdownProjects
            style={customStyles}
            allProjects={projects}
            onProjectChange={value => onProjectChange(value, task)}
            selectedProject={task.project}
            disabledEditionFrame={disableEdition}
            task={task}
            onPress={onPress}
        />
    )
}

const DropdownTag = ({ selectedTagsFormat, tags, onChangeTags, disabled, id, customStyles, onPress }) => {
    return (
        <MultiselectTags
            value={selectedTagsFormat}
            tags={tags}
            onChangeTag={value => onChangeTags(value)}
            maximunTag={3}
            customStyles={customStyles}
            indentificator={id}
            disabled={disabled}
            onPress={onPress}
        />
    )
}

const BilletTime = ({ time, styles }: { time: string; styles?: StyleProp<TextStyle> }) => {
    const globalStyles = getStyleSheet()
    return <Text style={[{ fontSize: 16, fontWeight: '500', color: globalStyles.text }, styles]}>{time}</Text>
}

const PomodoroQuantity = ({
    loadingTasks,
    quantity,
    styles,
}: {
    styles?: StyleProp<TextStyle>
    loadingTasks: boolean
    quantity: number
}) => {
    const globalStyles = getStyleSheet()

    return (
        <View
            style={[
                {
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10,
                    justifyContent: 'flex-end',
                },
                styles,
            ]}
        >
            <Text style={{ color: globalStyles.text }}>{quantity}</Text>
            {!loadingTasks ? (
                <Image source={require('../../../assets/icons/pomodoro.png')} style={{ width: 23, height: 23 }} />
            ) : null}
        </View>
    )
}

export default TaskRow
