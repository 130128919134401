import React from 'react'
import useMultiselectMembers, { Props } from './useMultiselectMembers'
import DropdownNative from '../MultiSelectNative/MultiSelect'
import { v4 } from 'uuid'
import useDimensions from '../../../hooks/useDimensions'

const clientsDropdownId = v4()

const MultiselectMembers: React.FC<Props> = props => {
    const [{ members, selectedMember, handleChange }] = useMultiselectMembers(props)
    const { windowWidth } = useDimensions()

    return (
        <DropdownNative
            value={selectedMember as any}
            placeholder="Members"
            optionLabel="label"
            optionValue="code"
            indentificator={clientsDropdownId}
            emptyFilterMessage="No members added"
            options={members}
            containerStyles={props.containerStyle}
            customStyles={[
                props.buttomStyle,
                windowWidth < 600 ? { paddingHorizontal: 10, paddingVertical: 10 } : null,
            ]}
            onChange={handleChange}
            includeHovered={props.includeHovered}
        />
    )
}

export default MultiselectMembers
