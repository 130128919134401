import React from 'react'
import { View, StyleSheet, Platform } from 'react-native'
import { Button } from 'react-native-elements'
import { ImagePreview } from './ImagePreview'
import { getStyleSheet } from '../../assets/theme/styles'
import useDimensions from '../../hooks/useDimensions'
import * as ImagePicker from 'expo-image-picker'
import { allExtensions } from '../../helpers/utils'
import uuid from 'react-native-uuid'

export type CustomFile = ImagePicker.ImagePickerAsset & { type: string }

export const ProfileImage = ({ selectedImage, setSelectedImage }) => {
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()

    const onSelectFile = async () => {
        const result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.Images,
            quality: 1,
            base64: true,
        })

        if (!result.canceled) {
            const file = result.assets[0]
            let type = ''
            let uri = file.uri

            if (Platform.OS === 'ios' || Platform.OS === 'android') {
                type = uri.split('.').find(e => allExtensions.includes(e)) || ''
                uri = `data:image/${type};base64,${file.base64}` // The image on android and ios platform does not come a base 64 correct format
            } else type = uri.split(';')[0].split(':')[1]

            setSelectedImage({ ...file, uri, type, id: String(uuid.v4()) })
        }
    }

    return (
        <View style={{ alignItems: windowWidth <= 900 ? 'center' : 'stretch' }}>
            <ImagePreview
                imageUri={selectedImage?.uri}
                imageStyle={[localStyles.imagePreview, styles.styleRow.container]}
            />

            <View>
                <Button
                    onPress={onSelectFile}
                    title="Select photo"
                    buttonStyle={[formStyles.buttonSave, { backgroundColor: styles.backgroundColorPrimaryButton }]}
                    titleStyle={formStyles.buttonSaveTitle}
                    containerStyle={{ width: '100%' }}
                />
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    inputText: {
        height: 50,
        color: '#ffffff',
        fontSize: 14,
        fontStyle: 'italic',
        borderWidth: 0,
    },
    buttonStyle: {
        backgroundColor: '#D00500',
        padding: 2,
        borderRadius: 4,
    },
    titleButton: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 16,
        textTransform: 'uppercase',
    },
    imagePreview: {
        borderRadius: 6,
        width: 159,
        height: 159,
        backgroundColor: '#000000',
    },
})

const formStyles = StyleSheet.create({
    buttonSave: {
        marginTop: 20,
        height: 50,
        borderRadius: 4,
    },
    buttonSaveTitle: {
        color: '#fff',
        fontWeight: 'bold',
        fontSize: 16,
    },
})
