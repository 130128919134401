import { Props } from './usePomodoroLabel'
import React from 'react'
import { View, Text, Image } from 'react-native'
import { getStyleSheet } from '../../../assets/theme/styles'

const PomodoroLabel: React.FC<Props> = props => {
    const styles = getStyleSheet()
    return (
        <View style={{ flexDirection: 'row', alignItems: 'center', gap: 10 }}>
            <Text style={{ color: styles.text }}>{props.label}</Text>
            <Image
                source={require('../../../assets/icons/pomodoro.png')}
                style={{ width: 23, height: 23 }}
                resizeMode="contain"
            />
        </View>
    )
}

export default PomodoroLabel
