import * as FileSystem from 'expo-file-system'
import { Platform } from 'react-native'
const { StorageAccessFramework } = FileSystem
import { shareAsync } from 'expo-sharing'

export async function handleMobileDownload(fileUrl: string, fileNameOutput: string, alertMessage: string) {
    const downloadResumable = FileSystem.createDownloadResumable(fileUrl, FileSystem.documentDirectory + 'generic.pdf')

    try {
        await downloadResumable.downloadAsync().then(async res => {
            if (!res?.uri) return
            if (Platform.OS === 'android') saveAndroidFile(res.uri, fileNameOutput, alertMessage)
            if (Platform.OS === 'ios') shareAsync(res.uri)
        })
    } catch (e) {
        console.warn(e)
    }
}

const saveAndroidFile = async (fileUri: string, fileName: string, alertMessage: string) => {
    try {
        const fileString = await FileSystem.readAsStringAsync(fileUri, { encoding: FileSystem.EncodingType.Base64 })

        const permissions = await StorageAccessFramework.requestDirectoryPermissionsAsync()
        if (!permissions.granted) return

        try {
            await StorageAccessFramework.createFileAsync(permissions.directoryUri, fileName, 'application/pdf')
                .then(async uri => {
                    await FileSystem.writeAsStringAsync(uri, fileString, {
                        encoding: FileSystem.EncodingType.Base64,
                    })
                    alert(alertMessage)
                })
                .catch(e => console.warn(e))
        } catch (e: any) {
            throw new Error(e.message)
        }
    } catch (err) {}
}

export const createFileOnMobile = async (data: string, fileName: string, alertMessage: string) => {
    const permissions = await StorageAccessFramework.requestDirectoryPermissionsAsync()
    if (!permissions.granted) return

    await StorageAccessFramework.createFileAsync(permissions.directoryUri, fileName, 'application/pdf')
        .then(async uri => {
            await FileSystem.writeAsStringAsync(uri, data)
            alert(alertMessage)
        })
        .catch(e => console.warn(e))
}
