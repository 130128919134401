import { uploadData, getUrl } from 'aws-amplify/storage'
import { CustomFile } from '../components/common/DropFileInput/DropFileInput'

export const ReportProblemService = () => {
    const putStorageFile = async (
        fileName: string,
        selectedFile: CustomFile,
        progressCallback?: (progress: any) => void
    ) => {
        return await uploadData({
            key: fileName,
            data: selectedFile as any,
            options: {
                contentType: selectedFile.type,
                accessLevel: 'guest',
                onProgress: ({ transferredBytes, totalBytes }) => {
                    if (totalBytes) {
                        const percentage = Math.round(transferredBytes / totalBytes) * 100
                        progressCallback && progressCallback(percentage)
                    }
                },
            },
        }).result
    }

    const getFileByName = async (fileName: string) => {
        return await getUrl({ key: fileName })
    }

    return {
        putStorageFile,
        getFileByName,
    }
}
