import React, { useEffect, useState } from 'react'
import { View, TextInput, StyleSheet, Image } from 'react-native'
import { getStyleSheet } from '../../../assets/theme/styles'
import { IProject } from '../../../interfaces/project.interface'
import useDimensions from '../../../hooks/useDimensions'
import ButtonNative from '../ButtonNative'
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons'
import { useUserProvider } from '../../../context/UserProvider'

interface IProps {
    items: IProject[]
    filterField?: string
    isCollapsed: boolean
    doNotCollapse?: boolean
    onFilteredItems: (filteredItems: IProject[] | any[]) => void
    setIsCollapsed: () => void
}

const Searchbar: React.FC<IProps> = ({
    items,
    doNotCollapse,
    filterField,
    isCollapsed,
    setIsCollapsed,
    onFilteredItems,
}) => {
    const styles = getStyleSheet()
    const { windowWidth } = useDimensions()
    const [itemsBackup, setItemsBacup] = useState<any[]>([])
    const { dbUserData } = useUserProvider()

    useEffect(() => {
        if (!itemsBackup.length) setItemsBacup(items)
    }, [items])

    const filterItems = (text: string) => {
        if (!text.length) onFilteredItems(itemsBackup)

        const itemsTmp = itemsBackup.filter(item => {
            return item[filterField || 'name'].toLowerCase().includes(text.toLowerCase())
        })

        onFilteredItems(itemsTmp)
    }

    return (
        <View style={localStyles.searchContainer}>
            <View
                style={{
                    width: windowWidth <= 500 ? '100%' : 'auto',
                    flexDirection: 'row',
                    gap: 20,
                    alignItems: 'center',
                }}
            >
                <View
                    style={[
                        { flexDirection: 'row', alignItems: 'center', flexGrow: 1, flexShrink: 1 },
                        windowWidth > 500 ? { width: 265 } : null,
                    ]}
                >
                    <TextInput
                        style={[localStyles.textInputSearch, styles.styleRow.colorText, styles.styleRow.container]}
                        placeholder="Search anything..."
                        placeholderTextColor="rgb(150, 150, 150)"
                        onChangeText={filterItems}
                    />
                    <Image source={require('../../../assets/icons/ic_search.png')} style={localStyles.iconSearch} />
                </View>

                {!doNotCollapse ? (
                    <ButtonNative
                        primaryTheme
                        containerStyle={{ minWidth: 'auto' }}
                        onPress={setIsCollapsed}
                        showIcon
                        iconColor="#fff"
                        disabled={dbUserData?.disabled}
                        icon={isCollapsed ? faMinus : faPlus}
                    />
                ) : null}
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    searchContainer: {
        flexDirection: 'row',
        marginBottom: 12,
        height: 41,
        justifyContent: 'flex-end',
    },
    textInputSearch: {
        paddingLeft: 10,
        height: 40,
        borderColor: 'gray',
        borderRadius: 6,
        fontStyle: 'italic',
        flexGrow: 1,
    },
    iconSearch: {
        width: 22,
        height: 22,
        position: 'absolute',
        right: 10,
    },
    iconCard: {
        width: 22,
        height: 22,
        position: 'absolute',
        marginTop: 10,
        right: 30,
    },
    iconList: {
        width: 22,
        height: 22,
        position: 'absolute',
        marginTop: 10,
        right: 0,
    },
})

export default Searchbar
