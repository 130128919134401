import moment from 'moment'
import * as RNLocalize from 'react-native-localize'

export const useDefaultLocale = () => {
    const getLocalFormat = () => {
        let localeData = moment.localeData()
        let dateFormatLocal = localeData.longDateFormat('L')
        const timeFormatLocal = 'false'
        const timeZoneLocal = RNLocalize.getTimeZone()

        return {
            dateFormatLocal,
            timeFormatLocal,
            timeZoneLocal,
        }
    }

    return {
        getLocalFormat,
    }
}
