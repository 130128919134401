import { useContext } from 'react'
import { ThemeContext } from '../assets/theme/ThemeProvider';
import { darkColors, lightColors } from '../assets/theme/colorThemes';

export const useThemeColor = () => {

    const { theme } = useContext<any>(ThemeContext);
    let selectedColorText: string = lightColors.text;
    let selectedReverseColorText: string = darkColors.reverseText;
    let backgroundColor: string = lightColors.backgroundColorSwitch;

    if (theme === 'dark') {
        selectedColorText = darkColors.text;
        selectedReverseColorText = lightColors.reverseText;
        backgroundColor = darkColors.backgroundColorSwitch;
    }

  return {
    selectedColorText,
    selectedReverseColorText,
    backgroundColor,
  }
}
