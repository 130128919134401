import useMultiselectTags, { Props } from './useMultiselectTags'
import DropdownNative from '../MultiSelectNative/MultiSelect'
import useDimensions from '../../../hooks/useDimensions'

const MultiselectTagsFilter: React.FC<Props> = props => {
    const { tagsMultiselect, handleChangeFilter } = useMultiselectTags(props)
    const { windowWidth } = useDimensions()

    return (
        <DropdownNative
            placeholder="Tags"
            optionLabel="name"
            optionValue="id"
            indentificator="multiTagReportBar"
            emptyFilterMessage="No tags added"
            options={tagsMultiselect}
            containerStyles={props.containerStyle}
            customStyles={[windowWidth < 600 ? { paddingHorizontal: 10, paddingVertical: 10 } : null]}
            onChange={value => handleChangeFilter(value)}
        />
    )
}

export default MultiselectTagsFilter
