import React, { useContext, useCallback } from 'react'
import { View, ScrollView, Text, StyleSheet, Image, Pressable, Platform } from 'react-native'
import { Sidebar } from '../components/sections/Sidebar'
import { TopBar } from '../components/sections/TopBar'
import { getStyleSheet } from '../assets/theme/styles'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { ReportDetailService } from '../services/ReportDetailService'
import { OrganizationService } from '../services/OrganizationService'
import { TagsService } from '../services/TagsService'
import moment from 'moment'
import { CSVLink } from 'react-csv'
import MultiselectProjects from '../components/common/MultiselectProjects/MultiselectProjects'
import MultiselectMembers from '../components/common/MultiSelectMembers/MultiselectMembers'
import MultiselectClients from '../components/common/MultiselectClients/MultiselectClients'
import MultiselectTagsFilter from '../components/common/MultiselectTags/MultiselectTagsFilter'
import useState from 'react-usestateref'
import DataTable, { MomentaryView } from '../components/common/DataTable'
import RowReportDetails from '../components/common/RowReportDetails'
import SkeletonTable from '../components/common/SkeletonTable'
import ButtonNative from '../components/common/ButtonNative'
import DatePicker from '../components/common/DatePicker/DatePicker'
import { OPTIONS_DATES_RANGE, RenderOptionsDatePicker } from './Reports'
import { useFocusEffect } from '@react-navigation/native'
import DropdownNative from '../components/common/MultiSelectNative/MultiSelect'
import uuid from 'react-native-uuid'
import useDimensions from '../hooks/useDimensions'
import { useUserProvider } from '../context/UserProvider'
import { useOverlayProvider } from '../context/OverlayProvider'

const headerColumns = [
    { label: '', fieldToSorteable: '', isSorteable: false, styles: { width: '5%' } },
    { label: 'NAME', fieldToSorteable: 'name', isSorteable: true, styles: { width: '25%', paddingLeft: 25 } },
    { label: 'PROJECT', fieldToSorteable: '', isSorteable: false, styles: { width: '10%', justifyContent: 'center' } },
    { label: 'TIME', fieldToSorteable: '', isSorteable: false, styles: { width: '20%', justifyContent: 'center' } },
    { label: 'DURATION', fieldToSorteable: '', isSorteable: false, styles: { width: '10%', justifyContent: 'center' } },
    { label: 'POMODORO', fieldToSorteable: '', isSorteable: false, styles: { width: '10%', justifyContent: 'center' } },
    {
        label: 'DATE',
        fieldToSorteable: 'createdDateFormat',
        isSorteable: true,
        styles: { width: '20%', justifyContent: 'center' },
    },
]

const exportTypeDropdownId = uuid.v4() as string
const detailsReportId = uuid.v4() as string
const resposiveMediumSize = 1100
const resposiveLargeSize = 1400

const ReportDetails = ({ ...props }) => {
    const current = 'ReportDetails'
    const styles = getStyleSheet()
    let initDate = moment().startOf('week').format('YYYY-MM-DD')
    let endDate = moment().add(1, 'days').endOf('week').format('YYYY-MM-DD')
    const { dbUserData, timeFormat } = useUserProvider()
    const { theme, userRole, userSub } = useContext<any>(ThemeContext)
    const [taskDataTable, setTaskDataTable] = useState<any>([])
    const [arrayTasks, setArrayTasks, arrayTasksRef] = useState<any>([])
    const [totalTime, setTotalTime] = useState('')
    const [totalPomodoro, setTotalPomodoro] = useState(0)
    const [loading, setLoading] = useState(true)
    const [filterByProject, setFilterByProject] = useState([])
    const [filterByMember, setFilterByMember] = useState([])
    const [filterByClient, setFilterByClient] = useState([])
    const [filterByTag, setFilterByTag] = useState([])
    const [exportData, setExportData] = useState<any>([])
    const [datesRange, setDatesRange, dateRangeRef] = useState<any[]>([])
    const [datesRangeFilter, setDatesRangeFilter, dateRangeFilterRef] = useState<any[]>([])
    const [dateRange, setDateRange] = useState<{ label: string; dates: Date[] }>(OPTIONS_DATES_RANGE[2])
    const [workSpaceName, setWorkSpaceName] = useState('')
    const [tags, setTags] = useState([])
    const [selectedExportTo, setSelectedExportTo] = useState<{ name: string; code: string } | null>(null)
    const { windowWidth } = useDimensions()
    const csvReport = ReportDetailService().exportDetailCsv(exportData)
    const { closeShowOverlay } = useOverlayProvider()

    const exportPdf = () => {
        ReportDetailService().exportDetailsPdf(exportData, dateRangeRef.current, totalTime, workSpaceName)
    }

    const applyFilters = (filterProjects: any[], filterMembers: any[], filterClients: any[], filterTags: any[]) => {
        let filterList: any[] = []
        let groupedTask: any[] = []
        let filteredArray: any[] = arrayTasksRef.current

        if (filterProjects.length > 0) {
            filterProjects.forEach(project => {
                let find = arrayTasksRef.current.filter(item => item.projectID === project)
                filterList = filterList.concat(find)
            })

            filteredArray = filterProjects.length > 0 ? filterList : arrayTasksRef.current
        }

        if (filterMembers.length > 0) {
            filterList = []
            filterMembers.forEach(member => {
                let find = filteredArray.filter(item => item.userSub === member)
                filterList = filterList.concat(find)
            })

            filteredArray = filterMembers.length > 0 ? filterList : arrayTasksRef.current
        }

        if (filterClients.length > 0) {
            filterList = []
            filterClients.forEach(client => {
                let find = filteredArray.filter(item => item.clientID === client)
                filterList = filterList.concat(find)
            })

            filteredArray = filterClients.length > 0 ? filterList : arrayTasksRef.current
        }

        if (filterTags.length > 0) {
            filterList = []
            filterTags.forEach(tag => {
                let find = filteredArray.filter(item => {
                    for (const element of item.arrayTags) {
                        if (element.code === tag) return true
                    }
                    return false
                })
                filterList = filterList.concat(find)
            })
        }

        if (
            filterProjects.length > 0 ||
            filterMembers.length > 0 ||
            filterClients.length > 0 ||
            filterTags.length > 0
        ) {
            groupedTask = ReportDetailService().groupedTask(filterList, timeFormat)
        } else {
            groupedTask = ReportDetailService().groupedTask(arrayTasksRef.current, timeFormat)
        }
        getDataTableResume(groupedTask)
    }

    const applyFilterDetailReport = () => {
        let beginDate = moment(dateRangeFilterRef.current[0], 'YYYY-MM-DD').format('YYYY-MM-DD')
        let finishDate = moment(dateRangeFilterRef.current[1], 'YYYY-MM-DD').add(1, 'days').format('YYYY-MM-DD')
        let datesRange = [beginDate, finishDate]
        getTaskDetail(datesRange, timeFormat)
    }

    const datesRangeExport = value => {
        let beginDate = moment(value[0], 'YYYY-MM-DD').format('YYYY/MM/DD')
        let finishDate = moment(value[1], 'YYYY-MM-DD').format('YYYY/MM/DD')
        let datesRange = [beginDate, finishDate]
        setDatesRange(datesRange)
    }

    const handleDateRange = value => {
        datesRangeExport(value)
        setDatesRangeFilter(value)
    }

    function handleFilterProject(value) {
        setFilterByProject(value)
    }

    const handleFilterMember = value => {
        setFilterByMember(value)
    }

    const handleFilterClient = value => {
        setFilterByClient(value)
    }

    const handleFilterTag = value => {
        setFilterByTag(value)
    }

    const getDataTableResume = groupedTask => {
        groupedTask.sort((a, b) => {
            return moment(b._lastChangedAt).diff(a._lastChangedAt)
        })
        const resumeDetail = ReportDetailService().resumeReportDetails(groupedTask)
        const exportDataFile = ReportDetailService().transformDataToExport(groupedTask)
        setExportData(exportDataFile)
        setTaskDataTable(groupedTask)
        setTotalPomodoro(resumeDetail.totalPomodoro)
        setTotalTime(resumeDetail.taskTotalTime)
    }

    const getTaskDetail = (datesRange, timeFormat) => {
        setLoading(true)
        ReportDetailService()
            .getTasksReportDetails(datesRange, userSub, timeFormat)
            .then(tasks => {
                setArrayTasks(tasks)
                applyFilters(filterByProject, filterByMember, filterByClient, filterByTag)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    const getDefaultOrganization = () => {
        OrganizationService()
            .getDefaultOrganizationName(userSub)
            .then(result => {
                setWorkSpaceName(result?.organization?.name)
            })
    }

    useFocusEffect(
        useCallback(() => {
            datesRangeExport([moment(), moment()])
            const datesRange = [initDate, endDate]

            if (userSub && userSub !== '' && dbUserData) {
                getDefaultOrganization()
                getTaskDetail(datesRange, timeFormat)
                TagsService()
                    .getAllTags(userSub)
                    .then(data => {
                        setTags(data)
                    })
            }
        }, [userRole, userSub, dbUserData, timeFormat])
    )

    const ExportsList = () => {
        return (
            <>
                <Pressable
                    onPress={() => {
                        closeShowOverlay()
                        exportPdf()
                    }}
                    style={{ padding: 6 }}
                >
                    <Text style={{ color: styles.text }}>PDF</Text>
                </Pressable>
                {Platform.OS === 'web' ? (
                    <CSVLink style={{ padding: 6, textDecoration: 'none' }} {...csvReport}>
                        <Pressable onPress={closeShowOverlay}>
                            <Text style={{ color: styles.text }}>CSV</Text>
                        </Pressable>
                    </CSVLink>
                ) : null}
            </>
        )
    }

    const HeaderDatatable = () => (
        <>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', marginTop: 10, width: '100%' }}>
                <DropdownNative
                    valueWhenUniqueSelection={selectedExportTo ? selectedExportTo.code : ''}
                    options={[]}
                    uniqueSelection
                    indentificator={exportTypeDropdownId}
                    childrenOverlay={<ExportsList />}
                    onChange={() => {}}
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Export"
                    hideSearch
                    containerStyles={{ flexGrow: 0 }}
                    customStyles={{ paddingHorizontal: 10, paddingVertical: 10 }}
                    overlayPanelStyles={{ minWidth: 110 }}
                />

                <ButtonNative
                    title="Filter"
                    primaryTheme
                    titleStyle={{ color: '#FFF' }}
                    containerStyle={{ marginLeft: 20, marginRight: 10 }}
                    buttonStyle={{ paddingVertical: 10 }}
                    onPress={applyFilterDetailReport}
                />
            </View>

            <View
                style={{
                    height: 1,
                    marginTop: 16,
                    backgroundColor: styles.separatorBorder,
                    width: '100%',
                }}
            />
        </>
    )

    return (
        <View style={localStyles.container}>
            <Sidebar children={{ active: current }} />
            <View style={localStyles.subContainer}>
                <ScrollView
                    showsVerticalScrollIndicator={false}
                    style={[styles.styleBackground.container, { paddingBottom: 20 }]}
                    stickyHeaderIndices={[0]}
                >
                    <TopBar children={{ active: current, initial: 1 }} />
                    <View
                        style={[
                            { paddingHorizontal: 30, width: '100%' },
                            windowWidth > resposiveMediumSize ? { alignItems: 'flex-start' } : null,
                        ]}
                    >
                        <View
                            style={[
                                localStyles.filterContainer,
                                windowWidth > resposiveLargeSize
                                    ? { flexDirection: 'row', gap: 26, marginTop: 30 }
                                    : null,
                            ]}
                        >
                            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                                <DatePicker
                                    customStyles={{ flexGrow: windowWidth <= 600 ? 1 : 0 }}
                                    identificator={detailsReportId}
                                    isSelectedByRange
                                    multipleValues={dateRange.dates}
                                    buttonLabel={dateRange.label}
                                    onChangeMultiple={handleDateRange}
                                >
                                    <RenderOptionsDatePicker dateRange={dateRange} setDateRange={setDateRange} />
                                </DatePicker>

                                <View style={{ marginLeft: 20 }}>
                                    <Text style={localStyles.titleResume}>total hours</Text>
                                    <Text style={[styles.styleRow.colorText, localStyles.textTotalResume]}>
                                        {totalTime}
                                    </Text>
                                </View>

                                <View style={{ marginLeft: 25 }}>
                                    <Text style={localStyles.titleResume}>pomodoros</Text>
                                    <View style={[{ flexDirection: 'row', alignItems: 'center', marginTop: 6 }]}>
                                        <Text style={[styles.styleRow.colorText, { fontSize: 18, fontWeight: '500' }]}>
                                            {totalPomodoro}
                                        </Text>
                                        <Image
                                            source={require('../assets/icons/pomodoro.png')}
                                            style={localStyles.iconPomodoro}
                                        />
                                    </View>
                                </View>
                            </View>

                            <View
                                style={[
                                    styles.styleRow.container,
                                    styles.styleBorder.border,
                                    localStyles.filterSubContainer,
                                    windowWidth > resposiveMediumSize ? { flexDirection: 'row' } : null,
                                    windowWidth > resposiveLargeSize ? { width: 'auto', marginTop: 0 } : null,
                                ]}
                            >
                                <Text
                                    style={[
                                        styles.styleRow.colorText,
                                        localStyles.textFilterBy,
                                        { display: windowWidth <= resposiveMediumSize ? 'none' : 'flex' },
                                    ]}
                                >
                                    Filter by:
                                </Text>

                                <MomentaryView customStyles={{ flexDirection: 'row' }} showViewUp={resposiveMediumSize}>
                                    <View
                                        style={[
                                            localStyles.filterColumn,
                                            windowWidth > resposiveMediumSize ? { width: 'auto' } : null,
                                        ]}
                                    >
                                        <Image
                                            resizeMode="contain"
                                            source={require('../assets/icons/ic_team.png')}
                                            style={[localStyles.icon]}
                                        />
                                        <MultiselectMembers
                                            containerStyle={windowWidth > resposiveMediumSize ? { flexGrow: 0 } : null}
                                            onMemberChange={handleFilterMember}
                                            filtering={true}
                                        />
                                    </View>
                                    <View
                                        style={[
                                            localStyles.filterColumn,
                                            windowWidth > resposiveMediumSize ? { width: 'auto' } : null,
                                        ]}
                                    >
                                        <Image
                                            resizeMode="contain"
                                            source={require('../assets/icons/ic_clients.png')}
                                            style={[localStyles.icon]}
                                        />
                                        <MultiselectClients
                                            containerStyle={windowWidth > resposiveMediumSize ? { flexGrow: 0 } : null}
                                            onClientChange={handleFilterClient}
                                        />
                                    </View>
                                </MomentaryView>

                                <MomentaryView customStyles={{ flexDirection: 'row' }} showViewUp={resposiveMediumSize}>
                                    <View
                                        style={[
                                            localStyles.filterColumn,
                                            windowWidth > resposiveMediumSize ? { width: 'auto' } : null,
                                        ]}
                                    >
                                        <Image
                                            resizeMode="contain"
                                            source={require('../assets/icons/ic_projects.png')}
                                            style={[localStyles.icon]}
                                        />
                                        <MultiselectProjects
                                            containerStyle={windowWidth > resposiveMediumSize ? { flexGrow: 0 } : null}
                                            onProjectChange={handleFilterProject}
                                        />
                                    </View>
                                    <View
                                        style={[
                                            localStyles.filterColumn,
                                            windowWidth > resposiveMediumSize ? { width: 'auto' } : null,
                                        ]}
                                    >
                                        <Image
                                            resizeMode="contain"
                                            source={require('../assets/icons/ic_tags.png')}
                                            style={[localStyles.icon]}
                                        />
                                        <MultiselectTagsFilter
                                            containerStyle={windowWidth > resposiveMediumSize ? { flexGrow: 0 } : null}
                                            allTags={tags}
                                            onChangeTag={handleFilterTag}
                                        />
                                    </View>
                                </MomentaryView>
                            </View>
                        </View>

                        <HeaderDatatable />

                        {loading && !taskDataTable.length ? (
                            <SkeletonTable rowsQuantity={8} />
                        ) : (
                            <DataTable
                                headerColumns={headerColumns as any}
                                itemsList={taskDataTable}
                                setItemsList={setTaskDataTable}
                                emptyMessage="No tasks"
                                headerCustomStyles={{
                                    display: windowWidth > 1000 ? 'flex' : 'none',
                                    paddingVertical: 14,
                                    paddingHorizontal: 20,
                                }}
                                isShowEmptyMessage={!taskDataTable.length}
                                customStyles={{ width: '100%', marginTop: windowWidth <= resposiveLargeSize ? 15 : 30 }}
                            >
                                {taskDataTable.map((reportDetails: any) => (
                                    <ReportDetailsContentRender
                                        loading={loading}
                                        reportDetails={reportDetails}
                                        tags={tags}
                                    />
                                ))}
                            </DataTable>
                        )}
                    </View>
                </ScrollView>
            </View>
        </View>
    )
}

const ReportDetailsContentRender = ({
    reportDetails,
    loading,
    tags,
}: {
    reportDetails: any
    loading: boolean
    tags: any
}) => {
    const [isRowExpand, setIsRowExpand] = useState(false)
    const toggleRowExpand = () => setIsRowExpand(!isRowExpand)

    return (
        <View style={{ gap: 10 }}>
            <RowReportDetails
                key={reportDetails.id}
                isRowExpand={isRowExpand}
                toggleRowExpand={toggleRowExpand}
                isLoading={loading}
                reportDetails={reportDetails}
                tags={tags}
                hasChildrenTask={reportDetails.tasks.length}
            />

            {reportDetails.tasks.length ? (
                <View style={{ display: isRowExpand ? 'flex' : 'none', gap: 6 }}>
                    {reportDetails.tasks.map((task: any) => (
                        <RowReportDetails
                            key={task.id}
                            isChildRow
                            isRowExpand={isRowExpand}
                            toggleRowExpand={toggleRowExpand}
                            isLoading={loading}
                            reportDetails={task}
                            tags={tags}
                            hasChildrenTask={false}
                        />
                    ))}
                </View>
            ) : null}
        </View>
    )
}

export default ReportDetails

const localStyles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: 'row',
    },
    subContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    filterContainer: {
        marginTop: 30,
    },
    titleResume: {
        marginTop: 2,
        color: '#818A94',
        textTransform: 'uppercase',
        fontSize: 10,
    },
    textTotalResume: {
        marginTop: 6,
        fontSize: 18,
        fontWeight: '500',
    },
    iconPomodoro: {
        width: 22,
        height: 22,
        marginLeft: 8,
        marginTop: -2,
    },
    filterSubContainer: {
        marginTop: 8,
        width: '100%',
        borderRadius: 6,
        borderWidth: 1,
        padding: 6,
    },
    textFilterBy: {
        marginVertical: 'auto',
        marginLeft: 18,
        width: 65,
    },
    containerOptionDatePicker: {
        flexDirection: 'column',
        columnGap: 10,
        rowGap: 18,
        borderWidth: 1,
        padding: 10,
    },
    filterColumn: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '50%',
        gap: 5,
    },
    icon: {
        width: 18,
        height: 18,
        marginLeft: 17,
    },
})
