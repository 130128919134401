import { View, Text, StyleSheet } from 'react-native'
import ButtonNative from './ButtonNative'
import { useNavigation } from '@react-navigation/native'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { outlineStyle } from '../../helpers/utils'
import { faWarning } from '@fortawesome/free-solid-svg-icons'
import useDimensions from '../../hooks/useDimensions'

const Banner = () => {
    const navigation = useNavigation()
    const { windowWidth } = useDimensions()

    return (
        <View
            style={[
                {
                    flexDirection: windowWidth <= 1000 ? 'column' : 'row',
                    padding: windowWidth <= 500 ? 10 : 15,
                },
                localStyles.subContainer,
            ]}
        >
            <View
                style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 10,
                    width: windowWidth <= 500 ? '90%' : 'auto',
                }}
            >
                <FontAwesomeIcon
                    size={20}
                    color="#e12a02"
                    style={[outlineStyle as any, { minWidth: 20 }]}
                    icon={faWarning}
                />
                <Text style={localStyles.message}>
                    Your payment didn't go through. Please try a different payment method.
                </Text>
            </View>

            <View style={{ flexDirection: 'row', gap: 18, alignItems: 'center' }}>
                <View style={[{ display: windowWidth <= 1000 ? 'none' : 'flex' }, localStyles.buttonSubContainer]} />
                <ButtonNative
                    onPress={() => {
                        //@ts-ignore
                        navigation.navigate('Checkout', { isUpdatePayment: 1 } as never)
                    }}
                    title="Update"
                    primaryTheme
                />
            </View>
        </View>
    )
}

const localStyles = StyleSheet.create({
    subContainer: {
        backgroundColor: '#fce9e5',
        borderColor: '#e12a02',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: 1,
        gap: 20,
        shadowColor: '#fce9e5',
        elevation: 5,
        shadowRadius: 8,
        shadowOffset: {
            width: 0,
            height: 2,
        },
    },
    message: {
        color: '#e12a02',
        fontSize: 16,
        fontWeight: '700',
    },
    buttonSubContainer: {
        opacity: 0.5,
        backgroundColor: '#e12a02',
        width: 1,
        height: 28,
    },
})

export default Banner
