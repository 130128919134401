import React, { useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import useCreateClientBar, { Props } from './useCreateClientBar'
import MultiselectTags from '../MultiselectTags/MultiselectTags'
import ButtonNative from '../ButtonNative'
import InputText from '../InputText'
import { View } from 'react-native'
import { getStyleSheet } from '../../../assets/theme/styles'
import { MomentaryView } from '../DataTable'
import useDimensions from '../../../hooks/useDimensions'

const CreateClientBar: React.FC<Props> = props => {
    const [
        {
            clientNameRef,
            name,
            allTags,
            tagsSelected,
            showUpgradeButton,
            setName,
            onTagsChange,
            onHandleCreate,
            handleKeyDownCreateClient,
        },
    ] = useCreateClientBar(props)

    const { windowWidth } = useDimensions()
    const navigation = useNavigation()
    const styles = getStyleSheet()
    const [barWidth, setBarWidth] = useState(0)

    const leftContents = () => {
        return (
            <View
                style={{
                    gap: 10,
                    flexDirection: windowWidth <= 600 ? 'column' : 'row',
                    alignItems: 'center',
                    paddingVertical: 10,
                    paddingHorizontal: 20,
                }}
            >
                <InputText
                    containerStyle={{ flexGrow: 1, width: windowWidth <= 600 ? '100%' : 'auto' }}
                    placeholder="Client"
                    ref={clientNameRef}
                    value={name}
                    onChange={e => setName(e.valueOf())}
                    onKeyPress={handleKeyDownCreateClient}
                    disabled={showUpgradeButton}
                />

                <MomentaryView
                    customStyles={{ flexDirection: 'row', alignItems: 'center', width: '100%' }}
                    showViewUp={600}
                >
                    <MultiselectTags
                        customStyles={{ width: windowWidth <= 600 ? '70%' : '20%', paddingRight: 10 }}
                        indentificator="tagDropdownBarClient"
                        tags={allTags}
                        value={tagsSelected}
                        onChangeTag={async value => onTagsChange(value)}
                        disabled={showUpgradeButton}
                    />

                    {showUpgradeButton ? (
                        <ButtonNative
                            title="Upgrade"
                            buttonStyle={{ backgroundColor: '#3B82f6' }}
                            primaryTheme
                            onPress={() => navigation.navigate('Upgrade' as never)}
                        />
                    ) : (
                        <ButtonNative
                            containerStyle={[
                                { minWidth: 'auto', flexGrow: 1 },
                                windowWidth > 600 ? { minWidth: 100, flexGrow: 0 } : null,
                            ]}
                            primaryTheme
                            title="Create"
                            onPress={onHandleCreate}
                        />
                    )}
                </MomentaryView>
            </View>
        )
    }

    const leftContentsUpgrade = () => {
        return (
            <View style={{ alignItems: 'flex-end', paddingVertical: 10, paddingHorizontal: 20 }}>
                <ButtonNative
                    primaryTheme
                    buttonStyle={{ backgroundColor: '#3B82f6' }}
                    title="Upgrade"
                    onPress={() => navigation.navigate('Upgrade' as never)}
                />
            </View>
        )
    }

    return (
        <View
            style={[styles.stylesBar.boxShadow, { backgroundColor: styles.backgroundColorRow, borderRadius: 6 }]}
            onLayout={({ nativeEvent: { layout } }) => setBarWidth(layout.width)}
        >
            <View style={{ width: barWidth, opacity: barWidth ? 1 : 0 }}>
                {showUpgradeButton ? leftContentsUpgrade() : leftContents()}
            </View>
        </View>
    )
}

export default CreateClientBar
