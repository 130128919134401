import { StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { useState, useEffect } from 'react'
import ColorPicker from 'react-native-wheel-color-picker'
import { useOverlayProvider } from '../../context/OverlayProvider'
import { getStyleSheet } from '../../assets/theme/styles'
import { faPaintBrush } from '@fortawesome/free-solid-svg-icons'
import DropdownNative from './MultiSelectNative/MultiSelect'
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome'
import { outlineStyle } from '../../helpers/utils'
import ItemSkeleton from './ItemSkeleton'

interface IColorPickerNative {
    identificator: string
    value: string
    customStyles?: StyleProp<ViewStyle>
    skeletonStyles?: StyleProp<ViewStyle>
    loading?: boolean
    onChange: (color: string) => void
}

const ColorPickerNative = ({
    identificator,
    value,
    onChange,
    customStyles,
    loading,
    skeletonStyles,
}: IColorPickerNative) => {
    const { activeId, isShowOverlay } = useOverlayProvider()
    const styles = getStyleSheet()
    const [color, setColor] = useState('')

    useEffect(() => {
        // Evento que se ejecuta cada vez que se cierra el color picker
        if (activeId === identificator && !isShowOverlay) {
            onChange(color)
        }
    }, [isShowOverlay, activeId])

    if (loading) return <ItemSkeleton customStyles={skeletonStyles} />

    return (
        <DropdownNative
            indentificator={identificator}
            isShowDownButton={false}
            onChange={() => {}}
            optionLabel=""
            optionValue=""
            options={[]}
            containerStyles={[{ flexGrow: 0 }, customStyles]}
            buttonTitleStyle={{ color: '#FFF' }}
            customStyles={{
                paddingHorizontal: 10,
                paddingVertical: 8,
                flexDirection: 'column',
                justifyContent: 'center',
                width: 36,
                backgroundColor: styles.brushIconBackground,
                borderRadius: 100,
                borderWidth: 2,
                borderColor: 'transparent',
            }}
            placeholder=""
            valueTemplate={<FontAwesomeIcon style={[outlineStyle as any]} color={`#${value}`} icon={faPaintBrush} />}
        >
            <View
                style={[
                    {
                        backgroundColor: styles.backgroundColorRow,
                    },
                    localStyles.subContainer,
                ]}
            >
                <ColorPicker
                    onColorChangeComplete={setColor}
                    shadeWheelThumb
                    color={`#${value}`}
                    gapSize={0}
                    thumbSize={50}
                    palette={[]}
                />
            </View>
        </DropdownNative>
    )
}

const localStyles = StyleSheet.create({
    subContainer: {
        width: 180,
        shadowColor: 'rgba(0, 0, 0, 0.4)',
        shadowOffset: {
            width: 0,
            height: 0,
        },
        shadowRadius: 15,
        elevation: 5,
        paddingHorizontal: 16,
        borderRadius: 10,
    },
})

export default ColorPickerNative
