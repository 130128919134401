import React, { useState, useEffect, useContext } from 'react'
import { ThemeContext } from '../assets/theme/ThemeProvider'
import { OrganizationService } from '../services/OrganizationService'
import { ProjectService } from '../services/ProjectService'
import { ClientService } from '../services/ClientService'
import { GroupService } from '../services/GroupService'

const UpgradeContext = React.createContext<any>({})

const UpgradeProvider = ({ children }) => {
    const { userSub } = useContext<any>(ThemeContext)
    const [maxProjectsNumber, setMaxProjectsNumber] = useState(0)
    const [createdProjectsNumber, setCreatedProjectsNumber] = useState(0)
    const [createdClientsNumber, setCreatedClientsNumber] = useState(0)
    const [createdGroupsNumber, setCreatedGroupsNumber] = useState(0)
    const [upgradePlan, setUpgradePlan] = useState(false)
    const [maxUsersNumber, setMaxUsersNumber] = useState(0)
    const [maxClientsNumber, setMaxClientsNumber] = useState(0)
    const [maxGroupsNumber, setMaxGroupsNumber] = useState(null)

    useEffect(() => {
        getMaxProjectsMaxUsers()
        getNumberCreatedProjects()
        getNumberCreatedGroups()
        if (userSub) {
            getNumberCreatedClients(userSub)
        }
    }, [userSub])

    const getMaxProjectsMaxUsers = () => {
        OrganizationService()
            .getOrganizationById()
            .then((response: any) => {
                setMaxProjectsNumber(response?.data?.getOrganization?.plan?.max_projects)
                setMaxUsersNumber(response?.data?.getOrganization?.plan?.max_users)
                setMaxClientsNumber(response?.data?.getOrganization?.plan?.max_clients)
                setMaxGroupsNumber(response?.data?.getOrganization?.plan?.max_groups)
            })
    }

    const getNumberCreatedProjects = () => {
        ProjectService()
            .getAllActiveProjectsByOrganization()
            .then(response => {
                setCreatedProjectsNumber(response.length)
            })
    }

    const getNumberCreatedGroups = () => {
        GroupService()
            .getAllGroupsByOrganization()
            .then(response => {
                setCreatedGroupsNumber(response.length)
            })
    }

    const getNumberCreatedClients = (userSub: string) => {
        ClientService()
            .getClientsActive(userSub)
            .then(response => {
                setCreatedClientsNumber(response.length)
            })
    }

    return (
        <UpgradeContext.Provider
            value={{
                upgradePlan,
                maxProjectsNumber,
                maxUsersNumber,
                maxClientsNumber,
                maxGroupsNumber,
                createdProjectsNumber,
                createdClientsNumber,
                createdGroupsNumber,
                setCreatedProjectsNumber,
                setCreatedGroupsNumber,
                setCreatedClientsNumber,
                setMaxUsersNumber,
                setMaxProjectsNumber,
                setMaxClientsNumber,
                setMaxGroupsNumber,
            }}
        >
            {children}
        </UpgradeContext.Provider>
    )
}

export { UpgradeProvider, UpgradeContext }
